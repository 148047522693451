import React, { useContext } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ConfigContext } from '../../../../context/ConfigContext';
import { CustomPagesList } from './CustomPagesList';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { CollapsibleMenuCard } from '../../../../components/AppContainer/CollapsibleMenuCard';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { CreateNewCustomPageForm } from 'components/FormDialog/CreateNewCustomPage';
import { useCustomPageAPI } from '../../../../context/fakeAPIHooks/useCustomPageAPI';

export const CustomPagesMenu: React.FC = () => {
  const { getCustomPages } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const CustomPageAPI = useCustomPageAPI();

  const handleAddCustomPage = async () => {
    const {pageName, page} = await formDialog<typeof CreateNewCustomPageForm>((props) => (
      <CreateNewCustomPageForm {...props} />
    ));
    if (!isValid(pageName)) return;
    const { error } = await CustomPageAPI.addPage(pageName, page);
    if (error) return;
    successMsg(`The custom page "${pageName}" has been successfully added`);
  };

  const isValid = (pageName: string) => {
    if (ifCustomPageExists(pageName)) {
      errorMsg(`An custom page named "${pageName}" already exists`);
      return false;
    }
    return true;
  };

  const ifCustomPageExists = (pageName: string) => getCustomPages()[pageName];

  return (
    <CollapsibleMenuCard title="Custom Pages">
      <CustomPagesList />
      <ButtonsContainer>
        <IconButton
          style={{ color: '#1e88e5' }}
          component="span"
          onClick={() => handleAddCustomPage()}
        >
          <AddIcon />
        </IconButton>
      </ButtonsContainer>
    </CollapsibleMenuCard>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;
