import React, { useState, useContext, useCallback } from 'react';
import { DialogTitle, DialogActions, Button } from '@material-ui/core';
import { TextInput, InputGroup, CheckboxInput, MapperList } from '../FormElements';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import {
  V2TextTemplateComponent,
  V2NumberTemplateComponent,
  V2BarcodeComponent,
  V2LocationComponent,
  V2PolylineComponent,
} from '@terragotech/form-renderer';
import DataMapperDialog from './DataMapperDialog';
import { useConfirm } from 'material-ui-confirm';
import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { isEqual } from 'lodash';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { useFormSchemas } from './hooks/useFormSchemas';
import { FormContext } from './contexts/FormContext';
import {
  checkDuplicateFormName,
  DUPLICATE_NAME_ERROR_MESSAGE,
} from '../../pages/aggregates/utils/formUtils';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import MultiplePoints from '../MultiplePoints';
import _ from 'lodash';
import { getPropertyObjectFromFormComponent } from '../../pages/aggregates/utils/V2FormTemplateToJsonSchema';

export type TextTemplateWithName =
  | (V2TextTemplateComponent & {
      name: string;
      droppableId: string;
    })
  | (V2NumberTemplateComponent & {
      name: string;
      droppableId: string;
    })
  | (V2BarcodeComponent & {
      name: string;
      droppableId: string;
    })
  | (V2LocationComponent & {
      name: string;
      droppableId: string;
    })
  | (V2PolylineComponent & {
      name: string;
      droppableId: string;
    });

interface TextInputEditFormProps {
  onSubmit: (result: TextTemplateWithName) => void;
  onClose: () => void;
  component: TextTemplateWithName;
  autosuggest?: boolean;
  computed?: boolean;
  isImportCommand?: boolean;
}

export const expandPath = (
  parts: string[],
  properties: JSONSchema6
): Array<{ [x: string]: JSONSchema6Definition | JSONSchema6Definition[] | undefined }> => {
  return parts.map((part, idx) => {
    let target = properties as JSONSchema6;
    let name = '';
    for (let i = 0; i < idx; i++) {
      target = _.get(target, parts[i]);
      name += parts[i].replace(/\[\d+\]/, '') + '.';
    }
    target = target ? _.get(target, part) : undefined;
    name += part.replace(/\[\d+\]/, '');
    if (target.type === 'array') {
      return { [name]: target.items };
    }
    return { [name]: target };
  });
};

export const TextInputEditForm: React.FC<TextInputEditFormProps> = ({
  onSubmit,
  onClose,
  component,
  autosuggest,
  computed,
  isImportCommand,
}) => {
  const confirm = useConfirm();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [required, setRequired] = useState(component.required);
  const [placeholder, setPlaceholder] = useState(component.placeholder);
  const [description, setDescription] = useState(component.description);
  const [info, setInfo] = useState(component.info);
  const [readOnly, setReadOnly] = useState(computed || component.readOnly);
  const [disableAutoSuggest, setDisableAutoSuggest] = useState(
    (component as V2TextTemplateComponent).disableAutoSuggest
  );
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const [multiplePoints, setMultiplePoints] = useState(
    (component as V2LocationComponent).multiplePoints || undefined
  );
  const [errorMap, setErrorMap] = useState<NodeMapDefinition[] | undefined>(
    component.errorMap || undefined
  );
  const [warningMap, setWarningMap] = useState<NodeMapDefinition[] | undefined>(
    component.warningMap || undefined
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);

  const [computedMap, setComputedMap] = useState(() => {
    const map = (component as V2TextTemplateComponent).computedMap || undefined;
    if (map && map.nodes && map.nodes['OUTPUT']) {
      map.nodes['OUTPUT'].config = { objectSchema: 'COMPUTED' };
    }
    return map;
  });
  const [computedOpen, setComputedOpen] = useState(false);

  const formSchemas = useFormSchemas();

  const [existingNameError, setExistingNameError] = useState(false);
  const { formDefinition } = useContext(FormContext);

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'form'
      ? []
      : expandPath(parts, formSchemas.FORM.schema.properties as JSONSchema6);

  let formValue = { value: getPropertyObjectFromFormComponent(component) };
  for (let i = 0; i < t.length; i++) {
    formValue = { ...formValue, ...t[i] };
  }

  const groupSchema = formSchemas.FORM.schema?.properties?.[component.droppableId] as JSONSchema6;

  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: formValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...formSchemas,
  };
  const handleClearConditionalMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setConditionalMap(undefined);
  };
  const handleClearComputedMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setComputedMap(undefined);
  };

  const getFormValues = () => ({
    type: component.type,
    name,
    label,
    ...(placeholder && { placeholder }),
    ...(required !== undefined && { required }),
    ...(description && { description }),
    ...(info && { info }),
    ...(readOnly !== undefined && { readOnly }),
    ...(disableAutoSuggest !== undefined && { disableAutoSuggest }),
    repeats,
    multiplePoints,
    ...(conditionalMap && { conditionalMap }),
    ...(computedMap && { computedMap }),
    ...(errorMap && { errorMap }),
    ...(warningMap && { warningMap }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The form has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    if (checkDuplicateFormName(name, component, formDefinition.components)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  const doesComputedHaveValue = useCallback(() => {
    return !_.isEmpty(computedMap);
  }, [computedMap]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <TextInput
        autoFocus
        id="Name"
        error={existingNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <CheckboxInput
        title="Required"
        checked={required}
        onChange={(value) => setRequired(value)}
        style={{ margin: '10px 25px' }}
      />
      <TextInput
        id="Placeholder"
        value={placeholder}
        onChange={(value) => setPlaceholder(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Description"
        value={description}
        onChange={(value) => setDescription(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Info"
        value={info}
        onChange={(value) => setInfo(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      {!computed && (
        <CheckboxInput
          title="Read-only"
          checked={readOnly}
          onChange={(value) => setReadOnly(value)}
          style={{ margin: '10px 25px' }}
        />
      )}
      {autosuggest && (
        <CheckboxInput
          title="Disable Autosuggest"
          checked={disableAutoSuggest}
          onChange={(value) => setDisableAutoSuggest(value)}
          style={{ margin: '0 25px 10px' }}
        />
      )}
      {computed && (
        <InputGroup title="Calculation" style={{ margin: '10px 25px' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button onClick={() => setComputedOpen(true)}>
              <div>
                Advanced
                <MappingActiveDisplay
                  isActive={doesComputedHaveValue()}
                  activeLabelText={'active'}
                />
              </div>
            </Button>
            <Button onClick={handleClearComputedMapper}>Clear</Button>
          </div>
          <DataMapperDialog
            mapScenario="COMPUTED_MAPPING"
            localSchemaDefinitions={{
              ...errorWarningSchemas,
              COMPUTED: {
                schemaLabel: 'Result',
                schema: {
                  type: 'object',
                  properties: {
                    result: (groupSchema?.properties || formSchemas.FORM.schema?.properties || {})[
                      component.name
                    ] as JSONSchema6Definition,
                  },
                },
              },
            }}
            onClose={() => {
              setComputedOpen(false);
            }}
            open={computedOpen}
            datamap={computedMap}
            setDatamap={setComputedMap}
          />
        </InputGroup>
      )}
      {!isImportCommand && (
        <RepeatableComponent
          repeats={repeats}
          setRepeats={setRepeats}
          disabledSwitch={multiplePoints?.enabled}
        />
      )}
      {component.type === 'location' && (
        <MultiplePoints
          multiplePoints={multiplePoints}
          setMultiplePoints={setMultiplePoints}
          disabledSwitch={repeats?.enabled}
        />
      )}
      <InputGroup title="Conditionals" style={{ margin: '10px 25px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button onClick={() => setConditionalOpen(true)}>
            <div>
              Advanced
              <MappingActiveDisplay
                isActive={doesConditionalHaveValue()}
                activeLabelText={'active'}
              />
            </div>
          </Button>
          <Button onClick={handleClearConditionalMapper}>Clear</Button>
        </div>
        <DataMapperDialog
          mapScenario="FORM_FIELD_CONDITIONAL"
          localSchemaDefinitions={errorWarningSchemas}
          onClose={() => {
            setConditionalOpen(false);
          }}
          open={conditionalOpen}
          datamap={conditionalMap}
          setDatamap={setConditionalMap}
        />
      </InputGroup>
      <MapperList
        title="Error Mapping"
        data={errorMap || []}
        localSchemas={errorWarningSchemas}
        setData={(data) => setErrorMap(data)}
        mapScenario={'FIELD_LEVEL_ERROR'}
      />
      <MapperList
        title="Warning Mapping"
        data={warningMap || []}
        localSchemas={errorWarningSchemas}
        setData={(data) => setWarningMap(data)}
        mapScenario={'FIELD_LEVEL_WARNING'}
      />
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
