import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { V2TextHeaderComponent } from '@terragotech/form-renderer';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame } from './Common';

export const HeaderText = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 2px;
  color: #333333;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const TextHeader: FunctionComponent<NestedFormControlProps<V2TextHeaderComponent>> = (props) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <HeaderText>{controlDefinition.label}</HeaderText>
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default TextHeader;
