import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { General } from './General';
import { Geographic } from './subpages/Geographic';
import { LegalDisclaimer } from './subpages/LegalDisclaimer';
import { Integrations } from './Integrations';
import { NearMaps } from './subpages/NearMaps';

export const Features: React.FC = () => (
  <FlexContainer>
    <Route path="/features/general" component={General} />
    <Route path="/features/general/legalDisclaimer" component={LegalDisclaimer} />
    <Route path="/features/general/geographic" component={Geographic} />
    <Route path="/features/integrations" component={Integrations} />
    <Route path="/features/integrations/mapOptions" component={NearMaps} />
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
