import { useContext } from 'react';
import { ConfigContext } from '../ConfigContext';
import { generateCustomPage } from '../../utils/jsonPartsGenerators';
import { errorMsg } from '../../components/SnackbarUtilsConfigurator';
import { PagesConfig } from '@terragotech/gen5-config-lib';
import { V2PageTemplate } from '@terragotech/page-renderer';

export const useCustomPageAPI = () => {
  const {
    getCustomPages,
    setCustomPages,
    getCustomPage,
    setCustomPage,
  } = useContext(ConfigContext);

  const promisify = <T extends Function>(
    child: T
  ): Promise<{ error: Error | null; data: T | null }> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ error: null, data: child() });
      } catch (e) {
        if (e instanceof Error) {
          errorMsg(e.message);
        }
        reject({ error: e as Error, data: null });
      }
    });
  };

  const addPage = (name: string, init?: Partial<V2PageTemplate>) =>
    promisify(() => {
      const pages = getCustomPages();
      pages[name] = generateCustomPage(init);
      setCustomPages(pages);
    });

  const deletePage = (name: string) =>
    promisify(() => {
      const pages = getCustomPages();
      delete pages[name];
      setCustomPages(pages);
    });

  const updatePage = (name: string, page: PagesConfig[keyof PagesConfig]) =>
    promisify(() => setCustomPage(name, page));
  
  const getPage = (name: string) =>
    promisify(() => getCustomPage(name));

  return {
    addPage,
    deletePage,
    getPage,
    updatePage,
  };
};
