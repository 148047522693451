import {
  Gen5Config,
  CommandAction,
  MobileActionBuiltIns,
  BuiltinWebActions,
  WebActionButton,
  MobileActionButton,
} from '@terragotech/gen5-config-lib';
import { ActionButtonType } from '../components/ActionButtonEditor/ActionButtonEditor';
import { UIConfigType, ActionButtons } from '../utils/types';

export const extractActionButtons = (
  config: Gen5Config,
  uiConfigType: UIConfigType,
  aggrUIName: string,
  actionButtonType: ActionButtonType
) => {
  if (uiConfigType === 'mobileUIConfig') {
    if (actionButtonType === 'editorWorkflows')
      return config.mobileUIConfig.aggregateUICustomizations?.[aggrUIName]?.editorWorkflows;
    if (actionButtonType === 'multiSelectActions')
      return config.mobileUIConfig.aggregateUICustomizations?.[aggrUIName]?.multiSelectActions;
    if (actionButtonType === 'editorActions')
      return config.mobileUIConfig.aggregateUICustomizations?.[aggrUIName]?.editorActions;
  }
  if (uiConfigType === 'webUIConfig') {
    if (actionButtonType === 'editorWorkflows')
      return config.webUIConfig.aggregateUICustomizations?.[aggrUIName]?.editorWorkflows;
    if (actionButtonType === 'multiSelectActions')
      return config.webUIConfig.aggregateUICustomizations?.[aggrUIName]?.multiSelectActions;
    if (actionButtonType === 'tableActions')
      return config.webUIConfig.aggregateUICustomizations?.[aggrUIName]?.tableActions;
  }
};

export const setExtractedActionButtons = (
  config: Gen5Config,
  uiConfigType: UIConfigType,
  aggrUIName: string,
  actionButtonType: ActionButtonType,
  actionButton: ActionButtons
) => {
  const mobileAggrUI = config.mobileUIConfig.aggregateUICustomizations;
  const webAggrUI = config.webUIConfig.aggregateUICustomizations;
  if (uiConfigType === 'mobileUIConfig' && mobileAggrUI && mobileAggrUI[aggrUIName]) {
    if (actionButtonType === 'editorWorkflows')
      mobileAggrUI[aggrUIName].editorWorkflows = actionButton as Array<
        CommandAction<MobileActionBuiltIns>
      >;
    if (actionButtonType === 'multiSelectActions')
      mobileAggrUI[aggrUIName].multiSelectActions = actionButton as MobileActionButton[];
    if (actionButtonType === 'editorActions')
      mobileAggrUI[aggrUIName].editorActions = actionButton as MobileActionButton[];
  }
  if (uiConfigType === 'webUIConfig' && webAggrUI && webAggrUI[aggrUIName]) {
    if (actionButtonType === 'editorWorkflows')
      webAggrUI[aggrUIName].editorWorkflows = actionButton as Array<
        CommandAction<BuiltinWebActions>
      >;
    if (actionButtonType === 'multiSelectActions')
      webAggrUI[aggrUIName].multiSelectActions = actionButton as WebActionButton[];
    if (actionButtonType === 'tableActions')
      webAggrUI[aggrUIName].tableActions = actionButton as WebActionButton[];
  }
  return config;
};
