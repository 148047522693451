import React, { useState, useEffect, useCallback } from 'react';
import { V2FormTemplate, V2GroupComponentDef } from '@terragotech/form-renderer';
import { makeStyles, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import UndoIcon from '@material-ui/icons/Undo';
import { blue } from '@material-ui/core/colors';
import { successMsg, warningMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { cloneDeep, isEqual } from 'lodash';
import styled from 'styled-components';

interface FormEditorCopyMenuProps {
  formDefinition: V2FormTemplate;
  setFormDefinition: (val: V2FormTemplate) => void;
  selectedItems: V2FormTemplate;
  lastPastedFormTemplate: V2FormTemplate | null;
  setLastPastedFormTemplate: (lastPastedFormTemplate: V2FormTemplate | null) => void;
}

export const FormEditorCopyMenu: React.FC<FormEditorCopyMenuProps> = ({
  formDefinition,
  setFormDefinition,
  selectedItems,
  lastPastedFormTemplate,
  setLastPastedFormTemplate,
}) => {
  const [formTemplateBeforePaste, setFormTemplateBeforePaste] = useState<V2FormTemplate | null>(
    null
  );
  const [isPasteVisible, setIsPasteButtonVisible] = useState<boolean | undefined>(false);
  const classes = useStyles();

  const checkIfClipboardValid = useCallback((clipboardString: string) => {
    try {
      const clipboard: unknown = JSON.parse(clipboardString);
      if (
        clipboard &&
        typeof clipboard === 'object' &&
        clipboard.hasOwnProperty('components') &&
        clipboard.hasOwnProperty('order')
      ) {
        return true;
      }
    } catch (error) {
      console.log('Error', error);
      return false;
    }
  }, []);

  const handleIsPasteVisible = useCallback(async () => {
    const pastedFormTemplate = await navigator.clipboard.readText();
    setIsPasteButtonVisible(checkIfClipboardValid(pastedFormTemplate));
  }, [checkIfClipboardValid]);

  useEffect(() => {
    handleIsPasteVisible();
  }, [handleIsPasteVisible]);

  const handleCopyFormItems = useCallback(() => {
    const anyFormItemSelected = Object.keys(selectedItems.components).length > 0;

    if (anyFormItemSelected) {
      navigator.clipboard.writeText(JSON.stringify(selectedItems));
      handleIsPasteVisible();
      if (getItemsCount(selectedItems) === 1) {
        successMsg('Selected item have been copied to the clipboard');
      } else {
        successMsg(
          `${getItemsCount(selectedItems)} selected items have been copied to the clipboard`
        );
      }
    } else {
      warningMsg('No selected items to copy');
    }
  }, [selectedItems, handleIsPasteVisible]);

  const getItemsCount = (formTemplate: V2FormTemplate) => {
    let count = 0;
    Object.entries(formTemplate.components).forEach(([_, component]) => {
      count++;
      if (component.type === 'group') {
        Object.keys(component.template.components).forEach(() => {
          count++;
        });
        if (Object.keys(component.template.components).length > 0) count--;
      }
    });
    return count;
  };

  const merge = useCallback(
    (sourceFormTemplate: V2FormTemplate, mergedFormTemplate: V2FormTemplate) => {
      const sourceFormTemplateCopy = cloneDeep(sourceFormTemplate);
      Object.entries(mergedFormTemplate.components).forEach(([componentName, component]) => {
        if (component.type === 'group' && sourceFormTemplate.order.includes(componentName)) {
          (sourceFormTemplateCopy.components[
            componentName
          ] as V2GroupComponentDef).template = merge(
            (sourceFormTemplate.components[componentName] as V2GroupComponentDef).template,
            component.template
          );
        } else {
          sourceFormTemplateCopy.components[componentName] = component;
        }
        const newOrder = [
          ...new Set([...sourceFormTemplateCopy.order, ...mergedFormTemplate.order].reverse()),
        ].reverse();
        sourceFormTemplateCopy.order = newOrder;
      });
      return sourceFormTemplateCopy;
    },
    []
  );

  const handlePasteFormItems = useCallback(async () => {
    const pastedFormTemplate = await navigator.clipboard.readText();
    if (checkIfClipboardValid(pastedFormTemplate)) {
      const parsedPastedFormTemplate: V2FormTemplate = JSON.parse(pastedFormTemplate);
      if (isEqual(lastPastedFormTemplate, parsedPastedFormTemplate))
        return warningMsg('Form Template already pasted.');
      if (Object.keys(parsedPastedFormTemplate.components).length > 0) {
        setLastPastedFormTemplate(cloneDeep(parsedPastedFormTemplate));
        setFormTemplateBeforePaste(cloneDeep(formDefinition));
        const mergedFormTemplate = merge(formDefinition, parsedPastedFormTemplate);
        setFormDefinition(mergedFormTemplate);
        if (getItemsCount(parsedPastedFormTemplate) === 1) {
          successMsg('Item has been pasted successfully');
        } else {
          successMsg(
            `${getItemsCount(
              parsedPastedFormTemplate
            )} selected items have been pasted successfully`
          );
        }
      } else {
        warningMsg('No items to paste');
      }
    } else {
      errorMsg('Invalid clipboard content, cannot be pasted');
    }
  }, [
    checkIfClipboardValid,
    formDefinition,
    lastPastedFormTemplate,
    merge,
    setFormDefinition,
    setLastPastedFormTemplate,
  ]);

  const keydownHandler = useCallback(
    async (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
        handleCopyFormItems();
      }
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) {
        handlePasteFormItems();
      }
    },
    [handleCopyFormItems, handlePasteFormItems]
  );

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [keydownHandler]);

  const handlePasteUndo = () => {
    if (formTemplateBeforePaste) {
      setFormDefinition(formTemplateBeforePaste);
      setLastPastedFormTemplate(null);
      setFormTemplateBeforePaste(null);
      successMsg('Pasted form items successfully rolled back');
    }
  };

  return formTemplateBeforePaste || selectedItems.order.length > 0 || isPasteVisible ? (
    <>
      <ClipBoardHeader>Copy menu</ClipBoardHeader>
      <ClipBoardBody>
        {formTemplateBeforePaste && (
          <IconButton
            onClick={handlePasteUndo}
            title="Undo"
            classes={{ label: classes.iconButton }}
          >
            <UndoIcon style={{ color: blue[300] }} />
            <p style={{ fontSize: 12, margin: '4px 0 0 0' }}>Undo</p>
          </IconButton>
        )}
        {selectedItems.order.length > 0 && (
          <IconButton
            onClick={handleCopyFormItems}
            title="Copy"
            classes={{ label: classes.iconButton }}
          >
            <FileCopyIcon style={{ color: blue[300] }} />
            <p style={{ fontSize: 12, margin: '4px 0 0 0' }}>Copy</p>
          </IconButton>
        )}
        {isPasteVisible && (
          <IconButton
            onClick={handlePasteFormItems}
            title="Paste"
            classes={{ label: classes.iconButton }}
          >
            <AssignmentReturnedIcon style={{ color: blue[300] }} />
            <p style={{ fontSize: 12, margin: '4px 0 0 0' }}>Paste</p>
          </IconButton>
        )}
      </ClipBoardBody>
    </>
  ) : null;
};

const ClipBoardHeader = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  height: 25px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
  margin-top: 10px;
`;

const ClipBoardBody = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  padding: 2px 5px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
`;

const useStyles = makeStyles((theme) => ({
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
