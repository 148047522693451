import { useContext } from 'react';
import { ConfigContext } from '../ConfigContext';
import {
  generateAggregateByName,
  generateNewEvent,
  generateNewCommand,
} from '../../utils/jsonPartsGenerators';
import { AggrPropertyRow, AggregateProperty, CommandType, Property } from '../../utils/types';
import { cloneDeep } from 'lodash';
import { useEventRefChanger } from '../../utils/useEventRefChanger';
import { useCommandActionRefChanger } from '../../utils/useCommandActionRefChanger';
import { getAggregateName } from '../../utils/navigationUtils';
import {
  AggregateConfig,
  RelationalPropertyType,
  CommandVersionDefinition,
  PropertyType,
  EventDefinition,
  CommandDefinition,
} from '@terragotech/gen5-config-lib';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { Events, Commands } from '../../utils/types';
import { useMapperRefChanger } from '../../utils/useMapperRefChanger';
import { propertiesObjsToArray } from '../../pages/aggregates/utils/propertiesObjsToArray';
import { propertiesArrtoObj } from '../../pages/aggregates/utils/propertiesArrayToObject';
import { JSONSchema6 } from 'json-schema';
import { errorMsg } from '../../components/SnackbarUtilsConfigurator';
// import { promisify } from 'util';

interface AggrNodeRow {
  [name: string]: EventDefinition | CommandDefinition;
}

export const useAggregateAPI = () => {
  const eventRefChanger = useEventRefChanger();
  const mapperRefChanger = useMapperRefChanger();
  const commandActionRefChanger = useCommandActionRefChanger();
  const {
    getConfig,
    setConfig,
    getAggregates,
    setAggregates,
    getAggregate,
    setAggregate,
    setDerivedPropertyMapping,
    getAggregateProperties,
    setAggregateProperties,
    getEvents,
    setEvents,
    getEventVersions,
    setEventVersions,
    getEventVersion,
    setEventVersion,
    setEventSchema,
    setAggregateMap,
    getCommands,
    setCommands,
    setCommandVersion,
    setEventInternalDescription,
    setCommandInternalDescription,
  } = useContext(ConfigContext);

  const promisify = <T extends Function>(
    child: T
  ): Promise<{ error: Error | null; data: T | null }> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ error: null, data: child() });
      } catch (e) {
        if (e instanceof Error) {
          errorMsg(e.message);
        }
        reject({ error: e as Error, data: null });
      }
    });
  };

  const addAggregate = (aggrName: string) =>
    promisify(() => {
      const newAggregate = generateAggregateByName(aggrName);
      setAggregates([...getAggregates(), newAggregate]);
    });

  const deleteAggregate = (aggrIndex: number) =>
    promisify(() => {
      setAggregates(getAggregates().filter((_, index) => index !== aggrIndex));
    });

  const replaceAggregate = (aggrIndex: number, aggregate: AggregateConfig) =>
    promisify(() => {
      const aggregates = getAggregates();
      aggregates[aggrIndex] = aggregate;
      setAggregates(aggregates);
    });

  const removeAggregateProperty = (aggrIndex: number, propToDelete: AggregateProperty) =>
    promisify(() => {
      const aggregate = getAggregate(aggrIndex);
      delete aggregate[propToDelete];
      setAggregate(aggrIndex, aggregate);
    });

  const addAggregateProperty = (aggrIndex: number, property: Property) =>
    promisify(() => {
      const properties = getAggregateProperties(aggrIndex);
      const newProperties = { ...properties, ...property };
      setAggregateProperties(aggrIndex, newProperties);
    });

  const updateAggregateProperty = (
    aggrIndex: number,
    oldPropertyName: string,
    property: Property
  ) =>
    promisify(() => {
      const removeForeignColumnIfNotValid = () => {
        const propertyValue = property[Object.keys(property)[0]];
        const isRelationalPropertyType = (prop: PropertyType): prop is RelationalPropertyType =>
          'relation' in prop;

        if (!isRelationalPropertyType(propertyValue) || !propertyValue.foreignColumn) return;

        const relatedAggregate = newConfig.aggregates.find(
          (aggr) => aggr.typeName === propertyValue.type
        );
        const relatedProperty = relatedAggregate?.properties[propertyValue.foreignColumn];
        const isPropertyOneToMany =
          propertyValue.hasOwnProperty('relation') && propertyValue.relation === 'ONE-TO-MANY';

        if (
          !isPropertyOneToMany ||
          !relatedProperty ||
          !isRelationalPropertyType(relatedProperty) ||
          relatedProperty.relation !== 'ONE-TO-ONE'
        )
          delete propertyValue.foreignColumn;
      };

      let newConfig = getConfig();
      const newPropertyName = Object.keys(property)[0];
      removeForeignColumnIfNotValid();
      const propArr = propertiesObjsToArray(newConfig.aggregates[aggrIndex].properties);
      const editedRow = propArr.findIndex((item) => item.name === oldPropertyName);
      propArr[editedRow] = propertiesObjsToArray(property)[0];
      if (newPropertyName !== oldPropertyName) {
        delete newConfig.aggregates[aggrIndex].properties[oldPropertyName];
        newConfig = mapperRefChanger.renamePropertyReferences(
          aggrIndex,
          newConfig,
          oldPropertyName,
          newPropertyName
        );
      }
      newConfig.aggregates[aggrIndex].properties = propertiesArrtoObj(propArr);
      setConfig(newConfig);
    });

  const deleteAggregateProperty = (aggrIndex: number, property: Property) =>
    promisify(() => {
      let newConfig = getConfig();
      const propertyName = Object.keys(property)[0];
      newConfig = mapperRefChanger.removePropertyReferences(aggrIndex, newConfig, propertyName);
      delete newConfig.aggregates[aggrIndex].properties[propertyName];
      setConfig(newConfig);
    });

  const moveAggregateProperty = (
    aggrIndex: number,
    propertyName: string,
    moveType: 'UP' | 'DOWN' | 'INDEX',
    targetIndex?: number
  ) =>
    promisify(() => {
      const properties = getAggregateProperties(aggrIndex);
      const propArr = propertiesObjsToArray(properties);
      const movedRowIndex = propArr.findIndex((item) => item.name === propertyName);
      const movedItem = propArr[movedRowIndex];
      if (moveType === 'UP') {
        if (movedRowIndex === 0)
          throw new Error(`Property "${propertyName}" is already at the top`);
        propArr.splice(movedRowIndex - 1, 2, movedItem, propArr[movedRowIndex - 1]);
      }
      if (moveType === 'DOWN') {
        if (movedRowIndex === propArr.length - 1)
          throw new Error(`Property "${propertyName}" is already at the bottom`);
        propArr.splice(movedRowIndex, 2, propArr[movedRowIndex + 1], movedItem);
      }
      if (moveType === 'INDEX' && targetIndex !== undefined) {
        propArr.splice(movedRowIndex, 1);
        propArr.splice(targetIndex, 0, movedItem);
      }
      propArr.forEach((prop: AggrPropertyRow) => {
        delete prop.order;
      });
      setAggregateProperties(aggrIndex, propertiesArrtoObj(propArr));
    });

  const aggrNodeObjsToArray = (obj: object | undefined) => {
    const arr = [];
    let counter = 1;
    for (const [key, value] of Object.entries(obj as object)) {
      arr.push({ name: key, order: counter++, ...value });
    }
    return arr;
  };

  const moveAggrEvents = (
    aggrIndex: number,
    eventName: string,
    moveType: 'UP' | 'DOWN',
    events?: object
  ) =>
    promisify(() => {
      const aEvents = aggrNodeObjsToArray(events);
      const movedRowIndex = aEvents.findIndex((item) => item.name === eventName);
      const movedItem = aEvents[movedRowIndex];

      if (moveType === 'UP') {
        if (movedRowIndex === 0) throw new Error(`Property "${eventName}" is already at the top`);
        aEvents.splice(movedRowIndex - 1, 2, movedItem, aEvents[movedRowIndex - 1]);
      }
      if (moveType === 'DOWN') {
        if (movedRowIndex === aEvents.length - 1)
          throw new Error(`Property "${eventName}" is already at the bottom`);
        aEvents.splice(movedRowIndex, 2, aEvents[movedRowIndex + 1], movedItem);
      }
      const reOrderedEvents = {} as AggrNodeRow;
      aEvents.forEach((item) => {
        const { name, order, ...event } = item;
        reOrderedEvents[name] = event;
      });
      setEvents(aggrIndex, reOrderedEvents as Events);
    });

  const moveAggrCommands = (
    aggrIndex: number,
    commandName: string,
    moveType: 'UP' | 'DOWN',
    commands?: object
  ) =>
    promisify(() => {
      const aCommands = aggrNodeObjsToArray(commands);
      const movedRowIndex = aCommands.findIndex((item) => item.name === commandName);
      const movedItem = aCommands[movedRowIndex];

      if (moveType === 'UP') {
        if (movedRowIndex === 0) throw new Error(`Property "${commandName}" is already at the top`);
        aCommands.splice(movedRowIndex - 1, 2, movedItem, aCommands[movedRowIndex - 1]);
      }
      if (moveType === 'DOWN') {
        if (movedRowIndex === aCommands.length - 1)
          throw new Error(`Property "${commandName}" is already at the bottom`);
        aCommands.splice(movedRowIndex, 2, aCommands[movedRowIndex + 1], movedItem);
      }
      const reOrderedCommands = {} as AggrNodeRow;
      aCommands.forEach((item) => {
        const { name, order, ...command } = item;
        reOrderedCommands[name] = command;
      });
      setCommands(aggrIndex, reOrderedCommands as Commands);
    });

  const updateDerivedPropertyMapping = (
    aggrIndex: number,
    derivedPropertyMapping: NodeMapDefinition
  ) =>
    promisify(() => {
      setDerivedPropertyMapping(aggrIndex, derivedPropertyMapping);
    });

  const updateEventSchema = (
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    eventSchema: JSONSchema6
  ) =>
    promisify(() => {
      setEventSchema(aggrIndex, eventName, versionIndex, eventSchema);
    });

  const updateAggregateMap = (
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    aggregateMap: NodeMapDefinition
  ) =>
    promisify(() => {
      setAggregateMap(aggrIndex, eventName, versionIndex, aggregateMap);
    });

  const updateCommandVersion = (
    aggrIndex: number,
    commandName: string,
    commandVersion: number,
    command: CommandVersionDefinition
  ) =>
    promisify(() => {
      setCommandVersion(aggrIndex, commandName, commandVersion, command);
    });

  const addNewEvent = (aggrIndex: number, eventName: string) =>
    promisify(() => {
      const events = getEvents(aggrIndex);
      if (!events) setEvents(aggrIndex, {});
      setEvents(aggrIndex, { ...events, [eventName]: generateNewEvent(eventName) });
    });

  const removeEvent = (aggrIndex: number, eventName: string) =>
    promisify(() => {
      let newConfig = getConfig();
      const aggregate = newConfig?.aggregates?.[aggrIndex];
      if (aggregate.events) {
        delete aggregate.events[eventName];
        newConfig = eventRefChanger.removeReferences(newConfig, aggrIndex, eventName);
        setConfig(newConfig);
      }
    });

  const addEventVersion = (aggrIndex: number, eventName: string, versionIndex: number) =>
    promisify(() => {
      const versions = getEventVersions(aggrIndex, eventName);
      if (versions) {
        const lastVersion = Math.max(
          ...versions.map((version: { versionNumber: number }) => version.versionNumber)
        );
        const newVersion = cloneDeep(versions[versionIndex]);
        newVersion.versionNumber = lastVersion + 1;
        versions.push(newVersion);
        setEventVersions(aggrIndex, eventName, versions);
      }
    });

  const removeEventVersion = (aggrIndex: number, eventName: string, versionIndex: number) =>
    promisify(() => {
      const versions = getEventVersions(aggrIndex, eventName);
      if (versions) {
        versions.splice(versionIndex, 1);
        setEventVersions(aggrIndex, eventName, versions);
      }
    });

  const resetEventSchema = (aggrIndex: number, eventName: string, versionIndex: number) =>
    promisify(() => {
      const version = getEventVersion(aggrIndex, eventName, versionIndex);
      if (version) {
        version.eventSchema = {};
        setEventVersion(aggrIndex, eventName, versionIndex, version);
      }
    });

  const resetEventAggregateMap = (aggrIndex: number, eventName: string, versionIndex: number) =>
    promisify(() => {
      const version = getEventVersion(aggrIndex, eventName, versionIndex);
      if (version) {
        version.aggregateMap = {
          outputDefinition: {
            outputNode: 'OUTPUT',
          },
        };
        setEventVersion(aggrIndex, eventName, versionIndex, version);
      }
    });

  const addNewCommand = (aggrIndex: number, commandName: string, commandType: CommandType) =>
    promisify(() => {
      const commands = getCommands(aggrIndex);
      if (!commands) setCommands(aggrIndex, {});
      setCommands(aggrIndex, {
        ...commands,
        [commandName]: generateNewCommand(commandName, commandType),
      });
    });

  const addCommandVersion = (
    aggrIndex: number,
    commandName: string,
    versionIndex: number,
    commandVersion: number,
    doRemap?: boolean
  ) =>
    promisify(() => {
      let config = getConfig();
      const versions = config.aggregates[aggrIndex].commands?.[commandName].versions;
      if (versions) {
        const lastVersion = Math.max(
          ...versions.map((version: { version: number }) => version.version)
        );
        const newVersion = cloneDeep(versions[versionIndex]);
        newVersion.version = lastVersion + 1;
        versions.push(newVersion);
        const aggrName = getAggregateName(config, aggrIndex);
        if (doRemap) {
          config = commandActionRefChanger.updateVersion(
            config,
            aggrName,
            commandName,
            commandVersion,
            lastVersion + 1
          );
        }
        setConfig(config);
      }
    });

  const removeCommand = (aggrIndex: number, commandName: string) =>
    promisify(() => {
      let config = getConfig();
      const commands = config.aggregates[aggrIndex].commands;
      if (commands) {
        delete commands[commandName];
        const aggrName = getAggregateName(config, aggrIndex);
        config = commandActionRefChanger.removeReferences(config, aggrName, commandName);

        setConfig(config);
      }
    });

  const removeCommandVersion = (
    aggrIndex: number,
    commandName: string,
    versionIndex: number,
    commandVersion: number
  ) =>
    promisify(() => {
      let config = getConfig();
      const versions = config.aggregates[aggrIndex].commands?.[commandName].versions;
      if (versions) {
        versions.splice(versionIndex, 1);
        const aggrName = getAggregateName(config, aggrIndex);
        config = commandActionRefChanger.removeReferences(
          config,
          aggrName,
          commandName,
          commandVersion
        );
        setConfig(config);
      }
    });

  const addEventComment = (aggrIndex: number, eventName: string, comment: string) =>
    promisify(() => {
      setEventInternalDescription(aggrIndex, eventName, comment);
    });

  const addCommandComment = (aggrIndex: number, commandName: string, comment: string) =>
    promisify(() => {
      setCommandInternalDescription(aggrIndex, commandName, comment);
    });

  return {
    addAggregate,
    replaceAggregate,
    deleteAggregate,
    removeAggregateProperty,
    addAggregateProperty,
    updateAggregateProperty,
    deleteAggregateProperty,
    moveAggregateProperty,
    moveAggrEvents,
    moveAggrCommands,
    updateDerivedPropertyMapping,
    updateEventSchema,
    updateAggregateMap,
    updateCommandVersion,
    addNewEvent,
    removeEvent,
    addEventVersion,
    removeEventVersion,
    resetEventSchema,
    resetEventAggregateMap,
    addNewCommand,
    addCommandVersion,
    removeCommand,
    removeCommandVersion,
    addEventComment,
    addCommandComment,
  };
};
