import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import { useConfig } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { Button, Divider, FormControlLabel, Switch } from '@material-ui/core';
import { SelectInput } from '../../../components/FormElements';
import moment from 'moment';
import _ from 'lodash';
import { DateFormatFormArray, DateFormatIsoArray, DateFormatTypeArray, DateSeparatorLabelArray, DATETIME_TOKEN_CONVERSION, DATE_FORMAT_TYPE, DEFAULT_DATETIME_FORMAT, getDateFormat, getTimeFormat, TimeFormatArray, validateDateFormat } from '@terragotech/gen5-shared-utilities';

export const MobileUIDateTimeEditor: React.FC = () => {
  const { config, setConfig } = useConfig();
  const [dateFormatType, setdateFormatType] = useState<string>(DEFAULT_DATETIME_FORMAT.DateFormatType);
  const [dateFormat, setdateFormat] = useState<string>(DEFAULT_DATETIME_FORMAT.DateFormat);
  const [timeFormat, setTimeFormat] = useState<string>(DEFAULT_DATETIME_FORMAT.TimeFormat);
  const [dateSeperator, setdateSeperator] = useState<string>(DEFAULT_DATETIME_FORMAT.DateSeperator);
  const [isSwitch, setIsSwitch] = useState<boolean>(DEFAULT_DATETIME_FORMAT.IsSwitch);
  const [isdisabled, setDisabled] = useState(true);

  const onSave = () => {
    const data = {
      dateFormatType: dateFormatType,
      dateFormat: dateFormat,
      dateSeperator: dateSeperator,
      timeFormat: timeFormat,
      isSwitch: isSwitch,
    };
    const configCopy = { ...config };
    configCopy.mobileUIConfig = {
      ...configCopy.mobileUIConfig,
      mobileDefaultDateTimeFormat: data,
    };
    setConfig(configCopy);
    successMsg('Date/time configuration has been successfully saved');
  };

  React.useEffect(() => {
    if (config?.mobileUIConfig?.mobileDefaultDateTimeFormat) {
      let isFound = DateFormatTypeArray.includes(
        config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.dateFormatType
      );
      setdateFormatType(config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.dateFormatType);
      if (isFound) {
        isFound = _.unionBy(DateFormatIsoArray, DateFormatFormArray).includes(
          config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.dateFormat
        );
      }
      setdateFormat(config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.dateFormat);
      setIsSwitch(config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.isSwitch);
      if (isFound) {
        isFound = TimeFormatArray.includes(
          config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.timeFormat
        );
      }
      setTimeFormat(config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.timeFormat);
      if (isFound) {
        isFound = DateSeparatorLabelArray.includes(
          config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.dateSeperator
        );
      }
      setdateSeperator(config?.mobileUIConfig?.mobileDefaultDateTimeFormat?.dateSeperator);
      if (!isFound) {
        setDefaultDateTime();
      }
    } else {
      setDefaultDateTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const setDefaultDateTime = () => {
    setdateFormatType(DEFAULT_DATETIME_FORMAT.DateFormatType);
    setdateFormat(DEFAULT_DATETIME_FORMAT.DateFormat);
    setIsSwitch(DEFAULT_DATETIME_FORMAT.IsSwitch);
    setTimeFormat(DEFAULT_DATETIME_FORMAT.TimeFormat);
    setdateSeperator(DEFAULT_DATETIME_FORMAT.DateSeperator);
  };

  return (
    <BrandingContainer>
      <HeaderContainer>
        <CardTitle>Default Time Format</CardTitle>
        <Button
          disabled={isdisabled}
          color="primary"
          variant="contained"
          onClick={onSave}
          style={{ margin: '10px 0' }}
        >
          Save
        </Button>
      </HeaderContainer>
      <GroupDivider />
      <Flexgrid>
        <div style={{ width: '400px' }}>
          <SelectInput
            title="Date format type"
            options={DateFormatTypeArray}
            value={dateFormatType}
            onChange={(e) => {
              setdateFormatType(e);
              setDisabled(false);
              setdateFormat(validateDateFormat(e, dateFormat) ?? validateDateFormat(dateFormatType, dateFormat));
            }}
            style={{ margin: '5px 25px 0', width: '350px' }}
            labelStyle={{ margin: '5px 25px 0' }}
          />
        </div>
        <div>
          <p style={{ margin: '7px' }}>Example Date format:</p>
          <h5 style={{ margin: '2px 7px', fontSize: '20px' }}>
            {dateFormat &&
              moment(new Date()).format(getDateFormat(dateFormatType, dateFormat, dateSeperator, {
                tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS,
              }))}
          </h5>
        </div>
      </Flexgrid>

      <Flexgrid>
        <div style={{ width: '400px' }}>
          <SelectInput
            title="Date Format"
            options={dateFormatType === DATE_FORMAT_TYPE.GeneralForm ? DateFormatFormArray : DateFormatIsoArray}
            value={dateFormat}
            onChange={(e) => {
              setdateFormat(e);
              setDisabled(false);
            }}
            style={{ margin: '5px 25px 0', width: '350px' }}
            labelStyle={{ margin: '5px 25px 0' }}
          />
        </div>

        {dateFormatType !== DATE_FORMAT_TYPE.ISO8601 && (
          <div>
            <p style={{ margin: '7px' }}>Example Time format:</p>
            <h5 style={{ margin: '2px 7px', fontSize: '20px' }}>
              {timeFormat && moment(new Date()).format(getTimeFormat(dateFormatType, dateFormat, timeFormat, {
                tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS,
              }))}
            </h5>
          </div>
        )}
      </Flexgrid>

      {dateFormatType !== DATE_FORMAT_TYPE.ISO8601 && (
        <>
          <SelectInput
            title="Date Seperator"
            options={DateSeparatorLabelArray}
            value={dateSeperator}
            onChange={(e) => {
              setdateSeperator(e);
              setDisabled(false);
            }}
            style={{ margin: '5px 25px 0', width: '350px' }}
            labelStyle={{ margin: '5px 25px 0' }}
          />

          <SelectInput
            title="Time Format"
            options={TimeFormatArray}
            value={timeFormat}
            onChange={(e) => {
              setTimeFormat(e);
              setDisabled(false);
            }}
            style={{ margin: '5px 25px 0', width: '350px' }}
            labelStyle={{ margin: '5px 25px 0' }}
          />
        </>
      )}

      <FormControlLabel
        control={
          <Switch
            checked={isSwitch}
            onChange={() => {
              setIsSwitch(!isSwitch);
              setDisabled(false);
            }}
            color="primary"
          />
        }
        label="for csv exports,ignore the display setting above,and always export using ISO 8601: yyyy-mm-ddThh:mm:ss-hh:mm."
      />
    </BrandingContainer>
  );
};

const BrandingContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  overflow-x: auto;
  padding: 10px 20px;
  max-width: 1200px;
`;

const Flexgrid = styled.div`
  display: flex;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardTitle = styled.p`
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;
