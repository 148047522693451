import styled from 'styled-components';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { EventMapDiagramModel } from '../EventMapDiagramModel';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { TinyTextInput } from '../InputControls/TinyTextInput';
import {
  AllNodeMap,
  mapScenarios,
  NodeKeys,
  MapScenarioType,
} from '@terragotech/gen5-datamapping-lib';
import { useHotkeys } from 'react-hotkeys-hook';

export const DROP_LAYER_DATA_TAG = 'EVENT_MAPPER';
const Wrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
`;
const ContextBackground = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.17);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ContextMenu = styled.div`
  background-color: blue;
  position: absolute;
  width: 275px;
  max-height: 400px;
  height: 305px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: linear-gradient(225deg, #3a3a3a, #313131);
  box-shadow: -10px 10px 20px #1c1c1c;
  margin-bottom: 10px;
`;
const TopSection = styled.div`
  font-size: 12px;
  color: #fff;
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-size: 16px;
  color: #fff;
  padding: 10px;
  padding-bottom: 0px;
`;
const SearchSection = styled.div`
  padding: 10px;
  display: flex;
`;
const ResultSection = styled.div`
  overflow-y: auto;
  margin-bottom: 10px;

  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
  }
`;
const ResultCategory = styled.div`
  top: 0px;
  position: -webkit-sticky;
  position: sticky;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-left: 4px;
  background: linear-gradient(225deg, #6a6a6a, #616161);
`;
const ResultEntry = styled.div<{ isSelected?: boolean }>`
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  margin-left: 4px;
  ${(props) => (props.isSelected ? 'background: linear-gradient(225deg, #616161, #00c4ff);' : '')}
`;

export interface DroppableNode {
  type: string;
}

export interface DropLayerProps {
  diagramEngine: DiagramEngine;
  mapScenario?: keyof MapScenarioType;
  children: React.ReactNode;
}
const MenuManager: React.FunctionComponent<DropLayerProps> = (props) => {
  const { diagramEngine, mapScenario } = props;
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState<string | null>('');
  const [selectedResult, setSelectedResult] = useState<string | null>(null);

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      //The following is the best I could come up with to determine if the user right clicked on a link
      //This prevents the new node menu from popping up when a link is deleted
      //@ts-ignore
      if(!(e.target?.nodeName === 'path' && e.target?.selected)){ 
        setXPos(e.clientX);
        setYPos(e.clientY);
        setMenuOpen((cur) => !cur);
      }
    },
    [setXPos, setYPos]
  );
  const handleContextClose = useCallback(() => {
    setSelectedResult(null);
    setSearchText(null);
    setMenuOpen(false);
  }, [setMenuOpen]);

  //Sort everything into categories and filter based on the search bar
  const results = useMemo(() => {
    const categorizedOutput: {
      [index: string]: { [key: string]: { value: string; label: string } };
    } = {}; //Object.values(AllNodeMap).reduce(

    Object.keys(AllNodeMap)
      .filter((key) => {
        return mapScenario
          ? mapScenarios[mapScenario].availableNodes.includes(key as NodeKeys)
          : true;
      })
      .filter(
        (key) =>
          !searchText ||
          (AllNodeMap as any)[key].nodeDefinition.defaultTitle
            .toLowerCase()
            .includes(searchText.toLowerCase())
      )
      .forEach((key) => {
        const { primaryCategory, NODE_TYPE, defaultTitle } = (AllNodeMap as any)[
          key
        ].nodeDefinition;
        categorizedOutput[primaryCategory] = {
          ...categorizedOutput[primaryCategory],
          [key]: { value: NODE_TYPE, label: defaultTitle },
        };
      });
    return categorizedOutput;
  }, [mapScenario, searchText]);

  const categories = Object.keys(results);
  //If the currently selected item is no longer visible, select first item
  useEffect(() => {
    const category = categories.find(
      (key) =>
        Object.keys(results[key]).findIndex(
          (itemKey) => results[key][itemKey].value === selectedResult
        ) >= 0
    );
    if (!category) {
      const firstCategory = Object.keys(results)[0];
      const firstItem = firstCategory
        ? results[firstCategory][Object.keys(results[firstCategory])[0]].value
        : null;
      setSelectedResult(firstItem as string | null);
    }
  }, [categories, results, selectedResult]);
  //Determine the category and index of the currently selected item
  const [category, itemIndex] = useMemo(() => {
    if (menuOpen && results && categories.length > 0) {
      const category =
        categories.find(
          (key) =>
            Object.keys(results[key]).findIndex(
              (itemKey) => results[key][itemKey].value === selectedResult
            ) >= 0
        ) || Object.keys(results)[0];
      const itemIndex =
        results[category] &&
        Object.keys(results[category]).findIndex((itemKey) => itemKey === selectedResult);
      return [category, itemIndex];
    }
    return ['', undefined];
  }, [categories, menuOpen, results, selectedResult]);
  //handle up arrow
  useHotkeys(
    'up',
    () => {
      //try to decrease the index
      if (itemIndex !== undefined && itemIndex > 0) {
        const newKey = Object.keys(results[category])[itemIndex - 1];
        setSelectedResult(newKey);
      } else {
        //if we would go less than 0 try to decrease the category and move to the last item
        const catIndex = categories.findIndex((key) => key === category);
        if (catIndex > 0) {
          const newCat = Object.keys(results)[catIndex - 1];
          const itemsInCategory = Object.keys(results[newCat]);
          const newKey = itemsInCategory[itemsInCategory.length - 1];
          setSelectedResult(newKey);
        }
      }
    },
    { enableOnTags: ['INPUT'] }
  );
  //handle down arrow
  useHotkeys(
    'down',
    () => {
      //TODO: add in handler for when nothing is selected.
      // check if the index is the last in the category
      const itemsInCategory = Object.keys(results[category || '']);
      if (itemIndex !== undefined && itemIndex < itemsInCategory.length - 1) {
        // if not, then increase
        const newKey = Object.keys(results[category])[itemIndex + 1];
        setSelectedResult(newKey);
      } else {
        // if item was last, check for a following category
        const catIndex = categories.findIndex((key) => key === category);
        if (catIndex < categories.length - 1) {
          // if category exists, move to its first item
          const newCat = Object.keys(results)[catIndex + 1];
          const itemsInCategory = Object.keys(results[newCat]);
          const newKey = itemsInCategory[0];
          setSelectedResult(newKey);
        }
      }
    },
    { enableOnTags: ['INPUT'] }
  );
  //handle enter selection
  useHotkeys(
    'enter',
    () => {
      menuOpen && selectedResult && handleChosenNode(selectedResult);
    },
    { enableOnTags: ['INPUT'] }
  );
  useHotkeys(
    'escape',
    () => {
      handleContextClose();
    },
    { enableOnTags: ['INPUT'] }
  );

  // handle the selection of a new node
  const handleChosenNode = useCallback(
    (node: string) => {
      const factories = diagramEngine.getNodeFactories();
      const factory = factories.getFactory(node);
      if (factory) {
        // grab a unique name here
        const temp = (diagramEngine.getModel() as EventMapDiagramModel).getUniqueName();
        const newModel = factory.generateModel({
          initialConfig: { id: temp, newlyCreated: true },
        });
        // set the position of that node based on the mouse
        const point = diagramEngine.getRelativeMousePoint({ clientX: xPos, clientY: yPos });
        newModel.setPosition(point);
        diagramEngine.getModel().addNode(newModel);
      }
      handleContextClose();
    },
    [diagramEngine, handleContextClose, xPos, yPos]
  );
  return (
    <Wrapper onContextMenu={handleContextMenu}>
      {props.children}
      {menuOpen && (
        <>
          <ContextBackground onClick={handleContextClose}></ContextBackground>

          <ContextMenu
            style={{
              position: 'fixed',
              top: yPos,
              left: xPos,
            }}
          >
            <TopSection>
              <Title>Add a Node</Title>

              <SearchSection>
                <TinyTextInput
                  onChange={(value) => setSearchText(value || null)}
                  value={searchText}
                  autoFocus={true}
                />
              </SearchSection>
            </TopSection>

            <ResultSection>
              <div>
                {Object.keys(results).map((category) => {
                  return (
                    <Fragment key={category}>
                      <ResultCategory>{category}</ResultCategory>
                      {Object.keys(results[category]).map((nodeKey) => {
                        const node = results[category][nodeKey];
                        return (
                          <ResultEntry
                            isSelected={selectedResult === nodeKey}
                            onClick={() => handleChosenNode(node.value)}
                            key={node.value}
                            onMouseEnter={() => setSelectedResult(nodeKey)}
                          >
                            {node.label}
                          </ResultEntry>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </div>
            </ResultSection>
          </ContextMenu>
        </>
      )}
    </Wrapper>
  );
};
export default MenuManager;
