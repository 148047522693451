import React from 'react';
import styled from 'styled-components';

import { CustomPagesMenu } from './components/CustomPagesMenu/CustomPagesMenu';
import { CustomPagesOverview } from './pages/CustomPagesOverview';
import { Route } from 'react-router-dom';
import { CustomPageEditor } from './pages/CustomPageEditor';
import { PageDialogServiceProvider } from 'components/PageDialog/PageDialogService';

export const CustomPages: React.FC = () => (
  <PageDialogServiceProvider>
    <FlexContainer>
      <Route path="/customPages/:customPage?" component={CustomPagesMenu} />
      <Route path="/customPages" component={CustomPagesOverview} exact />
      <Route path="/customPages/:customPage" component={CustomPageEditor} exact />
    </FlexContainer>
  </PageDialogServiceProvider>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
