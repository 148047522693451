import React, { useContext, useEffect } from 'react';
import { SelectInput } from '../../../components/FormElements/SelectInput';
import { ConfigContext } from '../../../context/ConfigContext';
import { FabActionRow } from '../../MobileUI/subpages/FabActions';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import {
  getCommandReferenceArr,
  commandRefObjToList,
  convertCommandRefToString,
} from '../../../utils/jsonPartsGenerators';

interface FabActionColumnInputProps {
  row: FabActionRow;
  onChange: (value: string) => void;
  forImport?: boolean;
}

export const FabActionColumnInput: React.FC<FabActionColumnInputProps> = ({
  row,
  onChange,
  forImport,
}) => {
  const { config } = useContext(ConfigContext);
  useEffect(() => {
    if (selectedAggrNotContainSelectedAction()) onChange('');
    // eslint-disable-next-line
  }, [row.aggregateName]);

  const getSelectedAggregateIndex = () => getAggregateIndex(config, row.aggregateName);

  const getSelectedAggregateCommands = () =>
    commandRefObjToList(
      getCommandReferenceArr(config, !forImport, getSelectedAggregateIndex(), forImport)
    );

  const selectedAggrNotContainSelectedAction = () =>
    !Object.keys(getSelectedAggregateCommands()).includes(convertCommandRefToString(row.action));

  return (
    <SelectInput
      variant="standard"
      options={Object.keys(getSelectedAggregateCommands())}
      value={convertCommandRefToString(row.action) || ''}
      onChange={(value) => onChange(value)}
    />
  );
};
