import React, { useContext, useState, useMemo, useCallback, MouseEvent } from 'react';
import {
  Paper,
  Button,
  FormControlLabel,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { ConfigContext } from '../../context/ConfigContext';
import { successMsg } from '../SnackbarUtilsConfigurator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ReorderIcon from '@material-ui/icons/Reorder';
import ClearIcon from '@material-ui/icons/Clear';
import { WebAggrUICustomization, MobileAggrUICustomization } from '../../utils/types';

interface Props {
  type: 'mobile' | 'web';
}
const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#333333',
    color: 'white',
    fontSize: 13,
    maxWidth: 200,
  },
  arrow: {
    color: '#333333',
  },
}))(Tooltip);

export const MapLabels: React.FC<Props> = ({ type }) => {
  const {
    getUICustomization,
    setUICustomization,
    getAggregates,
    getAggregateProperties,
  } = useContext(ConfigContext);
  const aggregateList = getAggregates();
  const { aggrUICustomization } = useParams() as { aggrUICustomization: string };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const aggregateIndex = aggregateList.findIndex(
    (aggregateType) => aggregateType.typeName === aggrUICustomization
  );
  const aggregateObject = getAggregateProperties(aggregateIndex);
  const aggregateProperties = Object.keys(aggregateObject).filter(
    (aggProp) => aggregateObject[aggProp].label !== 'none'
  );

  const currentCustomizations =
    (type === 'web'
      ? getUICustomization('webUIConfig', aggrUICustomization)
      : getUICustomization('mobileUIConfig', aggrUICustomization)) || null;

  const initialDropDownItems = aggregateProperties.filter(
    (aggProp) => !currentCustomizations?.mapLabelProperties?.includes(aggProp)
  );
  const initialMenuItems = currentCustomizations?.mapLabelProperties
    ? currentCustomizations.mapLabelProperties.filter(
        (aggProp) => !!aggregateProperties.includes(aggProp)
      )
    : [];
  const [dropDownItems, setDropDownItems] = useState([...initialDropDownItems]);
  const [menuItems, setMenuItems] = useState([...initialMenuItems]);

  const [showLabels, setShowLabels] = useState<boolean>(
    currentCustomizations?.showMapLabelTitles === null ||
      currentCustomizations?.showMapLabelTitles === undefined
      ? true
      : currentCustomizations.showMapLabelTitles
  );

  const [showEmptyLabels, setShowEmptyLabels] = useState<boolean>(
    currentCustomizations?.showEmptyMapLabels === null ||
      currentCustomizations?.showEmptyMapLabels === undefined
      ? true
      : currentCustomizations.showEmptyMapLabels
  );

  const handleSave = useCallback(() => {
    if (type === 'web') {
      setUICustomization('webUIConfig', aggrUICustomization, {
        ...(currentCustomizations as WebAggrUICustomization),
        mapLabelProperties: menuItems,
        showMapLabelTitles: showLabels,
        showEmptyMapLabels: showEmptyLabels,
      });
    } else {
      setUICustomization('mobileUIConfig', aggrUICustomization, {
        ...(currentCustomizations as MobileAggrUICustomization),
        mapLabelProperties: menuItems,
        showMapLabelTitles: showLabels,
        showEmptyMapLabels: showEmptyLabels,
      });
    }
    successMsg('Map Label Properties saved.');
  }, [
    aggrUICustomization,
    currentCustomizations,
    setUICustomization,
    menuItems,
    type,
    showLabels,
    showEmptyLabels,
  ]);

  //Moves to destination array, removes from origin array
  const handleArrayToArray = (
    index: number,
    originArray: string[],
    setOriginArray: (array: string[]) => void,
    destinationArray: string[],
    setDestinationArray: (array: string[]) => void,
    unShift?: boolean
  ) => {
    const tempDestinationArray = [...destinationArray];
    unShift
      ? tempDestinationArray.unshift(originArray[index])
      : tempDestinationArray.push(originArray[index]);
    setDestinationArray(tempDestinationArray);
    const tempOriginArray = [...originArray];
    tempOriginArray.splice(index, 1);
    setOriginArray(tempOriginArray);
  };

  const dropDownItemsMapped = useMemo(() => {
    return (
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{ style: { maxHeight: 200, minWidth: 245, maxWidth: 300 } }}
      >
        {dropDownItems.map((item, index) => {
          return (
            <MenuItem
              style={{ textOverflow: 'ellipsis' }}
              onClick={() =>
                handleArrayToArray(index, dropDownItems, setDropDownItems, menuItems, setMenuItems)
              }
              key={index}
            >
              {aggregateObject[item].label}
            </MenuItem>
          );
        })}
      </Menu>
    );
  }, [dropDownItems, menuItems, aggregateObject, open, anchorEl]);

  const menuItemsMapped = useMemo(() => {
    function onDragEnd(result: DropResult) {
      if (!result.destination) {
        return;
      }
      const newItems = [...menuItems];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      setMenuItems(newItems);
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="Arbitrary">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <List
                subheader={
                  menuItems.length > 0 ? (
                    <ListSubheader>Selected map label aggregate attributes:</ListSubheader>
                  ) : (
                    <ListSubheader />
                  )
                }
              >
                {menuItems.map((item, index) => (
                  <Draggable draggableId={item} key={item} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItem divider key={item}>
                          <ListItemIcon>
                            <ReorderIcon />
                          </ListItemIcon>
                          <ListItemText>{aggregateObject[item].label}</ListItemText>
                          <IconButton
                            edge="end"
                            onClick={() =>
                              handleArrayToArray(
                                index,
                                menuItems,
                                setMenuItems,
                                dropDownItems,
                                setDropDownItems,
                                true
                              )
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
              </List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }, [menuItems, dropDownItems, aggregateObject]);

  const showMapLabelGroup = useMemo(() => {
    if (menuItems.length > 0)
      return (
        <div>
          <FormControlLabel
            style={{ width: '90%', marginLeft: '10px' }}
            control={
              <Checkbox
                checked={showLabels}
                onChange={() => setShowLabels((prevState) => !prevState)}
                color="primary"
              />
            }
            label="Show labels for each attribute in the map label"
          />

          <FormControlLabel
            style={{ width: '90%', marginLeft: '10px' }}
            control={
              <Checkbox
                checked={showEmptyLabels}
                onChange={() => setShowEmptyLabels((prevState) => !prevState)}
                color="primary"
              />
            }
            label="Show attributes with blank values"
          />
        </div>
      );
    return null;
  }, [menuItems, showLabels, showEmptyLabels]);

  const previewItemsMapped = useMemo(() => {
    return menuItems.map((item) => (
      <div>{showLabels ? `${aggregateObject[item].label}:` : ''} 123abc</div>
    ));
  }, [menuItems, showLabels, aggregateObject]);

  return (
    <Container>
      <HeaderContainer>
        <CardTitle>Map Labels</CardTitle>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: '10px 0' }}
          onClick={handleSave}
        >
          Save
        </Button>
      </HeaderContainer>
      <div style={{ display: 'flex' }}>
        <BodyContainer>
          <div style={{ margin: '10px 0px' }}>
            <Button
              color="primary"
              variant="contained"
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleClick}
              style={{ margin: '10px 0px' }}
            >
              Add Attribute To Label
            </Button>
            {dropDownItemsMapped}
          </div>
          <div style={{ paddingBottom: '20px' }}>{menuItemsMapped}</div>
          {showMapLabelGroup}
        </BodyContainer>
        <div style={{ flex: 1 }}>
          <img
            src={require('../../resources/images/backgroundImageLabels.png').default}
            alt="Map Label Preview"
            style={{ height: 200, width: 200 }}
          />
          <DarkTooltip
            title={<>{previewItemsMapped}</>}
            placement="top"
            arrow
            open={previewItemsMapped.length >= 1 ? true : false}
          >
            <img
              src={require('../../resources/images/symbolCheckmark.png').default}
              style={{ position: 'relative', bottom: 93, left: 84 }}
              alt="Symbol with Checkmark"
            />
          </DarkTooltip>
        </div>
      </div>
    </Container>
  );
};

const BodyContainer = styled(Paper)({
  padding: '12px 24px',
  margin: 5,
  flex: 9,
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'column',
});
const Container = styled('div')({
  width: '100%',
  flex: 1,
});
const CardTitle = styled.p`
  word-break: break-word;
`;
const HeaderContainer = styled(Paper)({
  padding: '0px 20px',
  margin: 5,
  flex: 1,
  overflowX: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  verticalAlign: 'middle',
});
