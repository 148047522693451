import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import DataMapperDialog from './FormDialog/DataMapperDialog';
import { MapScenarioType, NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { propertiesToSchema } from '../pages/aggregates/utils/PropertiesToSchemaConverter';
import { PropertyCollection } from '@terragotech/gen5-config-lib';
import { metadataSchema } from '../utils/jsonPartsGenerators';
import { LocalSchemaDefinition } from '../utils/useSchemaLookup';
import { MappingActiveDisplay } from './MappingActiveDisplay';
import { JSONSchema6 } from 'json-schema';

interface TableEmbeddedMapperProps {
  value: NodeMapDefinition | undefined;
  onChange: (value: NodeMapDefinition | undefined) => void;
  properties?: PropertyCollection;
  mapScenario?: keyof MapScenarioType;
  form?: JSONSchema6;
}

export const TableEmbeddedMapper: React.FC<TableEmbeddedMapperProps> = ({
  value,
  onChange,
  properties,
  mapScenario,
  form,
}) => {
  const [mapperOpen, setMapperOpen] = useState(false);
  const localSchemaDefinitions: LocalSchemaDefinition = {
    METADATA: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
  };
  if (properties) {
    localSchemaDefinitions['STATE'] = {
      schema: propertiesToSchema(properties),
      schemaLabel: 'Properties',
    };
  }
  if (form) {
    localSchemaDefinitions['FORM'] = {
      schema: form,
      schemaLabel: 'Form',
    };
  }
  return (
    <>
      <Button onClick={() => setMapperOpen(true)}>
        <div>
          Advanced
          <MappingActiveDisplay isActive={value !== undefined} activeLabelText={'active'} />
        </div>
      </Button>
      <Button onClick={() => onChange(undefined)}>Clear</Button>
      <DataMapperDialog
        localSchemaDefinitions={localSchemaDefinitions}
        onClose={() => {
          setMapperOpen(false);
        }}
        open={mapperOpen}
        datamap={value}
        setDatamap={(data: NodeMapDefinition | undefined) => onChange(data)}
        mapScenario={mapScenario || (properties ? 'BUTTON_STATE' : 'FAB_BUTTON_STATE')}
      />
    </>
  );
};
