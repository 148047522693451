import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { WebUIGeneralEditor } from './subpages/WebUIGeneralEditor';
import { WebUIMenu } from './components/WebUIMenu/WebUIMenu';
import { AggregateUICustomisationsMenu } from './components/AggregateUICustomisationsMenu/AggregateUICustomisationsMenu';
import { ActionButtonEditor } from '../../components/ActionButtonEditor/ActionButtonEditor';
import { CardDefinition } from '../../components/CardDefinition/CardDefinition';
import { MapLabels } from '../../components/MapLabels/MapLabels';
import { FabActions } from '../MobileUI/subpages/FabActions';
import GeneralDialog from '../../components/GeneralDialog/GeneralDialog';
import { WebUIDateTimeEditor } from './subpages/WebUIDateTimeEditor';

export const WebUI: React.FC = () => (
  <FlexContainer>
    <WebUIMenu />
    <Route path="/webUI/aggregateUICustomizations" component={AggregateUICustomisationsMenu} />

    <Route path="/webUI/general" component={WebUIGeneralEditor} />
    <Route path="/webUI/fabActions" component={() => <FabActions type="web" />} />
    <Route path="/webUI/importActions" component={() => <FabActions type="webImport" />} />
    <Route path="/webUI/DefaultDateTimeFormat" component={WebUIDateTimeEditor} />
    <Route
      path="/webUI/aggregateUICustomizations/:aggrUICustomization/cardDefinition"
      component={() => <CardDefinition type="web" />}
    />
    <Route
      path="/webUI/aggregateUICustomizations/:aggrUICustomization/mapLabels"
      component={() => <MapLabels type="web" />}
    />
    <Route
      path="/webUI/aggregateUICustomizations/:aggrUICustomization/editorWorkflows"
      component={() => <ActionButtonEditor type="webEditorWorkflows" />}
    />
    <Route
      path="/webUI/aggregateUICustomizations/:aggrUICustomization/tableActions"
      component={() => <ActionButtonEditor type="webTableActions" />}
    />
    <Route
      path="/webUI/aggregateUICustomizations/:aggrUICustomization/multiSelectActions"
      component={() => <ActionButtonEditor type="webMultiSelectActions" />}
    />
    <Route
      path="/webUI/aggregateUICustomizations/:aggrUICustomization"
      exact={true}
      component={() => <GeneralDialog type="web" />}
    />
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
