import React, { useContext } from 'react';
import styled from 'styled-components';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Button from '@material-ui/core/Button';
import { ConfigContext } from '../../context/ConfigContext';
import { useHistory } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { configExample } from '../../utils/configExample';

export const NewConfig: React.FC = () => {
  const confirm = useConfirm();
  const history = useHistory();
  const { setConfig } = useContext(ConfigContext);

  const handleNewConfig = async () => {
    await confirm({
      description: 'Currently loaded config will be overwritten',
      confirmationText: 'Create new config',
    });
    setConfig(configExample);

    history.push('/');
  };

  return (
    <NewConfigButtonContainer>
      <NewConfigButton
        variant="contained"
        onClick={handleNewConfig}
        style={{ color: 'black' }}
        startIcon={<NoteAddIcon />}
      >
        Create new config
      </NewConfigButton>
    </NewConfigButtonContainer>
  );
};

const NewConfigButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 20px 0;
`;

const NewConfigButton = styled(Button)`
  && {
    width: 222px;
  }
`;
