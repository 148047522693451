import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Divider,
  Button,
  Paper,
  TextField,
  FormLabel,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import styled from 'styled-components';
import { useConfig } from '../../../context/ConfigContext';
import { CheckboxInput, InputGroup } from '../../../components/FormElements';
import { errorMsg, successMsg, warningMsg } from '../../../components/SnackbarUtilsConfigurator';
import { cloneDeep, isEqual } from 'lodash';
import { MaterialTableRef } from '../../../components/EditableTable';
import { useConfirm } from 'material-ui-confirm';
import { useParams, Prompt } from 'react-router-dom';
import { useCustomPageAPI } from '../../../context/fakeAPIHooks/useCustomPageAPI';
import { V2GroupComponentDef, V2PageTemplate } from '@terragotech/page-renderer';
import { PageEditor } from '../components/CustomPageEditor/PageEditor';

const useStyles = makeStyles({
  tooltip: {
    marginBottom: '1px',
    maxWidth: '200px',
  },
});

interface PromiseResult {
  success: boolean;
}

export interface CustomPageEditorRef {
  save: () => Promise<PromiseResult>;
  handleCancelChanges: () => Promise<PromiseResult>;
};

export const CustomPageEditor: React.FC<{
  editPage?: V2PageTemplate,
  aggType?: string,
  fullPage?: V2PageTemplate,
  ref?: React.Ref<CustomPageEditorRef | undefined>,
}> = React.forwardRef(({editPage, aggType, fullPage}, ref) => {
  const { config } = useConfig();
  const CustomPageAPI = useCustomPageAPI();
  const classes = useStyles();

  const { customPage: pageName } = useParams() as {
    customPage: string;
  };
  const tableRef = useRef<MaterialTableRef>(null);

  const [reset, setReset] = useState<number>(0);

  const confirm = useConfirm();
  const page = editPage ? cloneDeep(editPage) : config.pagesConfig?.[pageName];  // TODO: API get? Requires async.

  const pageDefault = {
    rows: 1,
    columns: 1,
    allowDynamicSizing: true,
    elements: {},
  };
  const [pageDefinition, setPageDefinition] = useState<V2PageTemplate>(cloneDeep(page || pageDefault));
  const [rows, setRows] = useState(editPage?.rows || pageDefault.rows);
  const [columns, setColumns] = useState(editPage?.columns || pageDefault.columns);
  const [allowDynamicSizing, setAllowDynamicSizing] = useState(editPage?.allowDynamicSizing || pageDefault.allowDynamicSizing);
  const [aggregateType, setAggregateType] = useState(aggType || '');
  const [firstRun, setFirstRun] = useState(true);
  const [isPublic, setIsPublic] = useState(page?.isPublic || false);

  const setPropertyStates = (state: {
    rows: number,
    columns: number,
    allowDynamicSizing: boolean,
    aggregateType?: string,
    page: V2PageTemplate
  }) => {
    const { rows, columns, allowDynamicSizing, aggregateType, page } = state;
    setRows(rows);
    setColumns(columns);
    setAllowDynamicSizing(allowDynamicSizing);
    setAggregateType(aggregateType ?? '');
    setPageDefinition(page);
  };

  useEffect(() => {
    if (page && !editPage) {
      setPropertyStates({ ...page, page: (page && cloneDeep(page)) || pageDefault });
      setReset((prev) => prev + 1);
    }
  }, [pageName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page && !firstRun) {
      setPageDefinition(p => {
        const copy = cloneDeep(p);
        copy.rows = rows;
        copy.columns = columns;
        copy.allowDynamicSizing = allowDynamicSizing;
        copy.aggregateType = aggregateType;
        return copy;
      });
    } else {
      setFirstRun(false);
    }
  }, [rows, columns, allowDynamicSizing, aggregateType, setPageDefinition, setFirstRun]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = async () => {
    const result: PromiseResult = { success: true };
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    if (lastEditingRow || showAddRow){
      warningMsg('Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form');
      result.success = false;
      return result;
    }

    const elementEntries = Object.entries(pageDefinition.elements);
    const hasEmptyGridAreas =
      [...Array(pageDefinition.rows).keys()].reduce((rAcc, gaRow) => 
        [...Array(pageDefinition.columns).keys()].reduce((cAcc, gaColumn) => {
          return cAcc || (
            // No element in this grid area
            !elementEntries.find(([_, e]) => e.row === gaRow && e.column === gaColumn)
            // No element spanning this grid area (does not account for rowSpan)
            && !elementEntries.find(([_, e]) => e.row === gaRow && e.column < gaColumn && e.column + e.columnSpan > gaColumn)
            // One or more elements after this grid area
            && elementEntries.some(([_, e]) => e.row > gaRow || e.row === gaRow && e.column > gaColumn)
          );
        }, rAcc),
        false
      );
    if (hasEmptyGridAreas) {
        errorMsg('The page editor cannot have empty spaces before elements.');
        result.success = false;
        return result;
    }

    if(!editPage){
      const { error } = await CustomPageAPI.updatePage(
        pageName,
        cleanPageTemplate({
          ...pageDefinition,
          columns,
          rows,
          isPublic
        }),
      );
      if (error) {
        result.success = false;
        return result;
      };
      successMsg('The custom page has been saved');
    }
    else{
      editPage.columns = columns;
      editPage.rows = rows;
      editPage.elements = pageDefinition.elements;
    }
    return result;
  };

  const cleanPageTemplate = (template: V2PageTemplate): V2PageTemplate => {
    //Given a page template, recusively remove any components that aren't used (based on the row and column attributes)
    const keptElements: V2PageTemplate['elements'] = {};
    // cycle through elements and add any within range found there to the keptElements
    Object.entries(template.elements).forEach(([key, e]) => {
      if (e.component.type === 'group') {
        const newElement = { ...e, component: e.component as V2GroupComponentDef };
        newElement.component.template = cleanPageTemplate(e.component.template);
        keptElements[key] = newElement;
      } else if (e.row < template.rows && e.column < template.columns) {
        keptElements[key] = { ...e };
      }
    });
    // if the component is a group type, run its template through this

    return {
      rows: template.rows,
      columns: template.columns,
      isPublic: template.isPublic,
      allowDynamicSizing: template.allowDynamicSizing,
      ...(aggregateType && { aggregateType }),
      ...(template.errorMaps && { errorMaps: template.errorMaps }),
      ...(template.warningMaps && { warningMaps: template.warningMaps }),
      ...(template.initialDataMap && { initialDataMap: template.initialDataMap }),
      elements: keptElements,
    };
  };

  const ifShowMessageBeforeRedirect = useCallback(() => {
    if (page && !editPage) {
      const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};  // TODO: Are these needed?
      return !isEqual(cleanPageTemplate(pageDefinition), page) || lastEditingRow || showAddRow
        ? 'The form has not been saved, do you want to redirect?'
        : true;
    } else return true;
  }, [page, editPage, tableRef?.current?.state, cleanPageTemplate, pageDefinition, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancelChanges = async () => {
    const result: PromiseResult = { success: true };
    try {
      await confirm({
        title: 'Are you sure you want to cancel these changes?',
        description: 'This cannot be undone.',
        confirmationText: 'Confirm',
      });
      if (page) {
        setPropertyStates({ ...page, page });
      }
      setReset((prev) => prev + 1);
    } catch (error) {
      result.success = false;
      console.log('request cancelled');
    } finally {
      return result;
    }
  };

  React.useImperativeHandle(ref, () => ({
    save,
    handleCancelChanges,
  }));

  return (
    <AggregateGeneralInfoContainer>
      {!editPage && 
        <CardTitle>Page Editor</CardTitle>
      }
      {!editPage && 
        <GroupDivider />
      }
      {!editPage && 
        <FormLabel component="legend" style={{ fontSize: 12, paddingTop: 10, paddingBottom: 10 }}>
          Options
        </FormLabel>
      }
      <CenterDiv>
        <TextField
          type="number"
          id="rows"
          onChange={(e) => setRows(+e.target.value)}
          value={rows}
          label="Rows"
          variant="outlined"
          InputProps={{
            inputProps: {
              min: 1,
            },
          }}
        />
        <TextField
          type="number"
          id="columns"
          onChange={(e) => setColumns(+e.target.value)}
          value={columns}
          label="Columns"
          variant="outlined"
          InputProps={{
            inputProps: {
              min: 1,
            },
          }}
        />
      </CenterDiv>
      {!editPage && 
        <CenterDiv>
          <CheckboxInput
            title="Allow Dynamic Sizing?"
            labelStyle={{ color: 'black' }}
            checked={allowDynamicSizing}
            onChange={(value) => setAllowDynamicSizing(value)}
            />
          <Tooltip
            placement="top"
            style={{ marginLeft: '-21px' }}
            classes={{ tooltip: classes.tooltip }}
            title={'When using a small screen, allows custom page to display items vertically, regardless of page layout.'}
          >
            <HelpOutlineOutlinedIcon style={{ fontSize: 17, marginLeft: -13 }} color="action" />
          </Tooltip>
        </CenterDiv>
      }
      {!editPage && 
        <CenterDiv>
          <CheckboxInput
            title="Is public?"
            labelStyle={{ color: 'black' }}
            checked={isPublic}
            onChange={(value) => setIsPublic(value)}/>
          <Tooltip
            placement="top"
            style={{ marginLeft: '-21px' }}
            classes={{ tooltip: classes.tooltip }}
            title={'If the page is public, you will be able to view this page without logging in at the URL below.'}
          >
            <HelpOutlineOutlinedIcon style={{ fontSize: 17, marginLeft: -13 }} color="action" />
          </Tooltip>
        </CenterDiv>
      }
      {!editPage && 
        <InputGroup title="Page URL" style={{ margin: '10px 0' }}>
          /page/{pageName + (aggregateType ? '/{id}' : '')}
        </InputGroup>
      }

      {/* Disabling for now; Aggregate Type is currently mandatory when creating a new page. */}
      {/* {!editPage && 
        <SelectInput
          title="Aggregate"
          options={getAggregateList()}
          onChange={(value) => setAggregateType(value)}
          value={aggregateType}
        />
      } */}

      <PageEditor
        pageDefinition={pageDefinition}
        setPageDefinition={setPageDefinition}
        isGroup={!!editPage}
        fullPageDefinition={fullPage || pageDefinition}
        reset={reset}
      />

      {!editPage && (
        <ButtonContainer>
          <Button
            color="default"
            variant="contained"
            onClick={handleCancelChanges}
            style={{
              margin: '10px 15px 10px 0',
              backgroundColor: 'white',
              color: 'black',
            }}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={save} style={{ margin: '10px 0' }}>
            Save
          </Button>
        </ButtonContainer>
      )}
      <Prompt message={ifShowMessageBeforeRedirect} />
    </AggregateGeneralInfoContainer>
  );
});

const AggregateGeneralInfoContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  padding: 10px 20px;
  max-width: 600px;
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;
