import React, { FunctionComponent } from 'react';
import { V2MapComponent } from '@terragotech/page-renderer';
import styled from 'styled-components';
import { NestedPageControlProps } from '../PageTypes';
import { StandardInputFrame, Label } from './Common';

const Placeholder = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #aaaaaa;
  margin-right: 15px;
`;
const PlaceHolderFrame = styled.div`
  display: flex;
  margin-left: 15px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;
const MapControl: FunctionComponent<NestedPageControlProps<V2MapComponent>> = (props) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.displayOptions)}
      />
      <PlaceHolderFrame>
        <Placeholder>{controlDefinition.placeholder}</Placeholder>
      </PlaceHolderFrame>
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default MapControl;
