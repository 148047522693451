import React, { useContext, useMemo, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { ConfigContext, useConfig } from '../../../../context/ConfigContext';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { successMsg } from '../../../../components/SnackbarUtilsConfigurator';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { getExpandedURLArr } from '../../../../utils/navigationUtils';
import { useCustomPageAPI } from '../../../../context/fakeAPIHooks/useCustomPageAPI';
import _ from 'lodash';
export const NO_CATEGORY_NAME_PLACEHOLDER = 'Ad-Hoc';

export const CustomPagesList: React.FC = () => {
  const { config } = useConfig();
  const { getCustomPages } = useContext(ConfigContext);
  const CustomPageAPI = useCustomPageAPI();
  const confirm = useConfirm();
  const history = useHistory();
  const params = useParams() as { customPage: string };
  const selectedCustomPage = params.customPage;

  const handleDeleteCustomPage = async (name: string) => {
    await confirm({
      description: 'The selected custom page will be permanently deleted.',
      confirmationText: 'Delete',
    });
    if (name === selectedCustomPage) history.push('/customPages');
    const { error } = await CustomPageAPI.deletePage(name);
    if (error) return;
    successMsg(`The custom page "${name}" has been successfully deleted`);
  };

  const [expandedNodes, setExpandedNodes] = useState<string[] | undefined>(
    getExpandedURLArr(history.location.pathname)
  );

  const pageGroupList = useMemo(() => {
    const groupNames = [NO_CATEGORY_NAME_PLACEHOLDER, ...config?.aggregates.map((a) => a.typeName)];
    const groups = _.groupBy(
      Object.entries(getCustomPages()),
      ([_, page]) => page.aggregateType ?? NO_CATEGORY_NAME_PLACEHOLDER
    );

    return groupNames.reduce((acc, groupName) => {
      acc.push({
        groupName,
        pageNames: groups[groupName]?.map(([pageName, _]) => pageName) ?? [],
      });
      return acc;
    }, [] as Array<{ groupName: string; pageNames: string[] }>);
  }, [config?.aggregates, getCustomPages]);

  return Object.keys(getCustomPages()).length !== 0 ? (
    <ScrollableTreeContainer>
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={(_: object, nodeId: string) => {
          history.push(nodeId);
        }}
        selected={history.location.pathname}
        style={{ marginRight: 6 }}
        onNodeToggle={(_: object, nodeIds: string[] | undefined) => setExpandedNodes(nodeIds)}
        expanded={expandedNodes}
      >
        {pageGroupList
          .filter((x) => x.pageNames.length > 0)
          .map(({ groupName, pageNames }) => (
            <TreeViewNode
              label={groupName}
              key={groupName}
              nodeId={`/customPages/group/${groupName}`}
              count={pageNames.length}
              color="green"
            >
              {pageNames.map((name, customPageIndex) => (
                <Wrapper key={name}>
                  <TreeViewNode
                    label={name}
                    nodeId={`/customPages/${name}`}
                    onDelete={() => handleDeleteCustomPage(name)}
                    color={customPageIndex}
                  />
                </Wrapper>
              ))}
            </TreeViewNode>
          ))}
      </TreeView>
    </ScrollableTreeContainer>
  ) : (
    <p style={{ color: 'rgba(0, 0, 0, 0.54', fontSize: 14, textAlign: 'center' }}>
      No custom pages defined
    </p>
  );
};
const Wrapper = styled.div`
  padding-left: 15px;
  & * ul {
    padding-left: 15px;
  }
`;

const ScrollableTreeContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;
