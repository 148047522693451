import React from 'react';
import { InputGroup, TextInput } from './FormElements';
import {
  Switch,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  // Typography,
  Tooltip,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import _ from 'lodash';
import styled from 'styled-components';

interface RepeatableProps {
  enabled?: boolean;
  min: number;
  max: number;
  addButtonText?: string;
  deleteConfirmationMessage?: string;
  allowReordering?: boolean;
  allowInitialDataDelete?: boolean;
  allowAdding?: boolean;
}
interface ComponentProps {
  repeats: RepeatableProps;
  setRepeats: React.Dispatch<React.SetStateAction<RepeatableProps>>;
  disabledSwitch?: boolean;
}
export const defaultRepeatableProps: RepeatableProps = {
  enabled: false,
  min: 1,
  max: 1,
  addButtonText: 'Add Field',
  deleteConfirmationMessage: 'Are you sure you want to delete this?',
  allowReordering: true,
  allowInitialDataDelete: true,
  allowAdding: true,
};
const IconWithTooltip = ({ className, helperText }: { className: string; helperText: string }) => (
  <Tooltip title={helperText} className={className}>
    <HelpIcon fontSize={'small'} />
  </Tooltip>
);

/*
//todo: in order to give backward compatibility we need to change the definition like below inside the formrenderer library
export interface V2RepeatableTemplateComponent extends V2FormComponent {
  repeats?: {
    enabled?: boolean;
    min: number;
    max: number;
    addButtonText?: string;
    deleteConfirmationMessage?: string;
    allowReordering?: boolean;
    allowInitialDataDelete?: boolean;
    allowAdding?: boolean;
  };
}*/
const RepeatableComponent = (props: ComponentProps) => {
  const { repeats, setRepeats, disabledSwitch } = props;
  const classes = useStyles();

  return (
    <InputGroup title="Repeating instances" style={{ margin: '10px 25px' }}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            onChange={(e, checked) => {
              setRepeats({ ...repeats, enabled: checked });
            }}
            checked={repeats.enabled}
            disabled={disabledSwitch}
          />
        }
        label="Show multiple instances of this field"
        color="primary"
      />
      <FormHelperText style={{ fontSize: 12, color: '#6F6F6F', marginBottom: 20 }}>
        configure how many instances of this field will show up in the workflow. Use this command's
        "Initial Data Map" to load this data into the instances configured below if desired
      </FormHelperText>
      {repeats.enabled && (
        <div>
          <MinMaxContainer>
            <div className={classes.minMax}>
              <TextInput
                id="Minimum"
                value={repeats.min}
                onChange={(value) => setRepeats({ ...repeats, min: _.toNumber(value) })}
                fullWidth={true}
                type="number"
                style={{ margin: 0 }}
              />
              <IconWithTooltip
                className={classes.minMaxToolTip}
                helperText={'The required # of instances'}
              />
            </div>
            <div className={classes.minMax} style={{ marginLeft: 20 }}>
              {repeats.max !== -1 && (
                <TextInput
                  id="Maximum"
                  value={repeats.max}
                  onChange={(value) => setRepeats({ ...repeats, max: _.toNumber(value) })}
                  fullWidth={true}
                  type="number"
                  style={{ margin: 0 }}
                  disabled={repeats.max === -1}
                />
              )}
              {repeats.max === -1 && (
                <TextInput
                  id="Maximum"
                  value="unlimited"
                  fullWidth={true}
                  style={{ margin: 0 }}
                  disabled={repeats.max === -1}
                />
              )}
              <FormControlLabel
                style={{ position: 'absolute', right: 20 }}
                control={<Switch checked={repeats.max === -1} color="primary" />}
                onChange={(e, checked) => {
                  if (checked) {
                    setRepeats({ ...repeats, max: -1 });
                  } else {
                    setRepeats({ ...repeats, max: 1 });
                  }
                }}
                label="unlimited"
                color="primary"
              />
              <IconWithTooltip
                className={classes.minMaxToolTip}
                helperText={'The allowed # of instances'}
              />
            </div>
          </MinMaxContainer>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <MinMaxContainer>
                <TextInput
                  id={`"Add" button Label`}
                  value={repeats.addButtonText}
                  onChange={(value) =>
                    setRepeats({
                      ...repeats,
                      addButtonText: value || defaultRepeatableProps.addButtonText,
                    })
                  }
                  fullWidth={true}
                  type="text"
                />
                <IconWithTooltip
                  className={classes.minMaxToolTip}
                  helperText={
                    'This is the button the user sees in the workflow that repeats this field when selected, adding additional instances up to the maximum specified above.'
                  }
                />
              </MinMaxContainer>
              <FormGroup>
                <CheckBoxContainer style={{ position: 'relative' }}>
                  <FormControlLabel
                    // control={<Checkbox checked={repeats.allowReordering} color="primary" />}
                    control={<Checkbox checked={false} color="primary" />}
                    label="Allow reordering"
                    disabled={true}
                    onChange={(e, checked) => {
                      setRepeats({ ...repeats, allowReordering: checked });
                    }}
                  />
                  <IconWithTooltip
                    className={classes.checkboxToolTip}
                    helperText={
                      'Allow instances of this field to be reordered by the user in the workflow'
                    }
                  />
                </CheckBoxContainer>
                <CheckBoxContainer style={{ position: 'relative' }}>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={repeats.allowInitialDataDelete} />}
                    label="Allow deleting of initial data"
                    onChange={(e, checked) => {
                      setRepeats({ ...repeats, allowInitialDataDelete: checked });
                    }}
                  />
                  <IconWithTooltip
                    className={classes.checkboxToolTip}
                    helperText={
                      'Allow users to delete initial data, if the minimum number of instances has not yet ben met.'
                    }
                  />
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <FormControlLabel
                    control={<Checkbox color="primary" checked={repeats.allowAdding} />}
                    label="Allow user to add instances"
                    onChange={(e, checked) => {
                      setRepeats({ ...repeats, allowAdding: checked });
                    }}
                  />
                  <IconWithTooltip
                    className={classes.checkboxToolTip}
                    helperText={
                      'Allow users to add additional instances on the workflow, up to the "Maximum" set above'
                    }
                  />
                </CheckBoxContainer>
              </FormGroup>
            </div>
            <TextField
              id="outlined-multiline-static"
              label="Delete confirmation message"
              multiline
              rows={5}
              value={repeats.deleteConfirmationMessage}
              style={{ width: '50%', marginLeft: 20, marginTop: 10 }}
              variant={'outlined'}
              color="primary"
              onChange={(e) =>
                setRepeats({
                  ...repeats,
                  deleteConfirmationMessage:
                    e.target.value || defaultRepeatableProps.deleteConfirmationMessage,
                })
              }
            />
          </div>
        </div>
      )}
    </InputGroup>
  );
};

const useStyles = makeStyles({
  minMax: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  minMaxToolTip: { position: 'absolute', right: 10 },
  checkboxToolTip: { position: 'relative' },
});

const MinMaxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10;
`;
const CheckBoxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default RepeatableComponent;
