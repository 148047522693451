export const defaultConfirm = ({
  confirmationText,
  description,
}: {
  confirmationText: string;
  description: string;
}): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (window.confirm(description)) {
      resolve();
    }
    reject();
  });
};
