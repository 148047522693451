import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { CanvasWidget } from '@projectstorm/react-canvas-core';

import { DiagramModel, DiagramEngine, DagreEngine } from '@projectstorm/react-diagrams';
import DropLayer from './DropLayer/DropLayer';
import ConfirmationContextProvider from './Contexts/ConfirmationContext';
import { defaultConfirm } from './Utils/defaultConfirm';
import MenuManager from './MenuManager/MenuManager';
import { CanvasBackground } from './Components/CanvasBackground';
import { MapScenarioType, mapScenarios } from '@terragotech/gen5-datamapping-lib';

function useForceUpdate() {
  const [, set] = useState(true); // boolean state
  return () => set((value) => !value); // toggle the state to force render
}
interface EventMapDiagram {
  mapScenario?: keyof MapScenarioType;
  model?: DiagramModel;
  engine: DiagramEngine;
  confirm?: ({
    confirmationText,
    description,
  }: {
    confirmationText: string;
    description: string;
  }) => Promise<void>;
  hasSetPosition?: boolean;
}

const dagreEngine = new DagreEngine({
  graph: {
    rankdir: 'LR',
    align: 'DR',
    ranker: 'longest-path',
    marginx: 50,
    marginy: 50,
    nodesep: 100,
    ranksep: 150,
  },
  includeLinks: false,
});
export const EventMapDiagram: FunctionComponent<EventMapDiagram> = ({
  engine,
  model,
  confirm,
  mapScenario,
  hasSetPosition
}) => {
  // on first render, we want to wait for one full draw and then distribute all nodes
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current && model && engine) {
      firstRender.current = false;
      setTimeout(() => {
        if(!hasSetPosition){
          dagreEngine.redistribute(model);
        }
        engine.zoomToFit();
        engine.repaintCanvas();
      }, 0);
    }
  }, [model, engine, hasSetPosition]);
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    // This logic is used to verify that all required nodes are present when working on the map
    if (model && mapScenario) {
      const scenario = mapScenarios[mapScenario];
      if (scenario) {
        const existingNodes = model.getNodes();
        const missingNodes = scenario.requiredNodes.filter((node) => {
          return (
            existingNodes.findIndex((existingNode) => existingNode.getID() === node.nodeId) < 0
          );
        });
        if (missingNodes.length > 0) {
          //If the node exists in the default node map, add to creatable Node list
          const creatableNodes = missingNodes.filter(
            (node) => !!(scenario.defaultNodeMap.nodes as any)[node.nodeId]
          );
          if (creatableNodes.length > 0 && confirm) {
            confirm({
              confirmationText: 'Create Nodes',
              description:
                'There are required nodes missing from this map. It may not work properly if the missing nodes are not created',
            }).then(() => {
              creatableNodes.forEach((creatableNode, index) => {
                const node = (scenario.defaultNodeMap.nodes as any)[creatableNode.nodeId];
                if (engine) {
                  const nodeFactory = engine.getNodeFactories().getFactory(node.type);
                  //const nodeFactory = basicNodeFactories[node.type];
                  if (nodeFactory) {
                    const transformNodeModel = nodeFactory.generateModel({
                      initialConfig: { ...node, id: creatableNode.nodeId },
                    });
                    transformNodeModel.setPosition(350, index * 150 + 50);
                    model.addNode(transformNodeModel);
                  } else {
                    console.error(`Transform type does not exist: ${node.type}`);
                  }
                }
              });
            });
          }
        }
      }
    }
  }, [model, engine, mapScenario, confirm]);
  return (
    <ConfirmationContextProvider confirm={confirm || defaultConfirm}>
      <Container>
        {model && (
          <MenuManager diagramEngine={engine} mapScenario={mapScenario}>
            <DropLayer diagramEngine={engine} onDroppedNode={forceUpdate}>
              <CanvasBackground>
                <CanvasWidget engine={engine} />
              </CanvasBackground>
            </DropLayer>
          </MenuManager>
        )}
      </Container>
    </ConfirmationContextProvider>
  );
};

const Container = styled.div`
  flex: 1 1 auto;
  background-color: rgb(100, 100, 100) !important;
  background-size: 50px 50px;
  display: flex;
  height: 100%;
`;
