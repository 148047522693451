import React, { useContext } from 'react';
import { SelectInput } from '../../../components/FormElements/SelectInput';
import { ConfigContext } from '../../../context/ConfigContext';
import { useParams } from 'react-router-dom';
import { RelationalPropertyType } from '@terragotech/gen5-config-lib';
import { AggrPropertyRow } from '../../../utils/types';
interface AnchoredLineEndpointsInputProps {
  value?: string[];
  row: AggrPropertyRow;
  onChange: (value: string[] | undefined) => void;
}

export const AnchoredLineEndpointsInput: React.FC<AnchoredLineEndpointsInputProps> = ({
  row,
  onChange,
  value,
}) => {
  const { config } = useContext(ConfigContext);
  const { aggregate } = useParams() as { aggregate: string };
  const getFilteredProperties = () => {
    return Object.entries(
      config.aggregates.find((aggr) => aggr.typeName === aggregate)?.properties || {}
    )
      .filter(
        ([name, value]) =>
          name !== row.name && (value as RelationalPropertyType).relation === 'ONE-TO-ONE'
      )
      .map((item) => item[0]);
  };

  const handleOnChange = (value: string[]) => onChange(value.length === 0 ? undefined : value);

  return (
    <SelectInput
      options={getFilteredProperties()}
      value={value || []}
      onChange={handleOnChange}
      multiple
    />
  );
};
