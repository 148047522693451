import React, { useState, useContext, useCallback } from 'react';
import { DialogTitle, DialogActions, Button } from '@material-ui/core';
import { TextInput, InputGroup, CheckboxInput, MapperList, SelectInput } from '../FormElements';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import DataMapperDialog from './DataMapperDialog';
import { useConfirm } from 'material-ui-confirm';
import { JSONSchema6 } from 'json-schema';
import { isEqual } from 'lodash';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { useFormSchemas } from './hooks/useFormSchemas';
import { ConfigContext } from '../../context/ConfigContext';
import { V2MapAggregateSelectorComponent } from '@terragotech/form-renderer';
import { FormContext } from './contexts/FormContext';
import { checkDuplicateFormName, DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import _ from 'lodash';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import { expandPath } from './TextInputEditForm';
import { getPropertyObjectFromFormComponent } from '../../pages/aggregates/utils/V2FormTemplateToJsonSchema';

export type AggregateSelectorTemplateWithName = V2MapAggregateSelectorComponent & {
  name: string;
  droppableId: string;
};

interface MapAggregateSelectorProps {
  onSubmit: (result: AggregateSelectorTemplateWithName) => void;
  onClose: () => void;
  component: AggregateSelectorTemplateWithName;
  autosuggest?: boolean;
}

export const MapAggregateSelector: React.FC<MapAggregateSelectorProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const { getAggregates } = useContext(ConfigContext);
  const confirm = useConfirm();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [selectableAggregateTypes, setSelectableAggregateTypes] = useState(
    component.selectableAggregateTypes || []
  );
  const [required, setRequired] = useState(component.required);
  const [placeholder, setPlaceholder] = useState(component.placeholder);
  const [description, setDescription] = useState(component.description);
  const [info, setInfo] = useState(component.info);
  const [readOnly, setReadOnly] = useState(component.readOnly);
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const [errorMap, setErrorMap] = useState<NodeMapDefinition[] | undefined>(
    component.errorMap || undefined
  );
  const [warningMap, setWarningMap] = useState<NodeMapDefinition[] | undefined>(
    component.warningMap || undefined
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);

  const formSchemas = useFormSchemas();

  const [existingNameError, setExistingNameError] = useState(false);
  const { formDefinition } = useContext(FormContext);

  const getSelectableAggregateOptions = getAggregates().map((item) => item.typeName);

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'form'
      ? []
      : expandPath(parts, formSchemas.FORM.schema.properties as JSONSchema6);

  let formValue = { value: getPropertyObjectFromFormComponent(component) };
  for (let i = 0; i < t.length; i++) {
    formValue = { ...formValue, ...t[i] };
  }
  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: formValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...formSchemas,
  };
  const handleClearConditionalMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setConditionalMap(undefined);
  };

  const getFormValues = () => ({
    type: component.type,
    name,
    label,
    ...(selectableAggregateTypes.length > 0 && { selectableAggregateTypes }),
    ...(placeholder && { placeholder }),
    ...(required !== undefined && { required }),
    ...(description && { description }),
    ...(info && { info }),
    ...(readOnly !== undefined && { readOnly }),
    repeats,
    ...(conditionalMap && { conditionalMap }),
    ...(errorMap && { errorMap }),
    ...(warningMap && { warningMap }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The form has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    if (checkDuplicateFormName(name, component, formDefinition.components)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };
  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);
  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <TextInput
        autoFocus
        id="Name"
        error={existingNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />

      <SelectInput
        title="Selectable Aggregate Types"
        options={getSelectableAggregateOptions}
        value={selectableAggregateTypes || []}
        onChange={setSelectableAggregateTypes}
        style={{ margin: '5px 25px 0' }}
        labelStyle={{ margin: '5px 25px 0' }}
        multiple
      />
      <CheckboxInput
        title="Required"
        checked={required}
        onChange={(value) => setRequired(value)}
        style={{ margin: '10px 25px' }}
      />
      <TextInput
        id="Placeholder"
        value={placeholder}
        onChange={(value) => setPlaceholder(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Description"
        value={description}
        onChange={(value) => setDescription(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Info"
        value={info}
        onChange={(value) => setInfo(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <CheckboxInput
        title="Read-only"
        checked={readOnly}
        onChange={(value) => setReadOnly(value)}
        style={{ margin: '10px 25px' }}
      />
      <RepeatableComponent repeats={repeats} setRepeats={setRepeats} />
      <InputGroup title="Conditionals" style={{ margin: '10px 25px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button onClick={() => setConditionalOpen(true)}>
            <div>
              Advanced
              <MappingActiveDisplay
                isActive={doesConditionalHaveValue()}
                activeLabelText={'active'}
              />
            </div>
          </Button>
          <Button onClick={handleClearConditionalMapper}>Clear</Button>
        </div>
        <DataMapperDialog
          mapScenario="FORM_FIELD_CONDITIONAL"
          localSchemaDefinitions={errorWarningSchemas}
          onClose={() => {
            setConditionalOpen(false);
          }}
          open={conditionalOpen}
          datamap={conditionalMap}
          setDatamap={setConditionalMap}
        />
      </InputGroup>
      <MapperList
        title="Error Mapping"
        data={errorMap || []}
        localSchemas={errorWarningSchemas}
        setData={(data) => setErrorMap(data)}
        mapScenario={'FIELD_LEVEL_ERROR'}
      />
      <MapperList
        title="Warning Mapping"
        data={warningMap || []}
        localSchemas={errorWarningSchemas}
        setData={(data) => setWarningMap(data)}
        mapScenario={'FIELD_LEVEL_WARNING'}
      />
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
