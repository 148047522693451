import React, { useContext, useRef } from 'react';
import { Paper } from '@material-ui/core';
import { cloneDeep, get } from 'lodash';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import styled from 'styled-components';
import { ConfigContext } from '../../../context/ConfigContext';
import { successMsg, errorMsg } from '../../../components/SnackbarUtilsConfigurator';
import { ActionButton, MobileActionBuiltIns } from '@terragotech/gen5-config-lib';
import {
  convertCommandRefToString,
  getAggregateList,
  commandActionButtonActionToRef,
} from '../../../utils/jsonPartsGenerators';
import { EditableTable, MaterialTableRef } from '../../../components/EditableTable';
import { getFabActionColumns } from '../../../utils/jsonPartsGenerators';
import { Prompt } from 'react-router-dom';
import { useUICustomizationAPI } from '../../../context/fakeAPIHooks/useUICustomizationAPI';
import { FabAction, ActionButtonRow } from '../../../utils/types';

type MobileActionButton = ActionButton<MobileActionBuiltIns>;

interface Props {
  type: 'mobile' | 'web' | 'webImport';
}

export type FabActionRow = MobileActionButton & { aggregateName?: string };
type FabActionRowWithID = FabActionRow & { tableData: { id: number } };

export const FabActions: React.FC<Props> = ({ type }) => {
  const { config } = useContext(ConfigContext);

  const UICustomizationAPI = useUICustomizationAPI();
  const tableRef = useRef<MaterialTableRef>(null);

  const UIConfigPath =
    type === 'mobile'
      ? 'mobileUIConfig.fabActions'
      : type === 'web'
      ? 'webUIConfig.fabActions'
      : 'webUIConfig.importActions';
  const UIConfig = type === 'mobile' ? 'mobileUIConfig' : 'webUIConfig';
  const title = type === 'webImport' ? 'Import Actions' : 'Fab Actions';
  const updateFunction =
    type === 'webImport'
      ? UICustomizationAPI.updateImportAction
      : UICustomizationAPI.updateFabAction;
  const addFunction =
    type === 'webImport' ? UICustomizationAPI.addImportAction : UICustomizationAPI.addFabAction;
  const deleteFunction =
    type === 'webImport'
      ? UICustomizationAPI.deleteImportAction
      : UICustomizationAPI.deleteFabAction;
  const moveFunction =
    type === 'webImport' ? UICustomizationAPI.moveImportAction : UICustomizationAPI.moveFabAction;

  const fabActions = cloneDeep(get(config, UIConfigPath) || []);

  const getFabActions = () =>
    (fabActions as FabAction[]).map((item) => ({
      aggregateName: item.aggregateName,
      ...item.button,
      action: convertCommandRefToString(item.button.action),
    }));

  const convertRowToFabAction = ({ aggregateName, ...rest }: FabActionRow) => ({
    aggregateName,
    button: commandActionButtonActionToRef({ ...rest } as ActionButtonRow),
  });

  const isButtonInvalid = (row: object) => {
    if (!(row as FabActionRow).aggregateName) {
      errorMsg('Aggregate name must be defined');
      return true;
    }
    if (!(row as FabActionRow).action) {
      errorMsg('Action must be defined');
      return true;
    }
    return false;
  };

  const addFabAction = async (row: object, resolve: (data: any) => void, reject: () => void) => {
    if (isButtonInvalid(row)) return reject();
    const fabAction = convertRowToFabAction(row as FabActionRow);
    const { error } = await addFunction(UIConfig, fabAction);
    if (error) reject();
    successMsg('Command action has been successfully added');
    resolve(null);
  };

  const updateFabAction = async (
    newRow: object,
    oldRow: object,
    resolve: (data: any) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(newRow)) return reject();
    const fabAction = convertRowToFabAction(newRow as FabActionRow);
    const fabActionIndex = (oldRow as FabActionRowWithID).tableData.id;
    const { error } = await updateFunction(UIConfig, fabActionIndex, fabAction);
    if (error) reject();
    successMsg('Editor action has been successfully updated');
    resolve(null);
  };

  const deleteFabAction = async (rowToDel: object) => {
    const fabActionIndex = (rowToDel as FabActionRowWithID).tableData.id;
    const { error } = await deleteFunction(UIConfig, fabActionIndex);
    if (error) return;
    successMsg('Editor action has been successfully deleted');
  };

  const moveRowFabAction = async (row: FabActionRowWithID, direction: 'UP' | 'DOWN') => {
    const fabActionIndex = (row as FabActionRowWithID).tableData.id;
    const { error } = await moveFunction(UIConfig, fabActionIndex, direction);
    if (error) return;
    if (direction === 'UP') {
      successMsg('Editor action has been moved up');
    }
    if (direction === 'DOWN') {
      successMsg('Editor action has been moved down');
    }
  };

  const fabActionAdditionalActions = [
    {
      icon: () => <KeyboardArrowUpIcon />,
      tooltip: 'Move up',
      onClick: (_: object, rowData: object) =>
        moveRowFabAction(rowData as FabActionRowWithID, 'UP'),
    },
    {
      icon: () => <KeyboardArrowDownIcon />,
      tooltip: 'Move down',
      onClick: (_: object, rowData: object) =>
        moveRowFabAction(rowData as FabActionRowWithID, 'DOWN'),
    },
  ];

  const getFabActionEditorColumns = getFabActionColumns(
    getAggregateList(config),
    type === 'webImport'
  );

  const ifShowMessageBeforeRedirect = () => {
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    return lastEditingRow || showAddRow
      ? 'The form has not been saved, do you want to redirect?'
      : true;
  };

  return (
    <PropertiesContainer>
      <CardTitle>{title}</CardTitle>
      <EditableTable
        columns={getFabActionEditorColumns}
        data={getFabActions()}
        toolbarStyle={otherAttributesTableToolbarStyle}
        onAdd={addFabAction}
        onUpdate={updateFabAction}
        onDelete={deleteFabAction}
        options={tableOptions}
        actions={fabActionAdditionalActions}
        tableRef={tableRef}
      />
      <Prompt message={ifShowMessageBeforeRedirect} />
    </PropertiesContainer>
  );
};

const PropertiesContainer = styled(Paper)`
  padding: 10px 20px;
  margin: 5px;
  flex: 1;
  overflow-x: auto;
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
  draggable: false,
};
