import * as React from 'react';
import { JSONSchema6 } from 'json-schema';
import ConfigPropertyDisplay from './ConfigPropertyDisplay';
import ConfigPropertyEditor from './ConfigPropertyEditor';
import styled from 'styled-components';

const BASE_HEIGHT = 24;
export const ConfigPropertyFrame = styled.div`
  height: ${BASE_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  color: #fff;
`;
interface ConfigPropertyProps<T> {
  isEditing: boolean;
  onChange: (val: T) => void;
  value: T;
  portValue?: string;
  onRemove?: () => void;
  options?: Array<{ optionLabel: string; optionValue: string }>;
  schemaType?: JSONSchema6;
  showLabel: boolean;
}
export const ConfigProperty: React.FunctionComponent<ConfigPropertyProps<unknown>> = (props) => {
  const { isEditing, onChange, value, onRemove, schemaType, showLabel, portValue } = props;

  return schemaType ? ( //@ts-ignore
    <ConfigPropertyFrame>
      {!isEditing ? (
        <ConfigPropertyDisplay
          propertyValue={value}
          propertySchema={schemaType}
        ></ConfigPropertyDisplay>
      ) : (
        <ConfigPropertyEditor
          showLabel={showLabel}
          onChange={onChange}
          onRemove={onRemove}
          propertySchema={schemaType}
          propertyValue={value}
          portValue={portValue}
        />
      )}
    </ConfigPropertyFrame>
  ) : (
    <></>
  );
};
