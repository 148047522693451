import * as React from 'react';
import styled from 'styled-components';
import { useConfig } from '../../context/ConfigContext';
import { Divider, Paper } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import JSONTree from 'react-json-tree';

export const Overview: React.FC = () => {
  const { config } = useConfig();

  return (
    <AggregatesListContainer>
      <CardTitle>JSON Overview</CardTitle>
      <GroupDivider />
      <CardBody className="jsonTreeContainer">
        {/* @ts-ignore */}
        <JSONTree
          data={cloneDeep(config)}
          //@ts-ignore
          shouldExpandNode={(_, __, level) => level < 4}
          hideRoot={true}
        />
      </CardBody>
    </AggregatesListContainer>
  );
};

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
  }
`;

const AggregatesListContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
  margin: 5px;
  max-height: calc(100vh - 100px);
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
  margin-left: 20px;
`;

const CardBody = styled.div`
  background: #212121;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;
