import { makeStyles } from '@material-ui/core';
import React from 'react';

interface MappingActiveDisplayProps {
  isActive: boolean;
  activeLabelText: string;
}
export const MappingActiveDisplay: React.FC<MappingActiveDisplayProps> = ({
  isActive,
  activeLabelText,
}) => {
  const classes = useStyles();

  return isActive ? (
    <div className={classes.mappingActiveContainer}>
      <span className={classes.mappingActiveContainerTextDivider}> </span>
      <span className={classes.mappingActiveContainerText}>{activeLabelText}</span>
      <span className={classes.mappingActiveContainerTextDivider}> </span>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

const useStyles = makeStyles({
  mappingActiveContainer: {
    textTransform: 'lowercase',
    color: '#64B6F7',
    size: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mappingActiveContainerText: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  mappingActiveContainerTextDivider: {
    flex: 1,
    height: 1,
    width: '5px',
    backgroundColor: '#64B6F7',
  },
});
