import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { TreeViewNode } from '../../components/TreeViewNode';
import { useHistory } from 'react-router-dom';
import { getPathToLevel } from '../../utils/navigationUtils';
import { CollapsibleMenuCard } from '../../components/AppContainer/CollapsibleMenuCard';

export const General: React.FC = () => {
  const history = useHistory();

  return (
    <CollapsibleMenuCard title="General">
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={(_: object, nodeId: string) => {
          history.push(nodeId);
        }}
        selected={getPathToLevel(history.location.pathname, 3)}
        style={{ marginRight: 6 }}
      >
        <TreeViewNode
          nodeId="/features/general/legalDisclaimer"
          label="Legal Disclaimer"
          key="Legal Disclaimer"
          color="green"
        />
        <TreeViewNode
          nodeId="/features/general/geographic"
          label="Geographic"
          key="Geographic"
          color="green"
        />
      </TreeView>
    </CollapsibleMenuCard>
  );
};
