import React, { useContext } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { AggregateConfig } from '@terragotech/gen5-config-lib/dist/AggregateConfig';

import { ConfigContext } from '../../../../context/ConfigContext';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { useParams } from 'react-router-dom';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';

interface EventsTreeProps {
  aggregate: AggregateConfig;
  aggrIndex: number;
}

export const EventsTree: React.FC<EventsTreeProps> = ({ aggregate, aggrIndex }) => {
  const { getAggregate, getEventVersions } = useContext(ConfigContext);
  const confirm = useConfirm();
  const AggregateAPI = useAggregateAPI();
  const { aggregate: typeName } = useParams() as { aggregate: string };

  const handleAddEventVersion = async (eventName: string, versionIndex: number) => {
    await confirm({
      description: `Do you want to create new version of "${eventName}" event?`,
      confirmationText: 'Create',
    });
    const { error } = await AggregateAPI.addEventVersion(aggrIndex, eventName, versionIndex);
    if (error) return;
    successMsg(`New version of the "${eventName}" has been successfully created`);
  };

  const handleRemoveEvent = async (eventName: string) => {
    const aggrName = getAggregate(aggrIndex).typeName;
    await confirm({
      description: `An event "${eventName}" of the "${aggrName}" aggregate will be permanently deleted.`,
      confirmationText: 'Delete',
    });
    const { error } = await AggregateAPI.removeEvent(aggrIndex, eventName);
    if (error) return;
    successMsg(`An event "${eventName}" of the "${aggrName}" has been successfully deleted.`);
  };

  const handleRemoveVersion = async (
    eventName: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    const aggrName = getAggregate(aggrIndex).typeName;
    await confirm({
      description: `The version "${versionNumber}" of the event "${eventName}" belogning to the "${aggrName}" aggregate will be permanently deleted.`,
      confirmationText: 'Delete',
    });
    const versions = getEventVersions(aggrIndex, eventName);
    if (versions?.length === 1) {
      return errorMsg(
        'At least one version of the event have to remain. Consider deleting the entire event instead'
      );
    }
    const { error } = await AggregateAPI.removeEventVersion(aggrIndex, eventName, versionIndex);
    if (error) return;
    successMsg(
      `The version "${versionNumber}" of the event "${eventName}" belogning to the "${aggrName}" has been successfully deleted.`
    );
  };

  const handleResetEventSchema = async (
    eventName: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    const aggrName = getAggregate(aggrIndex).typeName;
    await confirm({
      description: `The schema of the version "${versionNumber}" of the event "${eventName}" belogning to the "${aggrName}" aggregate will be cleared.`,
      confirmationText: 'Clear',
    });
    const { error } = await AggregateAPI.resetEventSchema(aggrIndex, eventName, versionIndex);
    if (error) return;
    successMsg(
      `The schema of the version "${versionNumber}" of the event "${eventName}" belogning to the "${aggrName}" has been successfully cleared.`
    );
  };

  const handleResetEventAggregateMap = async (
    eventName: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    const aggrName = getAggregate(aggrIndex).typeName;
    await confirm({
      description: `The aggregate map of the version "${versionNumber}" of the event "${eventName}" belogning to the "${aggrName}" aggregate will be cleared.`,
      confirmationText: 'Clear',
    });
    const { error } = await AggregateAPI.resetEventAggregateMap(aggrIndex, eventName, versionIndex);
    if (error) return;
    successMsg(
      `The aggregate map of the version "${versionNumber}" of the event "${eventName}" belogning to the "${aggrName}" has been successfully cleared.`
    );
  };

  return (
    <>
      {aggregate?.events &&
        Object.entries(aggregate.events).map(
          ([eventName, event]) =>
            event && (
              <TreeViewNode
                label={eventName}
                key={eventName}
                nodeId={`/aggregates/${typeName}/events/${eventName}`}
                count={event.versions ? event.versions.length : 0}
                onDelete={() => handleRemoveEvent(eventName)}
                color="orange"
              >
                {event.versions.map((version, versionIndex) => (
                  <TreeViewNode
                    label={`Version: ${version.versionNumber}`}
                    key={version.versionNumber}
                    nodeId={`/aggregates/${typeName}/events/${eventName}/version/${versionIndex}`}
                    onAdd={() => handleAddEventVersion(eventName, versionIndex)}
                    onDelete={() =>
                      handleRemoveVersion(eventName, versionIndex, version.versionNumber)
                    }
                    color={versionIndex}
                  >
                    <TreeViewNode
                      label="Event schema"
                      nodeId={`/aggregates/${typeName}/events/${eventName}/version/${versionIndex}/eventSchema`}
                      onRemove={() =>
                        handleResetEventSchema(eventName, versionIndex, version.versionNumber)
                      }
                      color="orange"
                    />
                    <TreeViewNode
                      label="Aggregate map"
                      nodeId={`/aggregates/${typeName}/events/${eventName}/version/${versionIndex}/aggregateMap`}
                      onRemove={() =>
                        handleResetEventAggregateMap(eventName, versionIndex, version.versionNumber)
                      }
                      color="purple"
                    />
                  </TreeViewNode>
                ))}
              </TreeViewNode>
            )
        )}
    </>
  );
};
