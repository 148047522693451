import React, { useState, useContext, useCallback } from 'react';
import { DialogTitle, DialogActions, Button } from '@material-ui/core';
import { TextInput, InputGroup, CheckboxInput, MapperList } from '../FormElements';
import {
  V2SingleSelectComponent,
  V2MultiSelectComponent,
  V2AggregateLoader,
  DynamicLoaderType,
} from '@terragotech/form-renderer';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { useConfirm } from 'material-ui-confirm';
import DataMapperDialog from './DataMapperDialog';
import { isEqual, cloneDeep } from 'lodash';
import { JSONSchema6 } from 'json-schema';
import { V2OptionsInput, isSimpleOption } from '../FormElements/V2OptionsInput';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { useFormSchemas } from './hooks/useFormSchemas';
import { FormContext } from './contexts/FormContext';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import { checkDuplicateFormName, DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import _ from 'lodash';
import { expandPath } from './TextInputEditForm';

export type SingleSelectWithName =
  | (V2SingleSelectComponent & {
      name: string;
      droppableId: string;
    })
  | (V2MultiSelectComponent & {
      name: string;
      droppableId: string;
    });

interface OptionsInputEditFormProps {
  onSubmit: (result: SingleSelectWithName) => void;
  onClose: () => void;
  component: SingleSelectWithName;
}
export const OptionsInputEditForm: React.FC<OptionsInputEditFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const confirm = useConfirm();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [required, setRequired] = useState(component.required);
  const [placeholder, setPlaceholder] = useState(component.placeholder);
  const [description, setDescription] = useState(component.description);
  const [info, setInfo] = useState(component.info);
  const [readOnly, setReadOnly] = useState(component.readOnly);
  const [options, setOptions] = useState(component.options);
  const [errorMap, setErrorMap] = useState<NodeMapDefinition[] | undefined>(
    component.errorMap || undefined
  );
  const [warningMap, setWarningMap] = useState<NodeMapDefinition[] | undefined>(
    component.warningMap || undefined
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [existingNameError, setExistingNameError] = useState(false);
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const { formDefinition } = useContext(FormContext);

  const formSchemas = useFormSchemas();

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'form'
      ? []
      : expandPath(parts, formSchemas.FORM.schema.properties as JSONSchema6);

  let formValue = { value: { type: 'string' } };
  for (let i = 0; i < t.length; i++) {
    formValue = { ...formValue, ...t[i] };
  }
  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: { type: 'object', properties: formValue } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...formSchemas,
  };
  // const addOption = useCallback(
  //   (option: object, resolve: (data:any) => void) => {
  //     setOptions([...options, option as Option]);
  //      resolve(null);
  //   },
  //   [options, setOptions]
  // );

  // const deleteOption = useCallback(
  //   (option: object) => {
  //     setOptions(options.filter((item) => item.value !== (option as Option).value));
  //   },
  //   [options, setOptions]
  // );

  // const updateOption = useCallback(
  //   (option: object, oldOption: object, resolve: (data:any) => void) => {
  //     setOptions(
  //       options.map((item) =>
  //         item.value === (oldOption as Option).value ? (option as Option) : item
  //       )
  //     );
  //      resolve(null);
  //   },
  //   [options, setOptions]
  // );

  const getOptions = () => {
    const optionsCopy = cloneDeep(options);
    if (isSimpleOption(optionsCopy)) {
      const { type, items } = optionsCopy;
      return { type, items: items || [] };
    } else if (optionsCopy.type === DynamicLoaderType) {
      const { type, optionsMap } = optionsCopy;
      return { type, optionsMap };
    } else {
      const { type, defaultFilter, mandatoryFilter } = optionsCopy;
      return {
        type,
        aggregateType: (optionsCopy as V2AggregateLoader).aggregateType,
        ...(defaultFilter && { defaultFilter }),
        ...(mandatoryFilter && { mandatoryFilter }),
      };
    }
  };

  const handleClearConditionalMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setConditionalMap(undefined);
  };
  // const getOptions = useMemo(() => cloneDeep(options), [options]);

  const getFormValues = () => ({
    type: component.type,
    name,
    label,
    ...(placeholder && { placeholder }),
    ...(required !== undefined && { required }),
    ...(description && { description }),
    ...(info && { info }),
    ...(readOnly !== undefined && { readOnly }),
    repeats,
    options: getOptions(),
    ...(conditionalMap && { conditionalMap }),
    ...(errorMap && { errorMap }),
    ...(warningMap && { warningMap }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The form has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    if (checkDuplicateFormName(name, component, formDefinition.components)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };
  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <div style={{ display: 'flex', flexDirection: 'row', minWidth: 1000 }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <TextInput
            autoFocus
            id="Name"
            error={existingNameError}
            helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
            value={name}
            onChange={(value) => setName(value || '')}
            style={{ margin: '10px 25px' }}
            fullWidth={false}
          />
          <TextInput
            id="Label"
            value={label}
            onChange={(value) => setLabel(value || '')}
            style={{ margin: '10px 25px' }}
            fullWidth={false}
          />
          <CheckboxInput
            title="Required"
            checked={required}
            onChange={setRequired}
            style={{ margin: '10px 25px' }}
          />
          <TextInput
            id="Placeholder"
            value={placeholder}
            onChange={setPlaceholder}
            style={{ margin: '10px 25px' }}
            fullWidth={false}
          />
          <TextInput
            id="Description"
            value={description}
            onChange={setDescription}
            style={{ margin: '10px 25px' }}
            fullWidth={false}
          />
          <TextInput
            id="Info"
            value={info}
            onChange={setInfo}
            style={{ margin: '10px 25px' }}
            fullWidth={false}
          />
          <CheckboxInput
            title="Read-only"
            checked={readOnly}
            onChange={(value) => setReadOnly(value)}
            style={{ margin: '10px 25px' }}
          />
          <V2OptionsInput options={options} setOptions={setOptions} localSchemas={errorWarningSchemas} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <RepeatableComponent repeats={repeats} setRepeats={setRepeats} />
          <InputGroup title="Conditionals" style={{ margin: '10px 25px', height: 70 }}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <Button onClick={() => setConditionalOpen(true)}>
                <div>
                  Advanced
                  <MappingActiveDisplay
                    isActive={doesConditionalHaveValue()}
                    activeLabelText={'active'}
                  />
                </div>
              </Button>
              <Button onClick={handleClearConditionalMapper}>Clear</Button>
            </div>
            <DataMapperDialog
              mapScenario="FORM_FIELD_CONDITIONAL"
              localSchemaDefinitions={errorWarningSchemas}
              onClose={() => {
                setConditionalOpen(false);
              }}
              open={conditionalOpen}
              datamap={conditionalMap}
              setDatamap={setConditionalMap}
            />
          </InputGroup>
          <MapperList
            title="Error Mapping"
            data={errorMap || []}
            localSchemas={errorWarningSchemas}
            setData={(data) => setErrorMap(data)}
            mapScenario="FIELD_LEVEL_ERROR"
          />
          <MapperList
            title="Warning Mapping"
            data={warningMap || []}
            localSchemas={errorWarningSchemas}
            setData={(data) => setWarningMap(data)}
            mapScenario="FIELD_LEVEL_WARNING"
          />
        </div>
      </div>
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
