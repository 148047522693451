import { makeStyles } from '@material-ui/core/styles';
import { FIELD_HEADER_HEIGHT } from '@terragotech/gen5-shared-components';

export const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    height: '100%',
  },
  content: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100% - ${FIELD_HEADER_HEIGHT}px)`,
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  dialogPaper: {
    top: '2vh',
    height: '96vh',
    maxHeight: '100%',
    width: '650px',
    maxWidth: '650px',
    '@media (max-width: 650px)': {
      width: '100%',
      margin: 0,
    },
  },
  alertText: {
    marginBottom: 4,
  },
  loadingContainer: {
    margin: 'auto',
  },
}));
