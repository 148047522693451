import { IconButton } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import React, { useRef, useState } from 'react';

interface FullScreenProps {
  children: React.ReactNode;
}

let styleBeforeFullScreen: object;

const openStyle = {
  transitionProperty: 'height, width, left, top',
  transitionDuration: '0.4s',
  transitionTimingFunction: 'ease-out',
  position: 'absolute',
  zIndex: 1300,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

export const FullScreen: React.FC<FullScreenProps> = ({ children }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenDivStyle, setFullScreenDivStyle] = useState<object>({
    height: '100%',
    width: '100%',
    position: 'relative',
  });
  const ref = useRef<HTMLInputElement>(null);

  const handleFullScreen = () => {
    if (ref?.current) {
      styleBeforeFullScreen = {
        position: 'absolute',
        transitionProperty: 'height, width, left, top',
        transitionDuration: '0.4s',
        transitionTimingFunction: 'ease-out',
        height: ref.current.offsetHeight,
        left: ref.current.offsetLeft,
        top: ref.current.offsetTop,
        width: ref.current.offsetWidth,
        zIndex: 1300,
      };
      setFullScreenDivStyle(styleBeforeFullScreen);
      setTimeout(() => {
        setFullScreenDivStyle(openStyle);
      }, 0);
    }
    setIsFullScreen(true);
  };
  const handleExitFullScreen = () => {
    setFullScreenDivStyle(styleBeforeFullScreen);
    setTimeout(() => {
      setFullScreenDivStyle({
        height: '100%',
        width: '100%',
        position: 'relative',
      });
      setIsFullScreen(false);
    }, 400);
  };

  return (
    <div style={fullScreenDivStyle} ref={ref}>
      {isFullScreen ? (
        <IconButton
          onClick={handleExitFullScreen}
          style={{ position: 'absolute', left: 10, top: 10, zIndex: 1350 }}
          title="Exit full-screen mode"
        >
          <FullscreenExitIcon style={{ color: 'white' }} />
        </IconButton>
      ) : (
        <IconButton
          onClick={handleFullScreen}
          style={{ position: 'absolute', left: 10, top: 10, zIndex: 1350 }}
          title="Full-screen mode"
        >
          <FullscreenIcon style={{ color: 'white' }} />
        </IconButton>
      )}
      {children}
    </div>
  );
};
