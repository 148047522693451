import React, { FunctionComponent } from 'react';
import { V2LocationComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label } from './Common';
import ArrowImage from './img/Arrow.png';

const Placeholder = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  color: #aaaaaa;
`;
const BodyFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftFrame = styled.div``;
const RightFrame = styled.div`
  margin-right: 15px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const LocationInputControl: FunctionComponent<NestedFormControlProps<V2LocationComponent>> = (
  props
) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <BodyFrame>
        <LeftFrame>
          <Label
            text={controlDefinition.label}
            required={controlDefinition.required}
            conditional={Boolean(controlDefinition.conditionalMap)}
          />

          <Placeholder>{controlDefinition.placeholder}</Placeholder>
        </LeftFrame>

        <RightFrame>
          <img src={ArrowImage} alt="disclosure Indicator" />
        </RightFrame>
      </BodyFrame>

      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default LocationInputControl;
