import React, { useContext } from 'react';
import { Paper, Divider, Button } from '@material-ui/core';
import styled from 'styled-components';

import '../../../utils/antDesignCss.css';
import { ConfigContext } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { eventEditorSchema } from '../../aggregates/utils/eventEditorSchema';
import schemaEditor from 'json-schema-editor-visual/dist/main.js';
import { useParams, Prompt } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useFunctionAPI } from '../../../context/fakeAPIHooks/useFunctionAPI';
import { JSONSchema6 } from 'json-schema';
require('json-schema-editor-visual/dist/main.css');

export interface CSVEvent {
  type: string;
  functionId: string;
  eventId: string;
  functionType: string;
  data: {
    [key: string]: string | null;
  };
  source: string;
  metadata: object;
}

export const FunctionInputSchemaEditor: React.FC = () => {
  const { getFunction } = useContext(ConfigContext);
  const FunctionAPI = useFunctionAPI();
  const { function: name, version } = useParams() as {
    function: string;
    version: string;
  };

  const option = { data: eventEditorSchema };
  const SchemaEditor = schemaEditor(option);

  let actualSchema: JSONSchema6 = {};

  const save = async () => {
    const { error } = await FunctionAPI.updateInputSchema(name, +version, actualSchema);
    if (error) return;
    successMsg('The event schema has been saved');
  };

  const ifShowMessageBeforeRedirect = () => {
    return !isEqual(actualSchema, getFunction(name)?.versions[+version]?.input)
      ? 'The form has not been saved, do you want to redirect?'
      : true;
  };

  return (
    <EventSchemaContainer>
      <CardTitle>Event schema</CardTitle>
      <GroupDivider />
      <SchemaEditorContainer>
        <SchemaEditor
          onChange={(schema: string) => {
            actualSchema = JSON.parse(schema);
          }}
          data={JSON.stringify(getFunction(name)?.versions[+version]?.input)}
        />
        <ButtonContainer>
          <Button
            color="primary"
            variant="contained"
            onClick={() => save()}
            style={{ margin: '10px 0' }}
          >
            Save
          </Button>
        </ButtonContainer>
      </SchemaEditorContainer>
      <Prompt message={ifShowMessageBeforeRedirect} />
    </EventSchemaContainer>
  );
};

const EventSchemaContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 10px 20px;
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;

const SchemaEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
