import React from 'react';
import styled from 'styled-components';
import OptionEditor, { QueryBuilderProperty } from './controls/OptionEditor';
import { OperatorName } from './defaults/defaultOperators';
import { AttributeBasedFilterSimpleOptionType } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { Translations } from './defaults/defaultTranslations';
import { QueryBuilderSchema } from './QueryBuilder';

interface ConditionProps {
  id: string;
  parentId: string;
  _key: string;
  operator: OperatorName;
  options: AttributeBasedFilterSimpleOptionType;
  translations: Translations;
  schema: QueryBuilderSchema;
  allowValue?: boolean;
}

const Condition: React.FC<ConditionProps> = ({
  id,
  parentId,
  _key,
  operator,
  options,
  translations,
  schema: {
    controls: { KeySelector, OperatorSelector, RemoveConditionAction },
    keys,
    operators,
    onPropChange,
    onConditionRemove,
  },
  allowValue,
}) => {
  const onElementChanged = (property: string, option: QueryBuilderProperty) => {
    onPropChange(property, option, id);
  };

  const onKeyChanged = (key: string) => {
    onElementChanged('key', key);
  };

  const onOperatorChanged = (operator: OperatorName) => {
    onElementChanged('operator', operator);
  };

  const onOptionChanged = (options: QueryBuilderProperty) => {
    onElementChanged('options', options);
  };

  const removeCondition = () => onConditionRemove(id, parentId);

  const getKeyType = () => keys.find((key) => key.name === _key)?.type;
  return (
    <ConditionContainer>
      <ConditionInputContainer>
        <KeySelector
          options={keys}
          option={_key}
          handleOnChange={onKeyChanged}
          style={{ width: 180 }}
        />
        <OperatorSelector
          options={operators}
          option={operator}
          handleOnChange={onOperatorChanged}
        />
        <OptionEditor
          operator={operator}
          option={options}
          handleOnChange={onOptionChanged}
          keyType={getKeyType()}
          allowValue={allowValue}
        />
      </ConditionInputContainer>
      <RemoveConditionAction
        label={translations.removeCondition.label}
        handleOnClick={removeCondition}
      />
    </ConditionContainer>
  );
};

const ConditionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: white;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 2px 2px 7px rgba(69, 81, 87, 0.2);
`;
const ConditionInputContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export default Condition;
