import React, { useState, useEffect, useCallback } from 'react';
import { V2PageTemplate, V2GroupComponentDef } from '@terragotech/page-renderer';
import { makeStyles, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import UndoIcon from '@material-ui/icons/Undo';
import { blue } from '@material-ui/core/colors';
import { successMsg, warningMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { cloneDeep, isEqual } from 'lodash';
import styled from 'styled-components';

interface PageEditorCopyMenuProps {
  pageDefinition: V2PageTemplate;
  setPageDefinition: (val: V2PageTemplate) => void;
  selectedItems: V2PageTemplate;
  lastPastedPageTemplate: V2PageTemplate | null;
  setLastPastedPageTemplate: (lastPastedPageTemplate: V2PageTemplate | null) => void;
}

export const PageEditorCopyMenu: React.FC<PageEditorCopyMenuProps> = ({
  pageDefinition,
  setPageDefinition,
  selectedItems,
  lastPastedPageTemplate,
  setLastPastedPageTemplate,
}) => {
  const [pageTemplateBeforePaste, setPageTemplateBeforePaste] = useState<V2PageTemplate | null>(
    null
  );
  const [isPasteVisible, setIsPasteButtonVisible] = useState<boolean | undefined>(false);
  const classes = useStyles();

  const checkIfClipbordValid = useCallback((clipboardString: string) => {
    try {
      const clipboard: unknown = JSON.parse(clipboardString);
      if (
        clipboard &&
        typeof clipboard === 'object' &&
        clipboard.hasOwnProperty('elements')
      ) {
        return true;
      }
    } catch (error) {
      console.log('Error', error);
      return false;
    }
  }, []);

  const handleIsPasteVisible = useCallback(async () => {
    const pastedPageTemplate = await navigator.clipboard.readText();
    setIsPasteButtonVisible(checkIfClipbordValid(pastedPageTemplate));
  }, [checkIfClipbordValid]);

  useEffect(() => {
    handleIsPasteVisible();
  }, [handleIsPasteVisible]);

  const handleCopyPageItems = useCallback(() => {
    const anyPageItemSelected = Object.keys(selectedItems.elements).length > 0;

    if (anyPageItemSelected) {
      navigator.clipboard.writeText(JSON.stringify(selectedItems));
      handleIsPasteVisible();
      if (getItemsCount(selectedItems) === 1) {
        successMsg('Selected item have been copied to the clipboard');
      } else {
        successMsg(
          `${getItemsCount(selectedItems)} selected items have been copied to the clipboard`
        );
      }
    } else {
      warningMsg('No selected items to copy');
    }
  }, [selectedItems, handleIsPasteVisible]);

  const getItemsCount = (pageTemplate: V2PageTemplate) => {
    let count = 0;
    Object.entries(pageTemplate.elements).forEach(([_, e]) => {
      count++;
      if (e.component.type === 'group') {
        Object.keys(e.component.template.elements).forEach(() => {
          count++;
        });
        if (Object.keys(e.component.template.elements).length > 0) count--;
      }
    });
    return count;
  };

  const merge = useCallback(
    (sourcePageTemplate: V2PageTemplate, mergedPageTemplate: V2PageTemplate) => {
      const sourcePageTemplateCopy = cloneDeep(sourcePageTemplate);
      Object.entries(mergedPageTemplate.elements).forEach(([key, e]) => {
        if (e.component.type === 'group' && sourcePageTemplate.elements[key]) {
          (sourcePageTemplateCopy.elements[
            key
          ].component as V2GroupComponentDef).template = merge(
            (sourcePageTemplate.elements[key].component as V2GroupComponentDef).template,
            e.component.template
          );
        } else {
          sourcePageTemplateCopy.elements[key] = e;
        }
      });
      return sourcePageTemplateCopy;
    },
    []
  );

  const handlePastePageItems = useCallback(async () => {
    const pastedPageTemplate = await navigator.clipboard.readText();
    if (checkIfClipbordValid(pastedPageTemplate)) {
      const parsedPastedPageTemplate: V2PageTemplate = JSON.parse(pastedPageTemplate);
      if (isEqual(lastPastedPageTemplate, parsedPastedPageTemplate))
        return warningMsg('Page Template already pasted.');
      if (Object.keys(parsedPastedPageTemplate.elements).length > 0) {
        setLastPastedPageTemplate(cloneDeep(parsedPastedPageTemplate));
        setPageTemplateBeforePaste(cloneDeep(pageDefinition));
        const mergedPageTemplate = merge(pageDefinition, parsedPastedPageTemplate);
        setPageDefinition(mergedPageTemplate);
        if (getItemsCount(parsedPastedPageTemplate) === 1) {
          successMsg('Item has been paseted successfully');
        } else {
          successMsg(
            `${getItemsCount(
              parsedPastedPageTemplate
            )} selected items have been paseted successfully`
          );
        }
      } else {
        warningMsg('No items to paste');
      }
    } else {
      errorMsg('Invalid clipboard content, cannot be pasted');
    }
  }, [
    checkIfClipbordValid,
    pageDefinition,
    lastPastedPageTemplate,
    merge,
    setPageDefinition,
    setLastPastedPageTemplate,
  ]);

  const keydownHandler = useCallback(
    async (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
        handleCopyPageItems();
      }
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 86) {
        handlePastePageItems();
      }
    },
    [handleCopyPageItems, handlePastePageItems]
  );

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [keydownHandler]);

  const handlePasteUndo = () => {
    if (pageTemplateBeforePaste) {
      setPageDefinition(pageTemplateBeforePaste);
      setLastPastedPageTemplate(null);
      setPageTemplateBeforePaste(null);
      successMsg('Pasted page items successfully rolled back');
    }
  };

  return pageTemplateBeforePaste || Object.keys(selectedItems.elements).length > 0 || isPasteVisible ? (
    <>
      <ClipBoardHeader>Copy menu</ClipBoardHeader>
      <ClipBoardBody>
        {pageTemplateBeforePaste && (
          <IconButton
            onClick={handlePasteUndo}
            title="Undo"
            classes={{ label: classes.iconButton }}
          >
            <UndoIcon style={{ color: blue[300] }} />
            <p style={{ fontSize: 12, margin: '4px 0 0 0' }}>Undo</p>
          </IconButton>
        )}
        {Object.keys(selectedItems.elements).length > 0 && (
          <IconButton
            onClick={handleCopyPageItems}
            title="Copy"
            classes={{ label: classes.iconButton }}
          >
            <FileCopyIcon style={{ color: blue[300] }} />
            <p style={{ fontSize: 12, margin: '4px 0 0 0' }}>Copy</p>
          </IconButton>
        )}
        {isPasteVisible && (
          <IconButton
            onClick={handlePastePageItems}
            title="Paste"
            classes={{ label: classes.iconButton }}
          >
            <AssignmentReturnedIcon style={{ color: blue[300] }} />
            <p style={{ fontSize: 12, margin: '4px 0 0 0' }}>Paste</p>
          </IconButton>
        )}
      </ClipBoardBody>
    </>
  ) : null;
};

const ClipBoardHeader = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  height: 25px;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
  margin-top: 10px;
`;

const ClipBoardBody = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  padding: 2px 5px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
`;

const useStyles = makeStyles((theme) => ({
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
