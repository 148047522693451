import React, { useContext, useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { ConfigContext } from '../../../../context/ConfigContext';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { getExpandedURLArr } from '../../../../utils/navigationUtils';
import { useFunctionAPI } from '../../../../context/fakeAPIHooks/useFunctionAPI';

export const FunctionsList: React.FC = () => {
  const { getFunctions } = useContext(ConfigContext);
  const FunctionAPI = useFunctionAPI();
  const confirm = useConfirm();
  const history = useHistory();
  const params = useParams() as { function: string };
  const selectedFunction = params.function;

  const handleAggrDelete = async (name: string) => {
    await confirm({
      description: 'All versions of the selected function will be permanently deleted.',
      confirmationText: 'Delete',
    });
    if (name === selectedFunction) history.push('/functions');
    const { error } = await FunctionAPI.deleteFunction(name);
    if (error) return;
    successMsg(`The function "${name}" has been successfully deleted`);
  };

  const handleAddFunctionVersion = async (name: string, versionIndex: number) => {
    await confirm({
      description: `Do you want to create new version of "${name}" function?`,
      confirmationText: 'Create',
    });
    const { error } = await FunctionAPI.addFunctionVersion(name, versionIndex);
    if (error) return;
    successMsg(`New version of the "${name}" has been successfully created`);
  };

  const handleRemoveFunctionVersion = async (
    name: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    await confirm({
      description: `The version "${versionNumber}" of the function "${name}" will be permanently deleted.`,
      confirmationText: 'Delete',
    });
    const versions = getFunctions()[name].versions;
    if (versions?.length === 1) {
      return errorMsg(
        'At least one version of the function must remain. Consider deleting the entire function instead'
      );
    }
    const { error } = await FunctionAPI.removeFunctionVersion(name, versionIndex);
    if (error) return;
    successMsg(
      `The version "${versionNumber}" of the function "${name}" has been successfully deleted.`
    );
  };

  const handleResetFunctionInputSchema = async (
    name: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    await confirm({
      description: `The input schema of the version "${versionNumber}" of the function "${name}" function will be cleared.`,
      confirmationText: 'Clear',
    });
    const { error } = await FunctionAPI.resetFunctionInputSchema(name, versionIndex);
    if (error) return;
    successMsg(
      `The input schema of the version "${versionNumber}" of the function "${name}" has been successfully cleared.`
    );
  };

  const handleResetFunctionOutputSchema = async (
    name: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    await confirm({
      description: `The output schema of the version "${versionNumber}" of the function "${name}" function will be cleared.`,
      confirmationText: 'Clear',
    });
    const { error } = await FunctionAPI.resetFunctionOutputSchema(name, versionIndex);
    if (error) return;
    successMsg(
      `The output schema of the version "${versionNumber}" of the function "${name}" has been successfully cleared.`
    );
  };
  const [expandedNodes, setExpandedNodes] = useState<string[] | undefined>(
    getExpandedURLArr(history.location.pathname)
  );

  const handleResetFunctionMap = async (
    name: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    await confirm({
      description: `The function map of the version "${versionNumber}" of the function "${name}" will be cleared.`,
      confirmationText: 'Clear',
    });
    const { error } = await FunctionAPI.resetFunctionAggregateMap(name, versionIndex);
    if (error) return;
    successMsg(
      `The function map of the version "${versionNumber}" of the function "${name}" has been successfully cleared.`
    );
  };

  return Object.keys(getFunctions()).length !== 0 ? (
    <ScrollableTreeContainer>
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={(_: object, nodeId: string) => {
          history.push(nodeId);
        }}
        selected={history.location.pathname}
        style={{ marginRight: 6 }}
        onNodeToggle={(_: object, nodeIds: string[] | undefined) => setExpandedNodes(nodeIds)}
        expanded={expandedNodes}
      >
        {Object.keys(getFunctions()).sort((a,b)=>a.toLocaleLowerCase()>b.toLocaleLowerCase() ? 1 : -1).map((name: string) => (
          <TreeViewNode
            label={name}
            key={name}
            nodeId={`/functions/${name}`}
            onDelete={() => handleAggrDelete(name)}
            color="green"
          >
            {getFunctions()[name].versions.map((version, versionIndex) => (
              <Wrapper>
                <TreeViewNode
                  label={`Version: ${version.versionNumber}`}
                  key={version.versionNumber}
                  nodeId={`/functions/${name}/version/${versionIndex}`}
                  onAdd={() => handleAddFunctionVersion(name, versionIndex)}
                  onDelete={() =>
                    handleRemoveFunctionVersion(name, versionIndex, version.versionNumber)
                  }
                  color={versionIndex}
                >
                  <TreeViewNode
                    label="Input schema"
                    nodeId={`/functions/${name}/version/${versionIndex}/input`}
                    onRemove={() =>
                      handleResetFunctionInputSchema(name, versionIndex, version.versionNumber)
                    }
                    color="orange"
                  />
                  <TreeViewNode
                    label="Output schema"
                    nodeId={`/functions/${name}/version/${versionIndex}/output`}
                    onRemove={() =>
                      handleResetFunctionOutputSchema(name, versionIndex, version.versionNumber)
                    }
                    color="orange"
                  />
                  <TreeViewNode
                    label="Function map"
                    nodeId={`/functions/${name}/version/${versionIndex}/templateMap`}
                    onRemove={() =>
                      handleResetFunctionMap(name, versionIndex, version.versionNumber)
                    }
                    color="purple"
                  />
                </TreeViewNode>
              </Wrapper>
            ))}
          </TreeViewNode>
        ))}
      </TreeView>
    </ScrollableTreeContainer>
  ) : (
    <p style={{ color: 'rgba(0, 0, 0, 0.54', fontSize: 14, textAlign: 'center' }}>
      No functions defined
    </p>
  );
};
const Wrapper = styled.div`
  padding-left: 15px;
  & * ul {
    padding-left: 15px;
  }
`;

const ScrollableTreeContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;
