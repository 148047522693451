import { PropertyCollection } from '@terragotech/gen5-config-lib';
import { JSONSchema6 } from 'json-schema';

interface PropsList {
  [name: string]: JSONSchema6;
}

export const propertiesToSchema = (properties: PropertyCollection): JSONSchema6 => {
  const props: PropsList = {};
  Object.entries(properties).forEach(([name, value]) => {
    if ('relation' in value && value.relation === 'ONE-TO-ONE') {
      props[name] = { type: 'string' };
    } else if ('relation' in value && value.relation === 'ONE-TO-MANY') {
      return;
    } else if (value.type === 'PhotoCollection') {
      props[name] = { type: 'array', items: { type: 'string', format: 'photo' } };
    } else if (value.type === 'FileCollection') {
      props[name] = {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: { type: 'string' },
            name: { type: 'string' },
          },
        },
      };
    } else if (value.type === 'DateTime') {
      props[name] = { type: 'string', format: 'date-time' };
    } else if (value.type === 'Geography' || value.type === 'AnchoredLine') {
      props[name] = { anyOf: [{ $ref: '/Geography/GeoJSON' }, { type: 'string' }] };
    } else {
      props[name] = { type: mapPropTypesToSchemaTypes(value.type) };
    }
  });
  return { properties: props, type: 'object' } as JSONSchema6;
};

const mapPropTypesToSchemaTypes = (type: string) => {
  switch (type) {
    case 'Int':
    case 'Float':
      return 'number';
    case 'Boolean':
      return 'boolean';
    case 'JSON':
    case 'String':
    case 'ID':
    case 'DateTime':
    default:
      return 'string';
  }
};
