import React, { useState, useContext } from 'react';
import { useConfirm } from 'material-ui-confirm';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useHistory } from 'react-router-dom';

import { ConfigContext } from '../../../../context/ConfigContext';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { successMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { isEmpty } from 'lodash';
import { getExpandedURLArr } from '../../../../utils/navigationUtils';
import { useUICustomizationAPI } from '../../../../context/fakeAPIHooks/useUICustomizationAPI';

export const AggregateUICustomisationsList: React.FC = () => {
  const history = useHistory();
  const UICustomizationAPI = useUICustomizationAPI();
  const { config } = useContext(ConfigContext);
  const confirm = useConfirm();
  const [expandedNodes, setExpandedNodes] = useState<string[] | undefined>(
    getExpandedURLArr(history.location.pathname)
  );

  const handleAggrDelete = async (aggrUIName: string) => {
    await confirm({
      description: `${aggrUIName} aggregate UI customisation will be permanently deleted along with the properties events and commands belonging to it.`,
      confirmationText: 'Delete',
    });
    const { error } = await UICustomizationAPI.deleteAggregateUICustomization(
      'mobileUIConfig',
      aggrUIName
    );
    if (error) return;
    successMsg(`The aggregate UI customisation "${aggrUIName}" has been successfully deleted`);
  };

  return config.mobileUIConfig.aggregateUICustomizations &&
    !isEmpty(config.mobileUIConfig.aggregateUICustomizations) ? (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      onNodeSelect={(_: object, nodeId: string) => {
        history.push(nodeId);
      }}
      selected={history.location.pathname}
      style={{ marginRight: 6 }}
      onNodeToggle={(_: object, nodeIds: string[] | undefined) => setExpandedNodes(nodeIds)}
      expanded={expandedNodes}
    >
      {Object.entries(config.mobileUIConfig.aggregateUICustomizations).map(([commandName]) => (
        <TreeViewNode
          label={commandName}
          key={commandName}
          nodeId={`/mobileUI/aggregateUICustomizations/${commandName}`}
          onDelete={() => handleAggrDelete(commandName)}
          color="green"
        >
          <TreeViewNode
            label="Card Definition"
            key="Card Definition"
            nodeId={`/mobileUI/aggregateUICustomizations/${commandName}/cardDefinition`}
          />
          <TreeViewNode
            label="Map Labels"
            key="Map Labels"
            nodeId={`/mobileUI/aggregateUICustomizations/${commandName}/mapLabels`}
          />
          <TreeViewNode
            label="Editor Workflows"
            key="Editor Workflows"
            nodeId={`/mobileUI/aggregateUICustomizations/${commandName}/editorWorkflows`}
          />
          <TreeViewNode
            label="Editor Actions"
            key="Editor Actions"
            nodeId={`/mobileUI/aggregateUICustomizations/${commandName}/editorActions`}
          />
          <TreeViewNode
            label="Multiselect Actions"
            key="Multiselect Actions"
            nodeId={`/mobileUI/aggregateUICustomizations/${commandName}/multiSelectActions`}
          />
        </TreeViewNode>
      ))}
    </TreeView>
  ) : (
    <p style={{ color: 'rgba(0, 0, 0, 0.54', fontSize: 14, textAlign: 'center' }}>
      No aggregate UI Customisations
    </p>
  );
};
