import { Button } from '@material-ui/core';
import { V2PageTemplate } from '@terragotech/page-renderer';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import _ from 'lodash';
import { cloneDeep } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useMemo, useState } from 'react';
import { PageContextProvider } from '../../../../components/PageDialog/contexts/PageContext';
import { MappingActiveDisplay } from '../../../../components/MappingActiveDisplay';
import DataMapperDialog from '../../../../components/FormDialog/DataMapperDialog';
import { InputGroup, MapperList } from '../../../../components/FormElements';
import { useConfig } from '../../../../context/ConfigContext';
import { metadataSchema } from '../../../../utils/jsonPartsGenerators';
import { getAggregateIndex } from '../../../../utils/navigationUtils';
import { PageLayoutEditor } from '../../components/CustomPageEditor/PageLayoutEditor';
import { propertiesToSchema } from 'pages/aggregates/utils/PropertiesToSchemaConverter';
import { convertV2PageTemplateToJsonSchema } from 'pages/aggregates/utils/V2PageTemplateToJsonSchema';

interface PageEditorProps {
  pageDefinition: V2PageTemplate;
  setPageDefinition: React.Dispatch<React.SetStateAction<V2PageTemplate>>;
  isGroup: boolean;
  fullPageDefinition: V2PageTemplate;
  reset: number;
}
export const PageEditor: React.FC<PageEditorProps> = ({
  pageDefinition,
  setPageDefinition,
  isGroup,
  fullPageDefinition,
  reset,
}) => {
  const { config } = useConfig();
  const confirm = useConfirm();

  const aggrIndex = useMemo(() => getAggregateIndex(config, pageDefinition.aggregateType), [config, pageDefinition.aggregateType]);

  const [initialDataMapOpen, setInitialDataMapOpen] = useState(false);
  const stateSchema = useMemo(() => aggrIndex > 0 ? propertiesToSchema(config?.aggregates?.[aggrIndex].properties) : {}, [config?.aggregates, aggrIndex]);
  const pageSchema = convertV2PageTemplateToJsonSchema(pageDefinition);

  const handleSetErrorMaps = (data: NodeMapDefinition[]) => {
    const pageDefinitionClone = cloneDeep(pageDefinition);
    if (pageDefinitionClone.hasOwnProperty('errorMaps') && !data) {
      delete pageDefinitionClone.errorMaps;
    } else {
      pageDefinitionClone.errorMaps = data;
    }
    setPageDefinition(pageDefinitionClone);
  };

  const handleSetWarningMaps = (data: NodeMapDefinition[]) => {
    const pageDefinitionClone = cloneDeep(pageDefinition);
    if (pageDefinitionClone.hasOwnProperty('warningMaps') && !data) {
      delete pageDefinitionClone.warningMaps;
    } else {
      pageDefinitionClone.warningMaps = data;
    }
    setPageDefinition(pageDefinitionClone);
  };

  const handleClearInitialDataMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    handleSetInitialDataMap(undefined);
  };

  const handleSetInitialDataMap = (data: NodeMapDefinition | undefined) => {
    const pageDefinitionClone = cloneDeep(pageDefinition);
    pageDefinitionClone.initialDataMap = data;
    setPageDefinition(pageDefinitionClone);
  };

  const doesInitialDataMapHaveValue = useCallback(() => {
    return !_.isEmpty(pageDefinition.initialDataMap);
  }, [pageDefinition.initialDataMap]);

  return (
    <>
      <PageContextProvider pageDefinition={fullPageDefinition}>
        {!isGroup ? 
        <InputGroup title="Initial Data Map" style={{ margin: '10px 0' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button onClick={() => setInitialDataMapOpen(true)}>
              <div>
                Advanced
                <MappingActiveDisplay
                  isActive={doesInitialDataMapHaveValue()}
                  activeLabelText={'active'}
                />
              </div>
            </Button>
            <Button onClick={handleClearInitialDataMapper}>Clear</Button>
          </div>
          <DataMapperDialog
            localSchemaDefinitions={{
              STATE: { schema: stateSchema, schemaLabel: 'Properties' },
              FORM: { schema: pageSchema, schemaLabel: 'Page State' },
              METADATA: {
                schema: metadataSchema,
                schemaLabel: 'Metadata',
              },
            }}
            onClose={() => {
              setInitialDataMapOpen(false);
            }}
            open={initialDataMapOpen}
            datamap={pageDefinition.initialDataMap}
            setDatamap={(data) => handleSetInitialDataMap(data)}
            mapScenario={'INITIAL_DATA_MAPPING'}
          />
        </InputGroup> : <></>}
        {!isGroup ? 
        <MapperList
          localSchemas={{
            STATE: { schema: stateSchema, schemaLabel: 'Properties' },
            FORM: { schema: pageSchema, schemaLabel: 'Page State' },
            METADATA: {
              schema: metadataSchema,
              schemaLabel: 'Metadata',
            },
          }}
          title="Error Mapping"
          data={pageDefinition.errorMaps || []}
          setData={(data) => handleSetErrorMaps(data as NodeMapDefinition[])}
          style={{ margin: '20px 0', display: 'none' }/**Hiding error/warning mappings until later */}
          mapScenario="FORM_LEVEL_ERROR"
        /> : <></>}
        {!isGroup ? 
        <MapperList
          localSchemas={{
            STATE: { schema: stateSchema, schemaLabel: 'Properties' },
            FORM: { schema: pageSchema, schemaLabel: 'Page State' },
            METADATA: {
              schema: metadataSchema,
              schemaLabel: 'Metadata',
            },
          }}
          title="Warning Mapping"
          data={pageDefinition.warningMaps || []}
          setData={(data) => handleSetWarningMaps(data as NodeMapDefinition[])}
          style={{ margin: '20px 0', display: 'none' }/**Hiding error/warning mappings until later */}
          mapScenario="FORM_LEVEL_WARNING"
        /> : <></>}
        <InputGroup
          title="Page editor"
          style={{
            padding: '10px 0 0 0',
            overflow: 'unset',
            backgroundColor: 'rgb(238,238,238)',
          }}
          titleStyle={{ transform: 'translate(0px, -36px) scale(0.75)', borderRadius: 5 }}
        >
          <PageLayoutEditor
            pageDefinition={pageDefinition}
            fullPageDefinition={fullPageDefinition}
            setPageDefinition={setPageDefinition}
            isGroup={isGroup}
            reset={reset}
          />
        </InputGroup>
      </PageContextProvider>
    </>
  );
};
