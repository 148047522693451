import { AggrPropertyRow } from '../../../utils/types';

export const propertiesObjsToArray = (propertiesObj: object | undefined) => {
  const arr: AggrPropertyRow[] = [];
  let counter = 1;
  for (const [key, value] of Object.entries(propertiesObj as object)) {
    arr.push({ relation: value.relation, name: key, order: counter++, ...value });
  }
  return arr;
};
