import React, { useState, useContext, useCallback } from 'react';
import { DialogTitle, DialogActions, Button } from '@material-ui/core';
import { TextInput, InputGroup } from '../FormElements';
import { V2StreetViewComponent } from '@terragotech/page-renderer';
import DataMapperDialog from './DataMapperDialog';
import { useConfirm } from 'material-ui-confirm';
import { isEqual } from 'lodash';
import { usePageSchemas } from './hooks/usePageSchemas';
import { PageContext } from './contexts/PageContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import _ from 'lodash';

export type StreetViewComponentDefWithName = V2StreetViewComponent & {
  name: string;
};

interface StreetViewEditFormFormProps {
  onSubmit: (result: StreetViewComponentDefWithName) => void;
  onClose: () => void;
  component: StreetViewComponentDefWithName;
}

export const StreetViewEditForm: React.FC<StreetViewEditFormFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const confirm = useConfirm();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState<string>(component.label);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [height, setHeight] = useState(component.height);

  const pageSchemas = usePageSchemas();

  const [existingNameError, setExistingNameError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const nameHelperTxt =
    'Duplicate name error. The name must be unique accross all workflow elements';

  const handleClearDisplayMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setDisplayOptions(undefined);
  };

  const getFormValues = () => ({
    type: component.type,
    name: name || '',
    label: label || '',
    height: height || undefined,
    ...(displayOptions && { displayOptions }),
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The page has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    if (checkDuplicatePageName(name, component, pageDefinition.elements)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(displayOptions);
  }, [displayOptions]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <TextInput
        autoFocus
        id="Name"
        error={existingNameError}
        helperText={existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Height"
        value={height}
        onChange={(value) => setHeight(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <InputGroup title="Conditionals" style={{ margin: '10px 25px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button onClick={() => setConditionalOpen(true)}>
            <div>
              Advanced
              <MappingActiveDisplay
                isActive={doesConditionalHaveValue()}
                activeLabelText={'active'}
              />
            </div>
          </Button>
          <Button onClick={handleClearDisplayMapper}>Clear</Button>
        </div>
        <DataMapperDialog
          mapScenario="DISPLAY_OPTIONS"
          localSchemaDefinitions={pageSchemas}
          onClose={() => {
            setConditionalOpen(false);
          }}
          open={conditionalOpen}
          datamap={displayOptions}
          setDatamap={setDisplayOptions}
        />
      </InputGroup>
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
