import React from 'react';
import { RadioGroup, FormControlLabel, Radio, FormLabel, FormControl } from '@material-ui/core';

interface RadioInputProps {
  value: string | undefined;
  onChange: (value: string) => void;
  options: RadioOptions;
  title: string;
  containerStyle?: React.CSSProperties;
}

export type RadioOptions = Array<{ value: string; label: string }>;

export const RadioInput: React.FC<RadioInputProps> = ({
  value,
  onChange,
  options,
  title,
  containerStyle,
}) => (
  <div>
    <FormControl component="fieldset" style={containerStyle}>
      <FormLabel component="legend" style={{ fontSize: 12 }}>
        {title}
      </FormLabel>
      <RadioGroup
        row
        name="type"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{ justifyContent: 'space-around' }}
      >
        {options.map(({ value: val, label }) => (
          <FormControlLabel key={val} value={val} control={<Radio />} label={label} />
        ))}
      </RadioGroup>
    </FormControl>
  </div>
);
