import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

interface PagePaletteProps {
  isGroup: boolean;
}
export const PagePalette: React.FC<PagePaletteProps> = ({ isGroup }) => {
  return (
    <>
      <PaletteHeader>Page Palette</PaletteHeader>
      {paletteItems
        .filter((x) => !isGroup || x.type !== 'group')
        .map((item, index) => (
          <Draggable key={item.type} draggableId={item.type} index={index}>
            {(provided, snapshot) => (
              <>
                <PaletteItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {item.label}
                </PaletteItem>
                {snapshot.isDragging && <Clone>{item.label}</Clone>}
              </>
            )}
          </Draggable>
        ))}
    </>
  );
};

const paletteItems = [
  { type: 'text', label: 'Text', icon: '' },
  { type: 'number', label: 'Number', icon: '' },
  { type: 'date', label: 'Date', icon: '' },
  { type: 'time', label: 'Time', icon: '' },
  { type: 'datetime', label: 'DateTime', icon: '' },
  { type: 'image', label: 'Image', icon: '' },
  { type: 'file', label: 'File', icon: '' },
  { type: 'group', label: 'Group', icon: '' },
  { type: 'aggregateMap', label: 'Map', icon: '' },
  { type: 'streetView', label: 'Street View (Web Detail Only)', icon: '' },
];

const PaletteItem = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const PaletteHeader = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  height: auto;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
`;

const Clone = styled(PaletteItem)`
  + div {
    display: none !important;
  }
`;
