import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  tooltip: {
    marginBottom: '1px',
    maxWidth: '200px',
  },
});

export const AggregateGeneralInfoContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  padding: 10px 20px;
  max-width: 600px;
`;

export const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

export const ButtonContainer = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const CenterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;
