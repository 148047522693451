import React, { useMemo, useContext, useCallback } from 'react';
import { InputGroup } from '../FormElements';
import { EditableTable, MaterialTableRef } from '../EditableTable';
import {
  actionButtonsToRows,
  getCardActionButtonsColumns,
  commandRefObjToList,
  getCommandReferenceArr,
  getAggregatePropertiesList,
  commandActionButtonActionToRef,
} from '../../utils/jsonPartsGenerators';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { ConfigContext } from '../../context/ConfigContext';
import { ConditionalCardActionButton } from '@terragotech/gen5-config-lib';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { MobileActionButtonWithID, ActionButtonWithID } from './CardDefinition';
import { successMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ActionButtonPicker } from '../FormDialog/ActionButtonPicker';
import { LibraryAdd } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useFormDialog } from '../FormDialog/FormDialogService';
import { ActionButtonRow, BuildinActions, ActionButton } from '../../utils/types';

interface CardDefinitionConditionalCardProps {
  type: 'mobile' | 'web';
  buttons: Array<ConditionalCardActionButton<BuildinActions>>;
  setButtons: (buttons: Array<ConditionalCardActionButton<BuildinActions>>) => void;
  conditionalCardTableRef: React.RefObject<MaterialTableRef>;
}

export const CardDefinitionConditionalCard: React.FC<CardDefinitionConditionalCardProps> = ({
  type,
  buttons,
  setButtons,
  conditionalCardTableRef,
}) => {
  const { config } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const getButtonsArray = useMemo(() => actionButtonsToRows(buttons), [buttons]);
  const { aggrUICustomization: aggrUIName } = useParams() as { aggrUICustomization: string };

  const aggregateProps = useMemo(() => getAggregatePropertiesList(config, aggrUIName), [
    config,
    aggrUIName,
  ]);

  const isButtonInvalid = useCallback((row: object) => {
    if (!(row as ActionButtonRow).action) {
      errorMsg('Property "Action" is required');
      return true;
    }
    return false;
  }, []);

  const getButtonsColumns = getCardActionButtonsColumns(
    commandRefObjToList(
      getCommandReferenceArr(config, false, getAggregateIndex(config, aggrUIName)),
      additionalActionOptions(type)
    ),
    config.aggregates[getAggregateIndex(config, aggrUIName)]?.properties,
    aggregateProps
  );

  const addButton = useCallback(
    (row: object, resolve: (data: any) => void, reject: () => void) => {
      const newButtons = cloneDeep(buttons);
      if (isButtonInvalid(row)) return reject();
      newButtons.push(commandActionButtonActionToRef(row as ActionButtonRow));
      setButtons(newButtons);
      resolve(null);
      successMsg('Secondary Button has been successfully added');
    },
    [buttons, setButtons, isButtonInvalid]
  );

  const updateButton = useCallback(
    (newRow: object, oldRow: object, resolve: (data: any) => void, reject: () => void) => {
      const newButtons = cloneDeep(buttons);
      if (isButtonInvalid(newRow)) return reject();
      newButtons[
        (oldRow as MobileActionButtonWithID).tableData.id
      ] = commandActionButtonActionToRef(newRow as ActionButtonRow);
      setButtons(newButtons);
      resolve(null);
      successMsg(`Secondary button has been successfully updated`);
    },
    [buttons, setButtons, isButtonInvalid]
  );

  const deleteButton = useCallback(
    (rowToDel: object) => {
      const newButtons = cloneDeep(buttons);
      newButtons.splice((rowToDel as ActionButtonWithID).tableData.id, 1);
      setButtons(newButtons);
      successMsg(`Secondary button has been successfully deleted`);
    },
    [buttons, setButtons]
  );

  const moveRowButton = useCallback(
    (row: ActionButtonWithID, direction: 'UP' | 'DOWN') => {
      const newButtons = cloneDeep(buttons);
      const rowIndex = row.tableData.id;
      if (direction === 'UP') {
        if (rowIndex === 0) return errorMsg(`Secondary button is already at the top`);
        newButtons.splice(rowIndex - 1, 2, newButtons[rowIndex], newButtons[rowIndex - 1]);
        successMsg(`Secondary button item has been moved up`);
      } else {
        if (rowIndex === newButtons.length - 1)
          return errorMsg(`Secondary button item is already at the bottom`);
        newButtons.splice(rowIndex, 2, newButtons[rowIndex + 1], newButtons[rowIndex]);
        successMsg(`Secondary button item has been moved down`);
      }
      setButtons(newButtons);
    },
    [buttons, setButtons]
  );

  const actionButtonsActions = useMemo(
    () => [
      {
        icon: () => <KeyboardArrowUpIcon />,
        tooltip: 'Move up',
        onClick: (_: object, rowData: object) =>
          moveRowButton(rowData as MobileActionButtonWithID, 'UP'),
      },
      {
        icon: () => <KeyboardArrowDownIcon />,
        tooltip: 'Move down',
        onClick: (_: object, rowData: object) =>
          moveRowButton(rowData as MobileActionButtonWithID, 'DOWN'),
      },
    ],
    [moveRowButton]
  );

  const getToolbarItem = useMemo(
    () => (
      <IconButton
        style={{ padding: 0, marginRight: 20 }}
        component="span"
        onClick={async () => {
          const actionButtons = await formDialog<typeof ActionButtonPicker>((props) => (
            <ActionButtonPicker
              {...props}
              scanOnlyAggregateName={aggrUIName}
              excludeActionsButtons={buttons as ActionButton[]}
              webOrMobile={type}
            />
          ));
          const newButtons = cloneDeep(buttons);
          newButtons.push(...actionButtons);
          setButtons(newButtons);
          successMsg('Secondary Button has been successfully added');
        }}
      >
        <LibraryAdd />
      </IconButton>
    ),
    [formDialog, buttons, setButtons, aggrUIName, type]
  );

  return (
    <InputGroup title="Buttons" style={{ marginTop: 20 }}>
      <EditableTable
        columns={getButtonsColumns}
        data={getButtonsArray}
        toolbarStyle={otherAttributesTableToolbarStyle}
        onAdd={addButton}
        onUpdate={updateButton}
        onDelete={deleteButton}
        options={tableOptions}
        actions={actionButtonsActions}
        toolbarItem={getToolbarItem}
        tableRef={conditionalCardTableRef}
      />
    </InputGroup>
  );
};

const additionalActionOptions = (type: 'mobile' | 'web') =>
  type === 'mobile'
    ? ['directions', 'streetView', 'assign', 'updateLocation']
    : ['csvExport', 'directions', 'streetView', 'pdfExport'];

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
  sorting: false,
  draggable: false,
};
