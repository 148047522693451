import React, { useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Button,
  Tooltip,
} from '@material-ui/core';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import AddIcon from '@material-ui/icons/Add';
import {
  MapScenarioType,
  NodeMapDefinition,
  mapScenarios,
} from '@terragotech/gen5-datamapping-lib';
import { cloneDeep } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { successMsg } from '../SnackbarUtilsConfigurator';
import { InputGroup } from './InputGroup';
import DataMapperDialog from '../FormDialog/DataMapperDialog';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';

interface Props {
  title: string;
  data: NodeMapDefinition[];
  setData: (data: NodeMapDefinition[] | undefined) => void;
  style?: React.CSSProperties;
  mapScenario: keyof MapScenarioType;
  localSchemas?: LocalSchemaDefinition;
}

export const MapperList = ({ title, data, setData, style, mapScenario, localSchemas }: Props) => {
  const confirm = useConfirm();
  const classes = useStyles();
  const [openedIndex, setOpenedIndex] = useState<number | undefined>(undefined);

  const handleSaveMapperData = (dataItem: NodeMapDefinition | undefined) => {
    if (dataItem) {
      const newData = cloneDeep(data);
      newData[openedIndex || 0] = dataItem;
      setData(newData);
      successMsg('The mapping has been successfully saved.');
    }
  };

  const handleOpenMapper = (index: number) => {
    setOpenedIndex(index);
  };

  const handleDeleteMappingItem = async (index: number) => {
    await confirm({
      description: `Selected mapping will be deleted`,
      confirmationText: 'Delete',
    });
    const newData = cloneDeep(data);
    newData.splice(index, 1);
    setData(newData.length !== 0 ? newData : undefined);
    successMsg('New mapping has been successfully deleted');
  };

  const handleClearConditionalMapper = async (index: number) => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    const newData = cloneDeep(data);
    newData[index] = { ...mapScenarios[mapScenario].defaultNodeMap };
    setData(newData);
    successMsg('New mapping has been successfully cleared');
  };

  const handleAddNewMappingItem = () => {
    const newData = cloneDeep(data);
    newData.push({ ...mapScenarios[mapScenario].defaultNodeMap });
    setData(newData);
    successMsg('New mapping has been successfully added');
  };

  const defaultWarningMapping = {
    nodes: {
      FORM: { type: 'FORM', config: {}, inputs: {} },
      FORM_WARNING: {
        type: 'FORM-WARNING',
        config: {},
        inputs: {
          warningMessage: {
            sourceObject: 'newNode5',
            sourcePath: '$.output',
          },
        },
      },
      newNode1: { type: 'METADATA', config: {} },
      newNode2: {
        type: 'DISTANCE-CALC',
        config: { distanceUnit: 'feet' },
        inputs: {
          secondLatitude: {
            sourceObject: 'newNode3',
            sourcePath: '$.lat',
          },
          secondLongitude: {
            sourceObject: 'newNode3',
            sourcePath: '$.lon',
          },
          firstLatitude: {
            sourceObject: 'newNode1',
            sourcePath: '$.latitude',
          },
          firstLongitude: {
            sourceObject: 'newNode1',
            sourcePath: '$.longitude',
          },
        },
      },
      newNode3: { type: 'STATE', config: {} },
      newNode4: {
        type: 'NUMBER-GREATER-THAN',
        config: {},
        inputs: {
          compareNumber: '75',
          baseNumber: {
            sourceObject: 'newNode2',
            sourcePath: '$.output',
          },
        },
      },
      newNode5: {
        type: 'CONDITIONAL-MAPPER',
        config: {},
        inputs: {
          booleanSource: {
            sourceObject: 'newNode4',
            sourcePath: '$.isGreater',
          },
          trueSource:
            'You are more than 75 Feet from the selected feature. Please verify your location.',
        },
      },
    },
    outputDefinition: { outputNode: 'FORM_WARNING' },
  } as const;

  const addNewDefaultMappingItem = () => {
    const newData = cloneDeep(data);
    newData.push(defaultWarningMapping);
    setData(newData);
    successMsg('New mapping has been successfully added');
  };

  return (
    <InputGroup
      title={title}
      collapsible={true}
      startCollapsed={false}
      style={{ margin: '10px 25px', overflow: 'auto', ...style }}
    >
      <Table className={classes.table}>
        <TableBody>
          {data.map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <p>{index + 1}</p>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleOpenMapper(index)}>Mapper</Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleClearConditionalMapper(index)}>Clear</Button>
              </TableCell>
              <TableCell style={{ width: 49 }}>
                <IconButton
                  style={{ color: 'black' }}
                  component="span"
                  onClick={() => handleDeleteMappingItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {data.length === 0 && (
        <p style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.54)' }}>Mapping list is empty</p>
      )}

      <DataMapperDialog
        onClose={() => {
          setOpenedIndex(undefined);
        }}
        open={typeof openedIndex === 'number'}
        datamap={data[openedIndex || 0] as NodeMapDefinition}
        setDatamap={(dataItem) => handleSaveMapperData(dataItem)}
        mapScenario={mapScenario}
        localSchemaDefinitions={localSchemas}
      />
      <AddButtonContainer>
        <IconButton component="span" onClick={handleAddNewMappingItem}>
          <AddIcon />
        </IconButton>
        {title === 'Warning Mapping' && (
          <Tooltip title="Proximity Mapping">
            <IconButton component="span" onClick={addNewDefaultMappingItem}>
              <MyLocationIcon />
            </IconButton>
          </Tooltip>
        )}
      </AddButtonContainer>
    </InputGroup>
  );
};

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const useStyles = makeStyles({
  table: {
    backgroundColor: '#fbfbfb',
  },
});
