import React, { useState } from 'react';
import { DialogActions, Button, DialogTitle, DialogContent, Dialog } from '@material-ui/core';
import { CustomColorTableInput } from '../../pages/MobileUI/components/CustomColorTableInput';
import { errorMsg } from '../SnackbarUtilsConfigurator';
import {
  Gen5ColorDefinition,
  Gen5ColorAttribute,
  Gen5ColorMap,
} from '@terragotech/gen5-config-lib/dist/Gen5ColorDefinition';

interface Props {
  open: boolean;
  onClose: () => void;
  aggregates: string[] | object;
  color: Gen5ColorDefinition | undefined;
  onSetColor: (color: Gen5ColorDefinition | undefined) => void;
}

export const ColorPickerDialog: React.FC<Props> = ({
  open,
  onClose,
  aggregates,
  color,
  onSetColor,
}) => {
  const [tempColor, setTempColor] = useState(color);
  const isColorValid = () => {
    if (typeof tempColor === 'object' && tempColor.hasOwnProperty('hexColorKey')) {
      if (!(tempColor as Gen5ColorAttribute).hexColorKey) {
        errorMsg('Hex Color Key property is required');
        return false;
      }
    } else if (typeof tempColor === 'object' && tempColor.hasOwnProperty('mapping')) {
      if (!(tempColor as Gen5ColorMap).colorKey) {
        errorMsg('Color Key property is required');
        return false;
      }
      if ((tempColor as Gen5ColorMap).mapping.some((item) => !item.value)) {
        errorMsg('Mapping item values are required');
        return false;
      }
    }
    return true;
  };
  const handleSave = () => {
    if (isColorValid()) onSetColor(tempColor);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ width: 550 }}>Color Editor</DialogTitle>
      <DialogContent style={{ padding: '0 25px' }}>
        <CustomColorTableInput aggregates={aggregates} color={tempColor} setColor={setTempColor} />
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
