import { gql } from '@apollo/client';

export const GET_AGGREGATE_TITLES = (aggregateType: string, aggregateQueryKey: string, aggregateLabelProperty: string) => gql`
  query GetAggregateTitles($filter: ${aggregateType}Filter) {
    ${aggregateQueryKey}(offset: 0, first: 10, filter: $filter) {
      id
      ${aggregateLabelProperty}
    }
  }
`;
