import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';

import '../../../utils/antDesignCss.css';
require('json-schema-editor-visual/dist/main.css');

export const CustomPagesOverview: React.FC = () => {
  return (
    <EventSchemaContainer>
      <h2>Custom Pages Overview</h2>
      <p>
        Custom pages can be used as pre-defined and reuseable layouts that have customizable
        rows and columns. All custom pages can be accessed using the "Custom Pages" dropdown in
        any Card Definition. Custom pages override any "Other Atributes" settings on the card.
      </p>
      <p>
        Each custom page must meet certain criteria:
      </p>
      <ul>
        <li>
          <strong>Aggregate Type</strong>: Selecting an aggregate type will allow use of the
          custom page with the Card Definition for that aggregate. The aggregate data will also
          be available in the custom page's node mapping. If no aggregate type is selected, the
          custom page will be available to Card Definition for all aggregates, but will not have
          aggregate data available.
        </li>
        <li>
          <strong>Elements</strong>: Each layout section can include one or more elements (attributes)
          for display. Drag and drop the desired element of the type of data you want to display,
          and select the data point to show from the output definition.
        </li>
        <li>
          <strong>Title</strong>: Each element has an optional title property, which will add a
          label to the element data. Each element has a toggle to change whether the content is
          displayed on the same line as the title or below it.
        </li>
      </ul>
    </EventSchemaContainer>
  );
};

const EventSchemaContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 10px 20px;
`;
