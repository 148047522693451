import React from 'react';
import styled from 'styled-components';
import requiredButton from './img/Danger.png';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

interface LabelProps {
  text: string;
  required?: boolean;
  info?: boolean;
  conditional?: boolean;
}

export const Label: React.FC<LabelProps> = ({ text, required, conditional }) => (
  <LabelHolder>
    <LabelText>{text}</LabelText>
    {required && (
      <BadgeHolder>
        <img src={requiredButton} alt="required" />
      </BadgeHolder>
    )}
    {conditional && <ConditionalVisibilityBadge />}
  </LabelHolder>
);

interface ConditionalVisibilityBadge {
  style?: React.CSSProperties;
}

export const ConditionalVisibilityBadge: React.FC<ConditionalVisibilityBadge> = ({ style }) => (
  <Tooltip title="This field has custom display options">
    <VisibilityOutlinedIcon style={{ color: '#aaaaaa', marginTop: -2, ...style }} />
  </Tooltip>
);

export const StandardInputFrame = styled.div`
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding-top: 9px;
  padding-bottom: 8px;
`;
export const LabelText = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  color: #333333;
`;
export const LabelHolder = styled.div`
  display: flex;
  margin-bottom: 2px;
`;
export const BadgeHolder = styled.div`
  margin-right: 8px;
  margin-top: -2px;
`;
