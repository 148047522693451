import React from 'react';
import { InputGroup, TextInput } from './FormElements';
import {
  Switch,
  FormControlLabel,
  // Typography,
  Tooltip,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import _ from 'lodash';
import styled from 'styled-components';

interface MultipleProps {
  enabled: boolean;
  maximum: number;
  unlimited: boolean;
}
interface ComponentProps {
  multiplePoints?: MultipleProps;
  setMultiplePoints: React.Dispatch<React.SetStateAction<MultipleProps | undefined>>;
  disabledSwitch?: boolean;
}
export const defaultMultiplePointsProps: MultipleProps = {
  enabled: false,
  maximum: 1,
  unlimited: false,
};
const IconWithTooltip = ({ className, helperText }: { className: string; helperText: string }) => (
  <Tooltip title={helperText} className={className}>
    <HelpIcon fontSize={'small'} />
  </Tooltip>
);

/*
//todo: in order to give backward compatibility we need to change the definition like below inside the formrenderer library
export interface V2MultiplePointsTemplateComponent extends V2FormComponent {
  multiplePoints?: {
    enabled: boolean;
    maximum: number;
    unlimited: boolean;
  };
}*/
const MultiplePoints = (props: ComponentProps) => {
  const { multiplePoints, setMultiplePoints, disabledSwitch } = props;
  const classes = useStyles();

  return (
    <InputGroup title="Multiple points per instance" style={{ margin: '10px 25px' }}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            onChange={(e, checked) => {
              if (checked === true) {
                if (multiplePoints) {
                  setMultiplePoints({ ...multiplePoints, enabled: checked });
                } else {
                  setMultiplePoints({ ...defaultMultiplePointsProps, enabled: checked });
                }
              } else {
                setMultiplePoints(undefined);
              }
            }}
            checked={multiplePoints?.enabled ?? false}
            disabled={disabledSwitch}
          />
        }
        label="Multiple points per instance"
        color="primary"
      />
      <FormHelperText style={{ fontSize: 12, color: '#6F6F6F', marginBottom: 20 }}>
        Configure if this will have multiple points corresponding to one instance
      </FormHelperText>
      {multiplePoints?.enabled && (
        <div>
          <MinMaxContainer>
            <div className={classes.minMax}>
              {multiplePoints?.enabled && (
                <TextInput
                  id="Maximum"
                  value={multiplePoints.maximum}
                  onChange={(value) => {
                    if (_.toNumber(value) >= 0 || !value) {
                      setMultiplePoints({ ...multiplePoints, maximum: _.toNumber(value) });
                    }
                  }}
                  fullWidth={true}
                  type="number"
                  style={{ margin: 0 }}
                />
              )}
              <FormControlLabel
                style={{ position: 'absolute', right: 20 }}
                control={<Switch checked={multiplePoints?.unlimited} color="primary" />}
                onChange={(e, checked) => {
                  if (checked) {
                    setMultiplePoints({ ...multiplePoints, unlimited: true });
                  } else {
                    setMultiplePoints({ ...multiplePoints, unlimited: false });
                  }
                }}
                label="unlimited"
                color="primary"
              />
              <IconWithTooltip
                className={classes.minMaxToolTip}
                helperText={
                  'The maximum number of instances per aggregate (must be greater than 0), unlimited switch means no maximum'
                }
              />
            </div>
          </MinMaxContainer>
        </div>
      )}
    </InputGroup>
  );
};

const useStyles = makeStyles({
  minMax: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  minMaxToolTip: { position: 'absolute', right: 10 },
  checkboxToolTip: { position: 'relative' },
});

const MinMaxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10;
`;
export default MultiplePoints;
