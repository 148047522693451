import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { Variant } from '.';

type SelectOption = string | number | string[];

interface SelectInputProps<T> {
  title?: string;
  value: T;
  onChange: (value: T) => void;
  options: object | T[] | T;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  variant?: Variant;
  fullWidth?: boolean;
  containerStyle?: React.CSSProperties;
  margin?: 'dense' | 'none' | 'normal';
  multiple?: boolean;
}

export const SelectInput = <T extends SelectOption>({
  title,
  value,
  onChange,
  options,
  labelStyle,
  style,
  variant,
  fullWidth = true,
  containerStyle,
  margin,
  multiple,
}: SelectInputProps<T>) => (
  <FormControl
    fullWidth={fullWidth}
    variant={variant ? variant : 'outlined'}
    margin={margin || 'dense'}
    style={containerStyle}
  >
    {title && (
      <InputLabel
        htmlFor={`${title}-label`}
        style={{ ...labelStyle, fontSize: 14, backgroundColor: 'white' }}
      >
        {title}
      </InputLabel>
    )}
    <Select
      labelId={`${title}-label`}
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      label={title}
      inputProps={{
        name: 'label',
        id: `${title}-label`,
      }}
      multiple={multiple}
      style={{ ...style }}
    >
      {options &&
        ((Array.isArray(options) && options.length === 0) || Object.keys(options).length === 0) && (
          <MenuItem value="" disabled>
            None available
          </MenuItem>
        )}
      {options && Array.isArray(options)
        ? options.map((item: T) => (
            <MenuItem key={item as string | number} value={item} style={{ height: 36 }}>
              {item}
            </MenuItem>
          ))
        : Object.entries(options).map(([propertyKey, value]) => (
            <MenuItem key={propertyKey} value={value} style={{ height: 36 }}>
              {propertyKey}
            </MenuItem>
          ))}
    </Select>
  </FormControl>
);
