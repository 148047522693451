import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { TreeViewNode } from '../../components/TreeViewNode';
import { useHistory } from 'react-router-dom';
import { getPathToLevel } from '../../utils/navigationUtils';
import { CollapsibleMenuCard } from '../../components/AppContainer/CollapsibleMenuCard';

export const Integrations: React.FC = () => {
  const history = useHistory();

  return (
    <CollapsibleMenuCard title="Integrations">
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={(_: object, nodeId: string) => {
          history.push(nodeId);
        }}
        selected={getPathToLevel(history.location.pathname, 3)}
        style={{ marginRight: 6 }}
      >
        <TreeViewNode
          nodeId="/features/integrations/mapOptions"
          label="Map Options"
          key="MapOptions"
          color="green"
        />
      </TreeView>
    </CollapsibleMenuCard>
  );
};
