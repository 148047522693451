import React, { useMemo, useCallback, useContext, useState } from 'react';
import { InputGroup } from '.';
import { SelectInput } from './SelectInput';
import { EditableTable, CSVResult } from '../EditableTable';
import { cloneDeep } from 'lodash';
import {
  V2OptionsType,
  V2SimpleOption,
  V2AggregateLoader,
  V2FormOptionsGroup,
  DynamicLoaderType,
  SimpleOptionType,
  AggregateLoaderType,
  UserLoaderType,
} from '@terragotech/form-renderer';
import { ConfigContext } from '../../context/ConfigContext';
import QueryFilterEditorDialog from '../FormDialog/QueryFilterEditorDialog';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { camelCaseToSpaceSeparated } from './TextInput';
import { useConfirm } from 'material-ui-confirm';
import { successMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import { propertiesObjsToArray } from '../../pages/aggregates/utils/propertiesObjsToArray';
import { SimpleOption } from '../SimpleOptionsUpload';
import { Button } from '@material-ui/core';
import SCVUploadResultsDialog from '../CSVUploadResultsDialog/CSVUploadResultsDialog';
import DataMapperDialog from 'components/FormDialog/DataMapperDialog';
import { MappingActiveDisplay } from 'components/MappingActiveDisplay';
import _ from 'lodash';
import { LocalSchemaDefinition } from 'utils/useSchemaLookup';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';

let CSVResultData: CSVResult;

interface Props {
  options: V2OptionsType;
  setOptions: (value: V2OptionsType) => void;
  aggregateOnly?: boolean;
  onDelete?: ()=>void;
  localSchemas?: LocalSchemaDefinition;
}

interface Option {
  value: string;
  label: string;
}

type V2OptionsTypes = typeof SimpleOptionType | typeof AggregateLoaderType | typeof UserLoaderType | typeof DynamicLoaderType;

type QueryFilterType = 'mandatoryFilter' | 'defaultFilter';

export const isSimpleOption = (options: V2OptionsType): options is V2SimpleOption =>
  options.type === SimpleOptionType;

export const isAggregateLoader = (options: V2OptionsType): options is V2AggregateLoader =>
  options.type === AggregateLoaderType;

export const V2OptionsInput: React.FC<Props> = ({ options, setOptions, aggregateOnly, onDelete, localSchemas }: Props) => {
  const { config } = useContext(ConfigContext);
  const [pickedQueryFilter, setPickedQueryFilter] = useState<QueryFilterType | undefined>(
    undefined
  );
  const confirm = useConfirm();
  const [CSVResultOpen, setcSVResultOpen] = useState(false);

  const [dynamicMap, setDynamicMap] = useState(options.type === DynamicLoaderType ? options.optionsMap || undefined : undefined);
  const [dynamicOpen, setDynamicOpen] = useState(false);
  const doesDynamicHaveValue = useCallback(() => {
    return !_.isEmpty(dynamicMap);
  }, [dynamicMap]);
  const handleClearDynamicMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setDynamicMap(undefined);
    if(options.type === DynamicLoaderType){
      const optionsCopy = cloneDeep(options);
      optionsCopy.optionsMap = undefined;
      setOptions(optionsCopy);
    }
  };

  const getOptions = useMemo(() => {
    if (isSimpleOption(options)) return cloneDeep(options.items || []);
    return [];
  }, [options]);

  const isOptionInvalid = useCallback((row: object) => {
    if (!(row as Option).value) {
      errorMsg('Property "Value" is required');
      return true;
    }
    if (!(row as Option).label) {
      errorMsg('Property "Label" is required');
      return true;
    }
    return false;
  }, []);

  const addOption = useCallback(
    (option: object, resolve: (data: any) => void, reject: () => void) => {
      if (isSimpleOption(options)) {
        if (isOptionInvalid(option)) return reject();
        const optionsCopy = cloneDeep(options);
        optionsCopy.items = [...(options.items || []), option as Option];
        setOptions(optionsCopy);
        resolve(null);
      }
    },
    [options, setOptions, isOptionInvalid]
  );

  const deleteOption = useCallback(
    (option: object) => {
      if (isSimpleOption(options)) {
        const optionsCopy = cloneDeep(options);
        optionsCopy.items = optionsCopy.items.filter(
          (item) => item.value !== (option as Option).value
        );
        setOptions(optionsCopy);
      }
    },
    [options, setOptions]
  );

  const CSVFileImport = (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: any) => void,
    reject: () => void
  ) => {
    if (isSimpleOption(options)) {
      setcSVResultOpen(true);
      CSVResultData = uploadedResults;
      setOptions({ items: rows as SimpleOption[], type: SimpleOptionType });
      successMsg(`CSV file successfully uploaded`);
      return resolve(null);
    }
    errorMsg(`Error while uploading CSV file`);
    return reject();
  };

  const updateOption = useCallback(
    (option: object, oldOption: object, resolve: (data: any) => void, reject: () => void) => {
      if (isSimpleOption(options)) {
        if (isOptionInvalid(option)) return reject();
        const optionsCopy = cloneDeep(options);
        optionsCopy.items = optionsCopy.items.map((item) =>
          item.value === (oldOption as Option).value ? (option as Option) : item
        );
        setOptions(optionsCopy);
        resolve(null);
      }
    },
    [options, setOptions, isOptionInvalid]
  );

  const handleChangeOptionType = (value: V2OptionsTypes) => {
    const optionsCopy = cloneDeep(options);
    optionsCopy.type = value;
    // if (isSimpleOption(optionsCopy) && value === AggregateLoaderType) delete optionsCopy.items;
    setOptions(optionsCopy);
  };

  const handleChangeAggregateType = async (value: string) => {
    if (isAggregateLoader(options)) {
      if (options.mandatoryFilter || options.defaultFilter)
        await confirm({
          description: `Changing the aggregate type requres the removal of current filters, are you sure?`,
          confirmationText: 'Change',
        });
      const optionsCopy = cloneDeep(options);
      delete optionsCopy.mandatoryFilter;
      delete optionsCopy.defaultFilter;
      optionsCopy.aggregateType = value;
      setOptions(optionsCopy);
    }
  };

  const getAggregateList = () => config.aggregates.map((item) => item.typeName);

  const handleClearQueryEditor = async (filterType: QueryFilterType) => {
    if (!isSimpleOption(options) && options.type !== DynamicLoaderType) {
      await confirm({
        description: `Current ${camelCaseToSpaceSeparated(
          filterType
        )} will be cleared, are you sure?`,
        confirmationText: 'Clear',
      });
      const optionsCopy = cloneDeep(options);
      delete optionsCopy[filterType];
      setOptions(optionsCopy);
    }
  };

  const getAggregateProperties = () =>
    config.aggregates[getAggregateIndex(config, (options as V2AggregateLoader).aggregateType)]
      .properties;

  const handleChangeFilter = (filter: V2FormOptionsGroup) => {
    if (!isSimpleOption(options) && options.type !== DynamicLoaderType && pickedQueryFilter) {
      const optionsCopy = cloneDeep(options);
      optionsCopy[pickedQueryFilter] = { filter };
      setOptions(optionsCopy);
    }
  };

  const handleChangeMap = (map?: NodeMapDefinition) => {
    if (options.type === DynamicLoaderType) {
      const optionsCopy = cloneDeep(options);
      optionsCopy.optionsMap = map;
      setOptions(optionsCopy);
      setDynamicMap(map);
    }
  };

  const getQueryFilterKeys = (type: typeof UserLoaderType | typeof AggregateLoaderType) => {
    switch (type) {
      case UserLoaderType:
        return [
          { name: 'username', type: 'String' },
          { name: 'email', type: 'String' },
          { name: 'family_name', type: 'String' },
          { name: 'given_name', type: 'String' },
          { name: 'roles', type: 'StringArray' },
        ];
      case AggregateLoaderType:
        return propertiesObjsToArray(getAggregateProperties());
    }
  };

  return (
    <InputGroup
      title="Options"
      style={{ margin: '10px 25px', paddingTop: 15, overflowY: 'auto' }}
      titleStyle={{ transform: 'translate(-20px, -41px) scale(0.75)', zIndex: 100 }}
    >
      {!aggregateOnly && <SelectInput
        title="Options type"
        value={options.type}
        options={[SimpleOptionType, AggregateLoaderType, UserLoaderType, DynamicLoaderType]}
        onChange={handleChangeOptionType}
      />}
      {options.type === SimpleOptionType && (
        <InputGroup
          title="Options"
          style={{ margin: '10px 0', paddingTop: 5, overflowY: 'auto' }}
          titleStyle={{ transform: 'translate(-20px, -31px) scale(0.75)', zIndex: 100 }}
        >
          <EditableTable
            columns={optionsColumns}
            data={getOptions}
            toolbarStyle={otherAttributesTableToolbarStyle}
            fileType="simpleOptions"
            onFileImport={CSVFileImport}
            onAdd={addOption}
            onUpdate={updateOption}
            onDelete={deleteOption}
            options={tableOptions}
          />
          <SCVUploadResultsDialog
            CSVResultOpen={CSVResultOpen}
            setcSVResultOpen={setcSVResultOpen}
            CSVResultData={CSVResultData}
          />
        </InputGroup>
      )}
      {(options.type === DynamicLoaderType) && (
        <InputGroup title="Mapping" style={{ margin: '10px 25px' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button onClick={() => setDynamicOpen(true)}>
              <div>
                Advanced
                <MappingActiveDisplay
                  isActive={doesDynamicHaveValue()}
                  activeLabelText={'active'}
                />
              </div>
            </Button>
            <Button onClick={handleClearDynamicMapper}>Clear</Button>
          </div>
          <DataMapperDialog
            mapScenario="DROPDOWN_OPTIONS"
            localSchemaDefinitions={localSchemas}
            onClose={() => {
              setDynamicOpen(false);
            }}
            open={dynamicOpen}
            datamap={dynamicMap}
            setDatamap={handleChangeMap}
          />
        </InputGroup>
      )}
      {options.type !== SimpleOptionType && options.type !== DynamicLoaderType && (
        <div>
          {options.type === AggregateLoaderType && (
            <SelectInput
              title="Aggregate type"
              value={options.aggregateType}
              options={getAggregateList()}
              onChange={handleChangeAggregateType}
            />
          )}
          {(options.type === UserLoaderType || options.aggregateType) && (
            <>
              <InputGroup title="Mandatory Filter" style={{ margin: '10px 0' }}>
                <Button onClick={() => setPickedQueryFilter('mandatoryFilter')}>Advanced</Button>
                {options.mandatoryFilter && (
                  <Button onClick={() => handleClearQueryEditor('mandatoryFilter')}>Clear</Button>
                )}
              </InputGroup>
              <QueryFilterEditorDialog
                open={Boolean(pickedQueryFilter)}
                onClose={() => setPickedQueryFilter(undefined)}
                filter={pickedQueryFilter && options[pickedQueryFilter]?.filter}
                setFilter={(filter) => handleChangeFilter(filter as V2FormOptionsGroup)}
                keys={getQueryFilterKeys(options.type)}
                filterName={pickedQueryFilter && camelCaseToSpaceSeparated(pickedQueryFilter)}
                allowValue={!!aggregateOnly}
              />
            </>
          )}
        </div>
      )}
      {
        onDelete && <div style={{justifyContent:'center', display:'grid'}} ><Button onClick={onDelete}>Remove</Button></div>
      }
    </InputGroup>
  );
};

const optionsColumns = [
  { title: 'Label', field: 'label' },
  { title: 'Value', field: 'value' },
];

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
};
