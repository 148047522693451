import React, { useState } from 'react';
import { Card, IconButton, Collapse } from '@material-ui/core';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface InputGroupProps {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  collapsible?: boolean;
  startCollapsed?: boolean;
}

const RotatingButton = styled(IconButton)<{ rotated: number }>`
  transform: rotate(${(props) => (props.rotated ? 0 : 180)}deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const InputGroup: React.FC<InputGroupProps> = ({
  title,
  children,
  style,
  titleStyle,
  collapsible,
  startCollapsed = false,
}) => {
  const [collapsed, setCollapsed] = useState(startCollapsed);
  return (
    <Card
      variant="outlined"
      style={{ padding: 20, margin: '10px 0', borderColor: 'rgb(220,220,220)', ...style }}
    >
      <p
        style={{ ...groupTitleStyle, ...titleStyle, marginBlockStart: collapsible ? 6 : undefined }}
      >
        {title}
        {collapsible && (
          <RotatingButton
            rotated={collapsed ? 1 : 0}
            onClick={() => setCollapsed((current) => !current)}
          >
            <ExpandMoreIcon />
          </RotatingButton>
        )}
      </p>
      <Collapse in={!collapsed}>{children}</Collapse>
    </Card>
  );
};

const groupTitleStyle = {
  position: 'absolute' as const,
  transform: 'translate(-20px, -46px) scale(0.75)',
  backgroundColor: 'white',
  padding: '0 10px',
  color: 'grey',
};
