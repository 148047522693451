import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { ColorPicker } from '../../../components/FormElements';
import EditIcon from '@material-ui/icons/Edit';
import { ColorPickerDialog } from '../../../components/FormDialog/ColorPickerDialog';
import {
  Gen5ColorDefinition,
  Gen5ColorMap,
  Gen5ColorAttribute,
} from '@terragotech/gen5-config-lib/dist/Gen5ColorDefinition';

export interface Gen5ColorPickerProps {
  color: Gen5ColorDefinition | undefined;
  setColor?: (value: Gen5ColorDefinition | undefined) => void;
  aggregates?: string[] | object;
}

export const Gen5ColorPicker: React.FC<Gen5ColorPickerProps> = ({
  color,
  setColor,
  aggregates,
}) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const type = () => {
    if (color) {
      if (typeof color === 'string') return 'string';
      if (typeof color === 'object' && color.hasOwnProperty('mapping')) return 'map';
      if (typeof color === 'object') return 'object';
    }
    return 'unknown';
  };

  const getMap = () => (
    <>
      {(color as Gen5ColorMap).colorKey && (
        <p style={{ marginTop: 5, fontSize: 14 }}>Color Key: {(color as Gen5ColorMap).colorKey}</p>
      )}
      <table>
        {(color as Gen5ColorMap).mapping.map((row) => (
          <tr>
            <td>
              <p style={{ margin: 0, fontSize: 14 }} key={row.value}>
                {row.value}:
              </p>
            </td>
            <td style={{ paddingTop: 7, paddingBottom: 7, paddingLeft: 10, width: 50 }}>
              <ColorPicker value={row.hexColor} disabled />
            </td>
          </tr>
        ))}
      </table>
    </>
  );

  const getObject = () => <p style={{ margin: 0 }}>{(color as Gen5ColorAttribute).hexColorKey}</p>;

  const getString = () => <ColorPicker value={color as string} disabled />;

  const getColor = () => {
    switch (type()) {
      case 'map':
        return getMap();
      case 'string':
        return getString();
      case 'object':
        return getObject();
      default:
        return null;
    }
  };
  const handleOpenColorPicker = () => setColorPickerOpen(true);

  const handleCloseColorPicker = () => setColorPickerOpen(false);

  const handleSetColor = (color: Gen5ColorDefinition | undefined) => {
    if (setColor) {
      setColor(color);
      handleCloseColorPicker();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      {getColor() && <div style={{ marginRight: 10 }}>{getColor()}</div>}
      {setColor && aggregates && (
        <>
          <IconButton style={{ color: 'black' }} component="span" onClick={handleOpenColorPicker}>
            <EditIcon />
          </IconButton>
          <ColorPickerDialog
            aggregates={aggregates}
            color={color}
            open={colorPickerOpen}
            onSetColor={handleSetColor}
            onClose={handleCloseColorPicker}
          />
        </>
      )}
    </div>
  );
};
