import React, { useState } from 'react';
import styled from 'styled-components';
import { Paper, IconButton, Divider } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

interface Props {
  children: React.ReactNode;
  title: string;
}

export const CollapsibleMenuCard: React.FC<Props> = ({ title, children }) => {
  const [isShrunken, setIsShrunken] = useState(false);

  const IconButtonStyles = {
    marginRight: !isShrunken ? '-5px' : undefined,
    flex: '2',
    margin: '0',
    padding: '0',
    justifyContent: 'flex-end',
    display: 'flex',
  };

  return (
    <AggregatesListContainer
      $isShrunken={isShrunken.toString()}
      onClick={() => isShrunken && setIsShrunken(!isShrunken)}
    >
      <TitleContainer $isShrunken={isShrunken.toString()}>
        {!isShrunken && <Title>{title}</Title>}
        <IconButton
          component="span"
          onClick={() => setIsShrunken(!isShrunken)}
          style={IconButtonStyles}
        >
          {!isShrunken ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
        </IconButton>
      </TitleContainer>
      {!isShrunken && (
        <>
          <GroupDivider />
          <CardBody>{children}</CardBody>
        </>
      )}
    </AggregatesListContainer>
  );
};

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
const AggregatesListContainer = styled(Paper)<{ $isShrunken: string }>`
  display: flex;
  flex-direction: column;
  padding: 0 10px 1rem 10px;
  width: ${({ $isShrunken }) => ($isShrunken === 'true' ? '50px' : 'auto')};
  cursor: ${({ $isShrunken }) => ($isShrunken === 'true' ? 'pointer' : undefined)};
  margin: 5px;
  max-height: ${({ $isShrunken }) =>
    $isShrunken === 'true' ? 'calc(100vh - 91px)' : 'calc(100vh - 122px)'};
  position: sticky;
  top: 75px;
`;

const TitleContainer = styled.div<{ $isShrunken: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  // justify-content: space-between;
  height: ${({ $isShrunken }) => ($isShrunken === 'true' ? '100%' : undefined)};
`;

const Title = styled.p`
  flex: 1;
  margin: 0;
  padding: 0;
  padding: 0;
  white-space: nowrap;
`;
