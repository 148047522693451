import React, { useCallback, useContext, useMemo } from 'react';
import { Paper, Divider, Button } from '@material-ui/core';
import styled from 'styled-components';
import { EventMapDiagram, useDataMapDiagram } from '../../../map-editor/src';
import { convertV2FormTemplateToJsonSchema } from '../../aggregates/utils/V2FormTemplateToJsonSchema';
import { sample } from '../../aggregates/utils/sampleForm';
import { FullScreen } from '../../../components/FullScreen';
import { ConfigContext } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useParams, Prompt } from 'react-router-dom';
import { useFunctionAPI } from '../../../context/fakeAPIHooks/useFunctionAPI';
import { useConfirm } from 'material-ui-confirm';
import { useSchemaLookup } from '../../../utils/useSchemaLookup';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { useFormDialog } from '../../../components/FormDialog/FormDialogService';
import { MapperShortCutInfoDialog } from '../../../components/FormDialog/MapperShortCutInfoDialog';

export const EventMapper: React.FC = () => {
  const { config, getFunction } = useContext(ConfigContext);
  const FunctionAPI = useFunctionAPI();

  //For delete confirmation
  const confirm = useConfirm();
  const formDialog = useFormDialog();

  const { function: name, version } = useParams() as {
    function: string;
    version: string;
  };
  const handleShortCutInfo = () => {
    formDialog<typeof MapperShortCutInfoDialog>((props) => <MapperShortCutInfoDialog {...props} />);
  };

  const handleSaveRequest = async () => {
    const thing = model?.getMapDefinition();
    if (thing) {
      const { error } = await FunctionAPI.updateFunctionMap(name, +version, thing);
      if (error) return;
      successMsg('Function map has been saved');
    }
  };

  const localSchemas = useMemo(() => {
    return {
      DATA: {
        schema:
          getFunction(name)?.versions[+version]?.input || convertV2FormTemplateToJsonSchema(sample),
        schemaLabel: 'Input',
      },
      STATE: {
        schema:
          getFunction(name)?.versions[+version]?.output ||
          convertV2FormTemplateToJsonSchema(sample),
        schemaLabel: 'Output',
      },
    };
  }, [name, version, getFunction]);
  const schemaLookup = useSchemaLookup({
    localSchemas: localSchemas,
  });
  const { model, engine, checkModelHasChanged, hasSetPosition } = useDataMapDiagram({
    //outputObject: config.functions[aggrIndex].properties || {},
    //sourceObjects,
    scenario: 'FUNCTION_MAPPING',
    schemaLookup: schemaLookup,
    dataMap: config.functions
      ? config.functions[name]?.versions[+version]?.aggregateMap
      : undefined,
  });

  const ifShowMessageBeforeRedirect = useCallback(() => {
    return checkModelHasChanged() ? 'The form has not been saved, do you want to redirect?' : true;
  }, [checkModelHasChanged]);

  return (
    <EventMapperContainer>
      <CardTitle style={{ padding: '10px 20px 0px' }}>Function map</CardTitle>
      <GroupDivider />
      <FullScreen>
        <EventMapDiagram
          model={model}
          engine={engine}
          hasSetPosition={hasSetPosition}
          confirm={confirm}
          mapScenario={'FUNCTION_MAPPING'}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveRequest}
          style={{ position: 'absolute', right: 20, bottom: 20 }}
        >
          Save
        </Button>
        <Button
          style={{
            position: 'absolute',
            right: 110,
            bottom: 20,
            color: '#ffffff',
            backgroundColor: 'black',
          }}
          onClick={handleShortCutInfo}
          variant="contained"
          startIcon={<KeyboardIcon />}
        >
          SHORTCUTS
        </Button>
        <Prompt message={ifShowMessageBeforeRedirect} />
      </FullScreen>
    </EventMapperContainer>
  );
};

const EventMapperContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - 92px);
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
  }
`;
