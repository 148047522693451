import React from 'react';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';

import '../../../utils/antDesignCss.css';
require('json-schema-editor-visual/dist/main.css');

export const FunctionsOverview: React.FC = () => {
  return (
    <EventSchemaContainer>
      <h2>Functions Overview</h2>
      <p>
        Functions can be used as pre-defined and reuseable sets of logic that have customizable
        inputs and outputs. All functions can be accessed using the "Functions" node in any mapping
        except functions, events, and derived properties. Functions that meet specific criteria
        outlined below can also be utilized as a part of the "Array Filter" and "Array Transform"
        nodes in any mapping except events and derived properties.
      </p>
      <p>
        The "Array Filter" node is used to filter items out of an array in a way defined by the
        function selected as the filter. For a function to be used for an array filter, the output
        must be a single boolean. If the output boolean is true for a given item, the item in the
        array will be present in the output array, whereas if the output boolean for the item is
        false, it will be removed from the output array.
      </p>
      <p>
        The "Array Reduce" node is used to condense an array into a single item in a way defined by
        the function selected as the reducer. For a function to be used for an array reduce, the
        output must only have a single parameter which will be the final state of the item. In
        addition, the input must have a parameter named "prev" with the same type as the output.
        This parameter is the running result of the reducer. It will be populated by a supplied
        initial value for the first item, then by the output from the previous item for each
        subsequent item in the array.
      </p>
      <p>
        The "Array Transform" node is used to mutate items of an array in a way defined by the
        function selected as the transformer. For a function to be used for an array transform, the
        output must only have a single parameter which will be the final state of the item in the
        output array, though it can be an object that can contain as many properties as you would
        like.{' '}
      </p>
      <p>
        The "Array Reduce", "Array Filter" and "Array Transform" nodes require the same signature
        for their input parameters, as described below, with the "Array Reduce" having the
        additional "prev" parameter as described above:
      </p>
      <ul>
        <li>
          <strong>Item of the array</strong>: You must always provide an input parameter that will
          be the individual item of the array, which will iterated over for each item in your array.
          This parameter can be named anything you would like.
        </li>
        <li>
          <strong>Full array</strong>: Optionally, you can include the full array to be available to
          you if necessary for the mapping. The name of this parameter does not matter, but it must
          be of type array and the items within the array must be of the same type as the individual
          item.
        </li>
        <li>
          <strong>Index</strong>: Optionally, if you include the full array as a parameter, you can
          also include the index of the current item within the array as a parameter. This must be
          named "index" and be of type number.
        </li>
        <li>
          <strong>Additional data</strong>: Optionally, you can include an input parameter named
          "data" which can be any type and that will allow you to send additional data into the
          array filter or array transform node separate from the array itself.
        </li>
      </ul>
    </EventSchemaContainer>
  );
};

const EventSchemaContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 10px 20px;
`;
