import React, { FocusEventHandler, useState } from 'react';
import { ReactComponent as CloseIcon } from '../icons/CloseIcon.svg';
import styled from 'styled-components';

interface TinyTextInputProps {
  value: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  onBlur?: FocusEventHandler;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  undefinable?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  type?: 'text' | 'number';
}

export const TinyTextInput: React.FC<TinyTextInputProps> = ({
  value,
  onChange,
  style,
  containerStyle,
  undefinable,
  placeholder,
  autoFocus,
  type = 'text',
  onBlur,
}) => {
  const [tempValue, setTempValue] = useState(value);

  const toggleNull = () => {
    if (value === null) {
      onChange(tempValue || '');
    } else {
      setTempValue(value);
      onChange(null);
    }
  };
  const setUndefined = () => {
    setTempValue(undefined);
    onChange(undefined);
  };

  return undefinable ? (
    <span style={{ ...inputContainerStyle, ...containerStyle }}>
      <NullableButton isNull={value === null} onClick={toggleNull}>
        <NullableButtonLabel isNull={value === null}>N</NullableButtonLabel>
      </NullableButton>
      <InputComponent
        autoFocus={autoFocus}
        type={value === null ? 'text' : type}
        style={{ ...style }}
        value={value === null ? 'null' : value || ''}
        onChange={(e: any) => onChange(e.target.value)}
        disabled={value === null}
        isNull={value === null}
        nullable={undefinable}
        placeholder={placeholder || 'undefined'}
        onBlur={onBlur}
      />
      <div onClick={setUndefined} style={setUndefinedButtonStyle} title="Set undefined">
        <CloseIcon style={setUndefinedIconStyle} />
      </div>
    </span>
  ) : (
    <InputComponent
      autoFocus={autoFocus}
      type={type}
      style={{ ...style }}
      value={value as string}
      onChange={(e: any) => onChange(e.target.value)}
      nullable={!!undefinable}
      isNull={value === null}
      onBlur={onBlur}
    />
  );
};

const NullableButton = styled.span<{ isNull: boolean }>`
  width: 25px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.isNull ? 'dimgrey' : 'grey')};
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  box-shadow: isNull ? 'rgb(96, 96, 96) 1px 1px 13px 0px inset' : undefined;
`;

const NullableButtonLabel = styled.p<{ isNull: boolean }>`
  margin-left: 1px;
  color: ${(props) => (props.isNull ? '#1890ff' : 'white')};
`;

const inputContainerStyle = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
} as const;

const setUndefinedButtonStyle = {
  padding: 2,
  marginBottom: 1,
  position: 'absolute',
  right: 0,
} as const;

const setUndefinedIconStyle = {
  fill: 'grey',
  fontSize: '1.1rem',
  width: '1rem',
  height: '1rem',
  marginTop: '0.3rem',
  cursor: 'pointer',
};

const InputComponent = styled.input<{ nullable: boolean; isNull: boolean }>`
  font-size: 12px;
  height: 16px;
  color: black;
  border: none;
  padding: 2px 5px;
  width: 100%;
  box-shadow: inset 1px 1px 3px 0 #424242;
  background-color: ${(props) => (props.nullable && props.isNull ? 'grey' : 'white')};
  outline-color: white;
  border-radius: ${(props) => (props.nullable ? '0 5px 5px 0' : '5px')};
  ::-webkit-inner-spin-button {
    margin-right: 10px;
  }
  ::-webkit-outer-spin-button {
    margin-right: 10px;
  }
`;
