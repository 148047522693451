import React, { useState } from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ColorPicker, TextInput, SelectInput } from '../../../components/FormElements';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {
  Gen5ColorDefinition,
  Gen5ColorMap,
  Gen5ColorAttribute,
} from '@terragotech/gen5-config-lib/dist/Gen5ColorDefinition';

export interface CustomColorTableInputProps {
  color: Gen5ColorDefinition | undefined;
  setColor: (value: Gen5ColorDefinition | undefined) => void;
  aggregates: string[] | object;
}
const getType = (color: Gen5ColorDefinition | undefined) => {
  if (!color) return undefined;
  if (typeof color === 'string') return 'Fixed Color';
  if (typeof color === 'object' && color.hasOwnProperty('mapping')) return 'map';
  return 'Color Attribute';
};

const defaultFixedColor = '#000000';

const defaultColorAttribute = {
  hexColorKey: '',
};

const defaultColorMappingItem = {
  value: '',
  hexColor: '',
};

const defaultColorMapping = { mapping: [defaultColorMappingItem], colorKey: '' };

const options = [undefined, 'Fixed Color', 'Color Attribute', 'map'];

type typeOptions = '' | 'Fixed Color' | 'Color Attribute' | 'map';

const getDefaultColor = (type: typeOptions) => {
  switch (type) {
    case 'Fixed Color':
      return defaultFixedColor;
    case 'Color Attribute':
      return defaultColorAttribute;
    case 'map':
      return defaultColorMapping;
    default:
      return undefined;
  }
};

export const CustomColorTableInput: React.FC<CustomColorTableInputProps> = ({
  color,
  setColor,
  aggregates,
}) => {
  const classes = useStyles();
  const [type, setType] = useState(getType(color));

  const handleAddNewMappingItem = () => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).mapping.push({ ...defaultColorMappingItem });
      setColor(copiedColor);
    }
  };

  const handleDeleteMappingItem = (index: number) => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).mapping.splice(index, 1);
      setColor(copiedColor);
    }
  };

  const handleMapChange = (value: string, index: number, type: 'value' | 'hexColor') => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).mapping[index][type] = value;
      setColor(copiedColor);
    }
  };

  const handleColorKeyChange = (value: string) => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).colorKey = value;
      setColor(copiedColor);
    }
  };

  return (
    <>
      <TypeSelectContainer>
        <SelectInput
          title="Label Property"
          variant="standard"
          options={options}
          value={type || ''}
          onChange={(value) => {
            setType(value as typeOptions);
            setColor(getDefaultColor(value as typeOptions));
          }}
        />
      </TypeSelectContainer>
      {type === 'Fixed Color' && (
        <StringColorContainer>
          <p>Fixed Color:</p>
          <ColorPicker value={color as string} onChange={(value) => setColor(value)} />
        </StringColorContainer>
      )}
      {type === 'Color Attribute' && (
        <Table className={classes.tableSmall}>
          <TableBody>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>Hex Color Key</TableCell>
              <TableCell style={{ width: 200, paddingRight: 0 }}>
                <SelectInput
                  title="Color Key"
                  variant="standard"
                  margin="none"
                  options={aggregates}
                  value={(color as Gen5ColorAttribute).hexColorKey}
                  onChange={(value) => setColor({ hexColorKey: value })}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {type === 'map' && (
        <>
          <SelectInput
            title="Color Key"
            variant="standard"
            options={aggregates}
            value={(color as Gen5ColorMap).colorKey || ''}
            onChange={(value) => handleColorKeyChange(value)}
          />
          <Table className={classes.table}>
            <TableBody>
              {(color as Gen5ColorMap).mapping.map((item, index) => (
                <TableRow>
                  <TableCell style={{ paddingLeft: 0 }}>
                    <TextInput
                      label="Value"
                      value={item.value}
                      onChange={(value) => handleMapChange(value || '', index, 'value')}
                      id={index.toString()}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell style={{ width: 50 }}>
                    <ColorPicker
                      value={item.hexColor}
                      onChange={(value) => handleMapChange(value, index, 'hexColor')}
                    />
                  </TableCell>
                  {(color as Gen5ColorMap).mapping.length > 1 && (
                    <TableCell style={{ width: 49 }}>
                      <IconButton
                        style={{ color: 'black' }}
                        component="span"
                        onClick={() => handleDeleteMappingItem(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <AddButtonContainer>
            <IconButton component="span" onClick={handleAddNewMappingItem}>
              <AddIcon />
            </IconButton>
          </AddButtonContainer>
        </>
      )}
    </>
  );
};

const TypeSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const StringColorContainer = styled.div`
  padding: 16px 16px 16px 0;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  box-sizing: border-box;
`;

const useStyles = makeStyles({
  table: {
    minWidth: 350,
    backgroundColor: '#fbfbfb',
  },
  tableSmall: {
    minWidth: 300,
    backgroundColor: '#fbfbfb',
  },
});
