import React, { useContext } from 'react';
export interface SymbolScaleValue {
  scale: number;
}

const SymbolScaleContext = React.createContext<SymbolScaleValue>({
  scale: 1,
});

const SymbolScaleContextProvider: React.FunctionComponent<{
  scale?: number;
  children: React.ReactElement;
}> = (props) => {
  return (
    <SymbolScaleContext.Provider value={{ scale: props.scale || 1 }}>
      {props.children}
    </SymbolScaleContext.Provider>
  );
};

export const useSymbolScale = (): SymbolScaleValue => useContext(SymbolScaleContext);
export default SymbolScaleContextProvider;
