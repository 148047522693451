import React, { Fragment } from 'react';
import Condition from './Condition';
import styled from 'styled-components';
import { ConjunctionConst } from './defaults/defaultConjunctions';
import { Translations } from './defaults/defaultTranslations';
import {
  QueryBuilderSchema,
  AttributeBasedFilterWithID,
  AttributeBasedFilterSimpleFiltersWithID,
} from './QueryBuilder';
import { isConditionGroupWithId } from './utils/isConditionGroup';
import { createCondition } from './utils/createCondition';
import { createConditionGroup } from './utils/createConditionGroup';
import { AttributeBasedFilterEqualType } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';

interface ConditionGroupProps {
  id: string;
  parentId: string | null;
  conjunction: ConjunctionConst;
  condition: Array<AttributeBasedFilterWithID | AttributeBasedFilterSimpleFiltersWithID>;
  translations: Translations;
  schema: QueryBuilderSchema;
  not?: boolean;
  allowValue?: boolean;
}

const ConditionGroup: React.FC<ConditionGroupProps> = ({
  id,
  parentId,
  conjunction,
  condition,
  translations,
  schema,
  not,
  allowValue
}) => {
  const {
    keys,
    controls: {
      ConjunctionSelector,
      NotToggle,
      AddConditionAction,
      AddGroupAction,
      RemoveGroupAction,
    },
    conjunctions,
    onGroupAdd,
    onGroupRemove,
    onConditionAdd,
    onPropChange,
    showNotToggle,
  } = schema;

  const hasParentGroup = () => !!parentId;

  const onConjunctionChange = (conjunction: ConjunctionConst) => {
    onPropChange('conjunction', conjunction, id);
  };

  const onNotToggleChange = (checked: boolean) => {
    onPropChange('not', checked, id);
  };

  const addGroup = () => {
    const newGroup = createConditionGroup();
    onGroupAdd(newGroup, id);
  };

  const removeGroup = () => onGroupRemove(id, parentId);

  const addCondition = () => {
    const newCondition = createCondition(keys);
    onConditionAdd(newCondition, id);
  };

  return (
    <ConditionalGroupContainer>
      <MenuContainer>
        <ConjunctionSelector
          options={conjunctions}
          option={conjunction}
          handleOnChange={onConjunctionChange}
        />
        {showNotToggle && (
          <NotToggle
            title={translations.NotToggle.title}
            checked={not}
            handleOnChange={onNotToggleChange}
          />
        )}
        <GroupButtons>
          <AddConditionAction
            label={translations.addCondition.label}
            handleOnClick={addCondition}
          />
          <AddGroupAction label={translations.addGroup.label} handleOnClick={addGroup} />
          {hasParentGroup() && (
            <RemoveGroupAction label={translations.removeGroup.label} handleOnClick={removeGroup} />
          )}
        </GroupButtons>
      </MenuContainer>
      {condition && condition.length !== 0 && (
        <ConditionContainer>
          {condition.map((r) => (
            <Fragment key={r.id}>
              {isConditionGroupWithId(r) ? (
                <ConditionGroup
                  id={r.id}
                  schema={schema}
                  parentId={id}
                  conjunction={r.conjunction}
                  translations={translations}
                  condition={r.condition}
                  allowValue={allowValue}
                  // not={r.not}
                />
              ) : (
                <Condition
                  id={r.id}
                  _key={r.key}
                  options={(r as AttributeBasedFilterEqualType).options}
                  operator={r.operator}
                  schema={schema}
                  parentId={id}
                  translations={translations}
                  allowValue={allowValue}
                />
              )}
            </Fragment>
          ))}
        </ConditionContainer>
      )}
    </ConditionalGroupContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConditionalGroupContainer = styled.div`
  padding: 20px;
  background: white;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 2px 2px 7px rgba(69, 81, 87, 0.2);
`;

const ConditionContainer = styled.div`
  background-color: rgb(238, 238, 238);
  border: 1px solid rgba(238, 238, 238, 0.12);
  padding: 5px;
  border-radius: 4;
  margin-top: 10px;
`;
const GroupButtons = styled.div``;

export default ConditionGroup;
