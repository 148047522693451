import { ActionButtonEditor } from './ActionButtonEditor';

export const isMobile = (type: ActionButtonEditor) => {
  switch (type) {
    case 'mobileEditorWorkflows':
    case 'mobileEditorActions':
    case 'mobileMultiSelectActions':
      return true;
    default:
      return false;
  }
};

export const getName = (type: ActionButtonEditor) => {
  switch (type) {
    case 'mobileEditorActions':
      return 'editorActions';
    case 'webTableActions':
      return 'tableActions';
    case 'webMultiSelectActions':
    case 'mobileMultiSelectActions':
      return 'multiSelectActions';
    case 'mobileEditorWorkflows':
    case 'webEditorWorkflows':
    default:
      return 'editorWorkflows';
  }
};

export const getTitle = (type: ActionButtonEditor) => {
  switch (type) {
    case 'mobileEditorWorkflows':
    case 'webEditorWorkflows':
      return 'Editor Workflows';
    case 'mobileEditorActions':
      return 'Editor Actions';
    case 'mobileMultiSelectActions':
    case 'webMultiSelectActions':
      return 'Multiselect Actions';
    case 'webTableActions':
      return 'Table Actions';
    default:
      return '';
  }
};

export const getAdditionalElements = (type: ActionButtonEditor) => {
  const mobileAdditionalActionOptions = ['directions', 'streetView', 'assign', 'updateLocation'];
  const webAdditionalActionOptions = ['csvExport', 'delete', 'directions', 'streetView', 'pdfExport'];
  switch (type) {
    case 'mobileEditorActions':
    case 'mobileMultiSelectActions':
      return mobileAdditionalActionOptions;
    case 'webTableActions':
    case 'webMultiSelectActions':
      return webAdditionalActionOptions;
    case 'mobileEditorWorkflows':
    case 'webEditorWorkflows':
    default:
      return [];
  }
};
