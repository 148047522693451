import React, { useContext, useRef } from 'react';
import { Paper, Divider, Button } from '@material-ui/core';
import styled from 'styled-components';

import '../../../utils/antDesignCss.css';
import { ConfigContext } from '../../../context/ConfigContext';
import { successMsg, errorMsg } from '../../../components/SnackbarUtilsConfigurator';
import { eventEditorSchema } from '../utils/eventEditorSchema';
import schemaEditor from 'json-schema-editor-visual/dist/main.js';
import { useParams, Prompt } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { isEqual, cloneDeep } from 'lodash';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import Papa from 'papaparse';
import { camelCase } from 'change-case';
import { v4 } from 'uuid';
import { Csv2EventsForm } from '../../../components/FormDialog/Csv2EventsForm';
import { useFormDialog } from '../../../components/FormDialog/FormDialogService';
import { useConfirm } from 'material-ui-confirm';
import { JSONSchema6 } from 'json-schema';
require('json-schema-editor-visual/dist/main.css');

interface Row {
  [key: string]: string | boolean | number | undefined;
}

export interface CSVEvent {
  type: string;
  aggregateId: string;
  eventId: string;
  aggregateType: string;
  data: {
    [key: string]: string | null;
  };
  source: string;
  metadata: object;
  eventSchemaVersion: number;
}

export const EventSchemaEditor: React.FC = () => {
  const { config, getEventSchema, getEventVersion } = useContext(ConfigContext);
  const AggregateAPI = useAggregateAPI();
  const confirm = useConfirm();
  const formDialog = useFormDialog();
  const { aggregate: aggrName, event: eventName, eventVersion } = useParams() as {
    aggregate: string;
    event: string;
    eventVersion: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const inputFile = useRef<HTMLInputElement>(null);
  const eventSchemaVersion =
    getEventVersion(aggrIndex, eventName, +eventVersion)?.versionNumber || 1;

  const option = { data: eventEditorSchema };
  const SchemaEditor = schemaEditor(option);

  let actualSchema: JSONSchema6 = {};

  const save = async () => {
    const { error } = await AggregateAPI.updateEventSchema(
      aggrIndex,
      eventName,
      +eventVersion,
      actualSchema
    );
    if (error) return;
    successMsg('The event schema has been saved');
  };

  const openCSVDataset = () => {
    inputFile.current?.click();
  };

  const onCSVImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: function (results: Papa.ParseResult<Row>) {
          const csvData: Row[] = results.data;
          const csvEvents = csvData.map((record: Row) => {
            const aggregateId = v4();
            const camelCasedRow: Record<string, string | null> = {};
            Object.keys(record).forEach((key) => {
              let value = null;
              if (record[key]) {
                if ((record[key] as string).trim() !== '')
                  value = (record[key] as string)
                    .trim()
                    .replace(new RegExp('"', 'g'), '\\"')
                    .replace(new RegExp('\\n', 'g'), '');
              }

              camelCasedRow[camelCase(key)] = value;
            });

            return {
              type: eventName,
              aggregateId: aggregateId,
              eventId: v4(),
              aggregateType: aggrName,
              data: {
                ...camelCasedRow,
              },
              source: 'configEditorIngest',
              metadata: {},
              eventSchemaVersion: +eventSchemaVersion,
            };
          });

          const csv2EventsResults = async (csvEvents: CSVEvent[]) => {
            const eventsDataProperties = await formDialog<typeof Csv2EventsForm>((props) => (
              <Csv2EventsForm
                events={csvEvents}
                aggrName={aggrName}
                eventName={eventName}
                version={+eventSchemaVersion}
                {...props}
              />
            ));

            const eventSchemaData = cloneDeep(actualSchema);
            if (!(eventSchemaData.properties?.data as JSONSchema6).properties)
              return errorMsg('Current event schema object is invalid');

            const csvTableMatchingRecords: string[] = [];
            Object.keys(eventsDataProperties).forEach((csvEvent: string) => {
              const matchedEvent = Object.keys(
                (eventSchemaData.properties?.data as JSONSchema6).properties || {}
              ).find((tableEvent: string) => csvEvent === tableEvent);
              if (matchedEvent) csvTableMatchingRecords.push(matchedEvent);
            });

            if (csvTableMatchingRecords.length > 0) {
              await confirm({
                description:
                  'This will append and/or overwrite the existing eventSchemas data structure. ',
                confirmationText: 'Update',
              });
            }
            if (eventSchemaData.properties) {
              (eventSchemaData.properties.data as JSONSchema6).properties = {
                ...(eventSchemaData.properties.data as JSONSchema6).properties,
                ...eventsDataProperties,
              };
            }

            const { error } = await AggregateAPI.updateEventSchema(
              aggrIndex,
              eventName,
              +eventVersion,
              eventSchemaData
            );
            if (error) return;
            successMsg('The event schema has been saved');
          };
          csv2EventsResults(csvEvents);
        },
        header: true,
      });
    }
  };

  const ifShowMessageBeforeRedirect = () => {
    return !isEqual(actualSchema, getEventSchema(aggrIndex, eventName, +eventVersion))
      ? 'The form has not been saved, do you want to redirect?'
      : true;
  };

  return (
    <EventSchemaContainer>
      <CardTitle>Event schema</CardTitle>
      <GroupDivider />
      <SchemaEditorContainer>
        <SchemaEditor
          onChange={(schema: string) => {
            actualSchema = JSON.parse(schema);
          }}
          data={JSON.stringify(getEventSchema(aggrIndex, eventName, +eventVersion))}
        />
        <ButtonContainer>
          <input
            id="csv2Events"
            accept=".csv"
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              if (e) {
                (e.target as HTMLInputElement).value = '';
              }
            }}
            ref={inputFile}
            onChange={onCSVImportClick}
            style={{ display: 'none' }}
            type="file"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={openCSVDataset}
            style={{ margin: '10px' }}
          >
            From CSV Dataset
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => save()}
            style={{ margin: '10px 0' }}
          >
            Save
          </Button>
        </ButtonContainer>
      </SchemaEditorContainer>
      <Prompt message={ifShowMessageBeforeRedirect} />
    </EventSchemaContainer>
  );
};

const EventSchemaContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 10px 20px;
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;

const SchemaEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
