import React, { useState } from 'react';
import { DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';
import { TextInput, RadioInput } from '../FormElements';
import { CommandType } from '../../utils/types';
import { errorMsg } from '../SnackbarUtilsConfigurator';

interface Props {
  onSubmit: (result: { commandName: string; type: CommandType }) => void;
  onClose: () => void;
}

export const CreateNewCommandForm: React.FC<Props> = ({ onSubmit, onClose }) => {
  const [commandName, setCommandName] = useState<string | undefined>(undefined);
  const [type, setType] = useState<CommandType>('BUTTON');
  const handleOnSubmit = () => {
    if (!commandName) return errorMsg('Command name is required');
    onSubmit({ commandName, type });
  };

  return (
    <>
      <DialogTitle>Create new command</DialogTitle>
      <DialogContent style={{ padding: '0 25px' }}>
        <TextInput
          id="commandName"
          value={commandName}
          variant="standard"
          onChange={(value) => setCommandName(value)}
          style={{ margin: 0 }}
        />
        <RadioInput
          title="Command Type"
          value={type}
          onChange={(value) => setType(value as 'FORM' | 'BUTTON' | 'INTERNAL')}
          options={commandTypeOptions}
          containerStyle={{ margin: '25px 0' }}
        />
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleOnSubmit} autoFocus>
          Create
        </Button>
      </DialogActions>
    </>
  );
};

const commandTypeOptions = [
  { value: 'BUTTON', label: 'Button' },
  { value: 'FORM', label: 'Form' },
  { value: 'INTERNAL', label: 'Internal' },
];
