import * as React from 'react';
import { useContext, useMemo } from 'react';

export interface ConfirmationContext {
  confirm: ({
    confirmationText,
    description,
    title,
  }: {
    confirmationText: string;
    description: string;
    title?: string;
  }) => Promise<void>;
  children?: React.ReactNode;
}

const defaultConfirmation: ConfirmationContext = {
  confirm: ({
    confirmationText,
    description,
    title,
  }: {
    confirmationText: string;
    description: string;
    title?: string;
  }) => {
    console.log(confirmationText, description, title);
    return Promise.resolve();
  },
};

const ConfirmationContext = React.createContext<ConfirmationContext>(defaultConfirmation);
const ConfirmationContextProvider: React.FunctionComponent<Partial<ConfirmationContext>> = (
  props
) => {
  const { confirm } = props;
  const ctx = useMemo(() => {
    if (confirm) {
      return { confirm };
    } else {
      return { confirm: () => Promise.resolve() };
    }
  }, [confirm]);

  return <ConfirmationContext.Provider value={ctx}>{props.children}</ConfirmationContext.Provider>;
};
export const useConfirm = (): ConfirmationContext => useContext(ConfirmationContext);
export default ConfirmationContextProvider;
