import React, { useState, useContext, useCallback } from 'react';
import { DialogTitle, DialogActions, Button } from '@material-ui/core';
import { TextInput, InputGroup, CheckboxInput } from '../FormElements';
import {
  V2MediaComponent
} from '@terragotech/page-renderer';
import DataMapperDialog from './DataMapperDialog';
import { useConfirm } from 'material-ui-confirm';
import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { isEqual } from 'lodash';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { usePageSchemas } from './hooks/usePageSchemas';
import { PageContext } from './contexts/PageContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import _ from 'lodash';

export type TextTemplateWithName =
  (V2MediaComponent & {
      name: string;
      droppableId: string;
    });

interface MediaEditFormProps {
  onSubmit: (result: TextTemplateWithName) => void;
  onClose: () => void;
  component: TextTemplateWithName & {height?: string, width?: string};
}

export const expandPath = (
  parts: string[],
  properties: JSONSchema6
): Array<{ [x: string]: JSONSchema6Definition | JSONSchema6Definition[] | undefined }> => {
  return parts.map((part, idx) => {
    let target = properties as JSONSchema6;
    let name = '';
    for (let i = 0; i < idx; i++) {
      target = _.get(target, parts[i]);
      name += parts[i].replace(/\[\d+\]/, '') + '.';
    }
    target = target ? _.get(target, part) : undefined;
    name += part.replace(/\[\d+\]/, '');
    if (target.type === 'array') {
      return { [name]: target.items };
    }
    return { [name]: target };
  });
};

export const MediaEditForm: React.FC<MediaEditFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const confirm = useConfirm();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [height, setHeight] = useState(component.height);
  const [width, setWidth] = useState(component.width);
  const [repeats, setRepeats] = useState(
    component.repeats
  );
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);

  const pageSchemas = usePageSchemas();

  const [existingNameError, setExistingNameError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const nameHelperTxt =
    'Duplicate name error. The name must be unique accross all workflow elements';

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'page'
      ? []
      : expandPath(parts, pageSchemas.FORM.schema.properties as JSONSchema6);

  let pageValue = { value: { type: 'string' } };
  for (let i = 0; i < t.length; i++) {
    pageValue = { ...pageValue, ...t[i] };
  }

  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: pageValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...pageSchemas,
  };
  const handleClearDisplayMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setDisplayOptions(undefined);
  };

  const getFormValues = () => ({
    type: component.type,
    name,
    label,
    repeats,
    ...(displayOptions && { displayOptions }),
    ...(width && { width }),
    ...(height && { height }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The page has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    if (checkDuplicatePageName(name, component, pageDefinition.elements)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  const doesDisplayOptionsHaveValue = useCallback(() => {
    return !_.isEmpty(displayOptions);
  }, [displayOptions]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <TextInput
        autoFocus
        id="Name"
        error={existingNameError}
        helperText={existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      {/* Disabling for now, until Repeatable rendering is implemented. */}
      <CheckboxInput
        title="Repeatable?"
        checked={repeats}
        onChange={(value) => setRepeats(value)}
        style={{ margin: '0 25px 10px' }}
      />
      {component.type === 'image' && <><TextInput
        id="Height"
        value={height}
        onChange={(value) => setHeight(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Width"
        value={width}
        onChange={(value) => setWidth(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      /></>}
      <InputGroup title="Display Options" style={{ margin: '10px 25px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button onClick={() => setConditionalOpen(true)}>
            <div>
              Advanced
              <MappingActiveDisplay
                isActive={doesDisplayOptionsHaveValue()}
                activeLabelText={'active'}
              />
            </div>
          </Button>
          <Button onClick={handleClearDisplayMapper}>Clear</Button>
        </div>
        <DataMapperDialog
          mapScenario="DISPLAY_OPTIONS"
          localSchemaDefinitions={errorWarningSchemas}
          onClose={() => {
            setConditionalOpen(false);
          }}
          open={conditionalOpen}
          datamap={displayOptions}
          setDatamap={setDisplayOptions}
        />
      </InputGroup>
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
