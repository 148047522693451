import React from 'react';
import styled from 'styled-components';

const PropertyCollectionFrame = styled.div`
  color: #000000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  background-color: rgba(200, 30, 30, 0);
  padding-right: 8px;
`;
const PropertyCollectionBorder = styled.div`
  border: gray 1px solid;
  padding-top: 5px;
  padding-bottom: 3px;
  width: 98%;
`;
const PropertyCollectionDisplayTitle = styled.span`
  color: #ffffff;
  font-size: 12px;
  background-color: rgba(51, 51, 51);
  padding: 2px;
  border-radius: 4px;
`;
const PropertyCollectionTitle = styled.span`
  color: #ffffff;
  font-size: 12px;
  background-color: rgba(51, 51, 51);
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
`;
const PropertyCollectionTitleLayout = styled.div`
  position: relative;
  left: 16px;
  top: 6px;
  width: 100%;
`;
const AddButton = styled.a`
  color: #ffffff;
  cursor: pointer;
  background-color: rgba(51, 51, 51);
  font-size: 14px;
  padding: 5px;
`;

interface PropertyCollectionFrameProps {
  configMode?: boolean;
  label: string;
  onAddClick?: () => void;
  collectionLength: number;
  hideLabel?: boolean;
  hide?: boolean;
  children?: React.ReactNode;
}
export const PropertyCollection: React.FunctionComponent<PropertyCollectionFrameProps> = (
  props
) => {
  const { children, configMode, label, onAddClick, hideLabel, hide } = props;

  return (
    <PropertyCollectionFrame>
      {configMode ? (
        (!hide && (
          <>
            <PropertyCollectionTitleLayout>
              <PropertyCollectionTitle>{label}</PropertyCollectionTitle>

              {onAddClick && <AddButton onClick={onAddClick}>+</AddButton>}
            </PropertyCollectionTitleLayout>

            <PropertyCollectionBorder>{children}</PropertyCollectionBorder>
          </>
        )) ||
        children
      ) : (
        <>
          {!hideLabel && <PropertyCollectionDisplayTitle>{label}</PropertyCollectionDisplayTitle>}
          {children}
        </>
      )}
    </PropertyCollectionFrame>
  );
};
