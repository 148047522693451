import React, { useContext, useState } from 'react';
import { Paper, Button, Tabs, Tab } from '@material-ui/core';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ConfigContext } from '../../../context/ConfigContext';
import { infoMsg, successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { MultiButtonDialog } from 'components/FormDialog/MultiButtonDialog';

export const NearMaps: React.FC = () => {
  const { setMapOptions, getNearMaps, getMapServices } = useContext(ConfigContext);
  const [nearMapSwitch, setNearMapSwitch] = useState<boolean>(getNearMaps());
  const [mapServiceSwitch, setMapServiceSwitch] = useState<boolean>(getMapServices());
  const formDialog = useFormDialog();

  const onSave = async () => {
    if (!mapServiceSwitch && mapServiceSwitch !== getMapServices()) {
      const confirm = await formDialog<typeof MultiButtonDialog>((props) => (
        <MultiButtonDialog
          title='Disable Map Options'
          description='Disabling Map Services will remove the related aggregate and data. Any aggregate and UI customizations made for Map Services will be lost. This cannot be undone. Are you sure you want to disable this option?'
          options={[{ value: 'true', label: 'Save' }]}
          {...props}
        />
      ));

      if (!confirm){
        infoMsg('Map Options Integration changes have not been saved');
        return;
      };
    }

    setMapOptions({nearMaps: nearMapSwitch, mapServices: mapServiceSwitch});
    successMsg('Map Options Integration has been saved successfully');
  };

  const onNearMapSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNearMapSwitch(!nearMapSwitch);
  };

  const onMapServiceSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapServiceSwitch(!mapServiceSwitch);
  };

  return (
    <Container>
      <HeaderContainer>
        <Typography variant="h6">Map Options</Typography>
        <Button variant="contained" size="small" color="primary" onClick={onSave}>
          Save
        </Button>
      </HeaderContainer>

      <BodyContainer>
        <Tabs
          value={0}
          onChange={() => {}}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Web" style={{ width: 600 }} />
        </Tabs>
        <Body>
          <FormControlLabel
            control={<Switch checked={nearMapSwitch} onChange={onNearMapSwitchChange} color="primary" />}
            label="Enable NearMap Basemap"
          />
          <Typography variant="caption">
            The NearMap API key must be added to the server as an ENV
          </Typography>
          <FormControlLabel
            control={<Switch checked={mapServiceSwitch} onChange={onMapServiceSwitchChange} color="primary" />}
            label="Enable Map Services"
          />
          <Typography variant="caption">
            Turning on Map Services will create a new aggregate and related data to support external map layers.
          </Typography>
        </Body>
      </BodyContainer>
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
  flex: 1,
});

const HeaderContainer = styled(Paper)({
  padding: '12px 12px',
  margin: 5,
  flex: 1,
  overflowX: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  verticalAlign: 'middle',
});

const BodyContainer = styled(Paper)({
  margin: '15px 5px',
  flex: 9,
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

const Body = styled('div')({
  padding: '12px 24px',
  display: 'flex',
  flexDirection: 'column',
});
