import * as React from 'react';
import { Dialog } from '@material-ui/core';

interface PromiseProps {
  resolve: <T>(result: T) => void;
  reject: () => void;
}

export type FormComponentType = React.FC<{
  onClose: () => void;
  onSubmit: <T>(result: T) => void;
}>;

function a<T>(
  formComponent: FormComponentType,
  disableClosingByClickingOutside?: boolean
): Promise<
  T extends React.FC<infer E>
    ? E extends { onSubmit: (...args: any[]) => void }
      ? Parameters<E['onSubmit']>[0]
      : unknown
    : unknown
> {
  return Promise.reject();
}

const FormDialogServiceContext = React.createContext<typeof a>(Promise.reject);

export const useFormDialog = () => React.useContext(FormDialogServiceContext);

export const FormDialogServiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [Form, setForm] = React.useState<FormComponentType | null>(null);
  const [
    disableClosingByClickingOutside,
    setDisableClosingByClickingOutside,
  ] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<PromiseProps>();

  const openFormDialog = (
    formComponent: FormComponentType | undefined,
    disableClosingByClickingOutside?: boolean
  ) => {
    if (disableClosingByClickingOutside) setDisableClosingByClickingOutside(true);
    formComponent && setForm(() => formComponent);

    return new Promise((resolve: <T>(result: T) => void, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    awaitingPromiseRef.current?.reject();
    setDisableClosingByClickingOutside(false);
    setForm(null);
  };

  const handleSubmit = <T,>(result: T) => {
    awaitingPromiseRef.current?.resolve(result);
    setForm(null);
  };

  return (
    <>
      <FormDialogServiceContext.Provider value={openFormDialog as typeof a}>
        {children}
      </FormDialogServiceContext.Provider>
      <Dialog
        open={Boolean(Form)}
        onClose={disableClosingByClickingOutside ? undefined : handleClose}
        maxWidth="xl"
        PaperProps={{ style: { minWidth: 650 } }}
      >
        {Form && <Form onClose={handleClose} onSubmit={handleSubmit} />}
      </Dialog>
    </>
  );
};
