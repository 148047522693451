import React from 'react';
import { ForeignColumnInput } from './ForeignColumnInput';
import { ValidOptionsCustomInput, ValidOptions } from './ValidOptionsCustomInput';
import { ValidOptionsCustomComponent } from './ValidOptionsCustomComponent';
import { AggrPropertyRow } from '../../../utils/types';
import { AnchoredLineEndpointsInput } from './AnchoredLineEndpointsInput';
import { SimpleColumnList } from './SimpleColumnList';

import { TitleToolTip } from './TitleToolTip';
import { MenuItem, Select } from '@material-ui/core';

const UITYPE_LOOKUP = {
  '': '',
  'Symbol Key': 'Symbol Key',
  Title: 'Title',
  Hidden: 'Hidden',
  DateTime: 'DateTime',
  JSON: 'JSON',
};
//Missing columns:
// - description? - string
// - initValue? - null | any
export const aggregatePropertiesTableColumns = (additionalTypes: object): object[] => [
  {
    title: (
      <TitleToolTip
        minWidth={75}
        title="Order"
        tooltipText="Determines the order that properties are shown in the Table and Attributes Card"
      />
    ),
    field: 'order',
    editable: 'never',
  },
  {
    title: (
      <TitleToolTip
        minWidth={75}
        title="Name"
        tooltipText={
          <>
            <p>Property named used on the back-end. Must be in camelCase.</p>
            <p>
              Exceptions to avoid:
              <ul style={{ paddingLeft: 20 }}>
                <li>ID -Camel case “Id” is OK (though should probably be avoided if possible) </li>
                <li>lastUpdated</li>
                <li>nodeId</li>
                <li> modifiedBy</li>
              </ul>
            </p>
          </>
        }
      />
    ),
    validate: (rowData: { name: string | undefined; }) =>{
      return rowData.name === undefined
        ? { isValid: false, helperText: 'Name cannot be empty' }
        : rowData.name === ''
        ? { isValid: false, helperText: 'Name cannot be empty' }
        : (rowData.name.match(new RegExp(/^[a-z]/)) === null)
        ? { isValid: false, helperText: 'First char of name cannot be uppercase.' }
        : true
    },
    field: 'name',
  },
  {
    title: (
      <TitleToolTip
        title="Label"
        minWidth={75}
        tooltipText="Property named visible on HMI.  No restrictions."
      />
    ),
    field: 'label',
  },
  {
    title: (
      <TitleToolTip
        title="Type"
        minWidth={75}
        tooltipText={
          <>
            <p>The type of data that is being stored in this property</p>
            <p>
              Options:
              <ul style={{ paddingLeft: 20 }}>
                <li>ID</li>
                <li>String (255 "max")</li>
                <li>Int</li>
                <li>Float</li>
                <li>DateTime</li>
                <li>Boolean</li>
                <li>JSON</li>
                <li>Geography</li>
                <li>PhotoCollection</li>
                <li>FileCollection</li>
                <li>Anchored Line</li>
                <li>Project</li>
                <li>Issue</li>
                <li>Light</li>
                <li>Folder</li>
              </ul>
            </p>
          </>
        }
      />
    ),
    field: 'type',
    lookup: {
      ID: 'ID',
      String: 'String',
      Int: 'Int',
      Float: 'Float',
      DateTime: 'DateTime',
      Boolean: 'Boolean',
      JSON: 'JSON',
      Geography: 'Geography',
      PhotoCollection: 'PhotoCollection',
      FileCollection: 'FileCollection',
      AnchoredLine: 'AnchoredLine',
      ...additionalTypes,
    },
  },
  {
    title: (
      <TitleToolTip
        title="Nullable"
        minWidth={75}
        tooltipText="Allow a null property on the back-end.  Typically should be set to true"
      />
    ),
    field: 'nullable',
    type: 'boolean',
    initialEditValue: true,
  },
  {
    title: (
      <TitleToolTip
        title="Filterable"
        minWidth={90}
        tooltipText="Allow filtering on both web and mobile filters"
      />
    ),
    field: 'filterable',
    type: 'boolean',
  },
  {
    title: (
      <TitleToolTip
        title="Editable"
        minWidth={75}
        tooltipText="Allow editing on the web table view"
      />
    ),
    field: 'editable',
    type: 'boolean',
  },
  {
    title: (
      <TitleToolTip
        title="Indexed"
        minWidth={75}
        tooltipText="Whether the database should optimize for searches of this particular field. This will mostly impact mobile filtering performance when filtering against this field, and backend integration performance if the integration will be searching against this particular field"
      />
    ),
    field: 'indexed',
    type: 'boolean',
  },
  {
    title: (
      <TitleToolTip
        title="Hide From Asset Attributes"
        minWidth={150}
        tooltipText={
          <>
            <p>Hidden on the Attributes Card</p>
            <p>
              Note: if a property does not have a value, it is already hidden from the Attributes
              Card
            </p>
          </>
        }
      />
    ),
    field: 'hideFromAssetAttributes',
    type: 'boolean',
  },
  {
    title: (
      <TitleToolTip
        title="Include In Full Text"
        minWidth={150}
        tooltipText="  Whether or not to include this field in the full text search index. This index is used when typing in the free form search bar at the top of the mobile list view, or in the free form search bar at the top of the table on web"
      />
    ),
    field: 'includeInFullText',
    type: 'boolean',
  },
  {
    title: (
      <TitleToolTip
        title="Filter Options"
        minWidth={120}
        tooltipText="  ‘DISTINCT’ is the only filter type currently available. Future plans include things like ranges for number fields, or categories. DISTINCT here means to show all known unique values on the filter page"
      />
    ),
    field: 'filterOptions',
    lookup: {
      '': '',
      DISTINCT: 'DISTINCT',
    },
  },
  {
    title: (
      <TitleToolTip
        title="Relation"
        minWidth={75}
        tooltipText={
          <>
            <p>Describes the relationship of this property to another</p>
            <p>
              <ul style={{ paddingLeft: 20 }}>
                <li>
                  One-to-One relationships
                  <ul>
                    <li>Should be linked One-to-One bidirectional</li>
                  </ul>
                </li>
                <li>
                  One-to-Many relationships
                  <ul>
                    <li>On the One side</li>
                    <ul>
                      <li>“Name” column as plural. For example: Pole properties fixtures</li>
                      <li>Relation: One-to-Many</li>
                      <li>Foreign Column should point to the Many side. For example: pole</li>
                    </ul>
                    <li>On the Many side</li>
                    <ul>
                      <li>Relation: One-to-One</li>
                    </ul>
                  </ul>
                  <li>Many-to-Many (Deprecated)</li>
                </li>
              </ul>
            </p>
          </>
        }
      />
    ),
    field: 'relation',
    export: false,
    lookup: {
      '': '',
      'ONE-TO-MANY': 'ONE-TO-MANY',
      'ONE-TO-ONE': 'ONE-TO-ONE',
      // 'MANY-TO-MANY': 'MANY-TO-MANY', TODO: Add Many to Many back in when it's needed
    },
  },
  {
    title: (
      <TitleToolTip
        title="Foreign Column"
        minWidth={100}
        tooltipText="Use only in correlation with the One-to-Many relationship. Ignored in all other cases"
      />
    ),
    field: 'foreignColumn',
    export: false,
    editComponent: ({
      rowData,
      onChange,
    }: {
      rowData: AggrPropertyRow;
      onChange: (value: string | undefined) => void;
    }) => <ForeignColumnInput row={rowData} onChange={onChange} />,
  },
  {
    title: (
      <TitleToolTip
        title="Valid Options"
        tooltipText="A list of values that this property may contain.  This list is shown as radio button options when editing this property. This only applies when directly editing the field on the web table. It is not used to limit the values that can be provided through mapping"
      />
    ),
    field: 'validOptions',
    editComponent: ({
      value,
      onChange,
    }: {
      value: ValidOptions | undefined;
      onChange: (value: ValidOptions) => void;
    }) => <ValidOptionsCustomInput validOptions={value} setValidOptions={onChange} />,
    render: (props: { validOptions: ValidOptions | undefined }) => {
      if (!props.validOptions) {
        props.validOptions = undefined;
      }
      return <ValidOptionsCustomComponent validOptions={props.validOptions} />;
    },
  },
  {
    title: 'Line Start',
    field: 'lineStart',

    editComponent: ({
      value,
      onChange,
      rowData,
    }: {
      value: string[] | undefined;
      onChange: (value: string[] | undefined) => void;
      rowData: AggrPropertyRow;
    }) =>
      rowData.type === 'AnchoredLine' ? (
        <AnchoredLineEndpointsInput row={rowData} onChange={onChange} value={value} />
      ) : null,
    render: ({ lineStart }: { lineStart?: string[] }) => <SimpleColumnList list={lineStart} />,
  },
  {
    title: 'Line End',
    field: 'lineEnd',
    editComponent: ({
      value,
      onChange,
      rowData,
    }: {
      value: string[] | undefined;
      onChange: (value: string[] | undefined) => void;
      rowData: AggrPropertyRow;
    }) =>
      rowData.type === 'AnchoredLine' ? (
        <AnchoredLineEndpointsInput row={rowData} onChange={onChange} value={value} />
      ) : null,
    render: ({ lineEnd }: { lineEnd?: string[] }) => <SimpleColumnList list={lineEnd} />,
  },
  {
    title: (
      <TitleToolTip
        title="UI Type"
        tooltipText={
          <>
            <p>Determines how to represent the property on the web table.</p>
            <p>
              <ul style={{ paddingLeft: 20 }}>
                <li>Symbol Key - default type to indicate symbology</li>
                <li>Title - Allows access to the Attributes Card via the :eye: symbol</li>
                <li>Hidden - Hidden from the table column</li>
                <li>
                  DateTime - Ensures that the Date string in the property is displayed in the user’s
                  current timezone. Also ensures the date time editor is used when editing the field
                </li>
                <li>JSON - Deprecated. Currently does the same thing as Hidden</li>
              </ul>
            </p>
          </>
        }
      />
    ),
    field: 'uiType',
    editComponent: ({
      value,
      onChange,
      rowData,
    }: {
      value: string;
      onChange: (value: unknown) => void;
      rowData: AggrPropertyRow;
    }) => (
      <Select
        value={
          rowData.type === 'Geography' ? 'JSON' : value ? value : Object.values(UITYPE_LOOKUP)[0]
        }
        disabled={rowData.type === 'Geography'}
        onChange={(event) => onChange(event.target.value)}
      >
        {Object.values(UITYPE_LOOKUP).map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    ),
    lookup: { ...UITYPE_LOOKUP },
  },
];
