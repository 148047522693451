import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

interface ActionElementProps {
  label: string;
  handleOnClick: () => void;
}

const ActionElement: React.FC<ActionElementProps> = ({ handleOnClick, label }) => {
  const classes = useStyles();
  return (
    <Button
      size="small"
      variant="contained"
      className={classes.button}
      onClick={() => handleOnClick()}
      style={{ marginLeft: 18 }}
    >
      {label}
    </Button>
  );
};

export default ActionElement;
