import React from 'react';
import styled from 'styled-components';

import { FunctionsMenu } from './components/FunctionsMenu/FunctionsMenu';
import { FunctionInputSchemaEditor } from './pages/FunctionInputSchemaEditor';
import { FunctionOutputSchemaEditor } from './pages/FunctionOutputSchemaEditor';
import { EventMapper } from './pages/EventMapper';
import { FunctionsOverview } from './pages/FunctionsOverview';
import { Route } from 'react-router-dom';
import { AggrComments } from '../aggregates/pages/AggrComments';

export const Functions: React.FC = () => (
  <FlexContainer>
    <Route path="/functions/:function?" component={FunctionsMenu} />

    <Route path="/functions" component={FunctionsOverview} exact />
    <Route path="/functions/:function" component={AggrComments} exact />

    <Route
      path="/functions/:function/version/:version/input"
      component={FunctionInputSchemaEditor}
      exact
    />
    <Route
      path="/functions/:function/version/:version/output"
      component={FunctionOutputSchemaEditor}
      exact
    />
    <Route path="/functions/:function/version/:version/templateMap" component={EventMapper} exact />
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
