import React, { FunctionComponent } from 'react';
import { SimpleOptionType, V2SingleSelectComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label } from './Common';
import UnselectedImage from './img/Unselected.png';
import SelectedImage from './img/Selected.png';

const RadioOption = styled.div``;
const RadioImageFrame = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 2px;
`;
const RadioRow = styled.div`
  display: flex;
  margin-top: 2px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const RadioButton: FunctionComponent<NestedFormControlProps<V2SingleSelectComponent>> = (props) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        required={controlDefinition.required}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />
      {controlDefinition.options.type === SimpleOptionType &&
        controlDefinition.options.items.map((option, key) => (
          <RadioRow key={option.value}>
            <RadioImageFrame>
              <img src={key === 0 ? SelectedImage : UnselectedImage} alt={option.label} />
            </RadioImageFrame>

            <RadioOption>{option.label}</RadioOption>
          </RadioRow>
        ))}

      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default RadioButton;
