import { JSONSchema6 } from 'json-schema';

export const getTypeSymbolsFromSchema = (schema: JSONSchema6): string[] | undefined => {
  if (schema.$ref) {
    return [schema.$ref.replaceAll('/', '').slice(0, 1)];
  } else if (schema.type) {
    if (Array.isArray(schema.type)) {
      return schema.type.map((singleType) => singleType[0].toUpperCase());
    } else if (typeof schema.type === 'string') {
      // special case for dates
      if (schema.format === 'date-time') {
        return ['D'];
      }
      if (schema.format === 'photo') {
        return ['P'];
      }
      if (schema.type === 'array') {
        // When an array, try to build type and attach []
        if (Array.isArray(schema.items)) {
          return schema.items.map((item) => {
            return `${getTypeSymbolsFromSchema(item as JSONSchema6)}[]`;
          });
        }
        return [`${getTypeSymbolsFromSchema(schema.items as JSONSchema6)}[]`];
      }

      return [schema.type[0].toUpperCase()];
    }
  } else if (schema.anyOf) {
    return schema.anyOf.reduce((acc: string[], subSchema) => {
      return [...acc, ...(getTypeSymbolsFromSchema(subSchema as JSONSchema6) || [])];
    }, []);
  }
};

export const areSchemasCompatible = (
  outboundSchema: JSONSchema6,
  inboundSchema: JSONSchema6
): boolean => {
  if (outboundSchema.$ref || inboundSchema.$ref) {
    // if either schema is using a ref, let's just check that the other is using the same ref..

    if (outboundSchema.$ref === inboundSchema.$ref) {
      return true;
    }
  }
  // for the outbound schema, if we have an anyof, all types must be compatible
  if (outboundSchema.anyOf) {
    return outboundSchema.anyOf.reduce<boolean>((acc, outboundSubSchema) => {
      return acc && areSchemasCompatible(outboundSubSchema as JSONSchema6, inboundSchema);
    }, true);
  }
  // for the inbound schema, if we have an anyof, any type must be compatible
  if (inboundSchema.anyOf) {
    return inboundSchema.anyOf.reduce<boolean>((acc, inboundSubSchema) => {
      return acc || areSchemasCompatible(outboundSchema, inboundSubSchema as JSONSchema6);
    }, false);
  }
  const inboundType = inboundSchema.type;
  const outboundType = outboundSchema.type;
  if (inboundType === outboundType) {
    return true;
  }

  if (Array.isArray(inboundType)) {
    return (inboundType as string[]).includes(String(outboundType));
  }
  return false;
};
