import React, { useCallback, useState, useEffect } from 'react';
import { Dialog, Paper, Button, DialogTitle, DialogActions } from '@material-ui/core';
import styled from 'styled-components';
import { successMsg } from '../SnackbarUtilsConfigurator';
import {
  QueryBuilder,
  AttributeBasedFilterWithID,
  AttributeBasedFilterSimpleFiltersWithID,
  Key,
} from '../../pages/aggregates/components/QueryEditor/QueryBuilder';
import cloneDeep from 'lodash/cloneDeep';
import { AttributeBasedFilter } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';

export interface QueryFilterEditorDialogProps {
  open: boolean;
  onClose: () => void;
  filter: AttributeBasedFilter | undefined;
  setFilter: (value: AttributeBasedFilter) => void;
  keys: Key[];
  filterName: string | undefined;
  allowValue?: boolean;
}

export type QueryItemWithID = AttributeBasedFilterWithID | AttributeBasedFilterSimpleFiltersWithID;

function QueryFilterEditorDialog({
  open,
  onClose,
  filter,
  setFilter,
  keys,
  filterName,
  allowValue
}: QueryFilterEditorDialogProps) {
  const [query, setQuery] = useState(filter);
  useEffect(() => {
    setQuery(filter);
  }, [filter]);

  const handleSaveRequest = useCallback(() => {
    if (query) {
      setFilter(query);
      successMsg('Query filter successfully saved');
    }
    onClose();
  }, [onClose, query, setFilter]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleQueryChange = (query: AttributeBasedFilterWithID) =>
    setQuery(removeIDs(cloneDeep(query)) as AttributeBasedFilter);

  const removeIDs = (item: QueryItemWithID) => {
    //@ts-ignore
    delete item.id;
    if (item.type === 'GroupFilter') {
      item.condition.map((item) => removeIDs(item as QueryItemWithID));
    }
    return item;
  };

  return (
    <Dialog maxWidth={false} open={open} onClose={handleCancel}>
      <DialogTitle style={{ borderBottom: '1px solid lightgray' }}>{filterName}</DialogTitle>
      <EventMapperContainer>
        <QueryBuilder allowValue={allowValue} keys={keys} onQueryChange={handleQueryChange} query={query} />
      </EventMapperContainer>
      <DialogActions style={{ padding: 15 }}>
        <Button color="primary" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSaveRequest} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const EventMapperContainer = styled(Paper)`
  padding: 15px;
  overflow-x: auto;
  min-width: 1200px;
  background: rgb(238, 238, 238);
  border-bottom: 1px solid lightgray;
  box-shadow: none;
`;
export default QueryFilterEditorDialog;
