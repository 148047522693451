import React, { useState, useContext } from 'react';
import { DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';
import { SelectInput } from '../FormElements';
import { ConfigContext } from '../../context/ConfigContext';

interface Props {
  onSubmit: (result: string) => void;
  onClose: () => void;
  configType: 'mobileUIConfig' | 'webUIConfig';
}

export const CreateAggregateUICustomizationDialog: React.FC<Props> = ({
  onSubmit,
  onClose,
  configType,
}) => {
  const { config } = useContext(ConfigContext);

  const ifAggrUICustomizationExists = (aggrName: string) =>
    config?.[configType]?.aggregateUICustomizations?.hasOwnProperty(aggrName);
  const getAggregateList = () =>
    config.aggregates
      .map((item) => item.typeName)
      .filter((item) => !ifAggrUICustomizationExists(item));
  const [selected, setSelected] = useState(getAggregateList()[0]);

  return (
    <>
      <DialogTitle>Add new Aggregate UI Customization</DialogTitle>

      <DialogContent>
        <SelectInput
          title="Aggregate UI Configuration"
          options={getAggregateList()}
          onChange={(value) => setSelected(value)}
          value={selected}
        />
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => onSubmit(selected)} autoFocus>
          Add
        </Button>
      </DialogActions>
    </>
  );
};
