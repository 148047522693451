import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { grey } from '@material-ui/core/colors';
import { Divider, makeStyles } from '@material-ui/core';
import { ConfigUpload } from './ConfigUpload';
import { ConfigDownload } from './ConfigDownload';
import { MenuList } from './MenuList';
import { useHistory } from 'react-router-dom';
import { NewConfig } from './NewConfig';
import { useAuthContext } from '@terragotech/gen5-shared-components';

interface Props {
  navDrawerOpen: boolean;
}

export const LeftDrawer: React.FC<Props> = (props) => {
  const styles = useStyles();
  const history = useHistory();

  const { ready, token, email, givenName, familyName } = useAuthContext();

  const handleHeaderClick = () => history.push('/');

  return (
    <Drawer classes={{ paper: styles.paper }} variant="persistent" open={props.navDrawerOpen}>
      <div className={styles.menuHeader} onClick={handleHeaderClick}>
        JSON Config Editor
      </div>
      {ready && token && (
        <Grid container alignItems="center" direction="column" spacing={1}>
          <Grid item>
            <Avatar
              className={styles.avatar}
            >{`${givenName?.[0].toUpperCase() || ''}${familyName?.[0].toUpperCase()}` || ''}</Avatar>
          </Grid>
          <Grid item>{email}</Grid>
        </Grid>
      )}
      <MenuContainer>
        <MenuTopContainer>
          <ConfigUpload />
          <NewConfig />
          <GroupDivider />
          <MenuList />
        </MenuTopContainer>
        <ConfigDownload />
      </MenuContainer>
    </Drawer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-y: hidden;
`;

const MenuTopContainer = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(70, 70, 70);
  }
`;

const useStyles = makeStyles((theme) => ({
  menuHeader: {
    fontSize: 22,
    color: 'white',
    fontWeight: 400,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    cursor: 'pointer',
  },
  paper: {
    width: 260,
    background: grey[900],
    color: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
    border: 0,
  },
  avatar: {
    marginTop: '8px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));
