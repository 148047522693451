import * as React from 'react';
import { ReactComponent as CloseIcon } from '../../icons/CloseIcon.svg';

import styled from 'styled-components';
import { NodeFrame, NodeTitleBox, NodeTitle, NodeCollapseArea, NodeDescriptionBox, NodeDescription } from './NodeComponents';
import { ReactComponent as ExpandLessIcon } from '../../icons/ExpandLessIcon.svg';
import { ReactComponent as ExpandMoreIcon } from '../../icons/ExpandMoreIcon.svg';
import { TinyTextInput } from '../../InputControls/TinyTextInput';
import { useState } from 'react';

interface TransformNodeWidgetProps {
  title: string;
  onTitleChange?: (newTitle: string) => void;
  onDescriptionChange?: (newTitle: string) => void;
  onConfigureClicked?: () => void;
  onOkConfigClicked?: () => void;
  onCancelConfigClicked?: () => void;
  onRemoveClicked?: () => void;
  onCollapseClicked?: () => void;

  description?: string;
  configMode?: boolean;
  isConfigurable?: boolean;
  isRemovable?: boolean;
  isTitleEditable?: boolean;
  isSelected?: boolean;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  children?: React.ReactNode;
}

export const TransformNodeWidget: React.FunctionComponent<TransformNodeWidgetProps> = (props) => {
  const {
    title,
    description,
    onTitleChange,
    onDescriptionChange,
    onRemoveClicked,
    onCollapseClicked,
    isRemovable,
    isSelected,
    isTitleEditable,
    isCollapsible,
    isCollapsed,
    children,
    onOkConfigClicked,
  } = props;
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [descriptionEditMode, setDescriptionEditMode] = useState(false);
  return (
    <NodeFrame isSelected={isSelected}>
      {isSelected && isRemovable && (
        <div
          onClick={onRemoveClicked}
          style={{
            position: 'absolute',
            top: '-24px',
            right: '-24px',
            width: 24,
            height: 24,
            cursor: 'pointer',
          }}
        >
          <CloseIcon fill="white" />
        </div>
      )}
      <NodeTitleBox>
        {!titleEditMode && ( 
          <NodeTitle onDoubleClick={() => setTitleEditMode(true)}>{title}</NodeTitle>
        )}
        {titleEditMode &&
          isTitleEditable && ( 
            <NodeTitle>
              <TinyTextInput
                value={title}
                onChange={(val) => onTitleChange && onTitleChange(val as string)}
                style={{ fontSize: 14, width: 'unset' }}
                onBlur={() => {
                  setTitleEditMode(false);
                  onOkConfigClicked && onOkConfigClicked();
                }}
              />
            </NodeTitle>
          )}
        {isCollapsible && (
          <NodeCollapseArea onClick={onCollapseClicked}>
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </NodeCollapseArea>
        )}
      </NodeTitleBox>
      {!isCollapsed && <NodeDescriptionBox>
        {!descriptionEditMode && ( 
          <NodeDescription onDoubleClick={() => setDescriptionEditMode(true)}>{description || "Description"}</NodeDescription>
        )}
        {descriptionEditMode &&
          isTitleEditable && (
            <NodeDescription>
              <TinyTextInput
                value={description}
                onChange={(val) => onDescriptionChange && onDescriptionChange(val as string)}
                style={{ fontSize: 12 }}
                onBlur={() => {
                  setDescriptionEditMode(false);
                  onOkConfigClicked && onOkConfigClicked();
                }}
              />
            </NodeDescription>
          )}
      </NodeDescriptionBox>}
      {children}
      <FooterBox></FooterBox>
    </NodeFrame>
  );
};

const BASE_HEIGHT = 24;

const FooterBox = styled.div`
  width: 100%;
  height: 13px;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${BASE_HEIGHT / 2}px;
  color: #fff;
`;
