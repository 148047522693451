import React, { useContext } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { AggregateConfig } from '@terragotech/gen5-config-lib/dist/AggregateConfig';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { ConfigContext } from '../../../../context/ConfigContext';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { getPathToLevel } from '../../../../utils/navigationUtils';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';

export const AggregatesList: React.FC = () => {
  const { getAggregates, setAggregates } = useContext(ConfigContext);
  const AggregateAPI = useAggregateAPI();
  const confirm = useConfirm();
  const history = useHistory();
  const params = useParams() as { aggregate: string };
  const selectedAggregate = params.aggregate;

  const handleAggrDelete = async (index: number) => {
    await confirm({
      description:
        'Selected aggregate will be permanently deleted along with the properties events and commands belonging to it.',
      confirmationText: 'Delete',
    });
    const aggrName = getAggregates()[index].typeName;
    if (aggrName === selectedAggregate) history.push('/aggregates');
    const { error } = await AggregateAPI.deleteAggregate(index);
    if (error) return;
    successMsg(`The aggregate "${aggrName}" has been successfully deleted`);
  };

  const moveRow = (movedRowIndex: number, moveType: 'UP' | 'DOWN') => {
    const aggregates = getAggregates();
    const movedItem = aggregates[movedRowIndex];
    if (moveType === 'UP') {
      if (movedRowIndex === 0)
        return errorMsg(`Aggregate ${movedItem.typeName} is already at the top`);
      aggregates.splice(movedRowIndex - 1, 2, movedItem, aggregates[movedRowIndex - 1]);
    } else {
      if (aggregates.length === movedRowIndex + 1)
        return errorMsg(`Aggregate ${movedItem.typeName} is already at the bottom`);
      aggregates.splice(movedRowIndex, 2, aggregates[movedRowIndex + 1], movedItem);
    }
    setAggregates(aggregates);
    if (moveType === 'UP') successMsg(`Aggregate "${movedItem.typeName}" has been moved up`);
    if (moveType === 'DOWN') successMsg(`Aggregate "${movedItem.typeName}" has been moved down`);
  };

  return getAggregates().length !== 0 ? (
    <ScrollableTreeContainer>
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={(_: object, nodeId: string) => {
          history.push(nodeId);
        }}
        selected={getPathToLevel(history.location.pathname, 2)}
        style={{ marginRight: 6 }}
      >
        {getAggregates().map((aggregate: AggregateConfig, aggrIndex: number) => (
          <TreeViewNode
            label={aggregate.typeName}
            key={aggregate.typeName}
            nodeId={`/aggregates/${aggregate.typeName}`}
            onMoveUp={() => moveRow(aggrIndex, 'UP')}
            onMoveDown={() => moveRow(aggrIndex, 'DOWN')}
            onDelete={() => handleAggrDelete(aggrIndex)}
            color="green"
          />
        ))}
      </TreeView>
    </ScrollableTreeContainer>
  ) : (
    <p style={{ color: 'rgba(0, 0, 0, 0.54', fontSize: 14, textAlign: 'center' }}>
      No aggregates defined
    </p>
  );
};

const ScrollableTreeContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;
