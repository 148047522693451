import React, { useMemo, useContext, useCallback } from 'react';
import { InputGroup } from '../FormElements';
import { EditableTable, MaterialTableRef } from '../EditableTable';
import {
  getCardDefinitionOtherAttributesColumns,
  getAggregatePropertiesList,
} from '../../utils/jsonPartsGenerators';
import { CardDefinitionOtherAttribute } from '../../utils/types';
import { cloneDeep } from 'lodash';
import { ConfigContext } from '../../context/ConfigContext';
import { useParams } from 'react-router-dom';
import { errorMsg, successMsg } from '../SnackbarUtilsConfigurator';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import _ from 'lodash';
import { getAggregateIndex } from '../../utils/navigationUtils';

type OtherAttributeID = CardDefinitionOtherAttribute & { tableData: { id: number } };

interface CardDefinitionOtherAttributesProps {
  otherAttributesTableRef: React.RefObject<MaterialTableRef>;
  otherAttributes: CardDefinitionOtherAttribute[];
  setOtherAttributes: (otherAttributes: CardDefinitionOtherAttribute[]) => void;
}

export const CardDefinitionOtherAttributes: React.FC<CardDefinitionOtherAttributesProps> = ({
  otherAttributesTableRef,
  otherAttributes,
  setOtherAttributes,
}) => {
  const { config } = useContext(ConfigContext);
  const { aggrUICustomization: aggrUIName } = useParams() as { aggrUICustomization: string };
  const aggregateProps = useMemo(() => getAggregatePropertiesList(config, aggrUIName), [
    config,
    aggrUIName,
  ]);

  const otherAttributeColumns = useMemo(
    () =>
      getCardDefinitionOtherAttributesColumns(
        aggregateProps,
        config.aggregates[getAggregateIndex(config, aggrUIName)]?.properties
      ),
    [aggregateProps, config, aggrUIName]
  );

  const getOtherAttributesData = useMemo(() => cloneDeep(otherAttributes), [otherAttributes]);

  const isAttributeInvalid = useCallback((row: object, oldRow?: object) => {
    if (!(row as OtherAttributeID).itemKey) {
      errorMsg('Property "Item Key" is required');
      return true;
    }
    if (!(row as OtherAttributeID).itemLabel) {
      errorMsg('Property "Item Label" is required');
      return true;
    }
    return false;
  }, []);

  const addAttribute = useCallback(
    (
      row: object | CardDefinitionOtherAttribute,
      resolve: (data: any) => void,
      reject: () => void
    ) => {
      const newOtherAttributes = cloneDeep(otherAttributes);
      if (isAttributeInvalid(row)) return reject();
      let cdef: CardDefinitionOtherAttribute = { ...(row as CardDefinitionOtherAttribute) };
      if (cdef.itemKey) {
        const attrType = _.find(aggregateProps, { name: cdef.itemKey });
        cdef = { ...(row as CardDefinitionOtherAttribute), type: attrType?.type };
        newOtherAttributes.push(cdef);
      }
      setOtherAttributes(newOtherAttributes);
      resolve(null);
      successMsg('"other Attribues" has been successfully added');
    },
    [otherAttributes, setOtherAttributes, isAttributeInvalid, aggregateProps]
  );

  const updateAttribute = useCallback(
    (newRow: object, oldRow: object, resolve: (data: any) => void, reject: () => void) => {
      const newOtherAttributes = cloneDeep(otherAttributes);
      if (isAttributeInvalid(newRow, oldRow)) return reject();
      let cdef: CardDefinitionOtherAttribute = { ...(newRow as CardDefinitionOtherAttribute) };
      if (cdef.itemKey) {
        const attrType = _.find(aggregateProps, { name: cdef.itemKey });
        cdef = { ...(newRow as CardDefinitionOtherAttribute), type: attrType?.type };
      }
      newOtherAttributes[(oldRow as OtherAttributeID).tableData.id] = cdef;
      setOtherAttributes(newOtherAttributes);
      resolve(null);
      successMsg(`"Other Attributes" item has been successfully updated`);
    },
    [otherAttributes, setOtherAttributes, isAttributeInvalid, aggregateProps]
  );

  const deleteAttribute = useCallback(
    (rowToDel: object) => {
      const newOtherAttributes = cloneDeep(otherAttributes);
      newOtherAttributes.splice((rowToDel as OtherAttributeID).tableData.id, 1);
      setOtherAttributes(newOtherAttributes);
      successMsg(`"Other Attributes" item has been successfully deleted`);
    },
    [otherAttributes, setOtherAttributes]
  );

  const moveRow = useCallback(
    (row: OtherAttributeID, direction: 'UP' | 'DOWN') => {
      const newOtherAttributes = cloneDeep(otherAttributes);
      const rowIndex = row.tableData.id;
      if (direction === 'UP') {
        if (rowIndex === 0) return errorMsg(`"Other Attributes" item is already at the top`);
        newOtherAttributes.splice(
          rowIndex - 1,
          2,
          newOtherAttributes[rowIndex],
          newOtherAttributes[rowIndex - 1]
        );
        successMsg(`"Other Attributes" item has been moved up`);
      } else {
        if (rowIndex === newOtherAttributes.length - 1)
          return errorMsg(`"Other Attributes" item is already at the bottom`);
        newOtherAttributes.splice(
          rowIndex,
          2,
          newOtherAttributes[rowIndex + 1],
          newOtherAttributes[rowIndex]
        );
        successMsg(`"Other Attributes" item has been moved down`);
      }
      setOtherAttributes(newOtherAttributes);
    },
    [otherAttributes, setOtherAttributes]
  );

  const otherAttributesTableActions = useMemo(
    () => [
      {
        icon: () => <KeyboardArrowUpIcon />,
        tooltip: 'Move up',
        onClick: (_: object, rowData: object) => moveRow(rowData as OtherAttributeID, 'UP'),
      },
      {
        icon: () => <KeyboardArrowDownIcon />,
        tooltip: 'Move down',
        onClick: (_: object, rowData: object) => moveRow(rowData as OtherAttributeID, 'DOWN'),
      },
    ],
    [moveRow]
  );
  return (
    <InputGroup title="Other Attributes" style={{ marginTop: 20, padding: 21 }}>
      <EditableTable
        columns={otherAttributeColumns}
        data={getOtherAttributesData}
        toolbarStyle={otherAttributesTableToolbarStyle}
        onAdd={addAttribute}
        onUpdate={updateAttribute}
        onDelete={deleteAttribute}
        options={tableOptions}
        actions={otherAttributesTableActions}
        tableRef={otherAttributesTableRef}
      />
    </InputGroup>
  );
};

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
  draggable: false,
};
