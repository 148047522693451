import React, { useState } from 'react';
import { DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';
import { InputGroup, SelectInput, TextInput } from '../FormElements';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { CSVEvent } from '../../pages/aggregates/pages/EventSchemaEditor';

interface Props {
  onSubmit: (result: object) => void;
  onClose: () => void;
  events: CSVEvent[];
  aggrName: string;
  eventName: string;
  version: number;
}

interface GenerateFileConfig {
  aggregateId: string;
  configEditorIngest: string;
  fileName: string;
  eventsPerFile: string;
}

const yyyymmdd = () => {
  const dateIn = new Date();
  const yyyy = dateIn.getFullYear();
  const mm = dateIn.getMonth() + 1; // getMonth() is zero-based
  const dd = dateIn.getDate();
  return String(10000 * yyyy + 100 * mm + dd); // Leading zeros for mm and dd
};

export const Csv2EventsForm: React.FC<Props> = ({
  onSubmit,
  onClose,
  events,
  aggrName,
  eventName,
  version,
}) => {
  const initialgeFileConfig = {
    aggregateId: '',
    configEditorIngest: 'configEditorIngest',
    fileName: `${aggrName}-${eventName}-${yyyymmdd()}`,
    eventsPerFile: events.length.toString(),
  };
  const [genFileConfig, setGenFileConfig] = useState<Partial<GenerateFileConfig>>(
    initialgeFileConfig
  );

  const getOptions = () => {
    const options: string[] = Object.keys(events[0].data);
    options.unshift('');
    return options;
  };

  const chunk = (array: object[]) => {
    const { eventsPerFile } = genFileConfig;
    const size = Number(eventsPerFile);
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  };

  const generateFiles = () => {
    const { aggregateId, fileName } = genFileConfig;
    events.forEach((event) => {
      event.eventSchemaVersion = version;
      event.source = genFileConfig.configEditorIngest as string;
      if (aggregateId) {
        Object.keys(event.data).forEach((key) => {
          if (key === aggregateId) {
            const dataValue = event.data[key];
            if (dataValue) event.aggregateId = dataValue;
          }
        });
      }
    });

    const noOfEventsPerFile = chunk(events);
    const zip: JSZip = new JSZip();
    noOfEventsPerFile.forEach((event, index: number) => {
      zip.file(`${fileName}_${++index}.json`, JSON.stringify(event));
    });

    zip.generateAsync({ type: 'blob' }).then((content) => saveAs(content, `${fileName}.zip`));
  };

  const onSave = () => {
    const reStructuredEventsData: Record<string, object> = {};
    Object.keys(events[0].data).forEach((eventName) => {
      reStructuredEventsData[eventName] = {
        $id: `#/properties/${eventName}`,
        type: 'string',
      };
    });
    onSubmit(reStructuredEventsData);
  };

  return (
    <>
      <DialogTitle>Inbound Events From CSV Dataset </DialogTitle>
      <DialogContent style={{ padding: '0 25px' }}>
        <TextInput id="aggrName" value={aggrName} disabled={true} label="Aggregate Type" />
        <TextInput
          id="eventName"
          value={`${eventName} (Version ${version})`}
          disabled={true}
          label="Event Type"
        />
        <InputGroup title="Generate Events File(s)">
          <SelectInput
            title="Column of Aggregate ID (Optional)"
            variant="standard"
            options={getOptions()}
            value={genFileConfig.aggregateId || ''}
            onChange={(value: string) => setGenFileConfig({ ...genFileConfig, aggregateId: value })}
          />
          <TextInput
            id="configEditorIngest"
            value={genFileConfig.configEditorIngest}
            label="Ingest Source Name"
            onChange={(value) => setGenFileConfig({ ...genFileConfig, configEditorIngest: value })}
          />
          <TextInput
            id="fileName"
            value={genFileConfig.fileName}
            label="Events File Name"
            onChange={(value) => setGenFileConfig({ ...genFileConfig, fileName: value })}
          />
          <TextInput
            id="eventsPerFile"
            type="number"
            inputProps={{ min: 1, max: 1000 }}
            value={genFileConfig.eventsPerFile}
            label="Events Per File"
            onChange={(value) => setGenFileConfig({ ...genFileConfig, eventsPerFile: value })}
          />
          <Button
            color="primary"
            variant="contained"
            disabled={
              !(
                genFileConfig.configEditorIngest &&
                genFileConfig.fileName &&
                genFileConfig.eventsPerFile
              )
            }
            onClick={generateFiles}
            style={{ float: 'right', textTransform: 'none' }}
          >
            Generate Event File(s)
          </Button>
        </InputGroup>
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          CANCEL
        </Button>
        <Button color="primary" onClick={onSave} autoFocus>
          APPLY
        </Button>
      </DialogActions>
    </>
  );
};
