import React, { useState } from 'react';
import { controlMap } from './MobileTheme';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { isEqual, cloneDeep } from 'lodash';
import {
  V2GroupComponentDef,
  V2FormComponentDef,
  V2FormTemplate,
} from '@terragotech/form-renderer';
import IconButton from '@material-ui/core/IconButton';
import { blue } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useConfirm } from 'material-ui-confirm';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { successMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { useMapperRefChanger } from '../../../../utils/useMapperRefChanger';
import { getAggregateIndex } from '../../../../utils/navigationUtils';
import { useConfig } from '../../../../context/ConfigContext';
import { useParams } from 'react-router-dom';
import { TextHeaderEditForm } from '../../../../components/FormDialog/TextHeaderEditForm';
import { GroupEditForm } from '../../../../components/FormDialog/GroupEditForm';
import { TextInputEditForm } from '../../../../components/FormDialog/TextInputEditForm';
import { OptionsInputEditForm } from '../../../../components/FormDialog/OptionsInputEditForm';
import { MediaEditForm } from '../../../../components/FormDialog/MediaEditForm';
import { FormContextProvider } from '../../../../components/FormDialog/contexts/FormContext';
import { AggregateContextProvider } from '../../../../components/FormDialog/contexts/AggregateContext';
import { MapAggregateSelector } from '../../../../components/FormDialog/MapAggregateSelectorForm';

interface CommandFormListProps {
  formDefinition: V2FormTemplate;
  setFormDefinition: (val: V2FormTemplate) => void;
  selectedItems: V2FormTemplate;
  setSelectedItems: (val: V2FormTemplate) => void;
  lastPastedFormTemplate: V2FormTemplate | null;
  setRefresh: (boolean: boolean) => void;
  groupDragging: boolean;
  isImportCommand?: boolean;
}

export type V2FormComponentDefWithName = V2FormComponentDef & { name: string; droppableId: string };

export const CommandFormList: React.FC<CommandFormListProps> = ({
  formDefinition,
  setFormDefinition,
  selectedItems,
  setSelectedItems,
  lastPastedFormTemplate,
  setRefresh,
  groupDragging,
  isImportCommand,
}) => {
  const { config } = useConfig();
  const { aggregate: aggrName } = useParams() as {
    aggregate: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const currentAggregate = config?.aggregates?.[aggrIndex];
  const [focusedItem, setFocusedItem] = useState('');

  const confirm = useConfirm();
  const formDialog = useFormDialog();
  const mapperRefChanger = useMapperRefChanger();

  const handleSelectedItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    formComponentName: string,
    group?: string
  ) => {
    const fromDefinitionCopy = cloneDeep(formDefinition);

    const addItemToForm = () => {
      const copySelectedItems = cloneDeep(selectedItems);
      (copySelectedItems.components[formComponentName] as V2FormComponentDef) = formComponentValue;
      const selectedComponentsArr = [...copySelectedItems.order, formComponentName];
      const selectedComponentsArrInOrder = formDefinition.order.filter((item) =>
        selectedComponentsArr.includes(item)
      );
      copySelectedItems.order = selectedComponentsArrInOrder;
      return copySelectedItems;
    };

    const removeItemFromForm = (declaredItemToRemove?: string) => {
      const formItemNameToRemove = declaredItemToRemove || formComponentName;
      const copySelectedItems = cloneDeep(selectedItems);
      copySelectedItems.order = copySelectedItems.order.filter(
        (item) => item !== formItemNameToRemove
      );
      delete copySelectedItems.components[formItemNameToRemove];
      return copySelectedItems;
    };

    const addItemToGroup = () => {
      const copySelectedItems = cloneDeep(selectedItems);
      if (!group) return copySelectedItems;
      if (!copySelectedItems.components[group]) {
        copySelectedItems.components[group] = fromDefinitionCopy.components[group];
        copySelectedItems.order = [...copySelectedItems.order, group];
        (copySelectedItems.components[group] as V2GroupComponentDef).template.components = {};
        (copySelectedItems.components[group] as V2GroupComponentDef).template.order = [];
      }
      (copySelectedItems.components[group] as V2GroupComponentDef).template.components[
        formComponentName
      ] = formComponentValue;

      const selectedComponentsArr = [
        ...(copySelectedItems.components[group] as V2GroupComponentDef).template.order,
        formComponentName,
      ];
      const selectedComponentsArrInOrder = (formDefinition.components[
        group
      ] as V2GroupComponentDef).template.order.filter((item) =>
        selectedComponentsArr.includes(item)
      );
      (copySelectedItems.components[
        group
      ] as V2GroupComponentDef).template.order = selectedComponentsArrInOrder;
      return copySelectedItems;
    };

    const removeItemFromGroup = () => {
      const copySelectedItems = cloneDeep(selectedItems);
      if (!group) return copySelectedItems;
      if ((selectedItems.components[group] as V2GroupComponentDef).template.order.length === 1) {
        return removeItemFromForm(group);
      }
      (copySelectedItems.components[
        group
      ] as V2GroupComponentDef).template.order = (copySelectedItems.components[
        group
      ] as V2GroupComponentDef).template.order.filter((item) => item !== formComponentName);
      delete (copySelectedItems.components[group] as V2GroupComponentDef).template.components[
        formComponentName
      ];
      return copySelectedItems;
    };

    let formComponentValue: V2FormComponentDef;
    if (group) {
      formComponentValue = (fromDefinitionCopy.components[group] as V2GroupComponentDef).template
        .components[formComponentName];
    } else {
      formComponentValue = fromDefinitionCopy.components[formComponentName] as V2FormComponentDef;
    }
    event.stopPropagation();

    // In that case, event.ctrlKey does the trick.
    if (event.ctrlKey || event.metaKey) {
      if (!group) {
        if (!selectedItems.components.hasOwnProperty(formComponentName)) {
          setSelectedItems(addItemToForm());
        } else {
          setSelectedItems(removeItemFromForm());
        }
      } else {
        if (
          !(selectedItems.components[
            group
          ] as V2GroupComponentDef)?.template.components.hasOwnProperty(formComponentName)
        ) {
          setSelectedItems(addItemToGroup());
        } else {
          setSelectedItems(removeItemFromGroup());
        }
      }
    } else if (!isEqual(emptySelectedItems, selectedItems)) {
      setSelectedItems(emptySelectedItems);
    }
    setFocusedItem(`${group || ''}${formComponentName}`);
  };

  const editItem = async (
    component: V2FormComponentDef,
    name: string,
    droppableId: string,
    index: number
  ) => {
    const removeItemFromForm = () => {
      form.order.splice(index, 1);
      delete form.components[name];
    };
    const addModifiedItemToForm = () => {
      //@ts-ignore
      delete value.name;
      (form.components[newName] as V2FormComponentDef) = {
        ...(value as V2FormComponentDef),
      };
      form.order.splice(index, 0, newName);
    };
    const removeItemFromGroup = () => {
      dropOrder.splice(index, 1);
      delete dropComponents[name];
    };
    const addModifiedItemToGroup = () => {
      //@ts-ignore
      delete value.name;
      dropComponents[newName] = {
        ...(value as V2FormComponentDef),
      };
      dropOrder.splice(index, 0, newName);
    };

    let form = cloneDeep(formDefinition);
    const item: V2FormComponentDefWithName = { ...cloneDeep(component), name, droppableId };
    const value = await formDialog<typeof TextHeaderEditForm>(
      // I made a questionable choice to move these values into context, without realizing the dialog is in a separate branch of the tree
      (props) => (
        <AggregateContextProvider aggregateConfig={currentAggregate}>
          <FormContextProvider formDefinition={formDefinition}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {getFormComponent(item, props, isImportCommand)}
            </div>
          </FormContextProvider>
        </AggregateContextProvider>
      ),
      true
    );
    const { name: newName } = value;
    successMsg(`Form element "${name}" has been successfully edited`);
    if (droppableId === 'form') {
      removeItemFromForm();
      addModifiedItemToForm();
      form = mapperRefChanger.renameFormReferences(form, name, newName);
      setFormDefinition(form);
      try {
        setRefresh(false);
      } catch (error) {
        console.error(error);
      }
      return;
    }
    const {
      template: { order: dropOrder, components: dropComponents },
    } = form.components[droppableId] as V2GroupComponentDef;
    removeItemFromGroup();
    addModifiedItemToGroup();
    form = mapperRefChanger.renameFormReferences(form, name, newName, droppableId);
    setFormDefinition(form);
  };

  const deleteItem = async (id: string, droppableId: string, index: number) => {
    let form = cloneDeep(formDefinition);
    const removeItemFromForm = () => {
      form.order.splice(index, 1);
      delete form.components[droppableId];
    };
    const removeItemFromGroup = () => {
      dropOrder.splice(index, 1);
      delete dropComponents[droppableId];
    };

    await confirm({
      description: `Do you want to delete "${id}" form element?`,
      confirmationText: 'Delete',
    });
    successMsg(`Form element "${id}" has been successfully deleted`);
    if (droppableId === 'form') {
      removeItemFromForm();
      form = mapperRefChanger.removeFormReferences(form, id);
      setFormDefinition(form);
      return;
    }
    const {
      template: { order: dropOrder, components: dropComponents },
    } = form.components[droppableId] as V2GroupComponentDef;
    removeItemFromGroup();
    form = mapperRefChanger.removeFormReferences(form, id, droppableId);
    setFormDefinition(form);
  };

  return (
    <>
      {formDefinition.order.map((name, index) => {
        const component = formDefinition.components[name] as V2GroupComponentDef;
        const Control = component && controlMap[component.type];
        if (Control) {
          return (
            <Draggable key={name} draggableId={name} index={index}>
              {(provided) => (
                <PanelItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  isFocused={focusedItem === name}
                  isSelected={isEqual(
                    selectedItems.components[name],
                    formDefinition.components[name]
                  )}
                  hasMargin={component.type === 'group'}
                  onClick={(e) => handleSelectedItem(e, name)}
                >
                  <Control
                    controlDefinition={component}
                    name={name}
                    isDropDisabled={groupDragging}
                    focusedItem={focusedItem}
                    selectedItems={selectedItems}
                    setSelectedItems={handleSelectedItem}
                    editItem={editItem}
                    deleteItem={deleteItem}
                    pasted={lastPastedFormTemplate?.order.includes(name)}
                    lastPastedFormGroup={
                      lastPastedFormTemplate?.components[name] as V2GroupComponentDef
                    }
                  />
                  {focusedItem === name && (
                    <ItemButtonsContainer isGroup={component.type === 'group'}>
                      <IconButton
                        style={{ color: blue[300], padding: 3 }}
                        component="span"
                        onClick={() => {
                          editItem(component, name, 'form', index);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        style={{ color: blue[300], padding: 3, marginRight: 3 }}
                        component="span"
                        onClick={() => {
                          deleteItem(name, 'form', index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ItemButtonsContainer>
                  )}
                </PanelItem>
              )}
            </Draggable>
          );
        } else {
          console.log(`Couldn't find component: ${formDefinition.components[name]}`);
        }
        return null;
      })}
    </>
  );
};

const getFormComponent = (
  item: V2FormComponentDefWithName,
  props: React.PropsWithChildren<{
    onClose: () => void;
    onSubmit: <T>(result: T) => void;
  }>,
  isImportCommand?: boolean
) => {
  switch (item.type) {
    case 'textheader':
      return <TextHeaderEditForm component={item} {...props} />;
    case 'group':
      return <GroupEditForm component={item} isImportCommand={isImportCommand} {...props} />;
    case 'textInput':
    case 'textArea':
    case 'numberInput':
      return (
        <TextInputEditForm
          component={item}
          isImportCommand={isImportCommand}
          autosuggest
          {...props}
        />
      );
    case 'computed':
      return (
        <TextInputEditForm component={item} isImportCommand={isImportCommand} computed {...props} />
      );
    case 'date':
    case 'time':
    case 'barcode':
    case 'location':
    case 'polyline':
      return <TextInputEditForm component={item} isImportCommand={isImportCommand} {...props} />;
    case 'mapAggregateSelector':
      return <MapAggregateSelector component={item} {...props} />;
    case 'radio':
    case 'select':
    case 'checkbox':
      return <OptionsInputEditForm component={item} {...props} />;
    case 'audioupload':
    case 'imageupload':
    case 'fileupload':
    case 'videoupload':
      return <MediaEditForm component={item} {...props} />;
    default:
      return null;
  }
};

const emptySelectedItems = {
  order: [],
  components: {},
};

const PanelItem = styled.div<{ isFocused?: boolean; hasMargin?: boolean; isSelected?: boolean }>`
  position: relative;
  background: #ffffff;
  color: #aaaaaa;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  background: ${(props) => props.isFocused && '#F4FAFE'};
  margin-bottom: ${(props) => props.hasMargin && '8px'};
  margin-top: ${(props) => props.hasMargin && '8px'};
  border: ${(props) => props.isSelected && `1px solid ${blue[300]}`};
  border-radius: ${(props) => props.isSelected && `3px`};
  &:focus {
    outline: none;
  }
`;

const ItemButtonsContainer = styled.div<{ isGroup?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${(props) => (props.isGroup ? 0 : 3)};
`;
