import { V2PageTemplate, V2PageComponentDef } from '@terragotech/page-renderer';
import { JSONSchema6 } from 'json-schema';

type TSEnum = string[];
interface TSObject {
  [key: string]: TSType;
}

type TSType = TSEnum | TSObject | 'string';
export const convertV2PageTemplateToJsonSchema = (v2PageTemplate: V2PageTemplate): JSONSchema6 => {
  const getPropertyObjectFromPageComponent = (PageComponent: V2PageComponentDef): JSONSchema6 => {
    switch (PageComponent.type) {
      case 'text':
        return { type: 'string' };
      case 'aggregateMap':
        return {
          type: 'object',
          properties: {
            value: {
              type: 'string',
            },
            values: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
            lat: {
              type: 'number',
            },
            lon: {
              type: 'number',
            },
            zoom: {
              type: 'number',
            },
          },
        };
      case 'image':
        return {
          type: 'array',
          items: {
            type: 'string',
            format: 'photo'
          },
        };
      case 'file':
        return {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              id: { type: 'string' },
            },
          },
        };
      case 'streetView':
        return {
          type: 'object',
          properties: {
            lat: { type: 'number' },
            lon: { type: 'number' },
          },
        };
      case 'number':
      case 'date':
      case 'time':
        return { type: 'number' };
      case 'datetime':
        return { type: 'string', format: 'date-time' };
      default:
        console.log(`Missing type ${PageComponent.type}`);
        return {};
    }
  };

  const properties: { [k: string]: JSONSchema6 } = {};
  // now we're going to populate the data object with the page template
  v2PageTemplate &&
    v2PageTemplate.elements &&
    Object.entries(v2PageTemplate.elements).forEach(([key, e]): void => {
      const PageComponent = e.component;
      // don't process textheaders, and at least for now, functions
      if (PageComponent) {
        let property: JSONSchema6;
        // if it's a group, recurse and get all of its interfaces
        if (PageComponent.type === 'group') {
          property = convertV2PageTemplateToJsonSchema(PageComponent.template);
          if (PageComponent.repeats) {
            properties[key] = {
              type: 'array',
              items: convertV2PageTemplateToJsonSchema(PageComponent.template),
            };
          } else {
            properties[key] = convertV2PageTemplateToJsonSchema(PageComponent.template);
          }
        }
        // if a regular component, add it to the current interface, unless it's a textheader or function(no types for those)
        else {
          property = getPropertyObjectFromPageComponent(PageComponent);
        }

        if (
          //general case for all other repeats
          'repeats' in PageComponent &&
          PageComponent.repeats
        ) {
          properties[key] = { type: 'array', items: property };
          // } else if (
          //   PageComponent.type === 'location' &&
          //   PageComponent?.repeats?.enabled === false &&
          //   PageComponent?.multiplePoints?.enabled &&
          //   (PageComponent?.multiplePoints?.maximum > 1 ||
          //     PageComponent?.multiplePoints?.unlimited === true)
          // ) {
          //   //special case for multiple points (array) on location type
          //   properties[key] = { type: 'array', items: property };
        } else {
          properties[key] = property;
        }
      }
    });

  return {
    type: 'object',
    properties,
  };
};
