import React from 'react';
import { DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';
interface Button {
  label: string;
  value: string;
}

interface Props {
  onSubmit: (result: string) => void;
  onClose: () => void;
  options: Button[];
  description?: string;
  title?: string;
}

export const MultiButtonDialog: React.FC<Props> = ({
  onSubmit,
  onClose,
  options,
  description,
  title,
}) => {
  return (
    <>
      <DialogTitle>{title || 'Which option do you choose?'}</DialogTitle>

      {description && <DialogContent style={{ maxWidth: 650 }}>{description}</DialogContent>}

      <DialogActions style={{ marginRight: 10, marginTop: 5 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        {options.map((option) => (
          <Button color="primary" onClick={() => onSubmit(option.value)} autoFocus>
            {option.label}
          </Button>
        ))}
      </DialogActions>
    </>
  );
};
