import React, { useMemo, useCallback } from 'react';
import { Paper, Divider, Button } from '@material-ui/core';
import styled from 'styled-components';
import { EventMapDiagram, useDataMapDiagram } from '../../../map-editor/src';
import { FullScreen } from '../../../components/FullScreen';
import { useConfig } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useParams, Prompt } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { propertiesToSchema } from '../utils/PropertiesToSchemaConverter';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import { useConfirm } from 'material-ui-confirm';
import { useSchemaLookup } from '../../../utils/useSchemaLookup';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { useFormDialog } from '../../../components/FormDialog/FormDialogService';
import { MapperShortCutInfoDialog } from '../../../components/FormDialog/MapperShortCutInfoDialog';

export const DerivedPropertyMapping: React.FC = () => {
  const { config, getAggregateProperties } = useConfig();
  const AggregateAPI = useAggregateAPI();
  //For delete confirmation
  const confirm = useConfirm();
  const formDialog = useFormDialog();

  const { aggregate: aggrName } = useParams() as {
    aggregate: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const localSchemas = useMemo(() => {
    return {
      STATE: {
        schema: propertiesToSchema(getAggregateProperties(aggrIndex) || {}),
        schemaLabel: 'Properties',
      },
    };
  }, [aggrIndex, getAggregateProperties]);
  const schemaLookup = useSchemaLookup({
    currentAggregateType: aggrName,
    localSchemas: localSchemas,
  });

  const handleShortCutInfo = () => {
    formDialog<typeof MapperShortCutInfoDialog>((props) => <MapperShortCutInfoDialog {...props} />);
  };

  const handleSaveRequest = async () => {
    const thing = model?.getMapDefinition();
    if (thing) {
      const { error } = await AggregateAPI.updateDerivedPropertyMapping(aggrIndex, thing);
      if (error) return;
      successMsg('Derived property map has been saved');
    }
  };

  const { model, engine, checkModelHasChanged, hasSetPosition } = useDataMapDiagram({
    //outputObject: config.aggregates[aggrIndex].properties || {},
    //sourceObjects,
    schemaLookup: schemaLookup,
    dataMap: config.aggregates[aggrIndex].derivedPropertyMapping,
  });

  const ifShowMessageBeforeRedirect = useCallback(() => {
    return checkModelHasChanged() ? 'The form has not been saved, do you want to redirect?' : true;
  }, [checkModelHasChanged]);

  return (
    <DerivedPropertyMappingContainer>
      <CardTitle style={{ padding: '10px 20px 0px' }}>Derived Property Mapping</CardTitle>
      <GroupDivider />
      <FullScreen>
        <EventMapDiagram
          model={model}
          engine={engine}
          hasSetPosition={hasSetPosition}
          confirm={confirm}
          mapScenario="DERIVED_PROPERTY_MAPPING"
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveRequest}
          style={{ position: 'absolute', right: 20, bottom: 20 }}
        >
          Save
        </Button>

        <Button
          style={{
            position: 'absolute',
            right: 110,
            bottom: 20,
            color: '#ffffff',
            backgroundColor: 'black',
          }}
          onClick={handleShortCutInfo}
          variant="contained"
          startIcon={<KeyboardIcon />}
        >
          SHORTCUTS
        </Button>
        <Prompt message={ifShowMessageBeforeRedirect} />
      </FullScreen>
    </DerivedPropertyMappingContainer>
  );
};

const DerivedPropertyMappingContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - 92px);
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
  }
`;
