import React, { useContext, useCallback, useMemo, useRef } from 'react';
import { Paper } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import styled from 'styled-components';
import { useParams, Prompt } from 'react-router-dom';
import { ConfigContext } from '../../context/ConfigContext';
import { successMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import {
  ActionButton,
  MobileActionBuiltIns,
  BuiltinWebActions,
} from '@terragotech/gen5-config-lib';
import {
  getActionButtonsColumns,
  getCommandReferenceArr,
  commandRefObjToList,
  commandActionButtonActionToRef,
  actionButtonsToRows,
} from '../../utils/jsonPartsGenerators';
import { EditableTable, MaterialTableRef } from '../EditableTable';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { useUICustomizationAPI } from '../../context/fakeAPIHooks/useUICustomizationAPI';
import { isMobile, getName, getAdditionalElements, getTitle } from './ActionButtonEditorUtils';
import { ActionButtonToolbarItem } from './ActionButtonToolbarItem';
import { ActionButtonRow } from '../../utils/types';
import { extractActionButtons } from '../../context/contextUtils';

type MobileActionButton = ActionButton<MobileActionBuiltIns>;
type WebActionButton = ActionButton<BuiltinWebActions>;
export type ActionButtonType =
  | 'editorWorkflows'
  | 'editorActions'
  | 'multiSelectActions'
  | 'tableActions';

type MobileActionButtonWithID = MobileActionButton & { tableData: { id: number } };
type WebActionButtonWithID = WebActionButton & { tableData: { id: number } };

export type ActionButtonEditor =
  | 'mobileEditorWorkflows'
  | 'mobileEditorActions'
  | 'mobileMultiSelectActions'
  | 'webEditorWorkflows'
  | 'webTableActions'
  | 'webMultiSelectActions';

interface Props {
  type: ActionButtonEditor;
}

export const ActionButtonEditor: React.FC<Props> = ({ type }) => {
  const { config } = useContext(ConfigContext);
  const UICustomizationAPI = useUICustomizationAPI();
  const tableRef = useRef<MaterialTableRef>(null);
  const webOrMobile = isMobile(type) ? 'mobile' : 'web';
  const uiType = webOrMobile === 'mobile' ? 'mobileUIConfig' : 'webUIConfig';

  const params = useParams() as { aggrUICustomization: string };
  const aggregateUICustomization = params.aggrUICustomization;

  const actionButtons = cloneDeep(
    extractActionButtons(config, uiType, aggregateUICustomization, getName(type)) || []
  );

  const isButtonInvalid = useCallback((row: object) => {
    if (!(row as ActionButtonRow).action) {
      errorMsg('Property "Action" is required');
      return true;
    }
    return false;
  }, []);

  const addActionButton = async (row: object, resolve: (data: any) => void, reject: () => void) => {
    if (isButtonInvalid(row)) return reject();
    const actionButton = commandActionButtonActionToRef(row as ActionButtonRow);
    const { error } = await UICustomizationAPI.addActionButton(
      uiType,
      aggregateUICustomization,
      getName(type),
      actionButton
    );
    if (error) reject();
    successMsg('Action button has been successfully added');
    resolve(null);
  };

  const updateActionButton = async (
    newRow: object,
    oldRow: object,
    resolve: (data: any) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(newRow)) return reject();
    const actionButton = commandActionButtonActionToRef(newRow as ActionButtonRow);
    const actionButtonIndex = (oldRow as MobileActionButtonWithID).tableData.id;
    const { error } = await UICustomizationAPI.updateActionButton(
      uiType,
      aggregateUICustomization,
      getName(type),
      actionButtonIndex,
      actionButton
    );
    if (error) reject();
    successMsg('Action button has been successfully updated');
    resolve(null);
  };

  const deleteActionButton = async (rowToDel: object) => {
    const actionButtonIndex = (rowToDel as MobileActionButtonWithID).tableData.id;
    const { error } = await UICustomizationAPI.deleteActionButton(
      uiType,
      aggregateUICustomization,
      getName(type),
      actionButtonIndex
    );
    if (error) return;
    successMsg(`Action button has been successfully deleted`);
  };

  const moveActionButton = async (
    row: MobileActionButtonWithID | WebActionButtonWithID,
    direction: 'UP' | 'DOWN'
  ) => {
    const actionButtonIndex = (row as MobileActionButtonWithID).tableData.id;
    const { error } = await UICustomizationAPI.moveActionButton(
      uiType,
      aggregateUICustomization,
      getName(type),
      actionButtonIndex,
      direction
    );
    if (error) return;
    if (direction === 'UP') {
      successMsg('Action button has been moved up');
    }
    if (direction === 'DOWN') {
      successMsg('Action button has been moved down');
    }
  };

  const actionButtonActions = [
    {
      icon: () => <KeyboardArrowUpIcon />,
      tooltip: 'Move up',
      onClick: (_: object, rowData: object) =>
        moveActionButton(rowData as MobileActionButtonWithID, 'UP'),
    },
    {
      icon: () => <KeyboardArrowDownIcon />,
      tooltip: 'Move down',
      onClick: (_: object, rowData: object) =>
        moveActionButton(rowData as MobileActionButtonWithID, 'DOWN'),
    },
  ];

  const getActionButtonColumns = useMemo(
    () =>
      getActionButtonsColumns(
        commandRefObjToList(
          getCommandReferenceArr(
            config,
            false,
            getAggregateIndex(config, aggregateUICustomization)
          ),
          getAdditionalElements(type)
        ),
        config.aggregates[getAggregateIndex(config, aggregateUICustomization)].properties
      ),
    [config, type, aggregateUICustomization]
  );

  const ifShowMessageBeforeRedirect = () => {
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    return lastEditingRow || showAddRow
      ? 'The form has not been saved, do you want to redirect?'
      : true;
  };

  const getToolbarItem = (
    <ActionButtonToolbarItem
      type={type}
      excludedActionButtons={actionButtons}
      uiType={uiType}
      aggregateUICustomization={aggregateUICustomization}
    />
  );

  return (
    <PropertiesContainer>
      <CardTitle>{getTitle(type)}</CardTitle>
      <EditableTable
        columns={getActionButtonColumns}
        data={actionButtonsToRows(actionButtons)}
        toolbarStyle={otherAttributesTableToolbarStyle}
        onAdd={addActionButton}
        onUpdate={updateActionButton}
        onDelete={deleteActionButton}
        options={tableOptions}
        actions={actionButtonActions}
        toolbarItem={getToolbarItem}
        tableRef={tableRef}
      />
      <Prompt message={ifShowMessageBeforeRedirect} />
    </PropertiesContainer>
  );
};

const PropertiesContainer = styled(Paper)`
  padding: 10px 20px;
  margin: 5px;
  flex: 1;
  overflow-x: auto;
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
  sorting: false,
  draggable: false,
};
