import React, { useContext } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ConfigContext } from '../../../../context/ConfigContext';
import { FunctionsList } from './FunctionsList';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { CollapsibleMenuCard } from '../../../../components/AppContainer/CollapsibleMenuCard';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { SingleTextInputForm } from '../../../../components/FormDialog/SingleTextInputForm';
import { useFunctionAPI } from '../../../../context/fakeAPIHooks/useFunctionAPI';

export const FunctionsMenu: React.FC = () => {
  const { getFunctions } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const FunctionAPI = useFunctionAPI();

  const handleAddFunction = async () => {
    const aggrName = await formDialog<typeof SingleTextInputForm>((props) => (
      <SingleTextInputForm title="Create new function" {...props} />
    ));
    if (!isValid(aggrName)) return;
    const { error } = await FunctionAPI.addFunction(aggrName);
    if (error) return;
    successMsg(`The function "${aggrName}" has been successfully added`);
  };

  const isValid = (aggrName: string) => {
    if (ifFunctionExists(aggrName)) {
      errorMsg(`An function named "${aggrName}" already exists`);
      return false;
    }
    return true;
  };

  const ifFunctionExists = (aggrName: string) => getFunctions()[aggrName];

  return (
    <CollapsibleMenuCard title="Functions">
      <FunctionsList />
      <ButtonsContainer>
        <IconButton
          style={{ color: '#1e88e5' }}
          component="span"
          onClick={() => handleAddFunction()}
        >
          <AddIcon />
        </IconButton>
      </ButtonsContainer>
    </CollapsibleMenuCard>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;
