import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import React from 'react';

type Color = 'orange' | 'purple' | 'blue' | 'green' | number;

interface AggregateNodeProps {
  children?: React.ReactNode;
  color?: Color;
  label: string;
  count?: number;
  nodeId: string;
  onAdd?: () => void;
  onDelete?: () => void;
  onRemove?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onLabelClick?: () => void;
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelInfo?: number;
  labelText: string;
  onAdd?: () => void;
  onDelete?: () => void;
  onRemove?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onLabelClick?: () => void;
};

export const TreeViewNode: React.FC<AggregateNodeProps> = ({
  children,
  label,
  count,
  nodeId,
  onAdd,
  onDelete,
  onRemove,
  onMoveUp,
  onMoveDown,
  color,
  onLabelClick,
}) => {
  const colors = ['#e3742f', '#a250f5', '#1a73e8', '#3c8039'];
  const bgColors = ['#fcefe3', '#f3e8fd', '#e8f0fe', '#e6f4ea'];
  const getColor = () => {
    switch (color) {
      case 'orange':
        return '#e3742f';
      case 'purple':
        return '#a250f5';
      case 'blue':
        return '#1a73e8';
      case 'green':
        return '#3c8039';
      default:
        if (typeof color === 'number') return colors[color % 4];
        return '#e3742f';
    }
  };
  const getBgColor = () => {
    switch (color) {
      case 'orange':
        return '#fcefe3';
      case 'purple':
        return '#f3e8fd';
      case 'blue':
        return '#e8f0fe';
      case 'green':
        return '#e6f4ea';
      default:
        if (typeof color === 'number') return bgColors[color % 4];
        return '#fcefe3';
    }
  };
  return (
    <StyledTreeItem
      nodeId={nodeId}
      onAdd={onAdd}
      onDelete={onDelete}
      onRemove={onRemove}
      onMoveUp={onMoveUp}
      onMoveDown={onMoveDown}
      onLabelClick={onLabelClick}
      labelText={label}
      labelInfo={count}
      color={getColor()}
      bgColor={getBgColor()}
    >
      {children}
    </StyledTreeItem>
  );
};

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

const StyledTreeItem = (props: StyledTreeItemProps) => {
  const {
    labelText,
    labelInfo,
    color,
    bgColor,
    nodeId,
    onDelete,
    onRemove,
    onAdd,
    onMoveUp,
    onMoveDown,
    onLabelClick,
    ...other
  } = props;

  const classes = useTreeItemStyles();

  const getLabelPadding = (text: string) => {
    switch (text) {
      case 'Settings':
        return '12';
      case 'Commands':
        return '12';
      case 'Events':
        return '12';
      default:
        return '0';
    }
  };

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>

          <Typography variant="caption" color="inherit" style={{ marginRight: 5, marginLeft: 5 }}>
            {labelInfo}
          </Typography>

          {onMoveUp && (
            <IconButton
              style={{ color: '#1e88e5', padding: 0 }}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onMoveUp();
              }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          )}

          {onMoveDown && (
            <IconButton
              style={{ color: '#1e88e5', padding: 0 }}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onMoveDown();
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          )}

          {onAdd && (
            <IconButton
              style={{ color: '#1e88e5', padding: 0 }}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onAdd();
              }}
            >
              <AddIcon />
            </IconButton>
          )}

          {onRemove && (
            <IconButton
              style={{ color: '#1e88e5', padding: 0 }}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onRemove();
              }}
            >
              <ClearIcon />
            </IconButton>
          )}

          {onDelete && (
            <IconButton
              style={{ color: '#1e88e5', padding: 0 }}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      }
      style={{
        // @ts-ignore
        '--tree-view-color': color,
        // @ts-ignore
        '--tree-view-bg-color': bgColor,
        marginLeft: getLabelPadding(labelText),
        color: 'black',
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      nodeId={nodeId}
      {...other}
      onLabelClick={onLabelClick}
    />
  );
};
const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        display: 'block',
      },
      '& > $content $label': {
        backgroundColor: 'transparent',
        padding: '0',
      },
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
      '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      display: 'flex',
      color: theme.palette.text.secondary,
      paddingRight: theme.spacing(1),
      //@ts-ignore
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: '.5rem 0 .5rem 0',
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      wordBreak: 'break-word',
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  })
);
