import styled from 'styled-components';
import { V2MediaFileComponent } from '@terragotech/form-renderer';
import React, { FunctionComponent } from 'react';
import { NestedFormControlProps } from '../FormTypes';
import { Label, StandardInputFrame } from './Common';

const StatusText = styled.div`
  display: flex;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #222222;
  margin-right: 12px;
`;
const StatusLabel = styled(StatusText as any)`
  font-weight: bold;
  margin-right: 4px;
`;
const AddMedia = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #56ccf2;
  margin-left: 15px;
`;
const StatusSection = styled.div`
  display: flex;
  margin-left: 15px;
  margin-top: 2px;
  margin-bottom: 4px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;
const MediaUpload: FunctionComponent<NestedFormControlProps<V2MediaFileComponent>> = (props) => {
  const { controlDefinition, pasted } = props;
  //TODO: Update this to use the icons
  let typeLabel = '';
  switch (controlDefinition.type) {
    case 'fileupload':
      typeLabel = 'file';
      break;
  }
  const repeatMin = controlDefinition.min || 0;
  const repeatMax = controlDefinition.max || 'Unlimited';
  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        required={controlDefinition.required}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />
      <StatusSection>
        <StatusText>0 added</StatusText>

        <StatusLabel>Required:</StatusLabel>

        <StatusText>{repeatMin}</StatusText>

        <StatusLabel>Allowed:</StatusLabel>

        <StatusText>{repeatMax}</StatusText>
      </StatusSection>
      <AddMedia>Add {typeLabel}</AddMedia>
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default MediaUpload;
