import React, { FunctionComponent } from 'react';
import { V2BarcodeComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label } from './Common';
import qrCodeImage from './img/QRCode.png';

const Placeholder = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-left: 10px;
  margin-right: 15px;
  color: #aaaaaa;
`;
const PlaceHolderFrame = styled.div`
  display: flex;
`;
const QrCodeFrame = styled.div`
  margin-left: 15px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const BarcodeControl: FunctionComponent<NestedFormControlProps<V2BarcodeComponent>> = (props) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        required={controlDefinition.required}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />

      <PlaceHolderFrame>
        <QrCodeFrame>
          <img src={qrCodeImage} alt="QrCode icon" />
        </QrCodeFrame>

        <Placeholder>{controlDefinition.placeholder || 'Scan QR code or Barcode'}</Placeholder>
      </PlaceHolderFrame>

      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default BarcodeControl;
