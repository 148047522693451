import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { MobileUIGeneralEditor } from './subpages/MobileUIGeneralEditor';
import { MobileUIMenu } from './components/MobileUIMenu/MobileUIMenu';
import { AggregateUICustomisationsMenu } from './components/AggregateUICustomisationsMenu/AggregateUICustomisationsMenu';
import { CardDefinition } from '../../components/CardDefinition/CardDefinition';
import { MapLabels } from '../../components/MapLabels/MapLabels';
import { FabActions } from './subpages/FabActions';
import { ActionButtonEditor } from '../../components/ActionButtonEditor/ActionButtonEditor';
import GeneralDialog from '../../components/GeneralDialog/GeneralDialog';
import { MobileUIDateTimeEditor } from './subpages/MobileUIDateTimeEditor';
export const MobileUI: React.FC = () => (
  <FlexContainer>
    <MobileUIMenu />
    <Route path="/mobileUI/aggregateUICustomizations" component={AggregateUICustomisationsMenu} />
    <Route path="/mobileUI/general" component={MobileUIGeneralEditor} />
    <Route path="/mobileUI/fabActions" component={() => <FabActions type="mobile" />} />
    <Route path="/mobileUI/DefaultDateTimeFormat" component={MobileUIDateTimeEditor} />
    <Route
      path="/mobileUI/aggregateUICustomizations/:aggrUICustomization/cardDefinition"
      component={() => <CardDefinition type="mobile" />}
    />
    <Route
      path="/mobileUI/aggregateUICustomizations/:aggrUICustomization/mapLabels"
      component={() => <MapLabels type="mobile" />}
    />
    <Route
      path="/mobileUI/aggregateUICustomizations/:aggrUICustomization/editorWorkflows"
      component={() => <ActionButtonEditor type="mobileEditorWorkflows" />}
    />
    <Route
      path="/mobileUI/aggregateUICustomizations/:aggrUICustomization/editorActions"
      component={() => <ActionButtonEditor type="mobileEditorActions" />}
    />
    <Route
      path="/mobileUI/aggregateUICustomizations/:aggrUICustomization/multiSelectActions"
      component={() => <ActionButtonEditor type="mobileMultiSelectActions" />}
    />
    <Route
      path="/mobileUI/aggregateUICustomizations/:aggrUICustomization"
      exact={true}
      component={() => <GeneralDialog type="mobile" />}
    />
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
