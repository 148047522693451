import React from 'react';

import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { useHistory } from 'react-router-dom';
import { getPathToLevel } from '../../../../utils/navigationUtils';
import { CollapsibleMenuCard } from '../../../../components/AppContainer/CollapsibleMenuCard';

export const WebUIMenu: React.FC = () => {
  const history = useHistory();

  return (
    <CollapsibleMenuCard title="Web UI">
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={(_: object, nodeId: string) => {
          history.push(nodeId);
        }}
        selected={getPathToLevel(history.location.pathname, 2)}
        style={{ marginRight: 6 }}
      >
        <TreeViewNode label="General" key="General" nodeId="/webUI/general" color="green" />
        <TreeViewNode
          label="Aggregate Customizations"
          key="Aggregate Customizations"
          nodeId="/webUI/aggregateUICustomizations"
          color="purple"
        />
        <TreeViewNode
          label="Fab Actions"
          key="Fab Actions"
          nodeId="/webUI/fabActions"
          color="orange"
        />
        <TreeViewNode
          label="Import Actions"
          key="Import Actions"
          nodeId="/webUI/importActions"
          color="orange"
        />
        <TreeViewNode
          label="Default Date/Time Format"
          key="Default Date/Time Format"
          nodeId="/webUI/DefaultDateTimeFormat"
          color="orange"
        />
      </TreeView>
    </CollapsibleMenuCard>
  );
};
