import { BaseModelOptions } from '@projectstorm/react-canvas-core';
import {
  AllNodeMap,
  AllNodeInstances,
  NodeSource,
  isNodeDataReference,
  mapScenarios,
} from '@terragotech/gen5-datamapping-lib';
import { AllConfigurations } from '@terragotech/gen5-datamapping-lib';
import {
  updatePortModelMapFromJSONSchema,
  getAllPortsFromPortModelMap,
  PortModelMap,
} from '../../DiagramPorts/buildSchemaPortModelFromJSONSchema';
import { NodeExternalLookups } from './DataMapperNodeFactory';
import { DefaultNodeModel, DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import { DiagramModel } from '@projectstorm/react-diagrams';
import SchemaDefinedPortModel from '../../DiagramPorts/SchemaDefinedPort';
import { Gen5PortModel } from '../../DiagramPorts/Gen5PortModel';
import { JSONSchema6 } from 'json-schema';

export interface BasicNodeModelOptions extends BaseModelOptions {
  type: string;
  id: string;
  //transformNode: T; // The actual transformNode instance defined in the data map
  //parameterDefinitions: ParameterDefinition[];
  nodeConfiguration?: AllConfigurations;
  lookups?: NodeExternalLookups;
  name?: string;
  description?: string;
  newlyCreated?: boolean;
}
export class DataMapperNodeModel extends DefaultNodeModel {
  //private parameterDefinitions: ParameterDefinition[];
  //private transformNode: BaseTransformNode;
  private configMap: { [index: string]: unknown };
  private datamapNodeInstance: AllNodeInstances;
  public name: string;
  public description: string | undefined;
  private inputPortModelMap: any;
  private outputPortModelMap: any;
  private newlyCreated: boolean | undefined;

  constructor(options: BasicNodeModelOptions) {
    super({
      ...options,
    });
    this.newlyCreated = options.newlyCreated;
    this.name = (options && options.name) || options.nodeConfiguration?.name || 'Untitled';
    this.description = options.description;

    // Build an instance of the datamapping lib object to maintain our configuration
    const NodeClass = (AllNodeMap as any)[options.type];
    this.datamapNodeInstance = new NodeClass(
      {
        accessorMap: options.lookups?.accessorMap,
        name: options.nodeConfiguration?.name,
        inputs: options.nodeConfiguration?.inputs,
        config: options.nodeConfiguration?.config,
        mapScenarios: mapScenarios,
      },
      { schemaLookup: options.lookups?.schemaLookup }
    );
    // We will build the ports from the json schemas, and also from the data in the nodeConfiguration.
    this.datamapNodeInstance.interfaceDefinition;
    // Ok, we're going to build our ports from the json schemas of the input and output
    this.buildPortsFromInterfaceDefinition();
  }

  public setName(newName: string): void {
    // would be good to find a way to ensure this is unique
    // now update the outputPort to use the new name
    this.name = newName;
  }
  public setDescription(newDescription?: string): void {
    // would be good to find a way to ensure this is unique
    // now update the outputPort to use the new name
    this.description = newDescription;
  }
  public refresh(): void {
    this.buildPortsFromInterfaceDefinition();
  }
  public removePort(port: DefaultPortModel): void {
    Object.values(port.getLinks()).forEach((link) => link.remove());

    super.removePort(port);
  }
  private syncPorts(expectedPorts: SchemaDefinedPortModel[], existingPorts: DefaultPortModel[]) {
    //remove unnecessary ports
    Object.values(existingPorts).forEach((port) => {
      if (expectedPorts.findIndex((inPort) => inPort.getName() === port.getName()) < 0) {
        this.removePort(port as DefaultPortModel);
      }
    });
    //add missing ports
    expectedPorts.forEach((port) => {
      if (existingPorts.findIndex((inPort) => inPort.getName() === port.getName()) < 0) {
        this.addPort(port);
      }
    });
  }
  private recreateAllPorts() {
    //Flatten the tree to send the full list of ports to the parent
    this.syncPorts(getAllPortsFromPortModelMap(this.inputPortModelMap), this.getInPorts());
    this.syncPorts(getAllPortsFromPortModelMap(this.outputPortModelMap), this.getOutPorts());
  }
  /**
   * This will build all of the possible ports of a node, taking into account both the schema and the configuration
   */
  private buildPortsFromInterfaceDefinition() {
    const interfaceDef = this.datamapNodeInstance.interfaceDefinition;
    const nodeConfiguration = this.datamapNodeInstance.getConfiguration();
    // Build a map of all the input ports that helps form our hierarchy

    this.inputPortModelMap = updatePortModelMapFromJSONSchema({
      existingMap: this.inputPortModelMap,
      schema: interfaceDef.InputParams as any,
      data: nodeConfiguration.inputs,
      inBound: true,
      fieldName: 'inputs',
    });
    this.outputPortModelMap = updatePortModelMapFromJSONSchema({
      existingMap: this.outputPortModelMap,
      schema: interfaceDef.OutputParams as any,
      data: {},
      inBound: false,
      fieldName: 'outputs',
      fieldPath: '$',
    });
    this.recreateAllPorts();
    this.connectPorts();
  }
  public getNodeDefinition(x?: number, y?: number): AllConfigurations {
    return {
      ...this.datamapNodeInstance.getConfiguration(x,y),
      description: this.description
    };
  }
  public getInputPortMap(): PortModelMap {
    return this.inputPortModelMap;
  }
  public getOutputPortMap(): PortModelMap {
    return this.outputPortModelMap;
  }

  /**
   *  connects ports based on current values from the transformNode. This can't be done in the constructor
   * because we have to wait for all nodes to be created.
   */
  public connectPorts(specificInputs?: Record<string, NodeSource>, pathPrefix?: string): void {
    // Go through all of the inputs and connect them to any available ports
    const inputs = specificInputs || this.datamapNodeInstance.getConfiguration().inputs;
    //TODO: make this recursive
    if (inputs) {
      Object.keys(inputs).forEach((key) => {
        const input = inputs[key];
        if (isNodeDataReference(input)) {
          //try to connect it
          // The key here is not going to be right for nested items. But the recursion should fix that.
          // Need to keep arrays in mind for this too.
          this.connectPort(
            this.getPort(`${pathPrefix ? `${pathPrefix}.` : ''}${key}`) as SchemaDefinedPortModel,
            input.sourceObject,
            input.sourcePath
          );
        } else if (Array.isArray(input)) {
          // if it's an object, and it's an array, try to cycle through each
          input.forEach((childInput, index) => {
            if(isNodeDataReference(childInput)){
              this.connectPort(
                this.getPort(`${pathPrefix ? `${pathPrefix}.` : ''}${key}[${index}]`) as SchemaDefinedPortModel,
                childInput.sourceObject,
                childInput.sourcePath
              );
            }
            if(typeof childInput === 'object' && childInput !== null) {
              this.connectPorts(childInput as Record<string, NodeSource>, `${pathPrefix ? `${pathPrefix}.` : ''}${key}[${index}]`)
            }
          });
        } else if (typeof input === 'object') {
          // if it's an object, but not an array, then we need to recurse
          if (input !== null) {
            this.connectPorts(
              input as Record<string, NodeSource>,
              pathPrefix ? `${pathPrefix}.${key}` : key
            );
          }
        }
      });
    }
  }
  public getConfigOptions(): JSONSchema6 {
    return this.datamapNodeInstance.interfaceDefinition.ConfigParams as any;
  }
  public getConfigValues(): Record<string, unknown> {
    return this.datamapNodeInstance.getConfiguration().config || {};
  }
  public setConfigValue(configKey: string, newVal: unknown): void {
    this.datamapNodeInstance.setConfigValue(configKey, newVal);
  }
  /**
   * This connects an individual input port to a given output port
   * @param inPort The port to connect
   * @param sourceObject The object/node it should be connected to
   * @param sourcePath The path of the port to connect to
   */
  public connectPort(
    inPort: SchemaDefinedPortModel,
    sourceObject: string | undefined,
    sourcePath: string | undefined
  ): void {
    const connectedPorts = inPort && inPort.getConnectedPorts();
    if (sourceObject && sourcePath) {
      if (!connectedPorts || connectedPorts.length === 0) {
        const parentCanvasModel = this.getParentCanvasModel();
        if (parentCanvasModel) {
          // try to find the source object
          const nodes = (parentCanvasModel as DiagramModel).getNodes();

          const sourceNode = nodes.find((node) => node.getID() === sourceObject);

          // if found, try to find the source port
          if (sourceNode) {
            let sourcePort = sourceNode.getPort(sourcePath);
            if (!sourcePort) {
              sourcePort = sourceNode.getPort(sourcePath.replace('$.', ''));
            }
            if (!sourcePort) {
              sourcePort = sourceNode.getPort(`$.${sourcePath}`);
            }

            // if found create a link
            if (sourcePort && inPort) {
              const link = inPort.createLinkModel();
              link.setTargetPort(inPort);
              link.setSourcePort(sourcePort);
              (this.getParentCanvasModel() as DiagramModel).addLink(link);
            }
          } else {
            console.warn(`Couldn't find node: ${sourceObject}`);
          }
        }
      }
    }
  }

  public getSourceObjectAndPathFromPort(inPort: Gen5PortModel): NodeSource | undefined {
    let result: NodeSource | undefined;

    const connectedPorts = inPort && (inPort.getConnectedPorts() as SchemaDefinedPortModel[]);
    if (connectedPorts && connectedPorts.length > 0) {
      const mainPort = connectedPorts[0];
      if (mainPort) {
        result = {
          sourceObject: mainPort.getParent().getID(),
          sourcePath: mainPort.getName(),
        };
      }
    } else {
      if (inPort.getFixedValue() !== undefined) {
        result = inPort.getFixedValue();
      }
    }
    return result;
  }
  public onPortConnected(
    ourPort: SchemaDefinedPortModel,
    connectedPort: SchemaDefinedPortModel
  ): void {
    // When a port is connected, we want to update the node configuration with the new value
    this.datamapNodeInstance.setInputValue(ourPort.getName(), {
      sourceObject: connectedPort.getParent().getID(),
      sourcePath: connectedPort.getName(),
    });
  }
  public disconnectPort(portName: string): void {
    // When a port is connected, we want to update the node configuration with the new value
    this.datamapNodeInstance.setInputValue(portName, undefined);
  }
  public updateInputFixedValue(ourPort: SchemaDefinedPortModel): void {
    if (ourPort.getConnectedPorts().length === 0) {
      const fixedValue = ourPort.getFixedValue();
      this.datamapNodeInstance.setInputValue(ourPort.getName(), fixedValue);
    }
  }
  getNamedPort(name: string): SchemaDefinedPortModel | null {
    return this.getPort(name) as SchemaDefinedPortModel;
  }

  setConfigParameter(paramName: string, paramValue: unknown): void {
    this.configMap[paramName] = paramValue;
  }

  getConfigParameter(paramName: string): string {
    return this.configMap[paramName] as string;
  }

  getConfigs(): { [index: string]: unknown } {
    return this.datamapNodeInstance.getConfiguration().config || {};
  }
  getNewlyCreated(): boolean {
    return this.newlyCreated || false;
  }

  setConfigs(configs: { [index: string]: unknown }): void {
    this.datamapNodeInstance.setConfigs(configs);
    this.configMap = configs;
    //Just in case the ports have changed.
    this.buildPortsFromInterfaceDefinition();
  }
  addCollectionEntry(port: SchemaDefinedPortModel): void {
    //TODO: try getting the existing values
    // Then if there is none, just add null to [0] and [1]
    //If there are entries, then add to entries+1
    const existingValues = (this.datamapNodeInstance.getInputValue(port.getName()) ||
      []) as NodeSource[];
    if (existingValues.length === 0) {
      this.datamapNodeInstance.setInputValue(port.getName() + '[0]', null);
      this.datamapNodeInstance.setInputValue(port.getName() + '[1]', null);
    } else {
      this.datamapNodeInstance.setInputValue(port.getName() + `[${existingValues.length}]`, null);
    }
    this.buildPortsFromInterfaceDefinition();
  }
  removeCollectionEntry(nodeToRemove: string): void {
    //TODO: try getting the existing values
    // Then if there is none, just add null to [0] and [1]
    //If there are entries, then add to entries+1
    const parentName = nodeToRemove.substring(0, nodeToRemove.indexOf('['));
    let index = Number.parseInt(
      nodeToRemove.substring(nodeToRemove.indexOf('[') + 1, nodeToRemove.indexOf(']'))
    );
    const existingValues = (this.datamapNodeInstance.getInputValue(parentName) ||
      []) as NodeSource[];
    while (index < existingValues.length) {
      const port = this.getPort(parentName + `[${index}]`);

      const connectedLinks = port?.getLinks();

      if (connectedLinks) {
        for (const key of Object.keys(connectedLinks)) {
          connectedLinks[key].remove();
        }
        const val = this.datamapNodeInstance.getInputValue(parentName + `[${index + 1}]`);
        this.datamapNodeInstance.setInputValue(parentName + `[${index}]`, val);
        if (typeof val === 'string' || val === null) {
          (port as SchemaDefinedPortModel).updateFixedValue(val as string);
        }
      }
      index++;
    }
    this.datamapNodeInstance.deleteInput(parentName);
    this.buildPortsFromInterfaceDefinition();
  }
}
