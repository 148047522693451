"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCoordinatesValid = void 0;
const isCoordinatesValid = (latitude, longitude) => {
    if (latitude === null || latitude === undefined || longitude === null || longitude === undefined) {
        return false;
    }
    return isFinite(latitude) && Math.abs(latitude) <= 90 && isFinite(longitude) && Math.abs(longitude) <= 180;
};
exports.isCoordinatesValid = isCoordinatesValid;
