import React from 'react';
import styled from 'styled-components';

import { AggregatePropertiesEditor } from './pages/AggregatePropertiesEditor';
import { AggregateGeneralInfoEditor } from './pages/AggregateGeneralInfoEditor';
import { AggregatesMenu } from './components/AggregatesMenu/AggregatesMenu';
import { EventSchemaEditor } from './pages/EventSchemaEditor';
import { EventMapper } from './pages/EventMapper';
import { AggregateMenu } from './components/AggregateMenu/AggregateMenu';
import { CommandEditor } from './pages/CommandEditor/CommandEditor';
import { Route } from 'react-router-dom';
import { DerivedPropertyMapping } from './pages/DerivedPropertyMapping';
import { AggrComments } from './pages/AggrComments';
import { AggregateReOrderEditor } from './pages/AggregateReOrderEditor';

export const Aggregates: React.FC = () => (
  <FlexContainer>
    <Route path="/aggregates/:aggregate?" component={AggregatesMenu} />
    <Route path="/aggregates/:aggregate" component={AggregateMenu} />
    <Route path="/aggregates/:aggregate" component={AggregateGeneralInfoEditor} exact />
    <Route path="/aggregates/:aggregate/properties" component={AggregatePropertiesEditor} />
    <Route
      path="/aggregates/:aggregate/derivedPropertyMapping"
      component={DerivedPropertyMapping}
    />

    <Route path="/aggregates/:aggregate/events/:name" component={AggrComments} exact />

    <Route path="/aggregates/:aggregate/commands/:name" component={AggrComments} exact />

    <Route path="/aggregates/:aggregate/events" component={AggregateReOrderEditor} exact />

    <Route path="/aggregates/:aggregate/commands" component={AggregateReOrderEditor} exact />

    <Route
      path="/aggregates/:aggregate/events/:event/version/:eventVersion/eventSchema"
      component={EventSchemaEditor}
    />
    <Route
      path="/aggregates/:aggregate/events/:event/version/:eventVersion/aggregateMap"
      component={EventMapper}
    />
    <Route
      path="/aggregates/:aggregate/commands/:command/version/:commandVersion"
      component={CommandEditor}
    />
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
