import React, { useContext, useState, useEffect } from 'react';
import { Paper, Divider, Button } from '@material-ui/core';
import styled from 'styled-components';
import { ConfigContext } from '../../../context/ConfigContext';
import { useParams, useLocation, Prompt, useHistory } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { isEqual } from 'lodash';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useFunctionAPI } from '../../../context/fakeAPIHooks/useFunctionAPI';

export const AggrComments: React.FC = () => {
  let internalDescription = '';
  const { config, getAggregate, getFunction } = useContext(ConfigContext);
  const location = useLocation();
  const history = useHistory();
  const isAggrEvent = location.pathname.includes('events');
  const isAggrCommand = location.pathname.includes('commands');
  const isFunction = location.pathname.includes('functions');
  const { aggregate: aggrName, name, function: funcName } = useParams() as {
    aggregate: string;
    name: string;
    function: string;
  };

  const reDirectToAggregates = () => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    history.push(location.pathname.substring(0, lastSlashIndex));
  };

  const aggrIndex = getAggregateIndex(config, aggrName);
  const aggregate = getAggregate(aggrIndex);
  if (isAggrEvent) {
    if (aggregate.events?.[name])
      internalDescription = aggregate.events?.[name].$internalDescription || '';
    else reDirectToAggregates();
  } else if (isAggrCommand) {
    if (aggregate.commands?.[name])
      internalDescription = aggregate.commands?.[name].$internalDescription || '';
    else reDirectToAggregates();
  } else if (isFunction) {
    const func = getFunction(funcName);
    if (func) internalDescription = func.$internalDescription || '';
    else reDirectToAggregates();
  }
  const [comment, setComment] = useState(internalDescription);
  const [editMode, setEditMode] = useState<boolean>(false);
  const AggregateAPI = useAggregateAPI();
  const FunctionAPI = useFunctionAPI();

  useEffect(() => {
    setEditMode(false);
    setComment(internalDescription);
  }, [internalDescription, name]);

  const toggleCommentMode = async () => {
    if (editMode) {
      if (isAggrEvent) {
        const { error } = await AggregateAPI.addEventComment(aggrIndex, name, comment);
        if (error) return;
      } else if (isAggrCommand) {
        const { error } = await AggregateAPI.addCommandComment(aggrIndex, name, comment);
        if (error) return;
      } else if (isFunction) {
        const { error } = await FunctionAPI.addComment(funcName, comment);
        if (error) return;
      }
    }
    setEditMode(!editMode);
  };

  const ifShowMessageBeforeRedirect = () =>
    !isEqual(internalDescription, comment)
      ? 'The form has not been saved, do you want to redirect?'
      : true;

  return (
    <AggrCommentsContainer>
      <HeaderContainer>
        <CardTitle>Internal Description</CardTitle>
        <Button size="small" color="primary" onClick={toggleCommentMode}>
          {editMode ? 'Save' : 'Edit'}
        </Button>
      </HeaderContainer>
      <GroupDivider />
      <CommentsContainer>
        {editMode && (
          <MDEditor
            value={comment}
            //@ts-ignore
            height="auto"
            preview="edit"
            commands={[
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.hr,
              commands.title,
              commands.divider,
              commands.quote,
              commands.code,
              commands.unorderedListCommand,
              commands.orderedListCommand,
              commands.checkedListCommand,
              commands.codeEdit,
              commands.codeLive,
              commands.fullscreen,
            ]}
            onChange={(val?: string): void => setComment(val || '')}
          />
        )}
        {!editMode && <MDEditor.Markdown source={comment} />}
        <Prompt message={ifShowMessageBeforeRedirect} />
      </CommentsContainer>
    </AggrCommentsContainer>
  );
};

const AggrCommentsContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 10px 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
