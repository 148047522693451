import React from 'react';

interface SimpleColumnListProps {
  list?: string[];
}

export const SimpleColumnList: React.FC<SimpleColumnListProps> = ({ list }) => {
  if (!list) return null;
  return (
    <>
      {list.map((item, i) => (
        <div>
          {item}
          {i !== list.length - 1 && ','}
        </div>
      ))}
    </>
  );
};
