import React from 'react';
import {
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  FormHelperText,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { ReactComponent as RightClickIcon } from '../../resources/images/icon-right-click.svg';
import { ReactComponent as LeftClickIcon } from '../../resources/images/icon-left-click.svg';

interface Props {
  onClose: () => void;
}

export const MapperShortCutInfoDialog: React.FC<Props> = ({ onClose }) => {
  return (
    <>
      <DialogTitle>Keyboard Shortcuts</DialogTitle>

      <DialogContent>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Select</Typography>
            <FormHelperText>Left click on node or link </FormHelperText>
          </LeftContainer>

          <StyledLeftClickIcon />
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Show Menu</Typography>
            <FormHelperText>Right click on node or link </FormHelperText>
          </LeftContainer>
          <StyledRightClickIcon />
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Multi-select</Typography>
            <FormHelperText>Shift + left drag on background</FormHelperText>
          </LeftContainer>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TextIconContainer>
              <p>shift</p>
            </TextIconContainer>
            <PlusSeperator> + </PlusSeperator>
            <StyledLeftClickIcon />
            <PlusSeperator> + </PlusSeperator>
            <TextIconContainer>
              <p>drag</p>
            </TextIconContainer>
          </div>
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Add to selection</Typography>
            <FormHelperText>Shift + left click on node or link</FormHelperText>
          </LeftContainer>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TextIconContainer>
              <p>shift</p>
            </TextIconContainer>
            <PlusSeperator> + </PlusSeperator>
            <StyledLeftClickIcon />
          </div>
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Delete link </Typography>
            <FormHelperText>Right click on link(line) </FormHelperText>
          </LeftContainer>
          <StyledRightClickIcon />
        </ShortcutElement>
        <ShortcutElement>
          <LeftContainer>
            <Typography variant="subtitle1">Delete selection set</Typography>
            <FormHelperText>Shift + delete with node(s) or link(s) selected</FormHelperText>
          </LeftContainer>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TextIconContainer>
              <p>shift</p>
            </TextIconContainer>
            <PlusSeperator> + </PlusSeperator>
            <TextIconContainer>
              <p>delete</p>
            </TextIconContainer>
          </div>
        </ShortcutElement>
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          CLOSE
        </Button>
      </DialogActions>
    </>
  );
};

const ShortcutElement = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledRightClickIcon = styled(RightClickIcon)`
  height: 30px;
  width: 30px;
  padding: 10px;
  background-color: #1e88e5;
`;
const StyledLeftClickIcon = styled(LeftClickIcon)`
  height: 30px;
  width: 30px;
  padding: 10px;
  background-color: #1e88e5;
`;
const PlusSeperator = styled.span`
  position: relative;
  font-size: large;
  color: #adaaaa;
  margin-left: 2px;
  margin-right: 2px;
`;

const TextIconContainer = styled.span`
  height: 50px;
  width: 50px;
  background-color: #1e88e5;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    font-size: 13px;
    bottom: 3px;
  }
`;

const LeftContainer = styled.div`
  & p {
    margin-top: 0px;
  }
`;
