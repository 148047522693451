import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
interface TitleToolTipProps {
  title: string;
  tooltipText: string | React.ReactElement;
  minWidth?: number | null;
}

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#333333',
    color: 'white',
    fontSize: 13,
  },
  arrow: {
    color: '#333333',
  },
}))(Tooltip);

export const TitleToolTip: React.FC<TitleToolTipProps> = ({ title, tooltipText, minWidth }) => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem' }}
    >
      <span style={{ whiteSpace: 'nowrap' }}>{title}</span>
      <DarkTooltip title={tooltipText} arrow>
        <HelpOutlineIcon style={{ fontSize: 17, verticalAlign: 'middle', position: 'relative' }} />
      </DarkTooltip>
    </div>
  );
};
