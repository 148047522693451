import React, { useState } from 'react';
import { colors } from '../../utils/colors';

interface ColorPickerProps {
  title?: string;
  value: string;
  onChange?: (value: string) => void;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  summaryBox?: string;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  title,
  value,
  onChange,
  inputStyle,
  labelStyle,
  containerStyle,
  disabled,
  summaryBox,
}) => {
  const [color, setColor] = useState(value);

  return (
    <React.Fragment>
      <div style={{ ...{ display: 'flex', alignItems: 'center' }, ...containerStyle }}>
        {title && (
          <p
            style={{
              ...{ fontSize: 14, color: colors.grey, display: 'inline', marginRight: 10 },
              ...labelStyle,
            }}
          >
            {title}:
          </p>
        )}
        <input
          style={{ ...inputStyle }}
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          onBlur={onChange && (() => onChange(color))}
          disabled={disabled}
        />
      </div>
      {summaryBox && (
        <p style={{ color: colors.grey, fontSize: '12px', margin: '5px 0 0' }}>{summaryBox}</p>
      )}
    </React.Fragment>
  );
};
