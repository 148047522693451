import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { TextInput } from '../../../../../components/FormElements';
import DeleteIcon from '@material-ui/icons/Delete';
import cloneDeep from 'lodash/cloneDeep';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import {
  AttributeBasedFilterSimpleOptionArrayType,
  AttributeBasedFilterSimpleOptionType,
} from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { AttributeBasedTextInput } from './AttributeBasedTextInput';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ValuesIcon from '@material-ui/icons/DynamicFeed';

interface ArrayOptionInputProps {
  value: AttributeBasedFilterSimpleOptionArrayType | undefined;
  onChange: (value: AttributeBasedFilterSimpleOptionArrayType | undefined) => void;
  onBlur: () => void;
  allowValue?: boolean;
}

export const ArrayOptionInput: React.FC<ArrayOptionInputProps> = ({ value, onChange, onBlur, allowValue }) => {
  const [tempValue, setTempValue] = useState<AttributeBasedFilterSimpleOptionArrayType | undefined>(
    value === '$$_GROUPS_$$' || value === '$$_ROLES_$$' || value === '$$_VALUES_$$' ? undefined : value
  );
  const handleAddValidOptionsEnum = () => {
    if (isArray(value)) {
      const copiedValue = cloneDeep(value);
      copiedValue.push('');
      onChange(copiedValue);
    }
  };

  const handleValidOptionsEnumChange = (val: string, index: number) => {
    if (isArray(value)) {
      const copiedValue = cloneDeep(value);
      copiedValue[index] = val;
      onChange(copiedValue);
    }
  };

  const handleDeleteValidOptionsEnum = (index: number) => {
    if (isArray(value)) {
      const copiedValue = cloneDeep(value);
      copiedValue.splice(index, 1);
      onChange(copiedValue);
    }
  };

  const handleToggleRoles = () => {
    if (value === '$$_ROLES_$$') {
      onChange(tempValue);
    } else {
      if (typeof value !== 'string') setTempValue(value);
      onChange('$$_ROLES_$$');
    }
  };
  const handleToggleValues = () => {
    if (value === '$$_VALUES_$$') {
      onChange(tempValue);
    } else {
      if (typeof value !== 'string') setTempValue(value);
      onChange('$$_VALUES_$$');
    }
  };

  const isArray = (
    value: AttributeBasedFilterSimpleOptionArrayType | undefined
  ): value is AttributeBasedFilterSimpleOptionType[] => Array.isArray(value);

  return (
    <ValidOptionsContainer>
      <InputContainer>
        {isArray(value) ? (
          value.map((item: AttributeBasedFilterSimpleOptionType, index) => (
            <InputRow key={index}>
              <TextInputContainer>
                <AttributeBasedTextInput
                  value={item as string}
                  onChange={(value) => handleValidOptionsEnumChange((value as string) || '', index)}
                  onBlur={onBlur}
                  id={item as string}
                  allowValue={allowValue}
                />
              </TextInputContainer>
              {value.length > 1 && (
                <IconButton
                  title="Delete"
                  style={{ color: 'black', margin: '4px 0' }}
                  component="span"
                  onClick={() => handleDeleteValidOptionsEnum(index)}
                  onBlur={onBlur}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </InputRow>
          ))
        ) : (
          // TODO: fix ID
          <TextInput id="id" value={value as string} noLabel style={{ margin: 0 }} disabled />
        )}
      </InputContainer>
      {value !== '$$_ROLES_$$' && value !== '$$_GROUPS_$$' && value !== '$$_VALUES_$$' && (
        <IconButton
          title="Add"
          component="span"
          onClick={handleAddValidOptionsEnum}
          style={{ color: 'black', marginLeft: isArray(value) && value.length === 1 ? 8 : 0 }}
          onBlur={onBlur}
        >
          <AddIcon />
        </IconButton>
      )}
      {/* <IconButton
        title="Groups"
        component="span"
        onClick={handleToggleGroups}
        style={{
          color: value === '$$_GROUPS_$$' ? 'rgb(66, 165, 245)' : 'black',
        }}
        onBlur={onBlur}
      >
        <GroupIcon />
      </IconButton> */}
      <IconButton
        title="Roles"
        component="span"
        onClick={handleToggleRoles}
        style={{
          color: value === '$$_ROLES_$$' ? 'rgb(66, 165, 245)' : 'black',
        }}
        onBlur={onBlur}
      >
        <PermContactCalendarIcon />
      </IconButton>
      {allowValue && <IconButton
        title="Values"
        component="span"
        onClick={handleToggleValues}
        style={{
          color: value === '$$_VALUES_$$' ? 'rgb(66, 165, 245)' : 'black',
        }}
        onBlur={onBlur}
      >
        <ValuesIcon />
      </IconButton>}
    </ValidOptionsContainer>
  );
};

const TextInputContainer = styled.div`
  margin: 4px 8px 4px 0;
  flex: 1;
`;

const InputRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ValidOptionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
