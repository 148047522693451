import { Button } from '@material-ui/core';
import { V2FormTemplate } from '@terragotech/form-renderer';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import _ from 'lodash';
import { cloneDeep } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormContextProvider } from '../../../../../components/FormDialog/contexts/FormContext';
import { MappingActiveDisplay } from '../../../../../components/MappingActiveDisplay';
import DataMapperDialog from '../../../../../components/FormDialog/DataMapperDialog';
import { InputGroup, MapperList } from '../../../../../components/FormElements';
import { useConfig } from '../../../../../context/ConfigContext';
import { metadataSchema } from '../../../../../utils/jsonPartsGenerators';
import { getAggregateIndex } from '../../../../../utils/navigationUtils';
import { CommandFormEditor } from '../../../components/CommandFormEditor/CommandFormEditor';
import { propertiesToSchema } from '../../../utils/PropertiesToSchemaConverter';
import { convertV2FormTemplateToJsonSchema } from '../../../utils/V2FormTemplateToJsonSchema';

interface FormCommandEditorProps {
  formDefinition: V2FormTemplate;
  isImportCommand?: boolean;
  setFormDefinition: (formDefinition: V2FormTemplate) => void;
}
export const FormCommandEditor: React.FC<FormCommandEditorProps> = ({
  formDefinition,
  isImportCommand,
  setFormDefinition,
}) => {
  const { config } = useConfig();
  const confirm = useConfirm();

  const { aggregate: aggrName } = useParams() as {
    aggregate: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);

  const [initialDataMapOpen, setInitialDataMapOpen] = useState(false);

  const stateSchema = propertiesToSchema(config?.aggregates?.[aggrIndex].properties);
  const formSchema = convertV2FormTemplateToJsonSchema(formDefinition);

  const handleSetErrorMaps = (data: NodeMapDefinition[]) => {
    const formDefinitionClone = cloneDeep(formDefinition);
    if (formDefinitionClone.hasOwnProperty('errorMaps') && !data) {
      delete formDefinitionClone.errorMaps;
    } else {
      formDefinitionClone.errorMaps = data;
    }
    setFormDefinition(formDefinitionClone);
  };

  const handleSetWarningMaps = (data: NodeMapDefinition[]) => {
    const formDefinitionClone = cloneDeep(formDefinition);
    if (formDefinitionClone.hasOwnProperty('warningMaps') && !data) {
      delete formDefinitionClone.warningMaps;
    } else {
      formDefinitionClone.warningMaps = data;
    }
    setFormDefinition(formDefinitionClone);
  };

  const handleClearInitialDataMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    handleSetInitialDataMap(undefined);
  };

  const handleSetInitialDataMap = (data: NodeMapDefinition | undefined) => {
    const formDefinitionClone = cloneDeep(formDefinition);
    formDefinitionClone.initialDataMap = data;
    setFormDefinition(formDefinitionClone);
  };

  const doesInitialDataMapHaveValue = useCallback(() => {
    return !_.isEmpty(formDefinition.initialDataMap);
  }, [formDefinition.initialDataMap]);

  return (
    <>
      <FormContextProvider formDefinition={formDefinition}>
        <InputGroup title="Initial Data Map" style={{ margin: '10px 0' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button onClick={() => setInitialDataMapOpen(true)}>
              <div>
                Advanced
                <MappingActiveDisplay
                  isActive={doesInitialDataMapHaveValue()}
                  activeLabelText={'active'}
                />
              </div>
            </Button>
            <Button onClick={handleClearInitialDataMapper}>Clear</Button>
          </div>
          <DataMapperDialog
            localSchemaDefinitions={{
              STATE: { schema: stateSchema, schemaLabel: 'Properties' },
              FORM: { schema: formSchema, schemaLabel: 'Form State' },
              METADATA: {
                schema: metadataSchema,
                schemaLabel: 'Metadata',
              },
            }}
            onClose={() => {
              setInitialDataMapOpen(false);
            }}
            open={initialDataMapOpen}
            datamap={formDefinition.initialDataMap}
            setDatamap={(data) => handleSetInitialDataMap(data)}
            mapScenario={'INITIAL_DATA_MAPPING'}
          />
        </InputGroup>
        <MapperList
          localSchemas={{
            STATE: { schema: stateSchema, schemaLabel: 'Properties' },
            FORM: { schema: formSchema, schemaLabel: 'Form State' },
            METADATA: {
              schema: metadataSchema,
              schemaLabel: 'Metadata',
            },
          }}
          title="Error Mapping"
          data={formDefinition.errorMaps || []}
          setData={(data) => handleSetErrorMaps(data as NodeMapDefinition[])}
          style={{ margin: '20px 0' }}
          mapScenario="FORM_LEVEL_ERROR"
        />
        <MapperList
          localSchemas={{
            STATE: { schema: stateSchema, schemaLabel: 'Properties' },
            FORM: { schema: formSchema, schemaLabel: 'Form State' },
            METADATA: {
              schema: metadataSchema,
              schemaLabel: 'Metadata',
            },
          }}
          title="Warning Mapping"
          data={formDefinition.warningMaps || []}
          setData={(data) => handleSetWarningMaps(data as NodeMapDefinition[])}
          style={{ margin: '20px 0' }}
          mapScenario="FORM_LEVEL_WARNING"
        />
        <InputGroup
          title="Form editor"
          style={{
            padding: '10px 0 0 0',
            overflow: 'unset',
            backgroundColor: 'rgb(238,238,238)',
          }}
          titleStyle={{ transform: 'translate(0px, -36px) scale(0.75)', borderRadius: 5 }}
        >
          <CommandFormEditor
            formDefinition={formDefinition}
            isImportCommand={isImportCommand}
            setFormDefinition={(val) => setFormDefinition(val)}
          />
        </InputGroup>
      </FormContextProvider>
    </>
  );
};
