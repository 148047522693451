import React, { useState, useContext } from 'react';
import { DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';
import { SelectInput } from '../FormElements';
import { ConfigContext } from '../../context/ConfigContext';
import { propertiesObjsToArray } from '../../pages/aggregates/utils/propertiesObjsToArray';
import { cloneDeep } from 'lodash';
import { errorMsg } from '../SnackbarUtilsConfigurator';

interface Props {
  onSubmit: (result: number) => void;
  onClose: () => void;
  aggrIndex: number;
  propertyName: string;
}

export const MoveItemToIndexForm: React.FC<Props> = ({
  onSubmit,
  onClose,
  aggrIndex,
  propertyName,
}) => {
  const { config } = useContext(ConfigContext);
  const [position, setPosition] = useState<string>('');

  const getOptions = () => {
    const reStructureddData = propertiesObjsToArray(
      cloneDeep(config?.aggregates?.[aggrIndex].properties) || {}
    );

    const result = reStructureddData.map((element, index) => {
      index = index + 1;
      return `${index} - ${element.label}`;
    });

    return result;
  };

  const getOrderNumber = (position: string) => {
    return parseInt(position.split(' - ')[0]);
  };

  const handleSubmit = () => {
    if (position) {
      onSubmit(getOrderNumber(position) - 1);
    } else {
      errorMsg('No order selected');
    }
  };

  return (
    <>
      <DialogTitle>{`Move property "${propertyName}" to order:`}</DialogTitle>
      <DialogContent style={{ padding: '0 25px' }}>
        <SelectInput
          variant="standard"
          options={getOptions()}
          value={position}
          onChange={setPosition}
        />
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit} autoFocus>
          Move
        </Button>
      </DialogActions>
    </>
  );
};
