import styled from 'styled-components';

export const NodeFrame = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  min-width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  border-radius: 15px;
  background: linear-gradient(225deg, #3a3a3a, #313131);
  box-shadow: -10px 10px 20px #1c1c1c
    ${(props) => props.isSelected && ',2px 2px 2px 2px #00c4ff, -2px -2px 2px 2px #00c4ff'};
`;

export const NodeTitleBox = styled.div`
  width: 100%;
  overflow: visible;
  background-color: #${(props) => props.color || '1e1e1e00'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px 15px 0px 0px;
`;
export const NodeTitle = styled.div`
  width: 100%;
  padding: 12px 25px;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  & > input {
    color: white;
    background-color: #383838;
    font-size: 16px;
  }
`;
export const NodeDescriptionBox = styled.div`
  width: 100%;
  overflow: visible;
  background-color: #${(props) => props.color || '1e1e1e00'};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NodeDescription = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 0px 25px 12px 25px;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  & > input {
    color: white;
    background-color: #383838;
    font-size: 12px;
  }
`;
export const NodeCollapseArea = styled.div`
  flex: 1 0 auto;
  cursor: pointer;
  width: 20px;
  color: white;
`;
