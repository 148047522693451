import {
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  Paper,
  Switch,
} from '@material-ui/core';
import { AggregateConfig, LocationProperty } from '@terragotech/gen5-config-lib';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useConfig } from '../../../context/ConfigContext';
import {
  CheckboxInput,
  InputGroup,
  SelectInput,
  TextInput,
} from '../../../components/FormElements';
import { errorMsg, successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { cloneDeep, isEqual } from 'lodash';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import QueryFilterEditorDialog from '../../../components/FormDialog/QueryFilterEditorDialog';
import { AttributeBasedFilter } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import { propertiesObjsToArray } from '../utils/propertiesObjsToArray';

export const AggregateGeneralInfoEditor: React.FC = () => {
  const { config } = useConfig();
  const history = useHistory();
  const AggregateAPI = useAggregateAPI();
  const { aggregate: aggrName } = useParams() as { aggregate: string };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const [aggrInfo, setAggrInfo] = useState<Partial<AggregateConfig>>(config.aggregates[aggrIndex]);
  const [queryFilterEditorDialogOpen, setQueryFilterEditorDialog] = useState(false);
  const [publicQueryFilterEditorDialogOpen, setPublicQueryFilterEditorDialog] = useState(false);
  const [primaryLocationType, setPrimaryLocationType] = useState<'GEOGRAPHY' | 'LATLON'>(
    getPrimaryLocationType(aggrInfo.primaryLocationProperty)
  );

  useEffect(() => {
    setPrimaryLocationType(getPrimaryLocationType(aggrInfo.primaryLocationProperty));
  }, [aggrInfo.primaryLocationProperty]);

  const [showMeasurement, setShowMeasurement] = useState<boolean | undefined>(
    typeof config.aggregates[aggrIndex].showMeasurementOnMap === 'undefined' ||
      config.aggregates[aggrIndex].showMeasurementOnMap === null
      ? true
      : config.aggregates[aggrIndex].showMeasurementOnMap
  );

  useEffect(() => {
    setAggrInfo(config.aggregates[aggrIndex]);
    setShowMeasurement(
      typeof config.aggregates[aggrIndex].showMeasurementOnMap === 'undefined' ||
        config.aggregates[aggrIndex].showMeasurementOnMap === null
        ? true
        : config.aggregates[aggrIndex].showMeasurementOnMap
    );
  }, [aggrIndex, config]);

  const handleObjectStoreChange = (node: string, value: string | boolean | undefined) => {
    if (aggrInfo.objectStore)
      setAggrInfo({
        ...aggrInfo,
        objectStore: { ...aggrInfo.objectStore, [node]: value },
      });
  };

  const ifShowMessageBeforeRedirect = () =>
    !isEqual(aggrInfo, config.aggregates[aggrIndex])
      ? 'The form has not been saved, do you want to redirect?'
      : true;

  const handleSetAttributeBasedAccessRule = (value: AttributeBasedFilter | undefined) =>
    setAggrInfo({ ...aggrInfo, attributeBasedAccessRule: value });

  const handleSetPublicAccessRule = (value: AttributeBasedFilter | undefined) =>
    setAggrInfo({ ...aggrInfo, publicAccessRule: value });

  const getPropertiesArray = (type?: string) => {
    const properties = config.aggregates[aggrIndex].properties;
    if (type) {
      return Object.entries(properties)
        .filter(([_, value]) => {
          return value.type === type;
        })
        .map((item) => item[0]);
    }
    return Object.keys(properties);
  };

  const handlePrimaryLocationChange = (
    value: string,
    type: 'latitude' | 'longitude' | 'geography'
  ) => {
    const aggrInfoCopy = cloneDeep(aggrInfo);
    if (type === 'geography') aggrInfoCopy.primaryLocationProperty = value;
    else {
      if (
        !aggrInfoCopy.primaryLocationProperty ||
        typeof aggrInfoCopy.primaryLocationProperty !== 'object'
      )
        (aggrInfoCopy.primaryLocationProperty as Partial<LocationProperty>) = {};
      (aggrInfoCopy.primaryLocationProperty as Partial<LocationProperty>)[type] = value
        ? value
        : undefined;
    }
    setAggrInfo(aggrInfoCopy);
  };

  const handleMeasurementChange = (value: boolean) => {
    const aggrInfoCopy = cloneDeep(aggrInfo);
    aggrInfoCopy.showMeasurementOnMap = value;
    setShowMeasurement(value);
    setAggrInfo(aggrInfoCopy);
  };

  const save = async () => {
    if (!aggrInfo.typeName) return errorMsg(`Type Name is required`);
    if (!aggrInfo.singularName) return errorMsg(`Singular Name is required`);
    if (!aggrInfo.pluralName) return errorMsg(`Plural name is required`);
    if (!aggrInfo.labelProperty) return errorMsg(`Label Property is required`);
    if (!aggrInfo.objectStore?.type) return errorMsg(`Object Store Type is required`);
    if (!aggrInfo.objectStore?.schema) return errorMsg(`Object Store Schema is required`);
    if (!aggrInfo.objectStore?.table) return errorMsg(`Object Store Table is required`);
    if (!aggrInfo.objectStore?.reducerName)
      return errorMsg(`Object Store Reducer Name is required`);
    if (
      config?.aggregates.some(
        (e: { typeName: string }) => e.typeName.toUpperCase() === aggrInfo?.typeName?.toUpperCase()
      ) &&
      aggrName !== aggrInfo.typeName
    )
      return errorMsg(`An aggregate named "${aggrInfo.typeName}" already exists`);
    if (
      primaryLocationType === 'LATLON' &&
      aggrInfo.primaryLocationProperty &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.latitude &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.longitude
    )
      return errorMsg('Location property for both latitude and longitude are not specified.');
    if (
      primaryLocationType === 'LATLON' &&
      aggrInfo.primaryLocationProperty &&
      (aggrInfo.primaryLocationProperty as LocationProperty)?.latitude &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.longitude
    )
      return errorMsg(
        'Location type longitude is not specified. Set either both longitude and latitude or remove latitude property'
      );
    if (
      primaryLocationType === 'LATLON' &&
      aggrInfo.primaryLocationProperty &&
      (aggrInfo.primaryLocationProperty as LocationProperty)?.longitude &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.latitude
    )
      return errorMsg(
        'Location type latitude is not specified. Set either both longitude and latitude or remove longitude property'
      );
    if (
      primaryLocationType === 'GEOGRAPHY' &&
      aggrInfo.primaryLocationProperty &&
      ((aggrInfo.primaryLocationProperty as LocationProperty)?.longitude ||
        (aggrInfo.primaryLocationProperty as LocationProperty)?.latitude ||
        aggrInfo?.primaryLocationProperty === '')
    ) {
      return errorMsg('Geography aggregate property is not specified. Specify and try again.');
    }
    if (config.aggregates[aggrIndex].typeName !== aggrInfo.typeName) {
      history.push(`/aggregates/${aggrInfo.typeName}`);
    }
    if (
      aggrInfo.primaryLocationProperty === '' ||
      (typeof aggrInfo.primaryLocationProperty === 'object' &&
        !aggrInfo.primaryLocationProperty.latitude &&
        !aggrInfo.primaryLocationProperty.longitude)
    ) {
      const aggrInfoCopy = cloneDeep(aggrInfo);
      aggrInfo.primaryLocationProperty = undefined;
      setAggrInfo(aggrInfoCopy);
    }
    const { error } = await AggregateAPI.replaceAggregate(aggrIndex, aggrInfo as AggregateConfig);
    if (!error) return successMsg('The aggregate has been saved');
  };

  if (aggrIndex === -1) {
    return null;
  }
  return (
    <AggregateGeneralInfoContainer>
      <CardTitle>Settings</CardTitle>
      <GroupDivider />
      <TextInput
        id="typeName"
        value={aggrInfo.typeName}
        onChange={(value) => setAggrInfo({ ...aggrInfo, typeName: value })}
      />
      <TextInput
        id="singularName"
        value={aggrInfo.singularName}
        onChange={(value) => setAggrInfo({ ...aggrInfo, singularName: value })}
      />
      <TextInput
        id="pluralName"
        value={aggrInfo.pluralName}
        onChange={(value) => setAggrInfo({ ...aggrInfo, pluralName: value })}
      />
      <SelectInput
        title="Label Property"
        value={aggrInfo.labelProperty || ''}
        onChange={(value: string) => setAggrInfo({ ...aggrInfo, labelProperty: value })}
        options={getPropertiesArray()}
      />
      <InputGroup title="Advanced" collapsible={true} startCollapsed={true}>
        <InputGroup title="Object Store">
          <CheckboxInput
            title="Rebuild on startup"
            checked={aggrInfo.objectStore?.rebuildOnStartup}
            onChange={(checked: boolean) => handleObjectStoreChange('rebuildOnStartup', checked)}
          />
          <TextInput
            id="table"
            value={aggrInfo.objectStore?.table}
            onChange={(value) => handleObjectStoreChange('table', value)}
          />
          <TextInput
            id="type"
            value={aggrInfo.objectStore?.type}
            onChange={(value) => handleObjectStoreChange('type', value)}
          />
          <TextInput
            id="reducerName"
            value={aggrInfo.objectStore?.reducerName}
            onChange={(value) => handleObjectStoreChange('reducerName', value)}
          />
          <TextInput
            id="schema"
            value={aggrInfo.objectStore?.schema}
            onChange={(value) => handleObjectStoreChange('schema', value)}
          />
        </InputGroup>
        <CheckboxInput
          title="Replay events on startup"
          checked={aggrInfo.replayEventsOnStartup}
          onChange={(checked: boolean) =>
            setAggrInfo({ ...aggrInfo, replayEventsOnStartup: checked || false })
          }
        />
        <CheckboxInput
          title="Exclude In Web UI"
          checked={aggrInfo.excludeInWebUI}
          onChange={(checked: boolean) =>
            setAggrInfo({ ...aggrInfo, excludeInWebUI: checked || undefined })
          }
        />
        <CheckboxInput
          title="Is Public"
          checked={aggrInfo.isPublic}
          onChange={(checked: boolean) =>
            setAggrInfo({ ...aggrInfo, isPublic: checked || undefined })
          }
        />
        {aggrInfo.isPublic && (
          <InputGroup title="Public Access Rules" style={{ margin: '10px 0' }}>
            <Button onClick={() => setPublicQueryFilterEditorDialog(true)}>Advanced</Button>
            {aggrInfo.publicAccessRule && (
              <Button onClick={() => handleSetPublicAccessRule(undefined)}>Clear</Button>
            )}
          </InputGroup>
        )}
        <CheckboxInput
          title="Is Reference Type (Project agnostic)"
          checked={aggrInfo.isReferenceType}
          onChange={(checked: boolean) =>
            setAggrInfo({ ...aggrInfo, isReferenceType: checked || undefined })
          }
        />
      </InputGroup>
      <InputGroup title="Row level permissions" style={{ margin: '10px 0' }}>
        <Button onClick={() => setQueryFilterEditorDialog(true)}>Advanced</Button>
        {aggrInfo.attributeBasedAccessRule && (
          <Button onClick={() => handleSetAttributeBasedAccessRule(undefined)}>Clear</Button>
        )}
      </InputGroup>
      <InputGroup title="Location type" style={{ margin: '10px 0' }}>
        <SelectInput
          key="type"
          title="Type"
          value={primaryLocationType}
          onChange={(value) => setPrimaryLocationType(value as 'LATLON' | 'GEOGRAPHY')}
          options={{ 'Point (Lat/Long)': 'LATLON', 'Polyline (Geography)': 'GEOGRAPHY' }}
        />
        {primaryLocationType === 'LATLON' && (
          <>
            <SelectInput
              title="Latitude"
              value={(aggrInfo.primaryLocationProperty as LocationProperty)?.latitude || ''}
              onChange={(value: string) => handlePrimaryLocationChange(value, 'latitude')}
              options={['', ...getPropertiesArray('Float')]}
            />
            <SelectInput
              title="Longitude"
              value={(aggrInfo.primaryLocationProperty as LocationProperty)?.longitude || ''}
              onChange={(value: string) => handlePrimaryLocationChange(value, 'longitude')}
              options={['', ...getPropertiesArray('Float')]}
            />
          </>
        )}
        {primaryLocationType === 'GEOGRAPHY' && (
          <>
            <SelectInput
              title="Geography aggregate property"
              value={(aggrInfo.primaryLocationProperty as string) || 'Some Property'}
              onChange={(value: string) => handlePrimaryLocationChange(value, 'geography')}
              options={[
                'Some Property',
                ...getPropertiesArray('Geography'),
                ...getPropertiesArray('AnchoredLine'),
              ]}
            />
            <label data-shrink="true" style={{ color: '#848484', fontSize: '13px' }}>
              Select the aggregate property that defines the location for this aggregate
            </label>

            <FormControlLabel
              style={{ marginTop: 5 }}
              control={
                <Switch
                  checked={showMeasurement}
                  onChange={(e: React.ChangeEvent, checked: boolean) =>
                    handleMeasurementChange(checked)
                  }
                  color="primary"
                />
              }
              label="Show measurements on Polylines"
            />
            <FormHelperText>
              Enabling this will show the length of each polyline in the selected unit of
              measurement
            </FormHelperText>
          </>
        )}
      </InputGroup>

      <QueryFilterEditorDialog
        open={Boolean(queryFilterEditorDialogOpen)}
        onClose={() => setQueryFilterEditorDialog(false)}
        filter={aggrInfo.attributeBasedAccessRule}
        setFilter={handleSetAttributeBasedAccessRule}
        keys={propertiesObjsToArray(config.aggregates[aggrIndex].properties)}
        filterName="Row level permissions"
      />
      <QueryFilterEditorDialog
        open={Boolean(publicQueryFilterEditorDialogOpen)}
        onClose={() => setPublicQueryFilterEditorDialog(false)}
        filter={aggrInfo.publicAccessRule}
        setFilter={handleSetPublicAccessRule}
        keys={propertiesObjsToArray(config.aggregates[aggrIndex].properties)}
        filterName="Public Access Rules"
      />
      <ButtonContainer>
        <Button color="primary" variant="contained" onClick={save} style={{ margin: '10px 0' }}>
          Save
        </Button>
      </ButtonContainer>
      <Prompt message={ifShowMessageBeforeRedirect} />
    </AggregateGeneralInfoContainer>
  );
};

const getPrimaryLocationType = (primaryLocationProperty: LocationProperty | string | undefined) =>
  typeof primaryLocationProperty === 'object' && 'latitude' in primaryLocationProperty
    ? 'LATLON'
    : 'GEOGRAPHY';

const AggregateGeneralInfoContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  overflow-x: auto;
  padding: 10px 20px;
  max-width: 600px;
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;
