import ReactDOMServer from 'react-dom/server';
import Circle from './Circle';
import React from 'react';

const MAX_CACHE = 128;
const imageCache: Record<string, string> = {}; // caches a value to an image

export const getSvgComponent = ({
  symbol,
  primaryColor,
  alphaNumericCharacter,
  alphaScale,
}: {
  symbol: string;
  primaryColor: string;
  alphaNumericCharacter?: string;
  alphaScale?: number;
}) => {
  switch (symbol) {
    case 'circle':
    case 'nodeonly':
    case 'node':
      return (
        <Circle
          primaryColor={primaryColor}
          alphaNumericCharacter={alphaNumericCharacter}
          alphaNumericScale={alphaScale}
        />
      );
    default:
      console.log(symbol);
      return <Circle primaryColor={'FFFFFF'} />;
  }
  // switch (symbol) {
  //   case 'node':
  //     return <Circle primaryColor={primaryColor} alphaNumericCharacter={alphaNumericCharacter} />;
  //   case 'blackbracket':
  //     return <Circle primaryColor={primaryColor} alphaNumericCharacter={alphaNumericCharacter} />;
  //   case 'flood':
  //     return <Circle primaryColor="FFFFFF" alphaNumericCharacter={alphaNumericCharacter} />;
  //   case 'outofscope':
  //     return <Circle primaryColor={primaryColor} alphaNumericCharacter={alphaNumericCharacter} />;
  //   case 'nodeonly':
  //     return (
  //       <Circle
  //         primaryColor={primaryColor}
  //         alphaNumericCharacter={alphaNumericCharacter}
  //         alphaNumericScale={alphaScale}
  //       />
  //     );
  //   case 'pin':
  //     return <Circle primaryColor={primaryColor} alphaNumericCharacter={alphaNumericCharacter} />;
  //   default:
  //     console.log(symbol);
  //     return <Circle primaryColor={'FFFFFF'} />;
  // }
};

export const getSvgComponentFromSymbolKey = (id: string) => {
  // use a styled component if we have a symbolKey, otherwise use a generic
  if (id && id.startsWith('symbol')) {
    const [, symbol, color, alphaNumericCharacter, alphaScale] = id.split('_');
    return getSvgComponent({
      primaryColor: color,
      symbol: symbol,
      alphaNumericCharacter: alphaNumericCharacter,
      alphaScale: alphaScale ? Number.parseFloat(alphaScale) : undefined,
    });
  } else {
    return getSvgComponent({
      primaryColor: '#AAAAAA',
      symbol: 'node',
      alphaNumericCharacter: '\uf128',
    });
  }
};

export const getSvgImageString = (symbolKey: string) => {
  if (imageCache[symbolKey]) {
    return imageCache[symbolKey];
  }
  let renderString = '';
  const component = getSvgComponentFromSymbolKey(symbolKey);
  if (component) {
    renderString = ReactDOMServer.renderToString(component);
  }
  function b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode(parseInt('0x' + p1, 16));
      })
    );
  }
  const testImg = 'data:image/svg+xml;Base64,' + b64EncodeUnicode(renderString);
  if (Object.keys(imageCache).length < MAX_CACHE) {
    imageCache[symbolKey] = testImg;
  }
  return testImg;
};
