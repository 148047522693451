import React from 'react';

export interface TinyDropdownProps {
  value: string | null | undefined;
  def: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  style?: React.CSSProperties;
  options: Array<{ optionLabel: string; optionValue: string | number }>;
}

export const TinyDropdown: React.FC<TinyDropdownProps> = ({
  value,
  onChange,
  style,
  options,
  def,
}) => {
  return (
    <select
      style={{ ...generalTinyDropdownStyle, ...style }}
      value={(value === 'undefined' || !value ? def : value) as string}
      onChange={(e) => onChange(e.target.value === '' ? undefined : e.target.value)}
    >
      {def ? undefined : <option value="">undefined</option>}
      {options.map((opt, index) => {
        return (
          <option value={opt.optionValue} key={index}>
            {opt.optionLabel}
          </option>
        );
      })}
    </select>
  );
};

const generalTinyDropdownStyle = {
  fontSize: 12,
  height: 16,
  color: 'black',
  border: 'none',
  borderRadius: 5,
  padding: '2px 5px',
  width: '100%',
  boxShadow: 'inset 1px 1px 3px 0 #424242',
  backgroundColor: 'white',
};
