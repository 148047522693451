import * as React from 'react';
import { FA5Font } from './FA5Font';

interface SymbolProps {
  primaryColor: string;
  secondaryColor?: string;
  alphaNumericCharacter?: string;
  alphaNumericScale?: number;
  selected?: boolean;
}

const Circle: React.FunctionComponent<SymbolProps> = (props) => {
  const {
    primaryColor,
    secondaryColor,
    selected,
    alphaNumericCharacter,
    alphaNumericScale,
  } = props;
  if (secondaryColor) {
    console.warn('The chosen symbol does not support a secondary color');
  }

  const dimension = 20;
  const center = dimension / 2;
  const radius = dimension / 3;
  const selectedRadius = 0.47 * dimension;
  const textScale = 0.4 * (alphaNumericScale || 1);

  return (
    <svg
      width={dimension}
      height={dimension}
      viewBox={`0 0 ${dimension} ${dimension}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style type="text/css">{FA5Font}</style>
      </defs>
      <g>
        {selected && (
          <ellipse
            cx={center}
            cy={center}
            rx={selectedRadius}
            ry={selectedRadius}
            fill={`#${primaryColor}`}
            stroke={'#F8F8F8'}
            strokeWidth={(1 / 40) * dimension}
          />
        )}
        <ellipse
          cx={center}
          cy={center}
          rx={alphaNumericCharacter ? radius : (2 / 3) * radius}
          ry={alphaNumericCharacter ? radius : (2 / 3) * radius}
          fill={alphaNumericCharacter ? '#fff' : `#${primaryColor}`}
          stroke={alphaNumericCharacter ? `#${primaryColor}` : '#fff'}
          strokeWidth={(1 / 16) * dimension}
        />
        {alphaNumericCharacter && (
          <text
            x={center}
            y={center + 0.5}
            textAnchor="middle"
            alignmentBaseline="middle"
            dominantBaseline="middle"
            fill={`#${primaryColor}`}
            fontSize={`${textScale * dimension}`}
            fontFamily='"Font Awesome 6 Pro"'
            // FA5 icons are mostly much higher in unicode char than 10,000
            fontWeight={alphaNumericCharacter.charCodeAt(0) > 10000 ? 900 : 400}
          >
            {alphaNumericCharacter}
          </text>
        )}
      </g>
    </svg>
  );
};
export default Circle;
