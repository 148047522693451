import React from 'react';
import { TextField } from '@material-ui/core';
import { Variant } from './index';

interface TextInputProps {
  id: string;
  value: string | number | undefined;
  onChange?: (value: string | undefined) => void;
  onBlur?: (value: string | undefined) => void;
  label?: string;
  style?: React.CSSProperties;
  inputProps?: object;
  inputLabelProps?: object;
  variant?: Variant;
  fullWidth?: boolean;
  autoFocus?: boolean;
  type?: 'text' | 'number';
  noLabel?: boolean;
  disabled?: boolean;
  isInt?: boolean;
  error?: boolean;
  multiline?: boolean;
  helperText?: string;
  rows?: number;
}

export const camelCaseToSpaceSeparated = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, (match: string) => ` ${match}`)
    .replace(/^./, (match: string) => match.toUpperCase());

export const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  style,
  inputProps,
  inputLabelProps,
  variant,
  fullWidth,
  autoFocus,
  type,
  noLabel,
  disabled,
  isInt,
  error,
  helperText,
  multiline,
  rows,
  ...props
}) => (
  <TextField
    margin="dense"
    id={id}
    label={noLabel ? '' : label || camelCaseToSpaceSeparated(id)}
    type={type || 'text'}
    error={error || false}
    helperText={helperText || ''}
    fullWidth={fullWidth ?? true}
    variant={variant || 'outlined'}
    style={{ ...textFieldStyle, ...style }}
    inputProps={{ ...inputProps, ...inputStyle }}
    InputLabelProps={{ ...inputLabelProps, ...inputStyle, ...{ height: 40 } }}
    value={value || ''}
    onChange={(e) => onChange && onChange(e.target.value ? e.target.value : undefined)}
    onBlur={(e) => onBlur && onBlur(e.target.value ? e.target.value : undefined)}
    autoFocus={autoFocus}
    disabled={disabled}
    multiline={multiline}
    rows={rows}
    {...props}
  />
);

const inputStyle = {
  style: { fontSize: 14, height: 19 },
};
const textFieldStyle = { margin: '10px 0' };
