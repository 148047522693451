import React from 'react';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { colors } from '../../utils/colors';

interface CheckboxInputProps {
  title: string;
  checked: boolean | undefined;
  onChange: (value: boolean) => void;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  title,
  checked,
  onChange,
  labelStyle,
  style,
}) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          name="rebuildOnStartup"
          checked={checked || false}
          onChange={(e) => onChange(e.target.checked)}
          color="primary"
        />
      }
      label={
        <Typography style={{ fontSize: 14, color: colors.grey, ...labelStyle }}>{title}</Typography>
      }
      style={{ ...style }}
    />
  </div>
);
