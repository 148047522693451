import React, { useState, useContext, useCallback, useRef } from 'react';
import { DialogTitle, DialogActions, Button } from '@material-ui/core';
import { TextInput, InputGroup, CheckboxInput } from '../FormElements';
import { V2GroupComponentDef } from '@terragotech/page-renderer';
import DataMapperDialog from './DataMapperDialog';
import { useConfirm } from 'material-ui-confirm';
import { isEqual } from 'lodash';
import { usePageSchemas } from './hooks/usePageSchemas';
import { PageContext } from './contexts/PageContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import _ from 'lodash';
import { CustomPageEditor, CustomPageEditorRef } from 'pages/customPages/pages/CustomPageEditor';
import { PageDialogServiceProvider } from './PageDialogService';

export type GroupComponentDefWithName = V2GroupComponentDef & {
  name: string;
};

interface GroupEditFormFormProps {
  onSubmit: (result: GroupComponentDefWithName) => void;
  onClose: () => void;
  component: GroupComponentDefWithName;
}

export const GroupEditForm: React.FC<GroupEditFormFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const confirm = useConfirm();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState<string>(component.label);
  const [repeats, setRepeats] = useState(
    component.repeats
  );
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);

  const pageSchemas = usePageSchemas();

  const customPageEditorRef = useRef<CustomPageEditorRef>();

  const [existingNameError, setExistingNameError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const nameHelperTxt =
    'Duplicate name error. The name must be unique accross all workflow elements';

  const handleClearDisplayMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setDisplayOptions(undefined);
  };

  const getFormValues = () => ({
    template: component.template,
    type: component.type,
    name: name || '',
    label: label || '',
    ...(displayOptions && { displayOptions }),
    repeats,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The page has not been saved, do you want to redirect?');

  const handleClose = async () => {
    if (customPageEditorRef.current) {
      let result = await customPageEditorRef.current.handleCancelChanges();
      return result.success && onClose();
    }

    return (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();
  }
  const handleSubmit = async () => {
    if (customPageEditorRef.current) {
      let result = await customPageEditorRef.current.save();
      if (!result.success)
        return;
    }

    if (checkDuplicatePageName(name, component, pageDefinition.elements)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(displayOptions);
  }, [displayOptions]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <TextInput
        autoFocus
        id="Name"
        error={existingNameError}
        helperText={existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      {/* Disabling for now, until Repeatable rendering is implemented. */}
      <CheckboxInput
        title="Repeatable?"
        checked={repeats}
        onChange={(value) => setRepeats(value)}
        style={{ margin: '0 25px 10px' }}
      />
      <InputGroup title="Conditionals" style={{ margin: '10px 25px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button onClick={() => setConditionalOpen(true)}>
            <div>
              Advanced
              <MappingActiveDisplay
                isActive={doesConditionalHaveValue()}
                activeLabelText={'active'}
              />
            </div>
          </Button>
          <Button onClick={handleClearDisplayMapper}>Clear</Button>
        </div>
        <DataMapperDialog
          mapScenario="DISPLAY_OPTIONS"
          localSchemaDefinitions={pageSchemas}
          onClose={() => {
            setConditionalOpen(false);
          }}
          open={conditionalOpen}
          datamap={displayOptions}
          setDatamap={setDisplayOptions}
        />
      </InputGroup>
      <PageDialogServiceProvider>
        <CustomPageEditor
          ref={customPageEditorRef}
          editPage={component.template}
          aggType={pageDefinition.aggregateType}
          fullPage={pageDefinition}
        />
      </PageDialogServiceProvider>
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
