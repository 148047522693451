import React, { useContext, useState } from 'react';
import { Divider, Paper, Button } from '@material-ui/core';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ConfigContext } from '../../../context/ConfigContext';
import TextField from '@material-ui/core/TextField';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';

export const LegalDisclaimer: React.FC = () => {
  const { setLegalDisclaimer, getLegalDisclaimer } = useContext(ConfigContext);
  const [disclaimerSwitch, setDisclaimerSwitch] = useState<boolean>(
    getLegalDisclaimer() ? true : false
  );
  const [disclaimerTxt, setDisclaimerTxt] = useState(getLegalDisclaimer());

  const onSave = (): void => {
    disclaimerSwitch ? setLegalDisclaimer(disclaimerTxt) : setLegalDisclaimer('');
    successMsg('Legal Disclaimer has been saved successfully');
  };

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisclaimerSwitch(!disclaimerSwitch);
  };

  return (
    <LegalDisclaimerContainer>
      <HeaderContainer>
        <Typography variant="h6">Legal Disclaimer</Typography>
        <Button variant="contained" size="small" color="primary" onClick={onSave}>
          Save
        </Button>
      </HeaderContainer>
      <GroupDivider />
      <FormContainer>
        <FormControlLabel
          control={<Switch checked={disclaimerSwitch} onChange={onSwitchChange} color="primary" />}
          label="Enable Legal Disclaimer"
        />
        {disclaimerSwitch && (
          <TextField
            id="disclaimerTxt"
            style={{ marginTop: '20px' }}
            variant="outlined"
            label="Disclaimer Text"
            multiline
            margin="dense"
            value={disclaimerTxt}
            onChange={(e) => setDisclaimerTxt(e?.target.value)}
          />
        )}
        <Typography variant="caption">
          Provide the legal disclaimer text that the user will be required to accept before using
          this app
        </Typography>
      </FormContainer>
    </LegalDisclaimerContainer>
  );
};

const LegalDisclaimerContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  overflow-x: auto;
  padding: 10px 20px;
  max-width: 1200px;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
