import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Conjunctions, ConjunctionConst } from '../defaults/defaultConjunctions';

interface ToggleSelectorProps {
  option: ConjunctionConst;
  handleOnChange: (conjunction: ConjunctionConst) => void;
  options: Conjunctions;
}

const ToggleSelector: React.FC<ToggleSelectorProps> = ({ option, handleOnChange, options }) => (
  <ToggleButtonGroup
    style={{ background: 'white' }}
    value={option}
    exclusive
    onChange={(_, option) => {
      handleOnChange(option);
    }}
  >
    {options.map((option) => (
      <ToggleButton key={`key-${option.name}`} value={option.name}>
        {option.label}
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

export default ToggleSelector;
