import React from 'react';
import { SelectInput } from '../../../../../components/FormElements/SelectInput';

type Options = Array<{ name: string }>;

interface OptionSelector<T> {
  option: T;
  handleOnChange: (key: T) => void;
  options: Options;
  style?: React.CSSProperties;
}

const OptionSelector = <T extends string>({
  handleOnChange,
  options,
  option,
  style,
}: OptionSelector<T>) => {
  const getOptions = () => options.map((option) => option.name);
  return (
    <SelectInput
      value={option}
      onChange={handleOnChange}
      options={getOptions()}
      fullWidth={false}
      style={{ ...{ marginRight: 10, background: 'white', width: 120 }, ...style }}
      containerStyle={{ margin: 0 }}
    />
  );
};

export default OptionSelector;
