import React, { useState } from 'react';
import { DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';
import { TextInput } from '../FormElements';
import { errorMsg } from '../SnackbarUtilsConfigurator';

interface Props {
  onSubmit: (result: string) => void;
  onClose: () => void;
  title: string;
}

export const SingleTextInputForm: React.FC<Props> = ({ onSubmit, onClose, title }) => {
  const [value, setValue] = useState<string | undefined>(undefined);
  const handleOnSubmit = () => {
    if (!value) return errorMsg('Text input is required');
    onSubmit(value);
  };

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ padding: '0 25px' }}>
        <TextInput
          id="singleTextInput"
          value={value}
          variant="standard"
          onChange={(value) => setValue(value)}
          style={{ margin: 0 }}
        />
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleOnSubmit} autoFocus>
          Create
        </Button>
      </DialogActions>
    </>
  );
};
