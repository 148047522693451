import React, { useContext, useRef } from 'react';
import { Paper } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styled from 'styled-components';
import { ConfigContext } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';

import { MaterialTableRef } from '../../../components/EditableTable';
import { useParams, useLocation } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import MDEditor from '@uiw/react-md-editor';
import MaterialTable from 'material-table';

export const AggregateReOrderEditor: React.FC = () => {
  interface AggrNodeRow {
    name: string;
    order: number;
    comment: string;
  }
  const { config, getAggregate } = useContext(ConfigContext);
  const AggregateAPI = useAggregateAPI();

  const location = useLocation();
  const isAggrEvent = location.pathname.includes('events');

  const { aggregate: aggrName } = useParams() as { aggregate: string };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const aggregate = getAggregate(aggrIndex);
  const columns = [
    { title: 'Name', field: 'name' },
    { title: 'Order', field: 'order' },
    {
      title: 'Internal Description',
      field: 'comment',
      render: (rowData: AggrNodeRow) => <MDEditor.Markdown source={rowData.comment} />,
    },
  ];

  const options = {
    search: false,
    sorting: false,
    draggable: false,
  };
  const allRecords = (records: object[]) =>
    records?.length > 20 ? [5, 10, 20, records.length] : [5, 10, 20];

  const tableRef = useRef<MaterialTableRef>(null);

  const convertObjsToArray = () => {
    const arr = [];
    let counter = 1;
    const obj = isAggrEvent ? aggregate.events : aggregate.commands;
    if (obj) {
      for (const [key, value] of Object.entries(obj as object)) {
        arr.push({
          name: key,
          order: counter++,
          ...(value.$internalDescription && { comment: value.$internalDescription }),
        });
      }
    }
    return arr;
  };

  const tableData = convertObjsToArray();

  const moveRow = async (rowName: string, moveType: 'UP' | 'DOWN', targetIndex?: number) => {
    if (isAggrEvent) {
      const { error } = await AggregateAPI.moveAggrEvents(
        aggrIndex,
        rowName,
        moveType,
        aggregate.events
      );
      if (error) return;
    } else {
      const { error } = await AggregateAPI.moveAggrCommands(
        aggrIndex,
        rowName,
        moveType,
        aggregate.commands
      );
      if (error) return;
    }

    if (moveType === 'UP') successMsg(`Property "${rowName}" has been moved up`);
    if (moveType === 'DOWN') successMsg(`Property "${rowName}" has been moved down`);
  };

  const additionalActions = [
    {
      icon: () => <KeyboardArrowUpIcon />,
      tooltip: 'Move up',
      onClick: (_: object, rowData: object) => moveRow((rowData as AggrNodeRow).name, 'UP'),
    },
    {
      icon: () => <KeyboardArrowDownIcon />,
      tooltip: 'Move down',
      onClick: (_: object, rowData: object) => moveRow((rowData as AggrNodeRow).name, 'DOWN'),
    },
  ];

  return (
    <PropertiesContainer>
      <MaterialTable
        title={isAggrEvent ? 'Events' : 'Commands'}
        options={{ pageSize: tableData.length, pageSizeOptions: allRecords(tableData), ...options }}
        components={{
          Container: (props: any) => props.children,
        }}
        columns={columns}
        data={tableData}
        actions={additionalActions}
        tableRef={tableRef}
      />
    </PropertiesContainer>
  );
};

const PropertiesContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  overflow-x: auto;
`;
