import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import styled from 'styled-components';
import { PropertyListUpload } from '../components/PropertyListUpload';
import { SimpleOptionsUpload, SimpleOption } from '../components/SimpleOptionsUpload';
import cloneDeep from 'lodash/cloneDeep';
import { AggrPropertyRow } from '../utils/types';

export interface EditableTableAction {
  icon: () => React.ReactElement;
  tooltip: string;
  onClick: (row: object, rowData: object) => void;
}

export interface MaterialTableRef {
  state: {
    lastEditingRow: object;
    showAddRow: boolean;
  };
}

export interface CSVResult {
  newRecordCount?: number;
  existingRecordCount?: number;
  badRecordCount?: number;
  afterImportCount?: number;
  badRecords?: string | undefined;
}

interface EditableTableProps {
  title?: string;
  data: object[];
  columns: object[];
  options?: object;
  fileType?: string;
  onExport?: (columns: object[], data: object[]) => void;
  onFileImport?: (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: any) => void,
    reject: () => void
  ) => void;
  actions?: EditableTableAction[] | undefined;
  onAdd: (row: object, resolve: (data: any) => void, reject: () => void) => void;
  onUpdate: (row: object, oldRow: object, resolve: (data: any) => void, reject: () => void) => void;
  onDelete: (row: object) => void;
  toolbarStyle?: React.CSSProperties;
  toolbarItem?: React.ReactElement;
  tableRef?: React.RefObject<MaterialTableRef>;
}

export type PartialAggrPropertyRowWithOrder = Partial<AggrPropertyRow> & { order?: number };

export interface BadRecords {
  name?: string;
  rowIndex?: number;
}
const allRecords = (records: object[]) =>
  records?.length > 20 ? [5, 10, 20, records.length] : [5, 10, 20];

export const EditableTable: React.FC<EditableTableProps> = React.memo(
  ({
    title,
    data,
    columns,
    onFileImport,
    onExport,
    fileType,
    onAdd,
    onUpdate,
    onDelete,
    actions,
    options,
    toolbarStyle,
    toolbarItem,
    tableRef,
  }) => {
    const clearEmptyProperties = (row: object) =>
      Object.fromEntries(
        Object.entries(row).map(([key, value]) => [key, value ? value : undefined])
      );

    const onCSVFileImport = (
      rows: object[],
      uploadedResults: CSVResult,
      resolve: (data: any) => void,
      reject: () => void
    ) => {
      if (onFileImport) {
        onFileImport(rows, uploadedResults, resolve, reject);
      }
    };

    return (
      <div style={{ position: 'relative' }}>
        <MaterialTable
          title={title || ''}
          options={{
            pageSize: data.length,
            pageSizeOptions: allRecords(data),
            exportCsv: (columns: object[], data: object[]) => {
              if (onExport) {
                onExport(columns, data);
              }
            },
            ...options,
          }}
          components={{
            Container: (props: { children: any }) => props.children,
            Toolbar: (props: any) => (
              <div
                style={{
                  ...toolbarStyle,
                  ...(toolbarItem && {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }),
                }}
              >
                <TableToolBarContainer>
                  <MTableToolbar {...props} />
                  {onFileImport && fileType === 'propertyList' && (
                    <PropertyListUpload
                      data={data as PartialAggrPropertyRowWithOrder[]}
                      fileImportResults={onCSVFileImport}
                    />
                  )}
                  {onFileImport && fileType === 'simpleOptions' && (
                    <SimpleOptionsUpload
                      data={data as SimpleOption[]}
                      fileImportResults={onCSVFileImport}
                    />
                  )}
                </TableToolBarContainer>
              </div>
            ),
          }}
          columns={columns}
          data={cloneDeep(data)}
          actions={actions}
          editable={{
            onRowAdd: (newData: object) =>
              new Promise((resolve, reject) => {
                onAdd(clearEmptyProperties(newData), resolve, reject);
              }),
            onRowUpdate: (rowToUpdate: object, oldRow: object | undefined) =>
              new Promise((resolve, reject) => {
                onUpdate(clearEmptyProperties(rowToUpdate), oldRow as object, resolve, reject);
              }),
            onRowDelete: (rowToDel: object) =>
              new Promise((resolve) => {
                onDelete(rowToDel);
                resolve(null);
              }),
          }}
          tableRef={tableRef}
        />
      </div>
    );
  }
);

const TableToolBarContainer = styled.div`
  display: flex;
  flex: 1;
  & > *:first-child {
    flex: 1;
    padding-right: 0px;
  }
  margin-right: 8px;
`;
