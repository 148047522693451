import createEngine, { DiagramEngine, LinkModelGenerics } from '@projectstorm/react-diagrams';
import { useCallback, useEffect, useMemo, useState } from 'react';
//import { DataSourceNodeFactory } from '../../old/DiagramNodes/DataSourceNode/DataSourceFactory';
//import { DataSourceModel } from '../../old/DiagramNodes/DataSourceNode/DataSourceModel';
//import { OutputStateNodeFactory } from '../DiagramNodes/OutputState/OutputStateFactory';
//import { OutputStateModel } from '../DiagramNodes/OutputState/OutputStateModel';
//import { transformNodeFactories } from '../DiagramNodes/TransformNodes/index';
import { EventMapDiagramModel } from '../EventMapDiagramModel';
//import { GenericTransformNodeModel } from '../DiagramNodes/TransformNodes/GenericTransform/GenericTransformNodeModel';
import { LinkModel } from '@projectstorm/react-diagrams';
import {
  BaseEntityEvent,
  BaseEntity,
  BaseEntityGenerics,
  DeleteItemsAction,
} from '@projectstorm/react-canvas-core';
import { buildNodeFactories } from '../DiagramNodes/NodeFactories';
import {
  NodeMapDefinition,
  AccessorMap,
  MapScenarioType,
  SchemaLookup,
} from '@terragotech/gen5-datamapping-lib';
import { DataMapperNodeModel } from '../DiagramNodes/DataMapperNode/DataMapperNodeModel';
import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

interface UseEventMapDiagramProps {
  dataMap?: NodeMapDefinition;
  accessorMap?: AccessorMap;
  schemaLookup?: SchemaLookup;
  scenario?: keyof MapScenarioType;
}
interface UseEventMapDiagramOutput {
  engine: DiagramEngine;
  model?: EventMapDiagramModel;
  checkModelHasChanged: () => boolean;
  hasSetPosition: boolean;
}
export const useDataMapDiagram = ({
  dataMap,
  accessorMap,
  schemaLookup,
  scenario,
}: UseEventMapDiagramProps): UseEventMapDiagramOutput => {
  // Basic engine setup
  const [currentModel, setCurrentModel] = useState<EventMapDiagramModel>();
  const [initialDataMap, setInitialDataMap] = useState<NodeMapDefinition>();
  const [hasSetPosition, setHasSetPosition] = useState(false);
  const engine = useMemo(() => {
    const d = createEngine({ registerDefaultDeleteItemsAction: false }); // use a custom delete
    const nodeFactories = d.getNodeFactories();
    const basicNodeFactories = buildNodeFactories({
      accessorMap: accessorMap,
      schemaLookup: schemaLookup,
    });
    Object.keys(basicNodeFactories).forEach((transformFactoryKey) => {
      nodeFactories.registerFactory(basicNodeFactories[transformFactoryKey]);
    });
    d.setMaxNumberPointsPerLink(0);
    setCurrentModel(undefined); // Model will be set in the triggered useEffect callback below.
    setInitialDataMap(undefined);
    return d;
  }, [accessorMap, schemaLookup, setCurrentModel]);

  useEffect(() => {
    // Create all of the transform nodes that are currently defined
    const localDataMap = cloneDeep(dataMap || (scenario && mapScenarios[scenario].defaultNodeMap));
    const model = new EventMapDiagramModel({
      outputDefinition: (localDataMap && localDataMap.outputDefinition) || {},
    });
    if (localDataMap && localDataMap.nodes) {
      Object.keys(localDataMap.nodes).forEach((key, index) => {
        const node = localDataMap.nodes ? localDataMap.nodes[key] : null;
        if (engine && node && node.type) {
          const nodeFactory = engine.getNodeFactories().getFactory(node.type);
          //const nodeFactory = basicNodeFactories[node.type];
          if (nodeFactory) {
            const transformNodeModel = nodeFactory.generateModel({
              initialConfig: { ...node, id: key },
            });
            if(node.position){
              transformNodeModel.setPosition(node.position.x, node.position.y);
              setHasSetPosition(true);
            }
            else{
              transformNodeModel.setPosition(350, index * 150 + 50);
            }
            model.addNode(transformNodeModel);
          } else {
            console.error(`Transform type does not exist: ${node.type}`);
          }
        }
      });
    }
    // tell every node in our model to connect itself to any parents
    model.getNodes().forEach((node) => {
      // validate that it's one of our nodes before casting
      if ('connectPorts' in node) {
        (node as DataMapperNodeModel).connectPorts();
      }
    });

    const fireAddConnectionEvent = () => {
      // const reconnectInferredNodes = () =>
      //   model.getNodes().forEach(node => {
      //     /*if (node instanceof GenericTransformNodeModel) {
      //       node.onConnectAddEvent();
      //       node.setPosition(node.getPosition().x + 0.0001, node.getPosition().y);
      //     }*/
      //   });
      // Solves inferable nodes chain issues, a more efficient version with could be written but it will require very complicated comarisions
      /*model
        .getNodes()
        .forEach((node) => node instanceof GenericTransformNodeModel && reconnectInferredNodes());*/
    };

    const fireRemoveConnectionEvent = () => {
      model.getNodes().forEach((node) => {
        /*if (node instanceof GenericTransformNodeModel) {
          node.onConnectRemoveEvent();
        }*/
      });
    };

    engine.setModel(model);
    // register an DeleteItemsAction with custom keyCodes (in this case, only Delete key)
    engine
      .getActionEventBus()
      .registerAction(new DeleteItemsAction({ keyCodes: [46], modifiers: { shiftKey: true } }));

    //@ts-ignore
    model.registerListener({
      linksUpdated: (
        event: BaseEntityEvent<BaseEntity<BaseEntityGenerics>> & {
          link: LinkModel<LinkModelGenerics>;
          isCreated: boolean;
        }
      ) => {
        event.link.registerListener({
          sourcePortChanged: () => {
            fireAddConnectionEvent();
          },
          targetPortChanged: () => {
            fireAddConnectionEvent();
          },
        });
        fireRemoveConnectionEvent();
        // immediately re-render and that makes the link lose it's starting point until the user moves.
        engine.repaintCanvas();
      },
      nodesUpdated: () => {
        fireAddConnectionEvent();
        // This is a hack to ensure that all node layers repaint when a new node is added
        // This really shouldn't be needed and I am not sure why it is.
        engine
          .getModel()
          .getNodeLayers()
          .forEach((layer) => {
            layer.allowRepaint(true);
          });
        engine.repaintCanvas();
      },
    });
    setCurrentModel(model);
    setInitialDataMap(cloneDeep(model.getMapDefinition()));
    fireAddConnectionEvent();
  }, [dataMap, engine, scenario]);

  const checkHasChanged = useCallback((): boolean => {
    if (!initialDataMap || !currentModel) return false;

    return !isEqual(initialDataMap, currentModel.getMapDefinition());
  }, [initialDataMap, currentModel]);

  return { engine, model: currentModel, checkModelHasChanged: checkHasChanged, hasSetPosition };
};
