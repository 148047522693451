import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, ListItemText, ListItem, List } from '@material-ui/core';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { menuGroups, MenuGroupDefinition, MenuItemDefinition } from '../../utils/navigationUtils';
import { useHistory } from 'react-router-dom';

export const MenuList: React.FC = () => {
  const refresh = useState(0);
  const history = useHistory();
  const theme = useTheme();

  const isItemSelected = (link: string) => {
    const { pathname } = history.location;
    if (link === '/') return pathname === link;
    return history.location.pathname.startsWith(link);
  };

  return (
    <ScrollableMenuList>
      {menuGroups.map((group: MenuGroupDefinition) => (
        <div key={group.title || Math.random().toString()}>
          {group.title && <GroupTitle>{group.title}</GroupTitle>}
          <List component="nav">
            {group.items.map((item: MenuItemDefinition) => (
              <ListItem
                key={item.text}
                button
                component={Link}
                to={item.link}
                onClick={() => {
                  refresh[1](new Date().getTime());
                }}
              >
                <ListItemText
                  primary={item.text}
                  style={{
                    marginLeft: 20,
                    color: isItemSelected(item.link) ? theme.palette.primary.light : 'rgb(220,220,220)',
                    fontSize: 16,
                  }}
                />
              </ListItem>
            ))}
          </List>
          <GroupDivider />
        </div>
      ))}
    </ScrollableMenuList>
  );
};

const ScrollableMenuList = styled.div`
  overflow-y: auto;
`;

const GroupTitle = styled.p`
  color: rgb(170, 170, 170);
  font-size: 14px;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(70, 70, 70);
  }
`;
