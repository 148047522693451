import React from 'react';
import { WebUIConfig } from '@terragotech/gen5-config-lib';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import { useConfig } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { UIGeneralEditor } from '../../../components/UIGeneralEditor';
import { cloneDeep } from 'lodash';

export const WebUIGeneralEditor: React.FC = () => {
  const { config, setConfig } = useConfig();

  const handleSave = (UIConfig: WebUIConfig): void => {
    const configCopy = { ...config };
    configCopy.webUIConfig = { ...configCopy.webUIConfig, ...UIConfig };
    setConfig(configCopy);
    successMsg('Branding configuration has been successfully saved');
  };

  return (
    <BrandingContainer>
      <UIGeneralEditor
        type="Web"
        UIConfig={cloneDeep(config.webUIConfig)}
        onSave={(UIConfig) => handleSave(UIConfig as WebUIConfig)}
      />
    </BrandingContainer>
  );
};

const BrandingContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  overflow-x: auto;
  padding: 10px 20px;
  max-width: 1200px;
`;
