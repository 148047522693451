import { Button, DialogActions, DialogTitle, FormHelperText, Typography } from '@material-ui/core';
import { AggregateLoaderType, V2AggregateLoader } from '@terragotech/form-renderer';
import { V2MapComponent } from '@terragotech/page-renderer';
import { EditableTable, MaterialTableRef } from 'components/EditableTable';
import { errorMsg, warningMsg } from 'components/SnackbarUtilsConfigurator';
import { SymbolProps } from 'components/UIGeneralEditor';
import { useConfig } from 'context/ConfigContext';
import SymbolScaleContextProvider from 'context/symbolScaleContext/symbolScaleContext';
import _, { isEqual } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { getSymbolKeyColumns } from 'utils/jsonPartsGenerators';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import { CheckboxInput, InputGroup, TextInput } from '../FormElements';
import { V2OptionsInput } from '../FormElements/V2OptionsInput';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import { PageContext } from './contexts/PageContext';
import DataMapperDialog from './DataMapperDialog';
import { usePageSchemas } from './hooks/usePageSchemas';

const columns = getSymbolKeyColumns();
export type MapComponentDefWithName = V2MapComponent & {
  name: string;
};

interface MapEditFormFormProps {
  onSubmit: (result: MapComponentDefWithName) => void;
  onClose: () => void;
  component: MapComponentDefWithName;
}

export const MapEditForm: React.FC<MapEditFormFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const confirm = useConfirm();
  const { config } = useConfig();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState<string>(component.label);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [aggregates, setAggregates] = useState(component.aggregates);
  const [height, setHeight] = useState(component.height);
  const [includeWMS, setIncludeWMS] = useState(component.includeWMS);
  const [matchWeb, setMatchWeb] = useState(!component.symbols);
  const [symbolLegend, setSymbolLegend] = useState<SymbolProps[]>(component.symbols || config?.webUIConfig?.symbolLegend || []);

  const pageSchemas = usePageSchemas();

  const isSymbolInvalid = useCallback((row: object) => {
    if (!(row as SymbolProps).name) {
      errorMsg('Property "Name" is required');
      return true;
    }
    if (!(row as SymbolProps).symbolKey) {
      errorMsg('Property "Symbol Key" is required');
      return true;
    }
    return false;
  }, []);
  
  const handleAddSymbol = useCallback(
    (symbol: object, resolve: (data: any) => void, reject: () => void) => {
      if (isSymbolInvalid(symbol)) return reject();
      setSymbolLegend([...symbolLegend, symbol as SymbolProps]);
      resolve(null);
    },
    [symbolLegend, setSymbolLegend, isSymbolInvalid]
  );

  const handleDeleteSymbol = useCallback(
    (symbol: object) => {
      setSymbolLegend(symbolLegend.filter((item) => item.name !== (symbol as SymbolProps).name));
    },
    [symbolLegend, setSymbolLegend]
  );

  const handleUpdateSymbol = useCallback(
    (symbol: object, oldSymbol: object, resolve: (data: any) => void, reject: () => void) => {
      if (oldSymbol) {
        if (isSymbolInvalid(symbol)) return reject();
        const symbolLegendCopy = [...symbolLegend];
        const index = symbolLegendCopy.findIndex(
          (item) => item.name === (oldSymbol as SymbolProps).name
        );
        symbolLegendCopy[index] = symbol as SymbolProps;
        setSymbolLegend(symbolLegendCopy);
        resolve(null);
      } else {
        reject();
      }
    },
    [symbolLegend, setSymbolLegend, isSymbolInvalid]
  );
  const tableRef = useRef<MaterialTableRef>(null);

  const [existingNameError, setExistingNameError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const nameHelperTxt =
    'Duplicate name error. The name must be unique accross all workflow elements';

  const handleClearDisplayMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setDisplayOptions(undefined);
  };

  const getFormValues = () => ({
    type: component.type,
    name: name || '',
    label: label || '',
    aggregates: aggregates || undefined,
    height: height || undefined,
    includeWMS: includeWMS || undefined,
    symbols: matchWeb ? undefined : symbolLegend.map((symbol) => ({
      name: symbol.name,
      symbolKey: symbol.symbolKey,
    })),
    ...(displayOptions && { displayOptions }),
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The page has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    if (lastEditingRow || showAddRow)
      return warningMsg(
        'Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form'
      );
    if (checkDuplicatePageName(name, component, pageDefinition.elements)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(displayOptions);
  }, [displayOptions]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <TextInput
        autoFocus
        id="Name"
        error={existingNameError}
        helperText={existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Height"
        value={height}
        onChange={(value) => setHeight(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <CheckboxInput
        title="Include Layers?"
        checked={includeWMS}
        onChange={(value) => setIncludeWMS(value)}
        style={{ margin: '0 25px 10px' }}
      />
      <InputGroup title="Conditionals" style={{ margin: '10px 25px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button onClick={() => setConditionalOpen(true)}>
            <div>
              Advanced
              <MappingActiveDisplay
                isActive={doesConditionalHaveValue()}
                activeLabelText={'active'}
              />
            </div>
          </Button>
          <Button onClick={handleClearDisplayMapper}>Clear</Button>
        </div>
        <DataMapperDialog
          mapScenario="DISPLAY_OPTIONS"
          localSchemaDefinitions={pageSchemas}
          onClose={() => {
            setConditionalOpen(false);
          }}
          open={conditionalOpen}
          datamap={displayOptions}
          setDatamap={setDisplayOptions}
        />
      </InputGroup>
      <InputGroup title="Aggregates" style={{ margin: '10px 25px' }}>
        {aggregates.map((agg,index)=>
              <V2OptionsInput options={agg} 
              setOptions={(newAgg)=>{setAggregates((prev)=>(prev.map((old,i)=>(i===index ? newAgg as V2AggregateLoader : old))))}} 
              aggregateOnly={true} 
              onDelete={()=>setAggregates(prev=>prev.filter((_val,i)=>(i!==index)))}/>
        )}
        <div style={{justifyContent:'center', display:'grid'}} ><Button onClick={()=>setAggregates(prev=>prev.concat({
          type: AggregateLoaderType,
          aggregateType: ''
        }))}>Add</Button></div>
      </InputGroup>
      <InputGroup title="Symbols" style={{ margin: '10px 25px', maxHeight: 500, overflowY: 'auto' }}>
            <Typography style={{ paddingLeft: '10px' }} variant="h6">
              Map Symbols
            </Typography>
            <CheckboxInput
              title="Match Web Symbols?"
              checked={matchWeb}
              onChange={(value) => setMatchWeb(value)}
              style={{ margin: '0 25px 10px' }}
            />
            {!matchWeb && <>
                <FormHelperText>{`symbol_circle_<color_as_hex>[_<text_or_unicode_glyph>[_<internal_symbol_scale_1.0_to_2.0>]]`}</FormHelperText>
                <FormHelperText>{`line_<style:'solid'|'dotted'>_[<width>][_<color_as_hex>]`}</FormHelperText>
                <SymbolScaleContextProvider scale={100}>
                  <EditableTable
                    data={symbolLegend}
                    options={tableOptions}
                    columns={columns}
                    onAdd={handleAddSymbol}
                    onUpdate={handleUpdateSymbol}
                    onDelete={handleDeleteSymbol}
                    tableRef={tableRef}
                  />
                </SymbolScaleContextProvider></>
            }
      </InputGroup>
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const tableOptions = {
  searchFieldAlignment: 'left',
  searchFieldStyle: { marginLeft: '-28px' },
};
