import { V2PageTemplate } from '@terragotech/page-renderer';
import { JSONSchema6 } from 'json-schema';
import React, { createContext, useContext, useMemo } from 'react';
import { convertV2PageTemplateToJsonSchema } from '../../../pages/aggregates/utils/V2PageTemplateToJsonSchema';

interface ProviderProps {
  pageDefinition: V2PageTemplate;
  children: React.ReactElement | React.ReactElement[];
}
interface ContextProps {
  pageSchema: JSONSchema6;
  pageDefinition: V2PageTemplate;
}
export const PageContext = createContext<ContextProps>({} as ContextProps);
export const PageContextProvider: React.FC<ProviderProps> = ({ children, pageDefinition }) => {
  const value = useMemo(() => {
    const pageSchema = pageDefinition ? convertV2PageTemplateToJsonSchema(pageDefinition) : {};
    return { pageDefinition, pageSchema };
  }, [pageDefinition]);
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};
export const usePage = (): ContextProps => useContext<ContextProps>(PageContext);
