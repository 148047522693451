import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { Aggregates } from './pages/aggregates/Aggregates';
import { WebUI } from './pages/WebUI/WebUI';
import { MobileUI } from './pages/MobileUI/MobileUI';
import { ConfigContext, GlobalContextProvider } from './context/ConfigContext';
import { SnackbarUtilsConfigurator } from './components/SnackbarUtilsConfigurator';
import { FormDialogServiceProvider } from './components/FormDialog/FormDialogService';
import { Overview } from './pages/Overview/Overview';
import { Features } from './pages/Features/Features';
import { Functions } from './pages/functions/Functions';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import blue from '@material-ui/core/colors/blue';
import { CustomPages } from './pages/customPages/CustomPages';
import {
  Apollo as ApolloProvider,
  AuthContext,
  ErrorBoundary,
  useAuthState,
  ServerPublicMetaContextProvider,
  SpinnerContextProvider,
  UserInfoProvider,
  theme,
} from '@terragotech/gen5-shared-components';
import { Redirect } from 'react-router-dom';
import LoginScreenContainer from './pages/Login/LoginScreenContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { makeStyles } from '@material-ui/core/styles';
import ProtectedRoute from 'components/ProtectedRoute';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'fixed',
    inset: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
});

export const App: React.FC = () => {
  const classes = useStyles();
  const authState = useAuthState();
  const { token, clearToken } = authState;

  return (
    <ErrorBoundary>
      <ApolloProvider
        token={token}
        onTokenExpired={clearToken}
        uri={
          process.env.NODE_ENV === 'production'
            ? '/graphql'
            : `${process.env.REACT_APP_SERVER_URL || 'http://localhost:3002'}/graphql`
        }
        onRedirectLogin={() => {
          console.log('Needs login to perform this action');
        }}
      >
        <AuthContext.Provider value={authState}>
          <UserInfoProvider>
            <GlobalContextProvider>
              <ConfigContext.Consumer>
                {(configContext) => {
                  return (
                    <ThemeProvider
                      theme={theme(
                        configContext?.config?.webUIConfig?.theme?.primary || blue[600],
                        configContext?.config?.webUIConfig?.theme?.secondary || blue[600]
                      )}
                    >
                      <ServerPublicMetaContextProvider
                        redirectSignInField="redirectConfigEditorSignIn"
                        redirectSignOutField="redirectConfigEditorSignOut"
                        renderLoading={() => (
                          <div className={classes.container}>
                            <CircularProgress color="primary" />
                          </div>
                        )}
                      >
                        <SpinnerContextProvider>
                          {/* @ts-ignore */}
                          <ConfirmProvider>
                            <SnackbarProvider
                              maxSnack={3}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                            >
                              <SnackbarUtilsConfigurator />
                              <DndProvider backend={HTML5Backend}>
                                <Router>
                                  <FormDialogServiceProvider>
                                    <Switch>
                                      <Route path="/login">
                                        <LoginScreenContainer
                                          onLoginSuccess={authState.loginSuccess}
                                        />
                                      </Route>
                                      <ProtectedRoute path="/aggregates" component={Aggregates} />
                                      <ProtectedRoute path="/functions" component={Functions} />
                                      <ProtectedRoute path="/customPages" component={CustomPages} />
                                      <ProtectedRoute path="/mobileUI" component={MobileUI} />
                                      <ProtectedRoute path="/webUI" component={WebUI} />
                                      <ProtectedRoute path="/features" component={Features} />
                                      <ProtectedRoute path="/" component={Overview} exact />
                                      <Redirect to="/" />
                                    </Switch>
                                  </FormDialogServiceProvider>
                                </Router>
                              </DndProvider>
                            </SnackbarProvider>
                          </ConfirmProvider>
                        </SpinnerContextProvider>
                      </ServerPublicMetaContextProvider>
                    </ThemeProvider>
                  );
                }}
              </ConfigContext.Consumer>
            </GlobalContextProvider>
          </UserInfoProvider>
        </AuthContext.Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};
