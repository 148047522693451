import withWidth from '@material-ui/core/withWidth';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import { LeftDrawer } from './LeftDrawer';

interface Props {
  width: string;
  children: React.ReactNode;
}

const AppContainerBase: React.FC<Props> = ({ width, children }) => {
  const [navDrawerOpen, setNavDrawerOpen] = useState(true);

  useEffect(() => {
    setNavDrawerOpen(width === 'md' || width === 'lg' || width === 'xl');
  }, [width]);

  return (
    <>
      <Header
        navDrawerOpen={navDrawerOpen}
        handleChangeRequestNavDrawer={() => setNavDrawerOpen(!navDrawerOpen)}
      />
      <LeftDrawer navDrawerOpen={navDrawerOpen} />;
      <Main navDrawerOpen={navDrawerOpen} width={width}>
        {children}
      </Main>
    </>
  );
};

const Main = styled.div<{ navDrawerOpen: boolean; width: string }>`
  margin: 70px 10px 10px 10px;
  padding-left: ${(props) => (props.navDrawerOpen && props.width !== 'xs' ? '261px' : 0)};
`;

export const AppContainer = withWidth()(AppContainerBase);
