import React, { useContext, useState } from 'react';
import { DialogActions, Button, DialogTitle, DialogContent } from '@material-ui/core';
import { TextInput, SelectInput } from '../FormElements';
import { errorMsg } from '../SnackbarUtilsConfigurator';
import { V2PageTemplate } from '@terragotech/page-renderer';
import { ConfigContext } from 'context/ConfigContext';
import { NOT_MAP_ASSETS_NAMES } from '@terragotech/gen5-shared-utilities';
import { NO_CATEGORY_NAME_PLACEHOLDER } from '../../pages/customPages/components/CustomPagesMenu/CustomPagesList';

interface Props {
  onSubmit: (result: { pageName: string; page: Partial<V2PageTemplate> }) => void;
  onClose: () => void;
}

export const CreateNewCustomPageForm: React.FC<Props> = ({ onSubmit, onClose }) => {
  const [pageName, setPageName] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<Partial<V2PageTemplate>>({});
  const { getAggregates, getCustomPages } = useContext(ConfigContext);

  const getAggregateList = () =>
    getAggregates()
      .filter((a) => !a.isReferenceType && !NOT_MAP_ASSETS_NAMES.includes(a.typeName))
      .map((a) => a.typeName);
  const pageNameExists = (pageName: string) => !!getCustomPages()[pageName];

  type ValidationResult =
    | {
        isValid: true;
      }
    | {
        isValid: false;
        message: string;
      };
  const validatePage = (page: Partial<V2PageTemplate>): ValidationResult => {
    // Just require aggregate type for now.
    if (!page?.aggregateType) return { isValid: false, message: 'Aggregate type is required.' };

    return { isValid: true };
  };

  const handleOnSubmit = () => {
    if (!pageName) return errorMsg('Page name is required.');

    if (pageNameExists(pageName)) {
      return errorMsg(`An custom page named "${pageName}" already exists.`);
    }

    const validationResult = validatePage(page);
    if (!validationResult.isValid) return errorMsg(validationResult.message);

    onSubmit({
      pageName,
      page: {
        ...page,
        aggregateType:
          page.aggregateType === NO_CATEGORY_NAME_PLACEHOLDER ? undefined : page.aggregateType,
      },
    });
  };

  return (
    <>
      <DialogTitle>Create new custom page</DialogTitle>
      <DialogContent style={{ padding: '0 25px' }}>
        <TextInput
          id="pageName"
          value={pageName}
          variant="standard"
          onChange={(value) => setPageName(value)}
          style={{ margin: 0 }}
        />
        <SelectInput
          title="Aggregate"
          options={[NO_CATEGORY_NAME_PLACEHOLDER].concat(...getAggregateList())}
          onChange={(value) => setPage((p) => ({ ...p, aggregateType: value }))}
          value={page.aggregateType || ''}
        />
      </DialogContent>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleOnSubmit} autoFocus>
          Create
        </Button>
      </DialogActions>
    </>
  );
};
