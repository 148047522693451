import React, { useContext } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ConfigContext } from '../../../../context/ConfigContext';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { AggregateUICustomisationsList } from './AggregateUICustomisationsList';
import { CreateAggregateUICustomizationDialog } from '../../../../components/FormDialog/CreateAggregateUICustomizationDialog';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { CollapsibleMenuCard } from '../../../../components/AppContainer/CollapsibleMenuCard';
import { useUICustomizationAPI } from '../../../../context/fakeAPIHooks/useUICustomizationAPI';

export const AggregateUICustomisationsMenu: React.FC = () => {
  const { config } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const UICustomizationAPI = useUICustomizationAPI();
  if (config === null) return null;

  const handleAddAggregateUICustomization = async () => {
    const aggrUIName = await formDialog<typeof CreateAggregateUICustomizationDialog>((props) => (
      <CreateAggregateUICustomizationDialog configType="mobileUIConfig" {...props} />
    ));
    if (!aggrUIName) return errorMsg(`No aggregate selected`);
    const { error } = await UICustomizationAPI.addNewAggregateUICustomization(
      'mobileUIConfig',
      aggrUIName
    );
    if (error) return;
    successMsg(`The aggregate "${aggrUIName}" has been successfully added`);
  };

  return (
    <CollapsibleMenuCard title="Aggregate UI Customisations">
      <AggregateUICustomisationsList />
      <ButtonsContainer>
        <IconButton
          style={{ color: '#1e88e5' }}
          component="span"
          onClick={() => handleAddAggregateUICustomization()}
        >
          <AddIcon />
        </IconButton>
      </ButtonsContainer>
    </CollapsibleMenuCard>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;
