import { Gen5PortModel, Gen5PortModelOptions } from './Gen5PortModel';
import { JSONSchema6 } from 'json-schema';
import { LinkModel } from '@projectstorm/react-diagrams';
import { DataMapperNodeModel } from '../DiagramNodes/DataMapperNode/DataMapperNodeModel';
import { areSchemasCompatible, getTypeSymbolsFromSchema } from '../Utils/JSONSchemaHelpers';

export interface SchemaDefinedPortModelOptions extends Gen5PortModelOptions {
  schema: JSONSchema6;
  depth?: number;
}
class SchemaDefinedPortModel extends Gen5PortModel {
  private schema: JSONSchema6;
  private depth: number;
  constructor(options: SchemaDefinedPortModelOptions) {
    super({ ...options }); // make sure the id of the port is equivalent to its path
    this.schema = options.schema;
    this.depth = options.depth || 0;
  }
  updateOptions(options: SchemaDefinedPortModelOptions) {
    this.fixedValue = options.fixedValue;
    if (
      this.schema.type != options.schema.type ||
      this.schema.$ref != options.schema.$ref ||
      this.schema.format != options.schema.format
    ) {
      Object.values(this.links).forEach((link) => {
        this.removeLink(link);
      });
    }
    this.schema = options.schema;
    this.depth = options.depth || 0;
  }
  /**
   *  This function check whether two ports should be allowed to connect. We will be using this to check the schema of the outbound port
   * to ensure that it can be handled by the inbound port.
   * @param port The port that is attempting to connect to this port
   * @param twoWayValidation ...unclea
   * @returns whether or not the port should be allowed to connect to this port
   */
  validateConnection(port: SchemaDefinedPortModel, twoWayValidation?: boolean): boolean {
    if (this.options.in) {
      return areSchemasCompatible(port.schema, this.schema);
    } else {
      return areSchemasCompatible(this.schema, port.schema);
    }
  }
  /**
   * @returns whether or not this port can be set to a fixed value
   */
  isFixedValueAllowed(): boolean {
    return (
      this.schema.type === 'string' ||
      this.schema.type === 'number' ||
      this.schema.type === 'boolean'
    );
  }
  /**
   * We override the default here in order to add a listener to the created link. This way we can fire our own connection events only
   * when a link is completely defined along both ends
   * @param link the link being added to this port
   */
  addLink(link: LinkModel): void {
    super.addLink(link);
    // This handler will allow us to tell the parent node whenever a link is fully connected to an input port
    // from there it can update jsut this port, or it could even update all ports
    const handler = (event: any) => {
      //if we have both a source and destination, then we are connected
      const sourcePort = event.entity.getSourcePort();
      const targetPort = event.entity.getTargetPort();
      if (sourcePort && targetPort) {
        this.fixedValue = undefined;
        //What are we connected to
        const oppositePort = this === sourcePort ? targetPort : sourcePort;
        // casting here. We could eventually convert this class to use PortModel directly instead of DefaultPortModel, but that would require us to
        //     reimplement some of its functions
        (this.parent as DataMapperNodeModel).onPortConnected(this, oppositePort);
      }
    };
    if (this.getOptions().in) {
      this.linkConnectionHandle = link.registerListener({
        sourcePortChanged: handler,
        targetPortChanged: handler,
      });
    }
  }
  removeLink(link: LinkModel): void {
    if (this.links[link.getID()]) {
      super.removeLink(link);
      const targetPort = link.getTargetPort();
      if (this.getName() === targetPort?.getName()) {
        this.fixedValue = undefined;
        (this.parent as DataMapperNodeModel).disconnectPort(targetPort.getName());
      }
      link.remove();
      if (this.linkConnectionHandle) {
        link.deregisterListener(this.linkConnectionHandle);
      }
    }
  }
  /**
   * This gives the single character symbol used to represent a type. It will likely also define a color in the future
   * @returns One character symbol representing the type
   */
  getTypeSymbol(): string {
    // This setup is overly simplistic, but should work for now.
    let outputType = '?';
    const types = getTypeSymbolsFromSchema(this.schema);
    if (types) {
      outputType = types.join('|');
    }
    return outputType;
  }
  /**
   * @returns how deep the port is within the schema
   */
  getDepth(): number {
    return this.depth;
  }
  getSchema(): JSONSchema6 {
    return this.schema;
  }
}
export default SchemaDefinedPortModel;
