import React from 'react';

import { AggregateList } from './AggregateList';
import { CollapsibleMenuCard } from '../../../../components/AppContainer/CollapsibleMenuCard';
import { useParams } from 'react-router-dom';

export const AggregateMenu: React.FC = () => {
  const params = useParams() as { aggregate: string };
  const aggregateName = params.aggregate;

  return (
    <CollapsibleMenuCard title={`${aggregateName} aggregate`}>
      <AggregateList />
    </CollapsibleMenuCard>
  );
};
