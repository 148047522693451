import React, { useContext } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { AggregateConfig } from '@terragotech/gen5-config-lib/dist/AggregateConfig';
import { ConfigContext } from '../../../../context/ConfigContext';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { useHistory, useParams } from 'react-router-dom';
import { useCommandActionRefChanger } from '../../../../utils/useCommandActionRefChanger';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';
import { getAggregateIndex } from '../../../../utils/navigationUtils';

interface CommandsTreeProps {
  aggregate: AggregateConfig;
  aggrIndex: number;
}

export const CommandsTree: React.FC<CommandsTreeProps> = ({ aggregate, aggrIndex }) => {
  const { config, getCommandVersions, getCommandVersion } = useContext(ConfigContext);
  const confirm = useConfirm();
  const history = useHistory();
  const AggregateAPI = useAggregateAPI();
  const { aggregate: typeName } = useParams() as { aggregate: string };
  const commandActionRefChanger = useCommandActionRefChanger();

  const shouldRefsBeRemapped = async (commandName: string, commandVersion: number) => {
    try {
      await confirm({
        title: 'References detected',
        description: `Command "${commandName}" version ${commandVersion} has references, do you want to remap them to the last version?`,
        confirmationText: 'Remap',
      });
      return true;
    } catch {
      return false;
    }
  };

  const handleAddVersion = async (commandName: string, versionIndex: number) => {
    await confirm({
      description: `Do you want to create new version of "${commandName}" command?`,
      confirmationText: 'Create',
    });
    let doRemap = false;
    const commandVersion = getCommandVersion(
      getAggregateIndex(config, typeName),
      commandName,
      versionIndex
    )?.version;
    if (!commandVersion) return;
    if (commandActionRefChanger.isAnyReference(config, typeName, commandName, commandVersion)) {
      doRemap = await shouldRefsBeRemapped(commandName, commandVersion);
    }
    const { error } = await AggregateAPI.addCommandVersion(
      aggrIndex,
      commandName,
      versionIndex,
      commandVersion,
      doRemap
    );
    if (error) return;
    successMsg(`New version of the "${commandName}" has been successfully created`);
  };

  const handleRemoveCommand = async (commandName: string) => {
    await confirm({
      description: `Command "${commandName}" will be permanently deleted.`,
      confirmationText: 'Delete',
    });

    const { error } = await AggregateAPI.removeCommand(aggrIndex, commandName);
    if (error) return;
    history.push(`/aggregates/${typeName}/commands`);
    successMsg(`Command "${commandName}" has been successfully deleted.`);
  };

  const handleRemoveVersion = async (
    commandName: string,
    versionIndex: number,
    commandVersion: number
  ) => {
    await confirm({
      description: `The version "${commandVersion}" of the command "${commandName}" will be permanently deleted.`,
      confirmationText: 'Delete',
    });
    const versions = getCommandVersions(aggrIndex, commandName);
    if (versions?.length === 1)
      return errorMsg(
        'At least one version of the command must remain. Consider deleting the entire command instead'
      );

    const { error } = await AggregateAPI.removeCommandVersion(
      aggrIndex,
      commandName,
      versionIndex,
      commandVersion
    );
    if (error) return;
    history.push(`/aggregates/${typeName}/commands`);
    successMsg(
      `The version "${commandVersion}" of the command "${commandName}" has been successfully deleted.`
    );
  };

  return (
    <>
      {aggregate?.commands &&
        Object.entries(aggregate.commands).map(([commandName, command]) => (
          <TreeViewNode
            label={commandName}
            key={commandName}
            nodeId={`/aggregates/${typeName}/commands/${commandName}`}
            count={command.versions ? command.versions.length : 0}
            onDelete={() => handleRemoveCommand(commandName)}
            color="orange"
          >
            {command.versions.map((version, versionIndex) => (
              <TreeViewNode
                label={`Version: ${version.version}`}
                key={version.version}
                nodeId={`/aggregates/${typeName}/commands/${commandName}/version/${versionIndex}`}
                onAdd={() => handleAddVersion(commandName, versionIndex)}
                onDelete={() => handleRemoveVersion(commandName, versionIndex, version.version)}
                color={versionIndex}
              />
            ))}
          </TreeViewNode>
        ))}
    </>
  );
};
