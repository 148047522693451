import React from 'react';
import { useSnackbar, VariantType, WithSnackbarProps, OptionsObject } from 'notistack';

let useSnackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};
const toast = (
  msg: string | React.ReactNode,
  variant: VariantType = 'default',
  config?: OptionsObject
) => useSnackbarRef.enqueueSnackbar(msg, { variant, ...config });

export const successMsg = (msg: string | React.ReactNode, config?: OptionsObject) =>
  toast(msg, 'success', config);
export const warningMsg = (msg: string | React.ReactNode, config?: OptionsObject) =>
  toast(msg, 'warning', config);
export const infoMsg = (msg: string | React.ReactNode, config?: OptionsObject) =>
  toast(msg, 'info', config);
export const errorMsg = (msg: string | React.ReactNode, config?: OptionsObject) =>
  toast(msg, 'error', config);
export const closeSnackBar = (key: React.ReactText) => useSnackbarRef.closeSnackbar(key);
