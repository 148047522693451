import React, { useContext } from 'react';
import styled from 'styled-components';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import prettier from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';
import { ConfigContext } from '../../context/ConfigContext';
//Not in love with this but will get us going for now.
import packageJson from '../../../package.json';
 
export const ConfigDownload: React.FC = () => {
  const { config, configName } = useContext(ConfigContext);

  const exportJson = async (): Promise<void> => {
    const json = prettier.format(
      JSON.stringify({ ...config, version: (packageJson as {version:string}).version}),
      {
        parser: 'json',
        plugins: [parserBabel],
      }
    );
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = configName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GenerateJsonContainer>
      <DownloadButton
        variant="contained"
        color="primary"
        startIcon={<CloudDownloadIcon />}
        onClick={exportJson}
        disabled={!config}
      >
        GENERATE .JSON
      </DownloadButton>
    </GenerateJsonContainer>
  );
};

const GenerateJsonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 20px 0;
  height: 45px;
`;

const DownloadButton = styled(Button)`
  && {
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    width: 222px;
  }
`;
