import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

interface FormPaletteProps {
  isImportCommand?: boolean;
}
export const FormPalette: React.FC<FormPaletteProps> = ({ isImportCommand }) => {
  return (
    <>
      <PaletteHeader>Form Palette</PaletteHeader>
      {paletteItems.map(
        (item, index) =>
          (!isImportCommand || item.isImport) && (
            <Draggable key={item.type} draggableId={item.type} index={index}>
              {(provided, snapshot) => (
                <>
                  <PaletteItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {item.label}
                  </PaletteItem>
                  {snapshot.isDragging && <Clone>{item.label}</Clone>}
                </>
              )}
            </Draggable>
          )
      )}
    </>
  );
};

const paletteItems = [
  { type: 'textArea', label: 'Text Area', icon: '' },
  { type: 'textInput', label: 'Text Input', icon: '', isImport: true },
  { type: 'computed', label: 'Calculated', icon: '' },
  { type: 'numberInput', label: 'Number Input', icon: '', isImport: true },
  { type: 'date', label: 'Date', icon: '' },
  { type: 'time', label: 'Time', icon: '' },
  { type: 'textheader', label: 'Text Header', icon: '' },
  { type: 'radio', label: 'Radio', icon: '' },
  { type: 'barcode', label: 'Barcode', icon: '' },
  { type: 'location', label: 'Location', icon: '' },
  { type: 'polyline', label: 'Create Polyline (Web Only)', icon: '' },
  { type: 'mapAggregateSelector', label: 'Location Aggregate Selector', icon: '' },
  { type: 'select', label: 'Dropdown', icon: '' },
  { type: 'checkbox', label: 'Checkbox', icon: '' },
  { type: 'group', label: 'Group', icon: '', isImport: true },
  { type: 'imageupload', label: 'Image Upload', icon: '' },
  { type: 'fileupload', label: 'File Upload', icon: '' },
  // { type: 'audioupload', label: 'Audio Upload', icon: '' },
  // { type: 'videoupload', label: 'Video Upload', icon: '' },
];

const PaletteItem = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  padding: 10px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const PaletteHeader = styled.div`
  background: #ffffff;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  display: flex;
  height: auto;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.87);
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
`;

const Clone = styled(PaletteItem)`
  + div {
    display: none !important;
  }
`;
