import * as React from 'react';
import { Dialog } from '@material-ui/core';

interface PromiseProps {
  resolve: <T>(result: T) => void;
  reject: () => void;
}

export type PageComponentType = React.FC<{
  onClose: () => void;
  onSubmit: <T>(result: T) => void;
}>;

function a<T>(
  pageComponent: PageComponentType,
  disableClosingByClickingOutside?: boolean
): Promise<
  T extends React.FC<infer E>
    ? E extends { onSubmit: (...args: any[]) => void }
      ? Parameters<E['onSubmit']>[0]
      : unknown
    : unknown
> {
  return Promise.reject();
}

const PageDialogServiceContext = React.createContext<typeof a>(Promise.reject);

export const usePageDialog = () => React.useContext(PageDialogServiceContext);

export const PageDialogServiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [Page, setPage] = React.useState<PageComponentType | null>(null);
  const [
    disableClosingByClickingOutside,
    setDisableClosingByClickingOutside,
  ] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<PromiseProps>();

  const openPageDialog = (
    pageComponent: PageComponentType | undefined,
    disableClosingByClickingOutside?: boolean
  ) => {
    if (disableClosingByClickingOutside) setDisableClosingByClickingOutside(true);
    pageComponent && setPage(() => pageComponent);

    return new Promise((resolve: <T>(result: T) => void, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    awaitingPromiseRef.current?.reject();
    setDisableClosingByClickingOutside(false);
    setPage(null);
  };

  const handleSubmit = <T,>(result: T) => {
    awaitingPromiseRef.current?.resolve(result);
    setPage(null);
  };

  return (
    <>
      <PageDialogServiceContext.Provider value={openPageDialog as typeof a}>
        {children}
      </PageDialogServiceContext.Provider>
      <Dialog
        open={Boolean(Page)}
        onClose={disableClosingByClickingOutside ? undefined : handleClose}
        maxWidth="xl"
        PaperProps={{ style: { minWidth: 650 } }}
      >
        {Page && <Page onClose={handleClose} onSubmit={handleSubmit} />}
      </Dialog>
    </>
  );
};
