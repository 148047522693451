import React, { useContext } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { ConfigContext } from '../../../../context/ConfigContext';
import { AggregatesList } from './AggregatesList';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { CollapsibleMenuCard } from '../../../../components/AppContainer/CollapsibleMenuCard';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { SingleTextInputForm } from '../../../../components/FormDialog/SingleTextInputForm';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';

export const AggregatesMenu: React.FC = () => {
  const { getAggregates } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const AggregateAPI = useAggregateAPI();

  const handleAddAggregate = async () => {
    const aggrName = await formDialog<typeof SingleTextInputForm>((props) => (
      <SingleTextInputForm title="Create new aggregate" {...props} />
    ));
    if (!isValid(aggrName)) return;
    const { error } = await AggregateAPI.addAggregate(aggrName);
    if (error) return;
    successMsg(`The aggregate "${aggrName}" has been successfully added`);
  };

  const isValid = (aggrName: string) => {
    if (ifAggregateExists(aggrName)) {
      errorMsg(`An aggregate named "${aggrName}" algready exists`);
      return false;
    }
    return true;
  };

  const ifAggregateExists = (aggrName: string) =>
    getAggregates().some(
      (e: { typeName: string }) => e.typeName.toUpperCase() === aggrName.toUpperCase()
    );

  return (
    <CollapsibleMenuCard title="Aggregates">
      <AggregatesList />
      <ButtonsContainer>
        <IconButton
          style={{ color: '#1e88e5' }}
          component="span"
          onClick={() => handleAddAggregate()}
        >
          <AddIcon />
        </IconButton>
      </ButtonsContainer>
    </CollapsibleMenuCard>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;
