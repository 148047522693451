import React, { FunctionComponent } from 'react';
import { V2NumberTemplateComponent } from '@terragotech/page-renderer';
import styled from 'styled-components';
import { NestedPageControlProps } from '../PageTypes';
import { StandardInputFrame, Label } from './Common';

const Placeholder = styled.div`
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  color: #aaaaaa;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const NumberInputControl: FunctionComponent<NestedPageControlProps<V2NumberTemplateComponent>> = (
  props
) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.displayOptions)}
      />
      <Placeholder>{controlDefinition.placeholder}</Placeholder>
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default NumberInputControl;
