import React, { useContext, useRef, useState } from 'react';
import { Paper } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import styled from 'styled-components';
import { ConfigContext } from '../../../context/ConfigContext';
import { aggregatePropertiesTableColumns } from '../components/aggregatePropertiesTableColumns';
import { successMsg, errorMsg } from '../../../components/SnackbarUtilsConfigurator';
import { cloneDeep, startCase } from 'lodash';
import { EditableTable, MaterialTableRef, CSVResult } from '../../../components/EditableTable';
import { useParams, Prompt } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { MoveItemToIndexForm } from '../../../components/FormDialog/MoveItemToIndexForm';
import { useFormDialog } from '../../../components/FormDialog/FormDialogService';
import { propertiesObjsToArray } from '../utils/propertiesObjsToArray';
import { aggregateTableRowToProperty } from '../utils/aggregateTableRowToProperty';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import { AggrPropertyRow } from '../../../utils/types';
import { DialogActions, Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Papa from 'papaparse';
import { ValidOptions } from '../components/ValidOptionsCustomInput';

let CSVResultData: CSVResult;

export interface OtherProps {
  tableData?: number;
  validOptions: ValidOptions | string;
}
export type PartialAggrPropertyRowWithOtherProps = Partial<AggrPropertyRow> & OtherProps;
export const AggregatePropertiesEditor: React.FC = () => {
  const { config, setConfig } = useContext(ConfigContext);
  const formDialog = useFormDialog();
  const AggregateAPI = useAggregateAPI();
  const [CSVResultOpen, setcSVResultOpen] = useState(false);

  const { aggregate: aggrName } = useParams() as { aggregate: string };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const tableRef = useRef<MaterialTableRef>(null);

  const propertiesArray = propertiesObjsToArray(
    cloneDeep(config?.aggregates?.[aggrIndex].properties)
  );

  const rowToPropertyObj = (row: AggrPropertyRow) => {
    const property = { ...row };
    const { name } = row;
    //@ts-ignore
    delete property.name;
    return { [name]: property } as object;
  };

  const addProperty = async (row: object, resolve: (data: any) => void, reject: () => void) => {
    if (isPropertyInvalid(row)) return reject();
    const property = aggregateTableRowToProperty(row as AggrPropertyRow);
    const { error } = await AggregateAPI.addAggregateProperty(aggrIndex, property);
    if (error) return reject();
    successMsg(`Property "${(row as AggrPropertyRow).name}" has been successfully added`);
    return resolve(null);
  };

  const updateProperty = async (
    newRow: object,
    oldRow: object | undefined,
    resolve: (data: any) => void,
    reject: () => void
  ) => {
    if (isPropertyInvalid(newRow, oldRow)) return reject();
    const oldPropertyName = (oldRow as AggrPropertyRow).name;
    const property = aggregateTableRowToProperty(newRow as AggrPropertyRow);
    const { error } = await AggregateAPI.updateAggregateProperty(
      aggrIndex,
      oldPropertyName,
      property
    );
    if (error) return reject();

    successMsg(`Property "${(newRow as AggrPropertyRow).name}" has been successfully updated`);
    return resolve(null);
  };
  const CSVFileImport = (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: any) => void,
    reject: () => void
  ) => {
    if (aggrIndex !== null && config?.aggregates?.[aggrIndex]?.properties) {
      const newConfig = cloneDeep(config);
      const newProps = Object.assign(
        {},
        ...rows.map((row) => {
          return rowToPropertyObj(row as AggrPropertyRow);
        })
      );

      newConfig.aggregates[aggrIndex].properties = newProps;
      setcSVResultOpen(true);
      CSVResultData = uploadedResults;
      setConfig(newConfig);
      successMsg(`CSV file successfully uploaded`);
      return resolve(null);
    }
    errorMsg(`Error while uploading CSV file`);
    return reject();
  };

  const deleteProperty = async (rowToDel: object) => {
    const property = aggregateTableRowToProperty(rowToDel as AggrPropertyRow);
    const { error } = await AggregateAPI.deleteAggregateProperty(aggrIndex, property);
    if (error) return;
    successMsg(`Property "${(rowToDel as AggrPropertyRow).name}" has been successfully deleted`);
  };

  const isPropertyInvalid = (row: object, oldRow?: object) => {
    if (!(row as AggrPropertyRow).name) return errorMsg('Property "Name" is required');
    if (!(row as AggrPropertyRow).label) return errorMsg('Property "Label" is required');
    if (!(row as AggrPropertyRow).type) return errorMsg('Property "Type" is required');
    if (oldRow) {
      if (
        (row as AggrPropertyRow).name in config.aggregates[aggrIndex].properties &&
        (row as AggrPropertyRow).name !== (oldRow as AggrPropertyRow)?.name
      )
        return errorMsg(`Property "${(row as AggrPropertyRow).name}" already exists`);
    } else {
      if ((row as AggrPropertyRow).name in config.aggregates[aggrIndex].properties)
        return errorMsg(`Property "${(row as AggrPropertyRow).name}" already exists`);
    }
    return false;
  };

  const getAggregateTypes = () => {
    const aggrTypes: { [key: string]: string } = {};
    config?.aggregates.forEach((aggr: { typeName: string }) => {
      aggrTypes[aggr.typeName] = aggr.typeName;
    });
    return aggrTypes;
  };

  const moveRow = async (
    rowName: string,
    moveType: 'UP' | 'DOWN' | 'INDEX',
    targetIndex?: number
  ) => {
    const { error } = await AggregateAPI.moveAggregateProperty(
      aggrIndex,
      rowName,
      moveType,
      targetIndex
    );
    if (error) return;
    if (moveType === 'UP') successMsg(`Property "${rowName}" has been moved up`);
    if (moveType === 'DOWN') successMsg(`Property "${rowName}" has been moved down`);
    if (moveType === 'INDEX')
      successMsg(`Property "${rowName}" has been moved to index: ${targetIndex}`);
  };

  const ifShowMessageBeforeRedirect = () => {
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    return lastEditingRow || showAddRow
      ? 'The form has not been saved, do you want to redirect?'
      : true;
  };

  const moveRowToIndex = async (rowName: string) => {
    const targetIndex = await formDialog<typeof MoveItemToIndexForm>((props) => (
      <MoveItemToIndexForm aggrIndex={aggrIndex} propertyName={rowName} {...props} />
    ));
    moveRow(rowName, 'INDEX', targetIndex);
  };
  const onCSVResultClose = () => {
    setcSVResultOpen(false);
  };
  const additionalActions = [
    {
      icon: () => <KeyboardArrowUpIcon />,
      tooltip: 'Move up',
      onClick: (_: object, rowData: object) => moveRow((rowData as AggrPropertyRow).name, 'UP'),
    },
    {
      icon: () => <KeyboardArrowDownIcon />,
      tooltip: 'Move down',
      onClick: (_: object, rowData: object) => moveRow((rowData as AggrPropertyRow).name, 'DOWN'),
    },
    {
      icon: () => <FormatListNumberedIcon />,
      tooltip: 'Move to order',
      onClick: (_: object, rowData: object) => moveRowToIndex((rowData as AggrPropertyRow).name),
    },
  ];

  const getDateTime = () => {
    const dateIn = new Date();
    const yyyy = dateIn.getFullYear();
    const mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    const dd = dateIn.getDate();
    return `${yyyy}${mm}${dd}-${dateIn.getHours()}:${dateIn.getMinutes()}`;
  };

  const removeUnwantedProp = (data: PartialAggrPropertyRowWithOtherProps[]) => {
    const columnsToCheck = ['relation', 'foreignColumn', 'filterOptions', 'validOptions', 'uiType'];
    columnsToCheck.forEach((column) => {
      const columnPropExists = data.some(
        (rowData: PartialAggrPropertyRowWithOtherProps) => rowData.hasOwnProperty(column) === true
      );

      if (columnPropExists) {
        data.forEach((rowData: PartialAggrPropertyRowWithOtherProps) => {
          switch (column) {
            case 'relation':
              if (!rowData.relation) rowData.relation = undefined;
              break;

            case 'foreignColumn':
              if (!rowData.foreignColumn) rowData.foreignColumn = undefined;
              break;

            case 'filterOptions':
              if (!rowData.filterOptions) rowData.filterOptions = undefined;
              break;

            case 'validOptions':
              if (!rowData.validOptions) (rowData.validOptions as string) = '';
              break;

            case 'uiType':
              if (!rowData.uiType) rowData.uiType = undefined;
              break;
          }
        });
      }
    });
  };

  const handleExport = (columns: object[], data: object[]) => {
    const clonedData = cloneDeep(data);
    const aggrPropsData = clonedData as PartialAggrPropertyRowWithOtherProps[];
    const reStructuredData = aggrPropsData.map((rowData, rowNo) => {
      const obj = {} as PartialAggrPropertyRowWithOtherProps;
      delete rowData.tableData;
      obj.order = rowData.order || rowNo++;
      !rowData.hasOwnProperty('label') ? (obj.label = '') : (obj.label = rowData.label);
      !rowData.hasOwnProperty('name') ? (obj.name = '') : (obj.name = rowData.name);
      !rowData.hasOwnProperty('type') ? (obj.type = undefined) : (obj.type = rowData.type);
      !rowData.hasOwnProperty('nullable')
        ? (obj.nullable = false)
        : (obj.nullable = rowData.nullable);
      !rowData.hasOwnProperty('filterable')
        ? (obj.filterable = false)
        : (obj.filterable = !rowData.filterable ? false : rowData.filterable);
      !rowData.hasOwnProperty('editable')
        ? (obj.editable = false)
        : (obj.editable = !rowData.editable ? false : rowData.editable);
      !rowData.hasOwnProperty('indexed')
        ? (obj.indexed = false)
        : (obj.indexed = !rowData.indexed ? false : rowData.indexed);
      !rowData.hasOwnProperty('hideFromAssetAttributes')
        ? (obj.hideFromAssetAttributes = false)
        : (obj.hideFromAssetAttributes = !rowData.hideFromAssetAttributes
            ? false
            : rowData.hideFromAssetAttributes);
      !rowData.hasOwnProperty('includeInFullText')
        ? (obj.includeInFullText = false)
        : (obj.includeInFullText = rowData.includeInFullText);

      if (rowData.hasOwnProperty('filterOptions')) {
        if (rowData.filterOptions !== undefined) obj.filterOptions = rowData.filterOptions;
      }
      if (rowData.hasOwnProperty('relation')) {
        if (rowData.relation !== undefined) obj.relation = rowData.relation;
      }

      if (rowData.hasOwnProperty('foreignColumn')) {
        if (rowData.foreignColumn !== undefined) obj.foreignColumn = rowData.foreignColumn;
      }

      if (rowData.validOptions) {
        if ((rowData.validOptions as ValidOptions).enum) {
          if ((rowData.validOptions as ValidOptions).enum.toString().length > 0) {
            (obj.validOptions as string) = (rowData.validOptions as ValidOptions).enum.toString();
          }
        }
      }

      if (rowData.hasOwnProperty('uiType')) {
        if (rowData.uiType !== undefined) obj.uiType = rowData.uiType;
      }

      return obj;
    });

    removeUnwantedProp(reStructuredData as PartialAggrPropertyRowWithOtherProps[]);

    const exportData = reStructuredData.map((obj) =>
      Object.fromEntries(Object.entries(obj).map(([key, value]) => [startCase(key), value]))
    );

    const fileName = `${aggrName}-properties-${getDateTime()}`;

    const csv = Papa.unparse(exportData, { header: true, skipEmptyLines: true });
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    if ((navigator as any).msSaveBlob) {
      csvURL = (navigator as any).msSaveBlob(csvData, `${fileName}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    const tempLink = document.createElement('a') as HTMLAnchorElement;
    tempLink.href = csvURL as string;
    tempLink.setAttribute('download', `${fileName}.csv`);
    tempLink.click();
  };

  return (
    <PropertiesContainer>
      <EditableTable
        title="Properties"
        options={{
          headerStyle: { position: 'sticky', top: 0 },
          maxBodyHeight: 'calc(100vh - 238px)',
          search: false,
          sorting: false,
          draggable: false,
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportAllData: false,
        }}
        columns={aggregatePropertiesTableColumns(getAggregateTypes())}
        data={propertiesArray}
        onAdd={addProperty}
        onFileImport={CSVFileImport}
        onExport={handleExport}
        fileType="propertyList"
        onUpdate={updateProperty}
        onDelete={deleteProperty}
        actions={additionalActions}
        tableRef={tableRef}
      />
      <Dialog
        onClose={onCSVResultClose}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        open={CSVResultOpen}
      >
        <DialogTitle id="alert-dialog-title">Imported CSV Results</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            The number of existing properties before the import:{' '}
            {CSVResultData?.existingRecordCount}
          </Typography>
          <Typography variant="subtitle1">
            The number of property records from the CSV that were imported:{' '}
            {CSVResultData?.newRecordCount}
          </Typography>
          <Typography variant="subtitle1">
            The number of property records from the CSV that were invalid:{' '}
            {CSVResultData?.badRecordCount}
          </Typography>
          <Typography variant="subtitle1">
            The number of properties after the import: {CSVResultData?.afterImportCount}
          </Typography>
          {CSVResultData?.badRecords && (
            <Typography variant="subtitle1">
              The number of Bad Records from CSV file on <b>lines</b>:{CSVResultData?.badRecords}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCSVResultClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Prompt message={ifShowMessageBeforeRedirect} />
    </PropertiesContainer>
  );
};

const PropertiesContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  overflow-x: auto;
  height: calc(100vh - 122px);
`;
