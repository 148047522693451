import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { V2GroupComponentDef, V2TextHeaderComponent } from '@terragotech/form-renderer';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import collapseImg from './img/Collapse.png';
import { FormControlProps } from '../FormTypes';
import { controlMap } from '.';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import blue from '@material-ui/core/colors/blue';
import { ConditionalVisibilityBadge } from './Common';

const GroupControl: FunctionComponent<FormControlProps<V2GroupComponentDef> & { name: string }> = ({
  controlDefinition,
  name,
  isDropDisabled,
  selectedItems,
  setSelectedItems,
  focusedItem,
  editItem,
  deleteItem,
  lastPastedFormGroup,
}) => {
  const { template } = controlDefinition;

  const existsInSelectedItems = (componentName: string) =>
    (selectedItems.components[name] as V2GroupComponentDef)?.template.components.hasOwnProperty(
      componentName
    );

  return (
    <Panel>
      <PanelHeader>
        <CollapseHolder>
          <img src={collapseImg} alt="Collapse Button" />
        </CollapseHolder>

        <PanelTitle>
          {controlDefinition.label}
          {controlDefinition.conditionalMap && (
            <ConditionalVisibilityBadge style={conditionalVisibilityBadgeStyle} />
          )}
        </PanelTitle>
      </PanelHeader>

      <PanelBody>
        <Droppable droppableId={name} isDropDisabled={isDropDisabled}>
          {(provided) => (
            //@ts-ignore
            <GroupInner {...provided.droppableProps} ref={provided.innerRef}>
              {template.order.map((componentName, index) => {
                // "as V2TextHeaderComponent" hardcoded because a strange TS issue occures"
                const component = template.components[componentName] as V2TextHeaderComponent;
                const Control = component && controlMap[component.type];
                if (Control) {
                  return (
                    <Draggable
                      key={name + componentName}
                      draggableId={name + componentName}
                      index={index}
                      isDragDisabled={isDropDisabled}
                    >
                      {(provided) => (
                        //@ts-ignore
                        <PanelItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isFocused={focusedItem === name + componentName}
                          isSelected={existsInSelectedItems(componentName)}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            setSelectedItems(event, componentName, name);
                          }}
                        >
                          <Control
                            controlDefinition={component}
                            name={componentName}
                            pasted={lastPastedFormGroup?.template.order.includes(componentName)}
                          />
                          {focusedItem === name + componentName && (
                            <div
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                margin: 3,
                              }}
                            >
                              <IconButton
                                style={{ color: blue[300], padding: 3 }}
                                component="span"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                  editItem(component, componentName, name, index);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                style={{ color: blue[300], padding: 3, marginRight: 3 }}
                                component="span"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                  deleteItem(componentName, name, index);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          )}
                        </PanelItem>
                      )}
                    </Draggable>
                  );
                } else {
                  console.log(`Couldn't find component: ${component.type}`);
                }
                return null;
              })}
              {provided.placeholder}
            </GroupInner>
          )}
        </Droppable>
      </PanelBody>
    </Panel>
  );
};

const Panel = styled.div`
  /* width: 355px; */
  background: #ffffff;
  margin-top: 8px;
`;
const PanelHeader = styled.div`
  height: 22px; // should be 30 total
  padding-top: 8px;
  border-bottom: 1px solid #cccccc;
  text-align: center;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #aaaaaa;
`;
const CollapseHolder = styled.div`
  position: absolute;
  margin-left: 10px;
`;
const PanelTitle = styled.div`
  position: relative;
`;
const PanelBody = styled.div``;
const GroupInner = styled.div`
  min-height: 30px;
`;

const PanelItem = styled.div<{ isFocused?: boolean; isSelected?: boolean }>`
  position: relative;
  /* width: 355px; */
  background: #ffffff;
  color: #aaaaaa;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  background: ${(props) => props.isFocused && '#F4FAFE'};
  border: ${(props) => props.isSelected && `1px solid ${blue[300]}`};
  border-radius: ${(props) => props.isSelected && `3px`};
  &:focus {
    outline: none;
  }
`;
const conditionalVisibilityBadgeStyle: React.CSSProperties = {
  position: 'absolute',
  marginTop: -5,
  marginLeft: 8,
};

export default GroupControl;
