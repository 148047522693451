import * as React from 'react';
import { useState } from 'react';
import { DragDropContext, Droppable, DropResult, DragStart } from 'react-beautiful-dnd';
import { V2FormTemplate, V2GroupComponentDef } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { createFormItem, generateFormItemName } from './defaultFormItems';
import { errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { useMapperRefChanger } from '../../../../utils/useMapperRefChanger';
import { FormEditorCopyMenu } from './FormEditorCopyMenu';
import { FormPalette } from './FormPalette';
import { CommandFormList } from './CommandFormList';
import { cloneDeep } from 'lodash';

interface FormBuildProps {
  formDefinition: V2FormTemplate;
  isImportCommand?: boolean;
  setFormDefinition: (val: V2FormTemplate) => void;
}

const emptySelectedItems = {
  order: [],
  components: {},
};

export const CommandFormEditor: React.FC<FormBuildProps> = ({
  formDefinition,
  isImportCommand,
  setFormDefinition,
}) => {
  const mapperRefChanger = useMapperRefChanger();

  const [groupDragging, setGroupDragging] = useState(false);
  const [selectedItems, setSelectedItems] = useState<V2FormTemplate>(emptySelectedItems);
  const [lastPastedFormTemplate, setLastPastedFormTemplate] = useState<V2FormTemplate | null>(null);
  const [refresh, setRefresh] = useState(true);

  React.useEffect(()=>{
    if(!refresh){
      setRefresh(true)
    }
  }, [refresh])

  const addFormItem = ({ destination, draggableId }: DropResult) => {
    if (!destination) return;

    const addNewItemToForm = () => {
      if (isImportCommand && draggableId !== 'group')
        return errorMsg('Only groups are allowed at the base level of an import form.');
      const name = generateFormItemName(draggableId, form.order);
      form.components[name] = createFormItem(draggableId, name, isImportCommand);
      form.order.splice(index, 0, name);
    };
    const addNewItemToGroup = () => {
      const name = generateFormItemName(draggableId, dropOrder);
      dropComponents[name] = createFormItem(draggableId, name);
      dropOrder.splice(index, 0, name);
    };

    const form = cloneDeep(formDefinition);
    const { droppableId, index } = destination;
    if (droppableId === 'form') {
      addNewItemToForm();
      return setFormDefinition(form);
    }
    const {
      template: { order: dropOrder, components: dropComponents },
    } = form.components[droppableId] as V2GroupComponentDef;
    addNewItemToGroup();
    return setFormDefinition(form);
  };

  const moveItemFromForm = (result: DropResult) => {
    if (!result.destination) return;
    const reorderItemInForm = () => {
      form.order.splice(sourceIndex, 1);
      form.order.splice(index, 0, name);
    };
    const addItemToGroup = () => {
      dropComponents[name] = item;
      dropOrder.splice(index, 0, name);
    };
    const removeItemFromForm = () => {
      form.order.splice(sourceIndex, 1);
      delete form.components[sourceId];
    };

    let form = cloneDeep(formDefinition);
    const { droppableId, index } = result.destination;
    const { droppableId: sourceId, index: sourceIndex } = result.source;
    const name = form.order[sourceIndex];
    if (droppableId === 'form') {
      reorderItemInForm();
      return setFormDefinition(form);
    }
    const item = form.components[name];
    const {
      template: { order: dropOrder, components: dropComponents },
    } = form.components[droppableId] as V2GroupComponentDef;
    if (dropOrder.includes(name))
      return errorMsg(
        'An element with the same name already exists in this group. Rename it before drag'
      );
    removeItemFromForm();
    addItemToGroup();
    form = mapperRefChanger.moveFormReferences(form, name, undefined, droppableId);
    setFormDefinition(form);
  };

  const moveItemFromGroup = (result: DropResult) => {
    if (!result.destination) return;
    const removeItemFromGroup = () => {
      sourceOrder.splice(sourceIndex, 1);
      delete sourceComponents[sourceId];
    };
    const addItemToGroup = () => {
      dropComponents[name] = item;
      dropOrder.splice(index, 0, name);
    };
    const addItemToForm = () => {
      form.components[name] = item;
      form.order.splice(index, 0, name);
    };

    let form = cloneDeep(formDefinition);
    const { droppableId, index } = result.destination;
    const { droppableId: sourceId, index: sourceIndex } = result.source;
    const {
      template: { order: sourceOrder, components: sourceComponents },
    } = form.components[sourceId] as V2GroupComponentDef;
    const name = sourceOrder[sourceIndex];
    const item = sourceComponents[name];

    if (droppableId === 'form') {
      if (form.order.includes(name))
        return errorMsg(
          'An element with the same name already exists in the form. Rename it before drag'
        );
      if (isImportCommand && item.type !== 'group') {
        return errorMsg('Only groups are allowed at the base level of an import form.');
      }
      removeItemFromGroup();
      addItemToForm();
      form = mapperRefChanger.moveFormReferences(form, name, sourceId);
      setFormDefinition(form);
      return;
    }

    const {
      template: { order: dropOrder, components: dropComponents },
    } = form.components[droppableId] as V2GroupComponentDef;

    if (droppableId === sourceId) {
      removeItemFromGroup();
      addItemToGroup();
    } else {
      if (dropOrder.includes(name))
        return errorMsg(
          'An element with the same name already exists in this group. Rename it before drag'
        );
      removeItemFromGroup();
      addItemToGroup();
    }
    form = mapperRefChanger.moveFormReferences(form, name, sourceId, droppableId);
    setFormDefinition(form);
  };

  const handleDragStart = (initial: DragStart) => {
    if (
      formDefinition.components[initial.draggableId]?.type === 'group' ||
      initial.draggableId === 'group'
    ) {
      setGroupDragging(true);
    } else {
      setGroupDragging(false);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    switch (source.droppableId) {
      case 'palette':
        addFormItem(result);
        break;
      case 'form':
        moveItemFromForm(result);
        break;
      default:
        moveItemFromGroup(result);
        break;
    }
    setGroupDragging(false);
    if (draggableId === 'group') {
      setRefresh(false);
    }
  };

  return refresh ? (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <FlexRowContainer>
        {/* @ts-ignore */}
        <FormContainer>
          <Droppable droppableId="form">
            {(droppableProvided) => (
              //@ts-ignore
              <FormInner {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                <CommandFormList
                  formDefinition={formDefinition}
                  setFormDefinition={setFormDefinition}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  lastPastedFormTemplate={lastPastedFormTemplate}
                  setRefresh={setRefresh}
                  groupDragging={groupDragging}
                  isImportCommand={isImportCommand}
                />
                {droppableProvided.placeholder}
              </FormInner>
            )}
          </Droppable>
        </FormContainer>
        {/* @ts-ignore */}
        <Palette>
          <Droppable droppableId="palette" isDropDisabled={true}>
            {(
              droppableProvided //@ts-ignore
            ) => (
              <PaletteInner {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                <FormPalette isImportCommand={isImportCommand} />
                <FormEditorCopyMenu
                  formDefinition={formDefinition}
                  setFormDefinition={setFormDefinition}
                  selectedItems={selectedItems}
                  lastPastedFormTemplate={lastPastedFormTemplate}
                  setLastPastedFormTemplate={setLastPastedFormTemplate}
                />
              </PaletteInner>
            )}
          </Droppable>
        </Palette>
      </FlexRowContainer>
    </DragDropContext>
  ) : null;
};

const FormContainer = styled.div(({ style }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#eeeeee',
  ...style,
}));

const Palette = styled.div`
  width: 180px;
  flex-direction: column;
  align-items: center;
  background: #eeeeee;
`;

const FlexRowContainer = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
`;

const FormInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  flex: 1;
`;

const PaletteInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  position: sticky;
  top: 65px;
`;
