import React, { useState, useContext, useCallback } from 'react';
import { DialogTitle, DialogActions, Button } from '@material-ui/core';
import { TextInput, InputGroup } from '../FormElements';
import { V2GroupComponentDef } from '@terragotech/form-renderer';
import DataMapperDialog from './DataMapperDialog';
import { useConfirm } from 'material-ui-confirm';
import { isEqual } from 'lodash';
import { useFormSchemas } from './hooks/useFormSchemas';
import { FormContext } from './contexts/FormContext';
import { checkDuplicateFormName, DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import { MappingActiveDisplay } from '../MappingActiveDisplay';
import _ from 'lodash';
import { JSONSchema6Definition } from 'json-schema';

export type GroupComponentDefWithName = V2GroupComponentDef & {
  name: string;
};

interface GroupEditFormFormProps {
  onSubmit: (result: GroupComponentDefWithName) => void;
  onClose: () => void;
  component: GroupComponentDefWithName;
  isImportCommand?: boolean;
}

export const GroupEditForm: React.FC<GroupEditFormFormProps> = ({
  onSubmit,
  onClose,
  component,
  isImportCommand,
}) => {
  const confirm = useConfirm();
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState<string>(component.label);
  const [description, setDescription] = useState(component.description);
  const [info, setInfo] = useState(component.info);
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);

  const [computedMap, setComputedMap] = useState(()=>{
    const map = component.computedMap || undefined
    if(map && map.nodes && map.nodes['OUTPUT']){
      map.nodes['OUTPUT'].config = {objectSchema:'COMPUTED'};
    }
    return map;
  }
);
  const [computedOpen, setComputedOpen] = useState(false);

  const formSchemas = useFormSchemas();

  const [existingNameError, setExistingNameError] = useState(false);
  const { formDefinition } = useContext(FormContext);

  const handleClearConditionalMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setConditionalMap(undefined);
  };
  const handleClearComputedMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setComputedMap(undefined);
  };

  const getFormValues = () => ({
    template: component.template,
    type: component.type,
    name: name || '',
    label: label || '',
    ...(description && { description }),
    ...(conditionalMap && { conditionalMap }),
    ...(computedMap && { computedMap }),
    repeats,
    ...(conditionalMap && { conditionalMap }),
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The form has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    if (checkDuplicateFormName(name, component, formDefinition.components)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  const doesComputedHaveValue = useCallback(() => {
    return !_.isEmpty(computedMap);
  }, [computedMap]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <TextInput
        autoFocus
        id="Name"
        error={existingNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Description"
        value={description}
        onChange={(value) => setDescription(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      <TextInput
        id="Info"
        value={info}
        onChange={(value) => setInfo(value)}
        style={{ margin: '10px 25px' }}
        fullWidth={false}
      />
      {!isImportCommand && (
        <InputGroup title="Calculation" style={{ margin: '10px 25px' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button onClick={() => setComputedOpen(true)}>
              <div>
                Advanced
                <MappingActiveDisplay
                  isActive={doesComputedHaveValue()}
                  activeLabelText={'active'}
                />
              </div>
            </Button>
            <Button onClick={handleClearComputedMapper}>Clear</Button>
          </div>
          <DataMapperDialog
            mapScenario="COMPUTED_MAPPING"
            localSchemaDefinitions={{
              ...formSchemas,
              COMPUTED: {
                schemaLabel: 'Result',
                schema: {
                  type: 'object',
                  properties: {
                    result: (formSchemas.FORM.schema?.properties || {})[
                      component.name
                    ] as JSONSchema6Definition,
                  },
                },
              },
            }}
            onClose={() => {
              setComputedOpen(false);
            }}
            open={computedOpen}
            datamap={computedMap}
            setDatamap={setComputedMap}
          />
        </InputGroup>
      )}
      {!isImportCommand && <RepeatableComponent repeats={repeats} setRepeats={setRepeats} />}
      <InputGroup title="Conditionals" style={{ margin: '10px 25px' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button onClick={() => setConditionalOpen(true)}>
            <div>
              Advanced
              <MappingActiveDisplay
                isActive={doesConditionalHaveValue()}
                activeLabelText={'active'}
              />
            </div>
          </Button>
          <Button onClick={handleClearConditionalMapper}>Clear</Button>
        </div>
        <DataMapperDialog
          mapScenario="FORM_FIELD_CONDITIONAL"
          localSchemaDefinitions={formSchemas}
          onClose={() => {
            setConditionalOpen(false);
          }}
          open={conditionalOpen}
          datamap={conditionalMap}
          setDatamap={setConditionalMap}
        />
      </InputGroup>
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
