import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { V2GroupComponentDef } from '@terragotech/page-renderer';
import collapseImg from './img/Collapse.png';
import { PageControlProps } from '../PageTypes';
import { ConditionalVisibilityBadge } from './Common';

const GroupControl: FunctionComponent<PageControlProps<V2GroupComponentDef> & { name: string }> = ({
  controlDefinition,
}) => {
  return (
    <Panel>
      <PanelHeader>
        <CollapseHolder>
          <img src={collapseImg} alt="Collapse Button" />
        </CollapseHolder>

        <PanelTitle>
          {controlDefinition.label}
          {controlDefinition.displayOptions && (
            <ConditionalVisibilityBadge style={conditionalVisibilityBadgeStyle} />
          )}
        </PanelTitle>
      </PanelHeader>
    </Panel>
  );
};

const Panel = styled.div`
  /* width: 355px; */
  background: #ffffff;
  margin-top: 8px;
`;
const PanelHeader = styled.div`
  height: 22px; // should be 30 total
  padding-top: 8px;
  border-bottom: 1px solid #cccccc;
  text-align: center;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #aaaaaa;
`;
const CollapseHolder = styled.div`
  position: absolute;
  margin-left: 10px;
`;
const PanelTitle = styled.div`
  position: relative;
`;
const conditionalVisibilityBadgeStyle: React.CSSProperties = {
  position: 'absolute',
  marginTop: -5,
  marginLeft: 8,
};

export default GroupControl;
