import React, { CSSProperties } from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { Menu as MenuIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import { useAuthContext } from '@terragotech/gen5-shared-components';
import TGLoginButton, { TGLoginButtonType } from 'components/Login/TGLoginButton';
import { useHistory } from 'react-router-dom';

interface Props {
  navDrawerOpen: boolean;
  handleChangeRequestNavDrawer(): void;
}

export const Header: React.FC<Props> = ({ navDrawerOpen, handleChangeRequestNavDrawer }) => {
  const { clearToken, ready, token } = useAuthContext();
  const history = useHistory();

  return (
    <AppBar style={appBarStyle(navDrawerOpen)} title="TerraGo Deployment Manager">
      <Toolbar style={{ paddingLeft: 0 }}>
        <IconButton
          onClick={handleChangeRequestNavDrawer}
          style={{ marginLeft: 5, marginRight: 5 }}
        >
          <MenuIcon style={{ color: 'white' }} />
        </IconButton>
        <Typography variant="h6" style={{ flex: 1 }}>
          TerraGo Deployment Manager
        </Typography>
        {ready && (
          <>
            {!token ? (
              <TGLoginButton
                type={TGLoginButtonType.secondary}
                onClick={() => history.push('/login')}
              >
                Login
              </TGLoginButton>
            ) : (
              <IconButton style={{ marginLeft: 5, marginRight: 5 }} onClick={() => clearToken()}>
                <ExitToAppIcon style={{ color: 'white' }} />
              </IconButton>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

const appBarStyle: (navDrawerOpen: boolean) => CSSProperties = (navDrawerOpen) => ({
  position: 'fixed',
  top: 0,
  overflow: 'hidden',
  maxHeight: 57,
  paddingLeft: navDrawerOpen ? 266 : 0,
  justifyContent: 'center',
});
