import { JSONSchema6 } from 'json-schema';
import SchemaDefinedPortModel, { SchemaDefinedPortModelOptions } from './SchemaDefinedPort';
export interface PortModelMap {
  [index: string]: {
    port?: SchemaDefinedPortModel;
    isArray?: boolean;
    childPorts?: PortModelMap[];
  };
}
export interface BuildSchemaPortModelFromJSONSchemaInput {
  existingMap: PortModelMap;
  schema: JSONSchema6;
  data?: unknown;
  depth?: number;
  label?: string;
  fieldName: string;
  fieldPath?: string;
  inBound: boolean;
  includeSelf?: boolean;
}

/**
 * This is used to flatten a PortModelMap into a single dimensional array of ports
 * @param map a valid PortModelMap
 * @returns an array of port models
 */
export const getAllPortsFromPortModelMap = (map: PortModelMap): SchemaDefinedPortModel[] => {
  const ports: SchemaDefinedPortModel[] = [];

  Object.keys(map).forEach((key) => {
    if (map[key].port) {
      //@ts-ignore
      ports.push(map[key].port);
    }
    if (map[key].childPorts) {
      //@ts-ignore
      map[key].childPorts.forEach((childPort) => {
        ports.push(...getAllPortsFromPortModelMap(childPort));
      });
    }
  });

  return ports;
};
const createOrUpdatePort = (
  existingMap: PortModelMap,
  portOptions: SchemaDefinedPortModelOptions
) => {
  // check into the existing port map to see if a particular port already exists.
  //  this is very very inefficient, but I want to validate first
  const allPorts = existingMap ? getAllPortsFromPortModelMap(existingMap) : [];
  // if(there is an existing port, return it)
  // if not, create a new one and return that
  const foundPort = allPorts.find((port) => port.getName() === portOptions.name);
  if (foundPort) {
    foundPort.updateOptions(portOptions);
    return foundPort;
  } else {
    const newPort = new SchemaDefinedPortModel(portOptions);
    return newPort;
  }
};
/**
 * This function will take a hierarchical JSON Schema and return a map of ports.
 * ### Note, we may also be required to send the data so we can handle arrays.
 * @param schema the JSON schema to build ports from
 * @param path The path to the item to be referenced
 * @param name The name of the referenced item
 * @returns
 */
export const updatePortModelMapFromJSONSchema = ({
  existingMap,
  schema,
  data,
  depth = 0,
  label,
  fieldName,
  fieldPath,
  inBound,
  includeSelf = false,
}: BuildSchemaPortModelFromJSONSchemaInput): PortModelMap => {
  const fieldPathPrefix = fieldPath ? `${fieldPath}.` : '';
  const ports: PortModelMap = {};
  if (schema) {
    if (schema.$ref) {
      ports[fieldName] = {
        ...ports[fieldName],
        port: createOrUpdatePort(existingMap, {
          schema: schema,
          label: `${label || schema.description || fieldName}`,
          depth,
          in: inBound,
          name: `${fieldPath || fieldName}`,
        }),
      };
    } else if (schema.type === 'object') {
      if (!schema?.properties) return {};
      if (includeSelf) {
        // We don't want to include ourself in the top level, but after that, it would be nice to include the current object
        ports[fieldName] = {
          ...ports[fieldName],
          port: createOrUpdatePort(existingMap, {
            schema: schema,
            label: `${label || schema.description || fieldName} object`,
            depth,
            in: inBound,
            name: `${fieldPath}`,
          }),
        };
      }
      //check to see if we have matching data...
      //cycle through the properties
      Object.keys(schema.properties).forEach((key) => {
        const property = (schema.properties as any)[key] as JSONSchema6;
        //this typeof is just to get around a typing issue
        if (property && typeof property === 'object') {
          //Check to see if the property has matching data
          const value = data ? (data as any)[key] : undefined;
          //handle various subTypes
          const childPorts = updatePortModelMapFromJSONSchema({
            existingMap,
            schema: property as JSONSchema6,
            data: value,
            depth: depth + (includeSelf ? 1 : 0),
            label: `${property.description || key}`,
            fieldName: `${key}`,
            fieldPath: `${fieldPathPrefix}${key}`,
            inBound,
            includeSelf: true,
          });

          ports[fieldName] = {
            ...ports[fieldName],
            childPorts: [...(ports[fieldName]?.childPorts || []), childPorts],
          };
        }
      });
    } else if (schema.type === 'array') {
      //for arrays, we need to create a port for the Array itself, as well as a port for each item in the array... right?
      // Other option is an array for the top item, unless the child items are already connected...
      //Either way, I'm thinking we should recurse, once for each value, or at least once if no values
      ports[fieldName] = {
        isArray: true,
        port: createOrUpdatePort(existingMap, {
          schema: schema,
          label: `${schema.description || fieldName} array`,
          depth,
          in: inBound,
          name: `${fieldPath}`,
        }),
      };
      const childPorts: PortModelMap[] = [];
      // Now add the children ports
      const childCount: number = ((data && (data as unknown[]).length) || 1) as number;
      for (let i = 0; i < childCount; i++) {
        childPorts.push(
          updatePortModelMapFromJSONSchema({
            existingMap,
            schema: schema.items as JSONSchema6,
            data: data ? (data as any)[i] : undefined,
            depth: depth + 1,
            label: `${schema.description || fieldName} [${i}]`,
            fieldName: `${fieldName}[${i}]`,
            fieldPath: `${fieldPath}[${i}]`,
            inBound,
            includeSelf: true,
          })
        );
      }
      ports[fieldName].childPorts = childPorts;
    } else if (schema.type || schema.anyOf) {
      //must be some other type, return a port of that type
      return {
        [fieldName]: {
          port: createOrUpdatePort(existingMap, {
            schema: schema,
            label: label || '',
            depth,
            in: inBound,
            //TODO: Figure out a good type for this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            fixedValue: data,
            name: `${fieldPath}`,
          }),
          isArray: false,
        },
      };
    }
  }
  return ports;
};

/**
 * This function helps resolve definition referencesin compelx JSON Schemas. It is not generally used currently
 * @param ref name of the JSONSchema ref
 * @param definitions definitions from the JSON Schema to pull refs from
 * @returns The schema defined by the reference
 */
//TODO: add this back into the schema parser
/*
const getPropertyFromDefinition = (
  ref: string,
  definitions?: { [k: string]: JSONSchema6 }
): JSONSchema6 => {
  const refKey = ref.replace('#/definitions/', '');
  const definition = definitions ? definitions[refKey] : {};

  return definition;
};
*/
