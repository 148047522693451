import React from 'react';
import { IconButton } from '@material-ui/core';
import { LibraryAdd } from '@material-ui/icons';
import { ActionButtonPicker } from '../FormDialog/ActionButtonPicker';
import { useFormDialog } from '../FormDialog/FormDialogService';
import { ActionButtonEditor } from './ActionButtonEditor';
import { getAdditionalElements, isMobile, getName } from './ActionButtonEditorUtils';
import { useUICustomizationAPI } from '../../context/fakeAPIHooks/useUICustomizationAPI';
import { successMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import { ActionButton } from '../../utils/types';

interface ActionButtonToolbarItemProps {
  type: ActionButtonEditor;
  excludedActionButtons: ActionButton[];
  uiType: 'mobileUIConfig' | 'webUIConfig';
  aggregateUICustomization: string;
}

export const ActionButtonToolbarItem: React.FC<ActionButtonToolbarItemProps> = ({
  type,
  excludedActionButtons,
  uiType,
  aggregateUICustomization,
}) => {
  const formDialog = useFormDialog();
  const webOrMobile = isMobile(type) ? 'mobile' : 'web';
  const UICustomizationAPI = useUICustomizationAPI();

  const handleAddActionButtons = async () => {
    const actionButtons = await formDialog<typeof ActionButtonPicker>((props) => (
      <ActionButtonPicker
        additionalActions={getAdditionalElements(type)}
        scanOnlyAggregateName={aggregateUICustomization}
        excludeActionsButtons={excludedActionButtons}
        webOrMobile={webOrMobile}
        {...props}
      />
    ));
    if (actionButtons.length === 0) return errorMsg('No Action Button was selected');
    const { error } = await UICustomizationAPI.addActionButtons(
      uiType,
      aggregateUICustomization,
      getName(type),
      actionButtons
    );
    if (error) return;
    successMsg('Selected Action Buttons has been successfully added');
  };

  return (
    <IconButton
      style={{ padding: 0, marginRight: 20 }}
      component="span"
      onClick={handleAddActionButtons}
    >
      <LibraryAdd />
    </IconButton>
  );
};
