import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useConfirm } from 'material-ui-confirm';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { ConfigContext } from '../../../../context/ConfigContext';
import { EventsTree } from './EventsTree';
import { TreeViewNode } from '../../../../components/TreeViewNode';
import { successMsg, errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { CommandsTree } from './CommandsTree';
import { getAggregateIndex, getExpandedURLArr } from '../../../../utils/navigationUtils';
import { useHistory, useParams } from 'react-router-dom';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { CreateNewCommandForm } from '../../../../components/FormDialog/CreateNewCommandForm';
import { AggregateProperty } from '../../../../utils/types';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';
import { SingleTextInputForm } from '../../../../components/FormDialog/SingleTextInputForm';

export const AggregateList: React.FC = () => {
  const { config, getAggregate, getEvents, getCommands } = useContext(ConfigContext);
  const { aggregate: typeName } = useParams() as { aggregate: string };
  const history = useHistory();
  const confirm = useConfirm();
  const AggregateAPI = useAggregateAPI();
  const formDialog = useFormDialog();

  const aggrIndex = getAggregateIndex(config, typeName);
  const aggregate = getAggregate(aggrIndex);
  const { properties, events, commands } = aggregate;
  const [expandedNodes, setExpandedNodes] = useState<string[] | undefined>(
    getExpandedURLArr(history.location.pathname)
  );
  const handleremoveAggregateProperty = async (type: AggregateProperty, index: number) => {
    const aggrName = aggregate.typeName;
    await confirm({
      description: `All ${type} of the "${aggrName}" aggregate will be permanently removed.`,
      confirmationText: 'Remove',
    });
    const { error } = await AggregateAPI.removeAggregateProperty(index, type);
    if (error) return;
    successMsg(`All ${type} of the "${aggrName}" aggregate has been successfully removed.`);
  };

  const handleAddEvent = async (aggrIndex: number) => {
    const eventName = await formDialog<typeof SingleTextInputForm>((props) => (
      <SingleTextInputForm title="Create new event" {...props} />
    ));
    if (!eventName) return errorMsg('Event name is required');
    if (getEvents(aggrIndex)?.hasOwnProperty(eventName))
      return errorMsg(`An event named "${eventName}" already exists`);
    const { error } = await AggregateAPI.addNewEvent(aggrIndex, eventName);
    if (error) return;
    successMsg(`New event "${eventName}" has been successfully created`);
  };

  const handleAddCommand = async (aggrIndex: number) => {
    const { commandName, type } = await formDialog<typeof CreateNewCommandForm>((props) => (
      <CreateNewCommandForm {...props} />
    ));
    if (getCommands(aggrIndex)?.hasOwnProperty(commandName))
      return errorMsg(`Command named "${commandName}" already exists`);
    const { error } = await AggregateAPI.addNewCommand(aggrIndex, commandName, type);
    if (error) return;
    successMsg(`New command "${commandName}" has been successfully created`);
  };

  return (
    <ScrollableTreeContainer>
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        onNodeSelect={(_: object, nodeId: string) => {
          history.push(nodeId);
        }}
        selected={history.location.pathname}
        style={{ marginRight: 0 }}
        onNodeToggle={(_: object, nodeIds: string[] | undefined) => setExpandedNodes(nodeIds)}
        expanded={expandedNodes}
      >
        <TreeViewNode label="Settings" nodeId={`/aggregates/${typeName}`} color="blue" />
        <TreeViewNode
          label="Properties"
          nodeId={`/aggregates/${typeName}/properties`}
          count={properties ? Object.keys(properties).length : 0}
          onRemove={() => handleremoveAggregateProperty('properties', aggrIndex)}
          color="orange"
        />
        <TreeViewNode
          label="Derived Property Mapping"
          nodeId={`/aggregates/${typeName}/derivedPropertyMapping`}
          onRemove={() => handleremoveAggregateProperty('derivedPropertyMapping', aggrIndex)}
          color="orange"
        />
        <Wrapper>
          <TreeViewNode
            label="Events"
            nodeId={`/aggregates/${typeName}/events`}
            count={events ? Object.keys(events).length : 0}
            onAdd={() => handleAddEvent(aggrIndex)}
            onRemove={() => handleremoveAggregateProperty('events', aggrIndex)}
            color="purple"
          >
            <EventsTree aggregate={aggregate} aggrIndex={aggrIndex} />
          </TreeViewNode>
        </Wrapper>
        <Wrapper>
          <TreeViewNode
            label="Commands"
            nodeId={`/aggregates/${typeName}/commands`}
            count={commands ? Object.keys(commands).length : 0}
            onAdd={() => handleAddCommand(aggrIndex)}
            onRemove={() => handleremoveAggregateProperty('commands', aggrIndex)}
            color="green"
          >
            <CommandsTree aggregate={aggregate} aggrIndex={aggrIndex} />
          </TreeViewNode>
        </Wrapper>
      </TreeView>
    </ScrollableTreeContainer>
  );
};
const ScrollableTreeContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  padding-left: 15px;
  & * ul {
    padding-left: 15px;
  }
`;
