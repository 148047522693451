import React, { useState, useContext, useCallback } from 'react';
import { DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import { TextInput, InputGroup } from '../FormElements';
import { V2TextHeaderComponent } from '@terragotech/form-renderer';
import DataMapperDialog from './DataMapperDialog';
import { useConfirm } from 'material-ui-confirm';
import { isEqual } from 'lodash';
import { useFormSchemas } from './hooks/useFormSchemas';
import { FormContext } from './contexts/FormContext';
import { checkDuplicateFormName, DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import _ from 'lodash';
import { MappingActiveDisplay } from '../MappingActiveDisplay';

interface TextHeaderEditFormProps {
  onSubmit: (result: TextHeaderWithName) => void;
  onClose: () => void;
  component: TextHeaderWithName;
}

export type TextHeaderWithName = V2TextHeaderComponent & { name: string };

export const TextHeaderEditForm: React.FC<TextHeaderEditFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const confirm = useConfirm();
  const [name, setName] = useState(component.name || '');
  const [label, setLabel] = useState(component.label || '');
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const formSchemas = useFormSchemas();

  const [existingNameError, setExistingNameError] = useState(false);
  const { formDefinition } = useContext(FormContext);

  const handleClearConditionalMapper = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    setConditionalMap(undefined);
  };

  const getFormValues = () => ({
    name,
    label,
    type: component.type,
    ...(conditionalMap && { conditionalMap }),
  });

  const isFormDirty = () => !isEqual(component, getFormValues());
  const isForceLeaveConfirmed = () =>
    window.confirm('The form has not been saved, do you want to redirect?');

  const handleClose = () =>
    (!isFormDirty() || (isFormDirty() && isForceLeaveConfirmed())) && onClose();

  const handleSubmit = () => {
    if (checkDuplicateFormName(name, component, formDefinition.components)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };
  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  return (
    <>
      <DialogTitle>{`${component.name} (${component.type})`}</DialogTitle>
      <DialogContent style={{ padding: '0 25px' }}>
        <TextInput
          autoFocus
          id={name}
          error={existingNameError}
          helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
          label="Name"
          value={name}
          onChange={(value) => setName(value || '')}
          style={{ margin: '10px 0' }}
        />
        <TextInput
          autoFocus
          id={label}
          label="Label"
          value={label}
          onChange={(value) => setLabel(value || '')}
          style={{ margin: '10px 0' }}
        />
        <InputGroup title="Conditionals" style={{ margin: '10px 0' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button onClick={() => setConditionalOpen(true)}>
              <div>
                Advanced
                <MappingActiveDisplay
                  isActive={doesConditionalHaveValue()}
                  activeLabelText={'active'}
                />
              </div>
            </Button>
            <Button onClick={handleClearConditionalMapper}>Clear</Button>
          </div>
          <DataMapperDialog
            mapScenario="FORM_FIELD_CONDITIONAL"
            localSchemaDefinitions={formSchemas}
            onClose={() => {
              setConditionalOpen(false);
            }}
            open={conditionalOpen}
            datamap={conditionalMap}
            setDatamap={setConditionalMap}
          />
        </InputGroup>
      </DialogContent>
      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit} autoFocus>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
