import React from 'react';
import { Table, TableBody, TableRow, TableCell, makeStyles, IconButton } from '@material-ui/core';
import { TextInput } from '../../../components/FormElements';
import DeleteIcon from '@material-ui/icons/Delete';
import cloneDeep from 'lodash/cloneDeep';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';

export interface ValidOptions {
  minimum?: number;
  maximum?: number;
  enum: string[];
}

interface ValidOptionsCustomInputProps {
  validOptions: ValidOptions | undefined;
  setValidOptions: (validOptions: ValidOptions) => void;
}

export const ValidOptionsCustomInput: React.FC<ValidOptionsCustomInputProps> = ({
  validOptions = validOptionsDefault,
  setValidOptions,
}) => {
  const classes = useStyles();

  const handleAddValidOptionsEnum = () => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.enum.push('');
    setValidOptions(validOptionsCopy);
  };

  const handleValidOptionsEnumChange = (value: string, index: number) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.enum[index] = value;
    setValidOptions(validOptionsCopy);
  };

  const handleDeleteValidOpttionsEnum = (index: number) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.enum.splice(index, 1);
    setValidOptions(validOptionsCopy);
  };

  const handleMinimumValue = (value: number | undefined) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.minimum = value;
    setValidOptions(validOptionsCopy);
  };

  const handleMaximumValue = (value: number | undefined) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.maximum = value;
    setValidOptions(validOptionsCopy);
  };

  return (
    <ValidOptionsContainer>
      <Table className={classes.table}>
        <TableBody>
          {validOptions.enum.map((item, index) => (
            <TableRow>
              <TableCell style={{ paddingRight: validOptions.enum.length > 1 ? 0 : 16 }}>
                <TextInput
                  value={item}
                  onChange={(value) => handleValidOptionsEnumChange(value || '', index)}
                  id={item}
                  variant="standard"
                  noLabel
                />
              </TableCell>
              {validOptions.enum.length > 1 && (
                <TableCell style={{ width: 49 }}>
                  <IconButton
                    style={{ color: 'black' }}
                    component="span"
                    onClick={() => handleDeleteValidOpttionsEnum(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <AddButtonContainer>
        <IconButton component="span" onClick={handleAddValidOptionsEnum}>
          <AddIcon />
        </IconButton>
      </AddButtonContainer>
      <TextInput
        value={validOptions.minimum}
        onChange={(value) => handleMinimumValue(value ? +value : undefined)}
        id="Minimum"
      />
      <TextInput
        value={validOptions.maximum}
        onChange={(value) => handleMaximumValue(value ? +value : undefined)}
        id="Maximum"
      />
    </ValidOptionsContainer>
  );
};

const validOptionsDefault: ValidOptions = {
  enum: [],
};

const useStyles = makeStyles({
  table: {
    minWidth: 250,
    margin: '0 -16px',
    backgroundColor: '#fbfbfb',
  },
  tableSmall: {
    minWidth: 200,
    margin: '0 -16px',
    backgroundColor: '#fbfbfb',
  },
});

const AddButtonContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

const ValidOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
