import React, { useState, useCallback, useRef } from 'react';
import { WebUIConfig, MobileUIConfig } from '@terragotech/gen5-config-lib';
import {
  Button,
  Divider,
  FormHelperText,
  Slider,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import styled from 'styled-components';
import { InputGroup, TextInput, ColorPicker, SelectInput } from './FormElements';
import { EditableTable, MaterialTableRef } from './EditableTable';
import { Prompt } from 'react-router-dom';
import { isEqual } from 'lodash';
import { warningMsg, errorMsg } from './SnackbarUtilsConfigurator';
import { CheckboxInput } from './FormElements/CheckboxInput';
import Typography from '@material-ui/core/Typography';
import { getSymbolKeyColumns } from '../utils/jsonPartsGenerators';
import SymbolScaleContextProvider from '../context/symbolScaleContext/symbolScaleContext';

export interface SymbolProps {
  preview?: number;
  name: string;
  symbolKey: string;
}
const columns = getSymbolKeyColumns();

type UIConfig = MobileUIConfig | WebUIConfig;
type UIGeneralType = 'Web' | 'Mobile';

interface UIGeneralProps {
  type: UIGeneralType;
  UIConfig: UIConfig;
  onSave: (UIConfig: UIConfig) => void;
}

interface SplitScreen {
  splitScreen?: {
    enabled?: boolean;
    defaultOn?: boolean;
  };
}

export const UIGeneralEditor: React.FC<UIGeneralProps> = ({
  type,
  UIConfig: UIConfigObj,
  onSave,
}) => {
  const [UIConfig, setUIConfig] = useState(UIConfigObj);
  const [symbolScale, setSymbolScale] = useState<number>(
    UIConfigObj?.symbolScaleFactor ? Number((UIConfigObj?.symbolScaleFactor * 100).toFixed(2)) : 100
  );
  const [enableSplitScrn, setEnableSplitScrn] = useState(
    (UIConfigObj?.enabledFeatures as SplitScreen)?.splitScreen?.enabled || false
  );

  const getSplitScrnVal = () => {
    if ((UIConfigObj?.enabledFeatures as SplitScreen)?.splitScreen?.defaultOn) {
      return 'On';
    } else {
      return 'Off';
    }
  };
  const [splitScrnDefault, setSplitScrnDefault] = useState(getSplitScrnVal());
  const [symbolLegend, setSymbolLegend] = useState<SymbolProps[]>(UIConfigObj?.symbolLegend || []);
  const tableRef = useRef<MaterialTableRef>(null);

  const handleInitialMapExtentsChange = (node: string, value: number | undefined) =>
    setUIConfig({
      ...UIConfig,
      initialMapExtents: { ...UIConfig.initialMapExtents, [node]: value },
    });

  const handleLabelChange = (node: string, value: string | boolean | undefined) =>
    setUIConfig({
      ...UIConfig,
      labels: { ...UIConfig.labels, [node]: value },
    });

  const handleThemeChange = (node: string, value: string | boolean | undefined) =>
    setUIConfig({
      ...UIConfig,
      theme: { ...UIConfig.theme, [node]: value },
    });

  const handleLineChange = (node: string, value: string) =>
    setUIConfig({
      ...UIConfig,
      line: { ...UIConfig.line, [node]: value } as { editColor: string },
    });

  const isSymbolInvalid = useCallback((row: object) => {
    if (!(row as SymbolProps).name) {
      errorMsg('Property "Name" is required');
      return true;
    }
    if (!(row as SymbolProps).symbolKey) {
      errorMsg('Property "Symbol Key" is required');
      return true;
    }
    return false;
  }, []);

  const handleAddSymbol = useCallback(
    (symbol: object, resolve: (data: any) => void, reject: () => void) => {
      if (isSymbolInvalid(symbol)) return reject();
      setSymbolLegend([...symbolLegend, symbol as SymbolProps]);
      resolve(null);
    },
    [symbolLegend, setSymbolLegend, isSymbolInvalid]
  );

  const handleDeleteSymbol = useCallback(
    (symbol: object) => {
      setSymbolLegend(symbolLegend.filter((item) => item.name !== (symbol as SymbolProps).name));
    },
    [symbolLegend, setSymbolLegend]
  );

  const handleUpdateSymbol = useCallback(
    (symbol: object, oldSymbol: object, resolve: (data: any) => void, reject: () => void) => {
      if (oldSymbol) {
        if (isSymbolInvalid(symbol)) return reject();
        const symbolLegendCopy = [...symbolLegend];
        const index = symbolLegendCopy.findIndex(
          (item) => item.name === (oldSymbol as SymbolProps).name
        );
        symbolLegendCopy[index] = symbol as SymbolProps;
        setSymbolLegend(symbolLegendCopy);
        resolve(null);
      } else {
        reject();
      }
    },
    [symbolLegend, setSymbolLegend, isSymbolInvalid]
  );

  const handleSave = () => {
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    if (lastEditingRow || showAddRow)
      return warningMsg(
        'Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form'
      );
    // we are casting here, because we need to be able to set the symbol scale which is only present on the web config currently
    const UIConfigCopy = UIConfig as WebUIConfig;
    // The material table adds a tableData field that this map helps filter out.
    UIConfigCopy.symbolLegend = symbolLegend.map((symbol) => ({
      name: symbol.name,
      symbolKey: symbol.symbolKey,
    }));
    UIConfigCopy.symbolScaleFactor = symbolScale / 100;
    onSave(UIConfigCopy);
  };

  const ifShowMessageBeforeRedirect = () => {
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    return !isEqual(UIConfig, UIConfigObj) || lastEditingRow || showAddRow
      ? 'The form has not been saved, do you want to redirect?'
      : true;
  };

  const isWebUIConfig = (UIConfig: UIConfig): UIConfig is WebUIConfig => type === 'Web';
  const isMobileUIConfig = (UIConfig: UIConfig): UIConfig is MobileUIConfig => type === 'Mobile';

  const handleAttributeSearchOnMap = (checked: boolean) => {
    if (isWebUIConfig(UIConfig))
      setUIConfig({
        ...UIConfig,
        enabledFeatures: { ...UIConfig.enabledFeatures, attributeSearchOnMap: checked },
      });
  };

  const handleAnalyticsEnabled = (checked: boolean) => {
    if (isWebUIConfig(UIConfig))
      setUIConfig({
        ...UIConfig,
        enabledFeatures: { ...UIConfig.enabledFeatures, analyticsModule: checked },
      });
  };

  const handleEnableSplitScrn = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMobileUIConfig(UIConfig))
      setUIConfig({
        ...UIConfig,
        enabledFeatures: {
          ...UIConfig.enabledFeatures,
          splitScreen: {
            enabled: event.target.checked,
            defaultOn: event.target.checked
              ? false
              : UIConfig.enabledFeatures?.splitScreen?.defaultOn,
          },
        },
      });
    setEnableSplitScrn(event.target.checked);
    if (!event.target.checked) setSplitScrnDefault('Off');
  };

  const handleSplitScrnDefault = (val: string) => {
    if (isMobileUIConfig(UIConfig))
      setUIConfig({
        ...UIConfig,
        enabledFeatures: {
          ...UIConfig.enabledFeatures,
          splitScreen: {
            ...UIConfig.enabledFeatures?.splitScreen,
            defaultOn: val === 'On' ? true : false,
          },
        },
      });
    setSplitScrnDefault(val);
  };

  const valuetext = useCallback((value: number) => `${value}%`, []);

  const handleSliderChange = useCallback(
    (_unused: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setSymbolScale(newValue as number);
    },
    []
  );

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSymbolScale(Number(event?.target?.value));
  }, []);

  return (
    <>
      <HeaderContainer>
        <CardTitle>General {type} UI Config</CardTitle>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSave()}
          style={{ margin: '10px 0' }}
        >
          Save
        </Button>
      </HeaderContainer>
      <GroupDivider />
      <FlexContainer>
        <FlexColumn>
          <InputGroup title="Initial Map Extents:">
            <TextInput
              id="lat"
              label="Latitude"
              type="number"
              value={UIConfig.initialMapExtents.lat}
              onChange={(value) => handleInitialMapExtentsChange('lat', value ? +value : undefined)}
            />
            <TextInput
              id="lon"
              type="number"
              label="Longitude"
              value={UIConfig.initialMapExtents.lon}
              onChange={(value) => handleInitialMapExtentsChange('lon', value ? +value : undefined)}
            />
            <TextInput
              id="zoom"
              type="number"
              label="Zoom Level"
              value={UIConfig.initialMapExtents.zoom}
              onChange={(value) =>
                handleInitialMapExtentsChange('zoom', value ? +value : undefined)
              }
            />
          </InputGroup>
          <InputGroup title="Labels:">
            <TextInput
              id="applicationName"
              value={UIConfig.labels.applicationName}
              onChange={(value) => handleLabelChange('applicationName', value)}
            />
            <TextInput
              id="copyrightInfo"
              value={UIConfig.labels.copyrightInfo}
              onChange={(value) => handleLabelChange('copyrightInfo', value)}
            />
          </InputGroup>
          <InputGroup title="Theme:">
            {type === 'Web' && (
              <>
                <ColorPicker
                  title="Primary"
                  value={UIConfig.theme.primary}
                  onChange={(value) => handleThemeChange('primary', value)}
                />
                <ColorPicker
                  title="Secondary"
                  value={UIConfig.theme.secondary}
                  onChange={(value) => handleThemeChange('secondary', value)}
                />
              </>
            )}
            <TextInput
              id="logoUrl"
              value={UIConfig.theme.logoUrl}
              onChange={(value) => handleThemeChange('logoUrl', value)}
            />
          </InputGroup>
          <InputGroup title="Line:">
            <ColorPicker
              title="Line edit color"
              summaryBox="The color of the line that the user sees when editing the line."
              value={UIConfig.line?.editColor || '#6CF3E9'}
              onChange={(value) => handleLineChange('editColor', value)}
            />
          </InputGroup>
          {isMobileUIConfig(UIConfig) && (
            <InputGroup title="Layout:">
              <FormContainer>
                <FormControlLabel
                  control={
                    <Switch
                      checked={enableSplitScrn}
                      onChange={handleEnableSplitScrn}
                      color="primary"
                    />
                  }
                  label="Enable Spilt Screen on wide devices in landscape"
                />

                {enableSplitScrn && (
                  <>
                    <SelectInput
                      title="Split Screen Default"
                      value={splitScrnDefault}
                      onChange={(value: string) => handleSplitScrnDefault(value)}
                      options={['On', 'Off']}
                    />
                    <FormHelperText>
                      Set wether or not mobile devices with wide screens have split view on or off
                      by default.
                    </FormHelperText>
                  </>
                )}
              </FormContainer>
            </InputGroup>
          )}
          {isWebUIConfig(UIConfig) && (
            <InputGroup title="Enabled Features:">
              <CheckboxInput
                title="Attribute Search On Map"
                checked={UIConfig.enabledFeatures?.attributeSearchOnMap}
                onChange={(checked) => handleAttributeSearchOnMap(checked)}
              />
              <CheckboxInput
                title="Analytics Module"
                checked={UIConfig.enabledFeatures?.analyticsModule}
                onChange={(checked) => handleAnalyticsEnabled(checked)}
              />
            </InputGroup>
          )}
        </FlexColumn>
        <FlexColumn>
          <InputGroup title="Symbol Legend:" style={{ flex: 1 }}>
            <Typography style={{ paddingLeft: '10px' }} variant="h6">
              Map Symbols
            </Typography>
            <FormHelperText>{`symbol_circle_<color_as_hex>[_<text_or_unicode_glyph>[_<internal_symbol_scale_1.0_to_2.0>]]`}</FormHelperText>
            <FormHelperText>{`line_<style:'solid'|'dotted'>_[<width>][_<color_as_hex>]`}</FormHelperText>
            <SymbolScaleContextProvider scale={symbolScale}>
              <EditableTable
                data={symbolLegend}
                options={tableOptions}
                columns={columns}
                onAdd={handleAddSymbol}
                onUpdate={handleUpdateSymbol}
                onDelete={handleDeleteSymbol}
                tableRef={tableRef}
              />
            </SymbolScaleContextProvider>

            <Typography id="symbol-scale-slider" style={{ marginTop: 10 }} variant="subtitle1">
              Scale map symbol size
            </Typography>
            <FormHelperText>See symbols above for preview. Default size is 100% </FormHelperText>
            <SliderContainer>
              <Slider
                max={200}
                min={1}
                value={symbolScale}
                onChange={handleSliderChange}
                aria-labelledby="symbol-scale-slider"
                getAriaValueText={valuetext}
                step={4}
                marks={marks}
                valueLabelDisplay="auto"
              />
              <OutlinedInput
                style={outlinedInputStyle}
                margin="dense"
                value={symbolScale}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                onChange={handleInputChange}
                disabled={false}
                // onBlur={handleBlur}
                inputProps={outlinedInputProps}
              />
            </SliderContainer>
          </InputGroup>
        </FlexColumn>
      </FlexContainer>

      <Prompt message={ifShowMessageBeforeRedirect} />
    </>
  );
};

const tableOptions = {
  searchFieldAlignment: 'left',
  searchFieldStyle: { marginLeft: '-28px' },
};

const marks = [
  {
    value: 1,
    label: '1%',
  },
  {
    value: 100,
    label: '100%',
  },
  {
    value: 200,
    label: '200%',
  },
];

const outlinedInputProps = {
  min: 1,
  max: 200,
  type: 'number',
  'aria-labelledby': 'input-slider',
};

const outlinedInputStyle = {
  marginLeft: 25,
  position: 'relative',
  bottom: 30,
  width: 120,
} as const;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardTitle = styled.p`
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;

const SliderContainer = styled.div`
  display: flex;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
