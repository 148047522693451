import React from 'react';
import { ValidOptions } from './ValidOptionsCustomInput';
import { Table, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

interface ValidOptionsCustomComponentProps {
  validOptions: ValidOptions | undefined;
}

export const ValidOptionsCustomComponent: React.FC<ValidOptionsCustomComponentProps> = ({
  validOptions,
}) => {
  const classes = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          {validOptions?.enum.map((row) => (
            <TableRow key={row}>
              <TableCell>{row}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(validOptions?.minimum || validOptions?.maximum) && (
        <MinMaxContainer>
          {validOptions?.minimum && <Paragraph>{`Min: ${validOptions.maximum}`}</Paragraph>}
          {validOptions?.maximum && <Paragraph>{`Max: ${validOptions.maximum}`}</Paragraph>}
        </MinMaxContainer>
      )}
    </>
  );
};

const MinMaxContainer = styled.div`
  margin-top: 40px;
`;

const Paragraph = styled.p`
  margin-top: 12px;
  white-space: nowrap;
`;

const useStyles = makeStyles({
  table: {
    margin: -16,
    minWidth: 120,
  },
});
