import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { TextInput } from '../../../../../components/FormElements';
import styled from 'styled-components';
import { AttributeBasedFilterSimpleOptionType } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import PersonIcon from '@material-ui/icons/Person';
import ValueIcon from '@material-ui/icons/Visibility';

interface AttributeBasedTextInputProps {
  id: string;
  value: AttributeBasedFilterSimpleOptionType | undefined;
  onChange: (arrayOption: AttributeBasedFilterSimpleOptionType | undefined) => void;
  onBlur: () => void;
  keyType?: string | undefined;
  allowValue?: boolean;
}

export const AttributeBasedTextInput: React.FC<AttributeBasedTextInputProps> = ({
  id,
  value,
  onChange,
  onBlur,
  keyType,
  allowValue
}) => {
  const [tempValue, setTempValue] = useState(value === '$$_USER_$$' || value === '$$_VALUE_$$' ? '' : value);
  const isNumber = keyType === 'Int' || keyType === 'Float';

  const handleToggleUser = () => {
    if (value === '$$_USER_$$') {
      onChange(tempValue);
    } else {
      if(value !== '$$_VALUE_$$')
        setTempValue(value);
      onChange('$$_USER_$$');
    }
  };
  const handleToggleValue = () => {
    if (value === '$$_VALUE_$$') {
      onChange(tempValue);
    } else {
      if(value !== '$$_USER_$$')
        setTempValue(value);
      onChange('$$_VALUE_$$');
    }
  };

  return (
    <AttributeBasedTextInputContainer>
      {/* TODO: fix id  */}
      <TextInput
        id={id}
        value={value as string}
        onChange={onChange}
        onBlur={onBlur}
        noLabel
        style={{ margin: 0 }}
        type={isNumber ? 'number' : 'text'}
        disabled={value === '$$_SELF_$$' || value === '$$_USER_$$' || value === '$$_VALUE_$$'}
        isInt={keyType === 'Int'}
      />
      <IconButton
        title="User"
        style={{
          color: value === '$$_USER_$$' ? 'rgb(66, 165, 245)' : 'black',
          padding: 6,
          position: 'absolute',
          right: 4,
        }}
        component="span"
        onClick={handleToggleUser}
        onBlur={onBlur}
      >
        <PersonIcon />
      </IconButton>
      {allowValue && <IconButton
        title="Value"
        style={{
          color: value === '$$_VALUE_$$' ? 'rgb(66, 165, 245)' : 'black',
          padding: 6,
          position: 'absolute',
          right: 34,
        }}
        component="span"
        onClick={handleToggleValue}
        onBlur={onBlur}
      >
        <ValueIcon />
      </IconButton>}
    </AttributeBasedTextInputContainer>
  );
};

const AttributeBasedTextInputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
